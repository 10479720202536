/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import DialogUhda from "../DialogUhda";
import { CommonsTexts, LabelsForm, LabelsForms, LinksInfo, SnackBarResponses } from "../../locale/en";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import TextfieldUhda from "../TextfieldUhda/TextfieldUhda";
import TextfieldMultilineUhda from "../TextfieldMultilineUhda/TextfieldMultilineUhda";
import FormSwitchUhda from "../FormSwitchUhda";
import ErrorIcon from "@material-ui/icons/Error";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import {
  QUESTION_CALCULATION,
  QUESTION_CALENDAR,
  QUESTION_INFO,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_NUMERIC,
  QUESTION_RANGE,
  QUESTION_SINGLE_CHOICE,
  QUESTION_TEXT,
  questionTypes,
  questionTypesByName,
} from "../../resources/QuestionConstants";
import { Alert } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import SnackBarUhda from "../SnackBarUhda";
import { makeStyles } from "@material-ui/core/styles";
import { validateVarName } from "../../utils/HelperFunctions";
import { textFieldStyles } from "../TextfieldUhda/TextFieldStyle";
import MUIEditor, { MUIEditorState, toolbarControlTypes } from "react-mui-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useStyles = makeStyles({

  button: {
    "&:hover": {
      backgroundColor: "transparent",
    }, "& .MuiTouchRipple-root span": {
      backgroundColor: "transparent",
      opacity: .3,
    },
  }
})

/**
 * @component
 * Component to add a new question for the form
 *
 * @param {function} handleClickNext: handle to show the next modal
 * @param {object} initialValues: array of dictionaries of the initial values
 * @param {object} values: values that will change with the new initial values
 * @param {function} setValues: values that will change
 * @param {boolean} showAddQuestion: state to show or not the new question
 * @param {function} setShowAddQuestion: function to set if the add question will be showed
 * @param {string} formId: id of the form
 * @param {string} studyId: id of the study
 * @param {function} changeMandatory: function to change the values of the mandatory
 * @param {function} changeConditional: function to change the values of the conditional
 * @param {boolean} hasNextModal: value to know if the questionary have more questions
 * @param {function} setHasNextModal: state to change when will be next question.
 *
 * @constructor
 * <AddQuestionModal hasNextModal={hasNextModal} setHasNextModal={setHasNextModal} formId={formId} studyId={studyId} handleClickNext={handleClickNext}
 * initialValues={initialValues} values={values} setValues={setValues} showAddQuestion={showAddQuestion} setShowAddQuestion={setShowAddQuestion}
 * changeConditional={changeConditional} changeMandatory={changeMandatory}
 />
 */
export default function AddQuestionModal({
  handleClickNext,
  initialValues,
  values,
  setValues,
  showAddQuestion,
  setShowAddQuestion,
  formId,
  studyId,
  changeMandatory,
  hasNextModal,
  setHasNextModal,
  questionsLength,
  questionsSummaryLength,
  setLoading,
  getQuestions,
  formTitle,
  questions,
  getSections,
  getSummary,
  setShowSnackbarAdd, sections, sectionState,
  isSummary, existSummary
}) {
  const [showError, setShowError] = useState(false);
  const [showErrorVar, setShowErrorVar] = useState(false);
  const [showErrorVarName, setShowErrorVarName] = useState(false);
  const [showErrorVarSpace, setShowErrorVarSpace] = useState(false);
  const [showErrorMoreInfo, setShowErrorMoreInfo] = useState();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [conditionalQuestions, setConditionalQuestions] = useState([]);
  const [showConditionalError, setShowConditionalError] = useState();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState()
  const [openSnackDeleteConditional, setOpenSnackDeleteConditional] = useState()
  const [openSnackAddRepeat, setOpenSnackAddRepeat] = useState()
  const [openSnackAddSpace, setOpenSnackAddSpace] = useState()
  const [sectionId, setSectionId] = useState(sectionState)
  const [isSectionIdSelected, setisSectionIdSelected] = useState(false)
  const [conditionalOperator, setConditonalOperator] = useState(1)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [variableMap, setVariableMap] = useState(0)
  const classes = useStyles()

  const [editorState, setEditorState] = React.useState(
    MUIEditorState.createEmpty()
  );
  const [editorStateHelperText, setEditorStateHelperText] = React.useState(
    MUIEditorState.createEmpty()
  );
  const [config, setConfig] = useState({
    toolbar: {
      controls: [
        toolbarControlTypes.bold,
        toolbarControlTypes.italic,
        toolbarControlTypes.underline,
        toolbarControlTypes.textAlign,
        toolbarControlTypes.blockType,
      ],
    },
  })
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const handleChangeSectionId = async (e) => {
    setSectionId(e.target.value)

  };

  const handleChangeQuestionType = async (e) => {
    await setValues({
      ...values,
      ["questionType"]: questionTypes[e.target.value].name,
    });
    setisSectionIdSelected(true)
  };
  const handleChangeConditionalVariable = (e, index) => {
    var a = conditionalQuestions.find(x => x.id === e.target.value)
    var currentList = values["conditionalList"];
    currentList[index]["variable"] = e.target.value
    currentList[index]["type"] = a["type"]
    setValues({ ...values, ["conditionalList"]: currentList });
    setValues({ ...values, ["conditionalVariable"]: e.target.value });
  };
  const handleChangeConditionalAnswer = (e, index) => {
    //Buscar el indice y buscar el valor
    var currentList = values["conditionalList"];
    currentList[index]["answer"] = e.target.value
    setValues({ ...values, ["conditionalList"]: currentList });

    setValues({ ...values, ["conditionalAnswer"]: e.target.value });
  };

  const handleChangeConditionalAnswerText = (e, index) => {
    //Buscar el indice y buscar el valor
    var currentList = values["conditionalList"];
    currentList[index]["answer"] = e.target.value
    setValues({ ...values, ["conditionalList"]: currentList });

    setValues({ ...values, ["conditionalAnswer"]: e.target.value });
  };

  const handleChangeConditionalOperator = (e) => {
    setConditonalOperator(e.target.value)
  };
  const handleInputChange = async (e) => {

    if (showErrorVarName)
      setShowErrorVarName(false)
    if (showErrorVarSpace)
      setShowErrorVarSpace(false)
    const { name, value } = e.target;
    await setValues({ ...values, [name]: value });
  };

  const onChange = (newState) => {
    //This has to be done because the newState is an array and have to pass to HTML to post
    const rawContent = convertToRaw(newState.getCurrentContent())
    if (rawContent.blocks[0].type === "default") {
      rawContent.blocks[0].type = "unstyled"
    }
    //Change the key of textAlign to text-align
    var stateHtml = draftToHtml(rawContent)
    //Change the textAlign to text-align
    stateHtml = stateHtml.replace(/textAlign/g, "text-align")

    setValues({ ...values, ["questionTitle"]: stateHtml })

    setEditorState(newState);
  };

  const onChangeHelperText = (newStateHelperText) => {
    //This has to be done because the newState is an array and have to pass to HTML to post
    const rawContent = convertToRaw(newStateHelperText.getCurrentContent())
    if (rawContent.blocks[0].type === "default") {
      rawContent.blocks[0].type = "unstyled"
    }
    var stateHtml = draftToHtml(rawContent)
    stateHtml = stateHtml.replace(/textAlign/g, "text-align")

    setValues({ ...values, ["helperText"]: stateHtml })

    setEditorStateHelperText(newStateHelperText);
  };
  const handleOnConditionalChange = async () => {
    await setValues({
      ...values,
      ["hasConditional"]: !values["hasConditional"],
    });
  };
  const handleOnLongTextChange = async () => {
    await setValues({
      ...values,
      ["longText"]: !values["longText"],
    });
  };

  const handleOnSummaryChange = async () => {
    await setValues({
      ...values,
      ["summary"]: !values["summary"],
    });
  };
  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    checkHasNextModal();
  }, [values["questionType"], values["hasConditional"]]);

  useEffect(() => {
    setTimeout(function () { //Start the timer
      setIsLoadingDelete(false)
    }.bind(this), 1000)
  }, [values]);

  useEffect(() => {
    var questionList = [];
    for (var i = 0; i < questions.length; i++) {
      if (
        questions[i]["type"] === questionTypes[QUESTION_SINGLE_CHOICE].name ||
        questions[i]["type"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name ||
        questions[i]["type"] === questionTypes[QUESTION_RANGE].name || questions[i]["type"] === questionTypes[QUESTION_CALCULATION].name
      ) {
        questionList.push(questions[i]);
      }
    }
    setConditionalQuestions(questionList);

    setValues(initialValues);
    setValues({ ...values, ["form"]: formId });
  }, [showMoreInfo]);
  useEffect(() => {
    setValues(initialValues);
    setValues({ ...values, ["form"]: formId });
  }, [showAddQuestion]);

  function hasWhiteSpace(s) {
    return s.indexOf(" ") >= 0;
  }

  const handleClickSubmit = async () => {
    if (
      values["variableName"] === "" ||
      values["questionType"] === "" ||
      values["questionTitle"] === ""
    ) {
      setShowError(true);
    } else if (!validateVarName(values["variableName"])) {
      setShowErrorVar(true);
    }
    else {
      if (!hasNextModal) {
        try {
          const questionBody = {
            order: isSummary ? questionsSummaryLength + 1 : questionsLength + 1,//TODO MIRAR COMO HACER LO DEL ORDER EN AMBOS
            formId: formId,
            sectionId: isSummary ? null : sectionId,
            summary: values.summary,
            variableName: values.variableName,
            standarName: "",
            conditional: null,
            mandatory: values.mandatory,
            type: questionTypesByName[values.questionType].name,
            minValue: null,
            maxValue: null,
            units: "",
            interval: null,
            answerOptions: [],
            // eslint-disable-next-line camelcase
            longText: values.longText ? 1 : 0,
            questionTranslation: [
              {
                locale: "en",
                question: values.mandatory ? values.questionTitle.replace("</p>", "<span>*</span></p>").replace("</h1>", "<span>*</span></h1>").replace("</h2>", "<span>*</span></h2>").replace("</h3>", "<span>*</span></h3>").replace("</h4>", "<span>*</span></h4>").replace("</h5>", "<span>*</span></h5>").replace("</h6>", "<span>*</span></h6>") : values.questionTitle.replace("</br></p>", "</p>").replace("<span>*</span>", ""),
                subtitle: "",
                info: values.helperText.replace("</br></p>", "</p>"),
              },
            ],
          };
          setLoading(true);
          const response = await MyAxiosInstance.post(
            `${BASE_PATH}/question`,
            questionBody
          );
          if (isSummary) {
            getSummary();
          } else {
            getSections();
          }
          //setShowAddQuestion(-999);
          setShowSnackbarAdd(true)
        } catch (e) {
          setLoading(true);
          if (hasWhiteSpace(values["variableName"])) {
            if (showMoreInfo) setShowMoreInfo(false)
            setShowErrorVarSpace(true)
          } else {
            if (showMoreInfo) setShowMoreInfo(false)
            setShowErrorVarName(true)
          }

        }
      } else {
        setShowMoreInfo(true);
        setShowErrorVar(false)
      }
    }
  };

  function validateCalculation(calculation) {
    // eslint-disable-next-line no-useless-escape
    const re = /([-+]?[\w]*?[\w]+[\s]+[\/\+\-\*])+([\s]+[-+]?[\w]*?[\w]+)/g;
    return re.test(String(calculation).toLowerCase());
  }

  const handleSubmitQuestion = async () => {
    var operator = conditionalOperator ? " AND " : " OR "

    var answerOptionsList = [];
    var conditionalString = ""
    for (var i = 0; i < values["answersList"].length; i++) {
      answerOptionsList.push({
        answerTitle: values["answersList"][i].answer,
        answerValue: values["answersList"][i].value,
        answerTranslations: [
          {
            locale: "en",
            text: values["answersList"][i].answer,
          },
        ],
      });
    }
    try {
      if (values.hasConditional) {
        for (var j = 0; j < values["conditionalList"].length; j++) {
          if (values["conditionalList"][j].type !== "RANGE" && values["conditionalList"][j].type !== "CALCULATION") {
            var a = conditionalQuestions.findIndex(x => x.id === values["conditionalList"][j].variable)
            var b = conditionalQuestions[a].answer_values.findIndex(x => x.id === values["conditionalList"][j].answer)
            //conditionalString = conditionalString.concat(conditionalQuestions[a].variable_name + "=" + conditionalQuestions[a].answer_values[b].value,operator);
            conditionalString += (conditionalQuestions[a].variable_name + "=" + conditionalQuestions[a].answer_values[b].value)
            if (j + 1 !== values["conditionalList"].length) {
              conditionalString += operator
            }
          } else {
            var c = conditionalQuestions.findIndex(x => x.id === values["conditionalList"][j].variable)
            conditionalString += (conditionalQuestions[c].variable_name + "=" + values["conditionalList"][j].answer)
            if (j + 1 !== values["conditionalList"].length) {
              conditionalString += operator
            }
          }

        }
      }
      const questionBody = {
        order: questionsLength + 1,
        formId: formId,
        sectionId: sectionId,
        summary: values.summary,
        variableName: values.variableName,
        standarName: "",
        conditional: values.hasConditional ? conditionalString : null,
        mandatory: values.mandatory,
        calculation: values.calculation,
        type: values.questionType,
        longText: values.longText ? 1 : 0, //TODO Look naming on the backend
        minValue:
          values.questionType === questionTypes[QUESTION_NUMERIC].name ||
            values.questionType === questionTypes[QUESTION_RANGE].name
            ? values.minValue
            : null,
        maxValue:
          values.questionType === questionTypes[QUESTION_NUMERIC].name ||
            values.questionType === questionTypes[QUESTION_RANGE].name
            ? values.maxValue
            : null,
        units:
          values.questionType === questionTypes[QUESTION_NUMERIC].name
            ? values.units
            : "",
        interval:
          values.questionType === questionTypes[QUESTION_RANGE].name
            ? values.interval
            : null,
        answerOptions: answerOptionsList,
        questionTranslation: [
          {
            locale: "en",
            question: values.mandatory ? values.questionTitle.replace("</p>", "<span>*</span></p>").replace("</h1>", "<span>*</span></h1>").replace("</h2>", "<span>*</span></h2>").replace("</h3>", "<span>*</span></h3>").replace("</h4>", "<span>*</span></h4>").replace("</h5>", "<span>*</span></h5>").replace("</h6>", "<span>*</span></h6>") : values.questionTitle.replace("</br></p>", "</p>").replace("<span>*</span>", ""),
            subtitle: "",
            info: values.helperText.replace("</br></p>", "</p>"),
          },
        ],

      };
      setLoading(true);
      const response = await MyAxiosInstance.post(
        `${BASE_PATH}/question`,
        questionBody
      );
      if (isSummary) {
        getSummary();
      } else {
        getSections();
      }
      setShowSnackbarAdd(true)
      //setShowAddQuestion(-999);
    } catch (e) {
      setLoading(true);
      if (hasWhiteSpace(values["variableName"])) {
        if (showMoreInfo) setShowMoreInfo(false)
        setShowErrorVarSpace(true)
      } else {
        if (showMoreInfo) setShowMoreInfo(false)
        setShowErrorVarName(true)
      }

    }
  };
  const handleAddAnswer = () => {
    var currentList = values["answersList"];
    if (
      currentList[currentList.length - 1]["answer"] !== "" &&
      currentList[currentList.length - 1]["value"] !== "" &&
      currentList[0]["answer"] !== "" &&
      currentList[0]["value"] !== ""
    ) {
      currentList.push({ answer: "", value: "" });
      setValues({ ...values, ["answersList"]: currentList });
      setShowErrorMoreInfo(false)
    } else {
      setShowErrorMoreInfo("Make sure all fields have been filled. The 'value' field must be a number.");
    }
  };

  const handleAddCondition = () => {
    var currentList = values["conditionalList"];
    if (
      currentList[currentList.length - 1]["variable"] !== "" &&
      currentList[currentList.length - 1]["answer"] !== "" &&
      currentList[0]["variable"] !== "" &&
      currentList[0]["answer"] !== ""
    ) {
      setVariableMap(variableMap + 1)
      currentList.push({ variable: "", answer: "", type: "" });
      setValues({ ...values, ["conditionalList"]: currentList });
      setShowErrorMoreInfo(false)
    } else {
      setShowErrorMoreInfo("Make sure you fill all the conditional.");
    }
  };
  const handleCloseDeleteLess = () => {
    setOpenSnackDelete(false);
  };
  const handleCloseDeleteConditional = () => {
    setOpenSnackDeleteConditional(false);
  };

  const handleCloseAddRepeat = () => {
    setOpenSnackAddRepeat(false);
  };

  const handleCloseAddSpace = () => {
    setOpenSnackAddSpace(false);
  };

  const handleDeleteAnswer = (index, e) => {
    var currentList = values["answersList"];
    if (values["answersList"].length <= 1) {
      setOpenSnackDelete(true)
    } else {
      setIsLoadingDelete(true)
      currentList.splice(index, 1)
      setValues({ ...values, ["answersList"]: currentList });
    }
  }
  const handleDeleteConditional = (index, e) => {
    var currentList = values["conditionalList"];
    if (values["conditionalList"].length <= 1) {
      setOpenSnackDeleteConditional(true)
    } else {
      setVariableMap(variableMap - 1)
      setIsLoadingDelete(true)
      currentList.splice(index, 1)
      setValues({ ...values, ["conditionalList"]: currentList });
    }
  }

  const checkHasNextModal = () => {
    switch (true) {
      case values["questionType"] === questionTypes[QUESTION_INFO].name &&
        values["hasConditional"] === true:
        setHasNextModal(true);
        break;
      case values["questionType"] === questionTypes[QUESTION_TEXT].name &&
        values["hasConditional"] === true:
        setHasNextModal(true);
        break;
      case values["questionType"] === questionTypes[QUESTION_CALENDAR].name &&
        values["hasConditional"] === true:
        setHasNextModal(true);
        break;
      case values["questionType"] === questionTypes[QUESTION_NUMERIC].name:
        setHasNextModal(true);
        break;
      case values["questionType"] === questionTypes[QUESTION_RANGE].name:
        setHasNextModal(true);
        break;
      case values["questionType"] ===
        questionTypes[QUESTION_SINGLE_CHOICE].name ||
        values["questionType"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name:
        setHasNextModal(true);
        break;
      case values["questionType"] === questionTypes[QUESTION_CALCULATION].name:
        setHasNextModal(true);
        break;
      default:
        setHasNextModal(false);
        break;
    }
  };

  const handleClickSubmitMoreInfo = async () => {
    if (values.hasConditional) {
      if (
        values["conditionalVariable"] === "" ||
        values["conditionalAnswer"] === ""
      ) {
        return setShowConditionalError("Please select a conditional answer");
      }
    }
    if (
      values["questionType"] === questionTypes[QUESTION_SINGLE_CHOICE].name ||
      values["questionType"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name
    ) {
      var currentList = values["answersList"];
      if (
        currentList[currentList.length - 1]["answer"] !== "" &&
        currentList[currentList.length - 1]["value"] !== "" &&
        currentList[0]["answer"] !== "" &&
        currentList[0]["value"] !== ""
      ) {
        return handleSubmitQuestion();
      } else {
        return setShowErrorMoreInfo("Make sure all fields have been filled. The 'value' field must be a number.");
      }
    } else if (values["questionType"] === questionTypes[QUESTION_RANGE].name) {
      if (
        values["minValue"] !== "" &&
        values["maxValue"] !== "" &&
        values["interval"] !== ""
      ) {
        if (parseInt(values["minValue"]) > parseInt(values["maxValue"])) {
          return setShowErrorMoreInfo("Make sure the values are correct");
        } else if (
          parseInt(values["interval"]) <= 0 ||
          values["interval"] > values["maxValue"] - values["minValue"]
        ) {
          return setShowErrorMoreInfo(
            "Make sure the interval value is correct"
          );
        }
        return handleSubmitQuestion();
      } else {
        return setShowErrorMoreInfo("Make sure all fields have been filled. The 'value' field must be a number.");
      }
    } else if (
      values["questionType"] === questionTypes[QUESTION_NUMERIC].name
    ) {
      if (
        values["minValue"] !== "" &&
        values["maxValue"] !== ""
      ) {
        if (parseInt(values["minValue"]) > parseInt(values["maxValue"])) {
          return setShowErrorMoreInfo("Make sure the values are correct");
        } else {
          return handleSubmitQuestion();
        }
      } else {
        return setShowErrorMoreInfo("Make sure all fields have been filled. The 'value' field must be a number.");
      }
    } else if (
      values["questionType"] === questionTypes[QUESTION_CALCULATION].name
    ) {
      if (!validateCalculation(values["calculation"])) {
        return setShowErrorMoreInfo("The formula seems to have a bad format, please review the formula and try again");
      } else {
        return handleSubmitQuestion();
      }
    }
    handleSubmitQuestion();
  };
  const inputProps = {
    step: 300,
  };
  const handleChangeAnswerOptions = (index, e) => {
    const { name, value } = e.target;

    var currentList = values["answersList"];
    if (name === "answer") {
      currentList[index]["answer"] = value;
    } else {
      currentList[index]["value"] = value;
    }

    setValues({ ...values, ["answersList"]: currentList });
  };

  return (
    <DialogUhda
      buttonColor={AppColors.PRIMARY}
      handleModalClose={() => {
        if (showMoreInfo) {
          setShowMoreInfo(false)
        } else {
          setShowAddQuestion(-999);
          setValues(initialValues)
        }
      }}
      size={true}
      textCancelButton={showMoreInfo ? "BACK" : CommonsTexts.CANCEL}
      textConfirmButton={
        hasNextModal
          ? showMoreInfo
            ? CommonsTexts.SAVE
            : CommonsTexts.NEXT
          : CommonsTexts.SAVE
      }
      handleCloseButton={() => {
        setLoading(false)
        setShowAddQuestion(-999);
        setValues(initialValues)

      }}
      closeButton={true}
      cancelButton={!showMoreInfo}
      handleShow={setShowAddQuestion}
      handleConfirm={
        showMoreInfo ? handleClickSubmitMoreInfo : handleClickSubmit
      }
      values={values}
      setValues={setValues}
      // title={TableTexts.ADD_TEAM_MEMBER_TITLE}
      title={"Add a question"}
      body={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          {showMoreInfo ? (
            <>
              <Grid
                container
                xs={12}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormControl margin="normal" style={{ width: "45%" }}>
                    <TextfieldUhda
                      isDisabled={true}
                      name="form"
                      label={"Form"}
                      value={formTitle}
                    />
                  </FormControl>
                  <FormControl margin="normal" style={{ width: "45%" }}>
                    <TextfieldUhda
                      isDisabled={true}
                      name="variableName"
                      label={"Variable name"}
                      value={values.variableName}
                    />
                  </FormControl>
                </Grid>

                {values.hasConditional && (
                  <FormControl margin="normal" style={{ width: "100%" }}>

                    <Grid
                      item
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography
                        style={{
                          color: AppColors.PRIMARY,
                          display: "inline",
                          cursor: "pointer",
                        }}
                      >
                        Condition{" "}
                      </Typography>
                      <IconButton style={{ color: AppColors.PRIMARY }}
                        onClick={handleClick}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                      <Popover
                        id={idPopover}
                        open={openPopover}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Grid container
                          style={{ width: "700px", height: "120px", margin: "1em" }}>
                          <Typography
                            style={{ color: AppColors.PRIMARY }}>{LabelsForms.CONDITIONAL_POPOVER}</Typography>
                        </Grid>
                      </Popover>
                    </Grid>
                  </FormControl>
                )}
                {isLoadingDelete ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress />
                  </div>) :
                  (<>{values.hasConditional && (
                    <>
                      {values["conditionalList"].map((variable, index) => (
                        <Grid
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          {index >= 1 && <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <FormControl
                              margin="normal"
                              style={{ width: "20%" }}
                              variant="outlined"
                            >
                              <InputLabel id="label"
                                style={{ paddingTop: 0, marginTop: "-5px" }}
                              >Conditional</InputLabel>
                              <Select
                                labelId="label"
                                label="Operator"
                                id="select"
                                disabled={index >= 2}
                                value={conditionalOperator}
                                onChange={handleChangeConditionalOperator}
                              >
                                <MenuItem value={1}>
                                  AND
                                </MenuItem>
                                <MenuItem value={0}>
                                  OR
                                </MenuItem>
                                )
                                )

                              </Select>
                            </FormControl>
                          </Grid>}
                          <FormControl
                            margin="normal"
                            style={{ width: "35%" }}
                            variant="outlined"
                          >
                            <InputLabel id="label"
                              style={{ paddingTop: 0, marginTop: "-5px" }}
                            >Variable</InputLabel>
                            <Select
                              labelId="label"
                              label="Variable"
                              id="select"
                              value={values["conditionalList"][index].variable}
                              onChange={(e) =>
                                handleChangeConditionalVariable(e, index)
                              }
                            >
                              {conditionalQuestions.map((question, a) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <MenuItem key={a} value={question["id"]}>
                                  {question["variable_name"]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Grid item style={{ width: "0" }}>=</Grid>
                          {(values["conditionalList"][index].type !== "CALCULATION" &&
                            values["conditionalList"][index].type !== "RANGE") ?
                            <FormControl
                              margin="normal"
                              style={{ width: "35%" }}
                              variant="outlined"
                            >
                              <InputLabel id="label"
                                style={{ paddingTop: 0, marginTop: "-5px" }}
                              >Answer</InputLabel>

                              <Select
                                labelId="label"
                                label="Answer"
                                id="select"
                                value={variable.answer}
                                onChange={(e) =>
                                  handleChangeConditionalAnswer(e, index)
                                }>
                                {values["conditionalVariable"] !== "" && index === values["conditionalList"].length - 1 ? (
                                  (conditionalQuestions
                                    .find((x) => x.id === values["conditionalList"][variableMap].variable) ?
                                    conditionalQuestions
                                      .find((x) => x.id === values["conditionalList"][variableMap].variable)
                                    ["answer_values"].map((question) => (
                                      // eslint-disable-next-line react/jsx-key
                                      <MenuItem value={question["id"]}>
                                        {question["translations"][0]["text"]}
                                      </MenuItem>
                                    )) : <MenuItem value={0}>-</MenuItem>
                                  ) //TODO Revisar porque siempre sale el primero y no deberia, se que esta puesto el 0
                                ) : (
                                  values["conditionalVariable"] !== "" ? (
                                    conditionalQuestions
                                      .find((x) => x.id === values["conditionalList"][index].variable)
                                    ["answer_values"].map((question) => (
                                      // eslint-disable-next-line react/jsx-key
                                      <MenuItem value={question["id"]}>
                                        {question["translations"][0]["text"]}
                                      </MenuItem>
                                    ))
                                  ) : <MenuItem value={0}>-</MenuItem>
                                )
                                }
                              </Select>

                            </FormControl> :
                            <FormControl margin="normal" style={{ width: "35%" }}>
                              <TextfieldUhda
                                label="Answer"
                                handleChange={(e) => handleChangeConditionalAnswerText(e, index)}
                                value={values.calculation}
                                isNumeric={false}
                              />

                            </FormControl>
                          }
                          {values["conditionalList"].length > 1 && <Button className={classes.button} style={{
                            marginLeft: "-9em",
                            marginRight: "-1em",
                            marginTop: "0.5em"
                          }}
                            onClick={(e) => handleDeleteConditional(index, e)}>
                            <DeleteIcon />
                          </Button>}

                        </Grid>

                      ))
                      }
                      <Typography
                        onClick={handleAddCondition}
                        style={{
                          font: "Roboto",
                          fontWeight: "bold",
                          color: AppColors.PRIMARY,
                          display: "inline",
                          marginRight: "20px",
                          cursor: "pointer",
                        }}
                      >
                        + Add condition
                      </Typography>
                    </>

                  )}</>)}
                {showConditionalError && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setShowConditionalError(false);
                    }}
                    style={{ marginBottom: "30px" }}
                  >
                    {showConditionalError}
                  </Alert>
                )}
                {values["questionType"] ===
                  questionTypes[QUESTION_NUMERIC].name && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="minValue"
                          label={"Min. Value"}
                          handleChange={handleInputChange}
                          value={values.minValue}
                          isNumeric={true}
                        />
                      </FormControl>
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="maxValue"
                          label={"Max. Value"}
                          handleChange={handleInputChange}
                          value={values.maxValue}
                          isNumeric={true}
                        />
                      </FormControl>
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="units"
                          label={"Units"}
                          handleChange={handleInputChange}
                          value={values.units}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {values["questionType"] ===
                  questionTypes[QUESTION_RANGE].name && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="minValue"
                          label={"Min. Value"}
                          handleChange={handleInputChange}
                          value={values.minValue}
                          isNumeric={true}
                        />
                      </FormControl>
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="maxValue"
                          label={"Max. Value"}
                          handleChange={handleInputChange}
                          value={values.maxValue}
                          isNumeric={true}
                        />
                      </FormControl>
                      <FormControl margin="normal" style={{ width: "30%" }}>
                        <TextfieldUhda
                          name="interval"
                          label={"Interval"}
                          handleChange={handleInputChange}
                          value={values.interval}
                          isNumeric={true}
                        />
                      </FormControl>
                    </Grid>
                  )}
                {values["questionType"] ===
                  questionTypes[QUESTION_CALCULATION].name && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl margin="normal" style={{ width: "100%" }}>
                        <TextfieldUhda
                          name="calculation"
                          label={"Calculation"}
                          handleChange={handleInputChange}
                          value={values.calculation}
                          isNumeric={false}
                        />
                      </FormControl>
                      <Typography
                        style={{
                          display: "inline",
                          cursor: "pointer",
                        }}
                      >
                        {LabelsForms.CALCULATION_INFO}
                      </Typography>
                    </Grid>
                  )}

                {(values["questionType"] ===
                  questionTypes[QUESTION_SINGLE_CHOICE].name ||
                  values["questionType"] ===
                  questionTypes[QUESTION_MULTIPLE_CHOICE].name) && (
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl margin="normal" style={{ width: "100%" }}>
                        <Grid
                          item
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            onClick={handleAddAnswer}
                            style={{
                              display: "inline",
                              marginRight: "20px",
                              cursor: "pointer",
                            }}
                          >
                            Answer options{" "}
                          </Typography>
                          <Typography
                            onClick={handleAddAnswer}
                            variant="h6"
                            style={{ display: "inline", cursor: "pointer" }}
                          >
                            {" "}
                            +
                          </Typography>
                        </Grid>
                      </FormControl>
                      {isLoadingDelete ? (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                          <CircularProgress />
                        </div>) :
                        (<>{values["answersList"].map((answer, index) => (
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <FormControl margin="normal" style={{ width: "40%" }}>
                              <TextfieldUhda
                                name={"answer"}
                                label={`Answer ${index + 1}`}
                                handleChange={(e) =>
                                  handleChangeAnswerOptions(index, e)
                                }
                                value={answer.answer}
                              />
                            </FormControl>
                            <FormControl margin="normal" style={{ width: "40%" }}>
                              <TextfieldUhda
                                name={"value"}
                                label={`Value ${index + 1}`}
                                handleChange={(e) =>
                                  handleChangeAnswerOptions(index, e)
                                }
                                value={answer.value}
                                isNumeric={true}
                                shrink={true}
                              />
                            </FormControl>
                            <Button style={{
                              marginLeft: "-9em",
                              marginRight: "-1em",
                              marginTop: "0.5em"
                            }}
                              onClick={(e) => handleDeleteAnswer(index, e)}>
                              <DeleteIcon />
                            </Button>
                          </Grid>
                        ))
                        }</>)}
                    </Grid>
                  )}
                {showErrorMoreInfo && (
                  <Alert
                    severity="error"
                    onClose={() => {
                      setShowErrorMoreInfo();
                    }}
                    style={{ marginBottom: "30px" }}
                  >
                    {showErrorMoreInfo}
                  </Alert>
                )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                xs={5}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <FormControl margin="normal" style={{ width: "100%" }}>
                  <TextfieldUhda
                    isDisabled={true}
                    name="form"
                    handleChange={handleInputChange}
                    label={"Form"}
                    value={formTitle}
                  />
                </FormControl>
                <FormControl margin="normal" style={{ width: "100%" }}>
                  <TextfieldUhda
                    isDisabled={true}
                    name="order"
                    handleChange={handleInputChange}
                    label={"Order"}
                    value={isSummary ? questionsSummaryLength + 1 : questionsLength + 1}
                  />
                </FormControl>
                {/* <FormControl
                  margin="normal"
                  style={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel id="label"
                    style={{ paddingTop: 0, marginTop: "-5px" }}
                  >Section</InputLabel>
                  <Select
                    labelId="label"
                    label="Section"
                    id="select"
                    value={sectionId}
                    onChange={handleChangeSectionId}
                    error={sectionId === "" && showError}
                  >
                    {sections.map((section) => (
                      <MenuItem
                        value={section.id}>{section.section_translations[0].title}</MenuItem>

                    ))}

                  </Select>

                </FormControl> */}
                <FormControl margin="normal" style={{ width: "100%" }}>
                  <TextfieldUhda
                    name="variableName"
                    handleChange={handleInputChange}
                    label={"Variable name"}
                    value={values.variableName}
                    maxLength={30}
                    error={(values["variableName"] === "" && showError) || showErrorVarName || showErrorVarSpace || showErrorVar}
                    helperText={
                      (showError &&
                        values["variableName"] === "" &&
                        "Make sure to fill the variable name") ||
                      (showErrorVarName && "This variable name is already being used in this form. Please, choose a different one") ||
                      (showErrorVarSpace && "Variable names do not support spaces. Please, write it again with no spaces. You may use symbols like - and _ to make the variable name more legible") ||
                      (showErrorVar && "Variable name only supports alphanumeric characters")
                    }
                    inputProps={{
                      endAdornment: ((showError && values["variableName"] === "") || (showErrorVarName) || (showErrorVarSpace) || (showErrorVar)) && (
                        <InputAdornment position="end">
                          <ErrorIcon style={{ color: AppColors.RED }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  margin="normal"
                  style={{ width: "100%" }}
                  variant="outlined"
                >
                  <InputLabel id="label"
                  >Question type</InputLabel>
                  <Select
                    labelId="label"
                    label="Question type"
                    id="select"
                    value={questionTypesByName[values.questionType].id}
                    onChange={handleChangeQuestionType}
                    error={values["questionType"] === "" && showError}
                  >
                    <MenuItem value={QUESTION_INFO}>Info</MenuItem>
                    <MenuItem value={QUESTION_TEXT}>Text</MenuItem>
                    <MenuItem value={QUESTION_CALENDAR}>Date</MenuItem>
                    <MenuItem value={QUESTION_NUMERIC}>Numerical</MenuItem>
                    <MenuItem value={QUESTION_RANGE}>Scale</MenuItem>
                    <MenuItem value={QUESTION_SINGLE_CHOICE}>
                      Single select
                    </MenuItem>
                    <MenuItem value={QUESTION_MULTIPLE_CHOICE}>
                      Multiple select
                    </MenuItem>
                    <MenuItem value={QUESTION_CALCULATION}>
                      Calculation
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  margin="normal"
                  style={{ width: "100%", alignItems: "flex-start" }}
                >
                  <a style={{ fontSize: "14px", color: AppColors.ACCENT }}
                    href={LinksInfo.QUESTION_TYPE}
                    target="_blank" rel="noreferrer">{CommonsTexts.LEARN_MORE}</a>

                </FormControl>
                {values.questionType == questionTypes[QUESTION_TEXT].name && (
                  <FormControl
                    style={{ width: "100%", alignItems: "flex-start" }}
                    margin="normal"
                  >
                    <FormSwitchUhda
                      marginLeft={0}
                      label="Long text"
                      value={values.longText}
                      handleChange={handleOnLongTextChange}
                    />
                  </FormControl>
                )}
                {values.questionType !== questionTypes[QUESTION_INFO].name && (
                  <FormControl
                    margin="normal"
                    style={{ width: "100%", alignItems: "flex-start" }}
                  >
                    <FormSwitchUhda
                      label="Mandatory"
                      value={values.mandatory}
                      handleChange={changeMandatory}
                    />
                  </FormControl>
                )}
                {(isSummary ? questionsSummaryLength >= 1 : questionsLength >= 1) && <FormControl
                  margin="normal"
                  style={{ width: "100%", alignItems: "flex-start" }}
                >
                  <FormSwitchUhda
                    label="Conditional"
                    value={values.hasConditional}
                    handleChange={handleOnConditionalChange}
                  />
                </FormControl>}
                {existSummary && <FormControl
                  margin="normal"
                  style={{ width: "100%", alignItems: "flex-start" }}
                >
                  <FormSwitchUhda
                    label="Include in summary"
                    value={values.summary}
                    handleChange={handleOnSummaryChange}
                  />
                </FormControl>}
              </Grid>
              <Grid
                item
                xs={6}
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                {/*/TO DO: Unncomment this because is the new editor for the question title*/}
                <FormControl margin="normal" style={{ width: "100%" }}>
                  <Typography>Question title</Typography>
                  <MUIEditor editorState={editorState} onChange={onChange} config={config} />
                </FormControl>
                <FormHelperText error={values["questionTitle"] === "" && showError} hidden={!(values["questionTitle"] === "" && showError)} classes={{ root: classes.root, error: classes.error }}>
                  <Grid container direction="row" >
                    <Typography>{LabelsForm.ERROR_QUESTION_TITLE}</Typography>
                    <InputAdornment >
                      <ErrorIcon style={{ marginTop: "1em", color: AppColors.RED }} />
                    </InputAdornment>
                  </Grid>
                </FormHelperText>

                {/* <FormControl margin="normal" style={{ width: "100%" }}>
                  <TextfieldMultilineUhda
                    className={values.questionTitle.length === 0 ? fieldClasses.textFieldLabel : fieldClasses.textFieldLabelWithText}

                    name="questionTitle"
                    handleChange={handleInputChange}
                    label={"Question title"}
                    value={values.questionTitle}
                    maxLength={550}
                    error={values["questionTitle"] === "" && showError}
                    helperText={
                      showError &&
                      values["questionTitle"] === "" &&
                      "Make sure to fill the title"
                    }
                    inputProps={{
                      endAdornment: showError &&
                        values["questionTitle"] === "" && (
                          <InputAdornment position="end">
                            <ErrorIcon style={{ color: AppColors.RED }} />
                          </InputAdornment>
                        ),
                    }}
                  />
                </FormControl> */}

                <FormControl margin="normal" style={{ width: "100%" }}>
                  <Typography>Helper text</Typography>
                  <MUIEditor value={values.helperText} editorState={editorStateHelperText} onChange={onChangeHelperText} config={config} />
                </FormControl>

                {/*   <FormControl margin="normal" style={{ width: "100%" }}>
                  <TextfieldMultilineUhda

                    name="helperText"
                    handleChange={handleInputChange}
                    label={"Helper text"}
                    value={values.helperText}
                    maxLength={550}
                  />
                </FormControl> */}
              </Grid>
            </>
          )}
          <SnackBarUhda handleClose={handleCloseAddRepeat} severity={"error"}
            message={SnackBarResponses.REPEATED_VARNAME}
            openSnack={openSnackAddRepeat} />
          <SnackBarUhda handleClose={handleCloseAddSpace} severity={"error"}
            message={SnackBarResponses.SPACE_VARNAME}
            openSnack={openSnackAddSpace} />
          <SnackBarUhda handleClose={handleCloseDeleteLess} severity={"error"}
            message={SnackBarResponses.ANSWER_OPTION}
            openSnack={openSnackDelete} />
          <SnackBarUhda handleClose={handleCloseDeleteConditional} severity={"error"}
            message={SnackBarResponses.CONDITIONAL_OPTION}
            openSnack={openSnackDeleteConditional} />
        </Grid >
      }
      show={showAddQuestion}
    />
  );
}
AddQuestionModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleClickNext: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
  changeMandatory: PropTypes.func.isRequired,
  hasNextModal: PropTypes.bool.isRequired,
  setHasNextModal: PropTypes.func.isRequired,
  questionsLength: PropTypes.number.isRequired,
};
