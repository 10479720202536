export const formTypesEnum = {
  CREATE: "CREATE",
  EDIT: "EDIT"
}

export const formTypes = {
  CREATE: {
    title: "Create a new",
    close: "Close",
    submit: "Create"
  },
  EDIT: {
    title: "Edit",
    close: "Close",
    submit: "Save"
  },
};
