import React from "react";
import PropTypes from "prop-types"
import { Checkbox, IconButton, TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import { AppColors } from "../../resources/AppColors"
import AddIcon from "@material-ui/icons/Add"

/**
 * @component
 * Component that shows the cells with their respective labels
 */
export default function TableHeadUhda(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    handleOpenModalNew,
    headCells,
    interactive,
    addable,
    canDelete,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead style={{ backgroundColor: AppColors.PRIMARY_WITH_OPACITY }}>
      <TableRow>
        {canDelete && <TableCell padding='checkbox'>
          {interactive && <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />}
        </TableCell>}
        {headCells.map((headCell) => (
          <TableCell style={{ color: AppColors.PRIMARY, fontWeight: "bold" }} key={headCell.id}
            align={headCell.disablePadding ? "right" : "left"} padding={"default"}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.isSortable ?
              <TableSortLabel style={{ color: AppColors.PRIMARY }} active={true}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel> :
              <div>
                {
                  headCell.label === "ACTIONS" ? addable &&
                    <IconButton onClick={(handleOpenModalNew)} style={{
                      backgroundColor: AppColors.PRIMARY,
                      color: AppColors.WHITE,
                      padding: "0.3em"
                    }}><AddIcon /></IconButton>
                    : headCell.label
                }
              </div>
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
TableHeadUhda.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  handleOpenModalNew: PropTypes.func.isRequired,
  headCells: PropTypes.object.isRequired
};
