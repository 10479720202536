/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, IconButton, Tab, Tabs } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import { SnackBarResponses, TableTexts } from "../../locale/en";
import DragAndDropUhda from "../DragAndDropUhda";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import SnackBarUhda from "../SnackBarUhda";
import CheckBoxUhda from "../CheckBoxUhda/CheckBoxUhda";
import EditSectionModal from "../QuestionModals/EditSectionModal";
import EditIcon from "@material-ui/icons/Edit";

const headCells = [
  { id: "", label: "" },
  /* { id: "Id", label: TableTexts.ORDER }, */
  { id: "code", label: TableTexts.CODE },
  { id: "questionType", label: TableTexts.QUESTION_TYPE },
  { id: "questionTitle", label: TableTexts.QUESTION_TITLE },
  { id: "mandatory", label: TableTexts.MANDATORY },
  { id: "conditional", label: TableTexts.CONDITIONAL },
  /*  { id: "summary", label: TableTexts.SUMMARY }, */
];

const TabsUhda = props => {
  const {
    loading,
    handleClickEdit,
    getQuestions,
    showEditQuestion,
    setShowDeleteQuestion,
    setShowDuplicateQuestion,
    setLoading,
    getSections,
    formId,
    studyId,
    sections,
    canEdit,
    canDelete,
    sectionState,
    setSectionState,
    stateSelect,
    setSelectQuestions,
    setShowDeleteModalConfirm
  } = props;
  const [sectionValue, setSectionValue] = React.useState(!sectionState ? 0 : sections.map(object => object.id).indexOf(sectionState));
  const [sectionEdit, setSectionEdit] = useState(0);
  const [showEditSection, setShowEditSection] = useState(-999);
  const [showSnackbarEditSection, setShowSnackbarEditSection] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [tabs, setTabs] = useState(sections)
  const [sectionTitle, setSectionTitle] = useState()
  // eslint-disable-next-line no-unused-vars
  const handleChangeSectionValue = (event, newValue) => {
    setSectionValue(newValue);
  };
  const handleEditSection = (sectionId) => {
    if (canEdit) {
      setShowEditSection(sectionId);
      setSectionTitle(sections.find(x => x.id === sectionId).section_translations[0].title)
      setSectionEdit(sections.find(x => x.id === sectionId))
    } else {
      // setOpenSnackAdd(true);
    }
  }

  const handleCloseEditSection = () => {
    setShowSnackbarEditSection(false);
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newTabs = Array.from(tabs);
    const [removed] = newTabs.splice(result.source.index, 1);
    newTabs.splice(result.destination.index, 0, removed);
    setTabs(newTabs);
    var sectionsBody = { "sections": [] }
    for (var i = 0; i < newTabs.length; i++) {
      sectionsBody.sections.push(
        {
          "id": newTabs[i].id,
          "order": i + 1
        }
      )
    }
    try {
      setSectionValue(result.destination.index)
      setLoading(true)
      const response = await MyAxiosInstance.put(`${BASE_PATH}/form/${formId}/sections`, sectionsBody)
      if (response.status === 200) {
        getSections()
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    return newTabs

  };
  useEffect(() => {
    getSections()
    getQuestions()
  }, [showEditSection, showEditQuestion]);

  useEffect(() => {
    setSelectQuestions(selectedQuestions)
  }, [selectedQuestions]);
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>

      {
        tabs.map(
          (section, index) => (
            // eslint-disable-next-line react/jsx-key
            <div

              role="tabpanel"
              hidden={sectionValue !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
            >
              {sectionValue === index && (
                <Box sx={{ p: 3 }} style={{ paddingLeft: 0, width: stateSelect ? "73.2em" : window.innerWidth - 400 }}>
                  {!loading && !stateSelect &&
                    <DragAndDropUhda handleClickEdit={handleClickEdit} getQuestions={getQuestions}
                      formId={formId} setShowDeleteQuestion={setShowDeleteQuestion} setShowDuplicateQuestion={setShowDuplicateQuestion}
                      setLoading={setLoading} getSections={getSections}
                      loading={loading} canEdit={canEdit} canDelete={canDelete}
                      headCells={headCells}
                      userData={section.questions} />}
                  {!loading && stateSelect &&
                    <CheckBoxUhda handleClickEdit={handleClickEdit} getQuestions={getQuestions}
                      formId={formId} setShowDeleteQuestion={setShowDeleteQuestion}
                      setLoading={setLoading} getSections={getSections}
                      loading={loading} canEdit={canEdit} canDelete={canDelete}
                      headCells={headCells}
                      userData={section.questions} selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions} />
                  }
                </Box>

              )}
            </div>

          )
        )
      }
      {showEditSection >= 0 && (
        <EditSectionModal
          sectionTitle={sectionTitle}
          formId={formId}
          studyId={studyId}
          sectionsLength={sections.length}
          showEditSection={showEditSection}
          setShowEditSection={setShowEditSection}
          getSections={getSections}
          setLoading={setLoading}
          setShowSnackbarAddSection={setShowSnackbarEditSection}
          sectionEdit={sectionEdit}
          setShowDeleteModalConfirm={setShowDeleteModalConfirm}
        />
      )}
      <SnackBarUhda handleClose={handleCloseEditSection} message={SnackBarResponses.SECTION_SAVED}
        openSnack={showSnackbarEditSection} />
    </Box>
  )
}

TabsUhda.propTypes = {
  bg: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

TabsUhda.defaultProps = {
  bg: AppColors.WHITE
};

export default TabsUhda;
