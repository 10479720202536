/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
import { React, useContext, useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { AppColors } from "./resources/AppColors";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  AppBar,
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography
} from "@material-ui/core";
import Login from "./pages/LoginPage";
import StudiesPage from "./pages/StudiesPage";
import ForgotPassPage from "./pages/ForgotPassPage";
import FormPage from "./pages/FormPage";
import ChangePassPage from "./pages/ChangePassPage";
import AuthState from "./context/authentication/authState";
import AuthContext from "./context/authentication/authContext";
import CreateFormPage from "./pages/CreateFormPage";
import EditFormPage from "./pages/EditFormPage";
import NavbarUhda from "./components/NavbarUhda";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import studiesIcon from "./img/studies_icon.svg";
import HomePage from "./pages/HomePage";
import CollaboratorsPage from "./pages/CollaboratorsPage";
import ParticipantsPage from "./pages/ParticipantsPage";
import NotFoundPage from "./pages/NotFoundPage";
import StudyPage from "./pages/StudyPage";
import { useTextStyles } from "./resources/AppTexts";
import MenuIcon from "@material-ui/icons/Menu";
import { CommonsTexts, LabelsDrawer } from "./locale/en";
import AccountPage from "./pages/AccountPage";
import DashboardPage from "./pages/DashboardPage";
import FormsPage from "./pages/FormsPage";
import DisseminationPage from "./pages/DisseminationPage";
import StudyParticipantsPage from "./pages/StudyParticipantsPage";
import SettingsPage from "./pages/SettingsPage";
import TeamMembersPage from "./pages/TeamMembersPage";
import ForgotPassSecondPage from "./pages/ForgotPassSecondPage";
import SummaryPage from "./pages/SummaryPage";
import taglineIcon from "./img/UhDa_Studies.png"
import "./styles/app.scss";
import ChatIndex from "./components/ChatUhda";
import { useDispatch, useSelector } from "react-redux";
import { initializeChatUnreadMessages } from "reducers/chatReducer";

window.abierto = false;
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
    minHeight: "100vh !important",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center",
  },
  imageIcon: {
    height: "100%",
  },
  icon: {
    height: "50px",
  },
  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

// TODO: Private route should only be concerned about credentials and permissions
// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, drawer, ...rest }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-param-reassign
  if (drawer == null) drawer = true;
  const authContext = useContext(AuthContext);
  const { checkAuth } = authContext;
  const texts = useTextStyles();
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useState(() => {
    try {
      let item = null;
      if (sessionStorage.length > 0) {
        item = sessionStorage.getItem("user");
      } else if (localStorage.length > 4) {
        item = localStorage.getItem("user");
      } else {
        item = null;
      }
      return item;
    } catch (error) {

      return "ERROR";
    }
  });

  const [open, setOpen] = useState(); //false
  const [selected, setSelected] = useState(null);

  const dispatch = useDispatch()
  const unreadChatMessages = useSelector(({ unreadChatMessagesState }) => unreadChatMessagesState)

  useEffect(() => {
    // * If visited page is chat don't dispatch to not have a conflict of concurrent connections to stream chat
    if (!unreadChatMessages && rest.path !== "/participant/:id/chat") {
      dispatch(initializeChatUnreadMessages())
    }
  }, [unreadChatMessages, rest.path])

  const handleDrawerOpen = () => {
    setOpen(true);
    window.abierto = true;
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.abierto = false;
  };

  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);

  useEffect(() => {
    checkAuth();
    setSelected(0);
  }, []);

  // TODO: All this needs to go away, do the same than in CBM
  const [devicesSize, setDevicesSize] = useState(1680)

  useEffect(() => {
    const getWindowSize = () => {
      setDevicesSize(window.innerWidth)
    }
    // Add event listener to listen for window sizes 
    window.addEventListener("resize", getWindowSize);
    // Call handler right away so state gets updated with initial window size

    getWindowSize()
    return window.removeEventListener("resize", getWindowSize)

  }, [open]);

  const drawerIconsList = [
    studiesIcon,
  ];
  const drawerLinkList = [
    "/studies",
  ];

  return (
    <>
      <NavbarUhda
        open={open}
        setOpen={setOpen}
        setSelected={setSelected}
        logged={user}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <Route
          {...rest}
          render={(props) =>
            user ? <Component {...props} /> : <Redirect to="/login" />
          }
        />
      </main>
      <Drawer
        anchor="left"
        open={drawer}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        {open && (
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {<MenuIcon style={{ color: AppColors.PRIMARY }} />}
            </IconButton>
          </div>
        )}

        {open === false && (
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerOpen}>
              {<MenuIcon style={{ color: AppColors.PRIMARY }} />}
            </IconButton>
          </div>
        )}
        <Divider />
        <List>
          {[
            LabelsDrawer.STUDIES,
          ].map((text, index) => (
            <Link
              to={drawerLinkList[index]}
              onClick={() => {
                setSelected(index);
                window.abierto = false;
              }}
              style={{ color: AppColors.WHITE, textDecoration: "none" }}
            >
              <ListItem
                button
                key={text}
                selected={selected === index}
                style={{
                  backgroundColor:
                    selected === index && AppColors.PIRMARY_WITH_OPACITY,
                  borderLeft:
                    selected === index && "5px solid " + AppColors.PRIMARY,
                  paddingLeft:
                    (selected === index && "22px") ||
                    (selected !== index && "27px"),
                }}
              >
                <ListItemIcon>
                  <Icon classes={classes.iconRoot} style={{ height: "1.4em", width: "3em", zIndex: "1", marginLeft: devicesSize > 1000 && !open ? "-8px" : "-6px" }}>
                    <img
                      style={{ height: "1.5em", width: "1.2em" }}
                      alt="icon"
                      className={classes.imageIcon}
                      src={drawerIconsList[index]}
                    />
                  </Icon>
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: texts.subtitle_bold }}
                  primary={text}
                  style={{ color: AppColors.PRIMARY }}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <AppBar position="relative" className={classes.appBar}
        style={{
          paddingBottom: "1em",
          paddingLeft: "10em",
          top: "auto",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY,
        }}>
        <Box style={{ marginBottom: 0 }} direction="row" display="flex" justifyContent="center" m={3}
          className="bottom-bar"
        >
          <Box style={{ marginTop: "0.8em", width: "30em", justifyContent: "center", alignItems: "center", marginRight: 0, marginLeft: "7.5em" }} mx="auto">
            <Typography style={{ marginLeft: "10em" }}>{CommonsTexts.ABOUT}</Typography>
            <Typography>{CommonsTexts.ABOUT_TEXT}</Typography>

          </Box>
          <Box style={{ margin: 0 }} direction="row" display="flex" justifyContent="center" m={3}
            className="bottom-bar"
          >
            <Typography style={{ marginTop: "0.8em" }} >Powered by </Typography>
            <img alt='icon' className={classes.icon} src={taglineIcon} />
          </Box>
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vSz4FmaCBAcvcuZ1Kw9eB7nmCgSS0lwaY5C3rMN9vN3Xd7Mhva9U8ffdGx-e1vIqgCn0P7KtlyT27b_/pub'
              target="_blank" rel="noreferrer">{CommonsTexts.PRIVACY}</a>
          </Box>
        </Box>

      </AppBar>
    </>
  );
};

function App() {
  return (
    <AuthState>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot" component={ForgotPassPage} />
          <Route exact path="/change" component={ChangePassPage} />
          <Route path="/change/:id" component={ForgotPassSecondPage} />
          <PrivateRoute path="/account" component={AccountPage} />
          <PrivateRoute
            path="/study/:id/dashboard"
            component={() => <DashboardPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/forms"
            component={() => <FormsPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/participants"
            component={() => <StudyParticipantsPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/dissemination"
            component={() => <DisseminationPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/settings"
            component={() => <SettingsPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            exact
            path="/study"
            component={() => <SettingsPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/teamMembers"
            component={() => <TeamMembersPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/form/:id/summary"
            //path="/summary"
            component={() => <SummaryPage drawer={window.abierto} />}
            drawer={false}
          />
          <PrivateRoute
            path="/study/:id/form/:id"
            component={() => <FormPage drawer={window.abierto} />}
            drawer={false}
          />

          <PrivateRoute
            exact
            path="/study/:id/form"
            component={FormPage}
            drawer={false}
          />

          <PrivateRoute
            exact
            path="/study/:id"
            component={() => <StudyPage drawer={window.abierto} />}
            drawer={false}
          />

          <PrivateRoute
            exact
            path="/participant/:id/chat"
            component={() => <ChatIndex drawer={window.abierto} />}
            drawer={false}
          />

          <Route exact path="/" component={() => <Redirect to="/login" />} />
          <PrivateRoute path="/home" component={HomePage} drawer={true} />
          <PrivateRoute path="/studies" component={StudiesPage} />

          {/*
<PrivateRoute path="/form" component={FormPage} /> A JSX comment */}
          {/*TODO Ahora solo esta para hacer el styling*/}
          <PrivateRoute path="/participants" component={ParticipantsPage} />
          <PrivateRoute path="/collaborators" component={CollaboratorsPage} />
          {/*<PrivateRoute path="/usage" component={ AdminPage } />*/}
          <PrivateRoute path="/form/create" component={CreateFormPage} />
          <PrivateRoute path="/form/:id" component={EditFormPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </AuthState>
  );
}

export default App;
