/* eslint-disable */

import { createSlice } from "@reduxjs/toolkit"
import { CHAT_REGISTER } from "resources/ApiUrls"
import { StreamChat } from "stream-chat"
import MyAxiosInstance from "utils/MyAxiosInstance"
import MyStorageManager from "utils/StorageManager"

const unreadChatMessagesSlice = createSlice({
  name: "unreadMessages",
  initialState: null,
  reducers: {
    setUnreadMessages(state, action) {
      return action.payload
    },
    setMessageAsRead(state, action) {
      return state?.filter(channeLId => channeLId !== action.payload)
    }
  },
})

export const { setUnreadMessages, setMessageAsRead } =
  unreadChatMessagesSlice.actions

export const initializeChatUnreadMessages = () => {
  return async (dispatch) => {
    try {
      const storageManager = new MyStorageManager()
      const userId = storageManager.getId()
      const apiKey = "hcq6wjqutq32"

      const filters = { members: { $in: [userId] } }
      const sort = { has_unread: -1, last_message_at: -1 }

      const token = await MyAxiosInstance.get(CHAT_REGISTER) // * participant's uuid is chat id

      const client = StreamChat.getInstance(apiKey);

      await client.connectUser({ id: userId }, token.data.data.value)

      const channelsQuery = await client.queryChannels(filters, sort)

      await client.disconnectUser()

      const unreadChannelsIds = channelsQuery.filter(channel => channel.state.unreadCount)
        .map(filteredChannel => filteredChannel.id)

      dispatch((setUnreadMessages(unreadChannelsIds)))
    } catch (error) {
      throw new Error(`Error while querying unread channels: ${error}`)
    }
  }
}

export const setMessageAsReadAction = (channelId) => {
  return (dispatch) => {
    dispatch(setMessageAsRead(channelId))
  }
}

export default unreadChatMessagesSlice.reducer