import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import PropTypes from "prop-types";

import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={ 6 } variant="filled" { ...props } />;
}

/**
 * @component
 * Component to create snackbars
 *
 * @param {string} message: message of the snackbar
 * @param {boolean} openSnack: state to check when the snackbar will be open
 * @param {function} handleClose: handle to close the snackbar
 *
 * @constructor
 * <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.STUDY_DELETED} openSnack={openDelete} />

 */
const SnackBarUhda = ({message, openSnack, handleClose, severity}) => {
  // eslint-disable-next-line no-param-reassign
  if (severity == null) severity = "success"

  return (
    <div>
      <Snackbar style={ {paddingTop: "2em"} } anchorOrigin={ {
        vertical: "top",
        horizontal: "right",
      } } open={ openSnack } autoHideDuration={ 5000 } onClose={ handleClose }>
        <Alert onClose={ handleClose } severity={ severity }>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

SnackBarUhda.propTypes = {
  severity: PropTypes.string,
  openSnack: PropTypes.bool.isRequired,
  message: PropTypes.string,
  handleClose: PropTypes.func,
}

export default SnackBarUhda;
