/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Box, Grid, Popover, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import { LabelsForm, LinksInfo, SnackBarResponses, TableTexts } from "../../locale/en";
import DragAndDropUhda from "../DragAndDropUhda";
import SnackBarUhda from "../SnackBarUhda";
import CheckBoxUhda from "../CheckBoxUhda/CheckBoxUhda";
import EditSectionModal from "../QuestionModals/EditSectionModal";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  iconLabelWrapper2: {
    flexDirection: "row-reverse",
  },
  labelContainer: {
    width: "auto",
    padding: 0,
  },
}));
const headCells = [
  { id: "", label: "" },
  { id: "code", label: TableTexts.CODE },
  { id: "questionType", label: TableTexts.QUESTION_TYPE },
  { id: "questionTitle", label: TableTexts.QUESTION_TITLE },
  { id: "mandatory", label: TableTexts.MANDATORY },
  { id: "conditional", label: TableTexts.CONDITIONAL },
];

const TabUhda = props => {
  const {
    loading,
    handleClickEdit,
    getQuestions,
    showEditQuestion,
    setShowEditQuestion,
    setShowDeleteQuestion,
    setLoading,
    getSections,
    getSummary,
    formId,
    studyId,
    summary,
    canEdit,
    canDelete,
    sectionState,
    setSectionState,
    stateSelect,
    setSelectQuestions,
    selectQuestions,
    showDeleteSectionConfirm,
    setShowDeleteModalConfirm
  } = props;
  const [sectionValue, setSectionValue] = React.useState(0);
  //const [sectionValue, setSectionValue] = React.useState(!sectionState ? 0 : sections.map(object => object.id).indexOf(sectionState));
  const [sectionEdit, setSectionEdit] = useState(0);
  const [showEditSection, setShowEditSection] = useState(-999);
  const [showSnackbarEditSection, setShowSnackbarEditSection] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState();
  const [tabs, setTabs] = useState(summary)
  const [sectionTitle, setSectionTitle] = useState()
  const handleChangeSectionValue = (event, newValue) => {
    setSectionValue(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const idPopover = openPopover ? "simple-popover" : undefined;

  const handleEditSection = (sectionId) => {
    if (canEdit) {
      setShowEditSection(sectionId);
      setSectionTitle(summary.summary_translations[0].title)
      setSectionEdit(summary)
    } else {
      // setOpenSnackAdd(true);
    }
  }

  const handleCloseEditSection = () => {
    setShowSnackbarEditSection(false);
  };

  useEffect(() => {
    //getSummary()
    //getQuestions()
  }, [showEditSection, showEditQuestion, loading]);

  useEffect(() => {
    setSelectQuestions(selectedQuestions)
  }, [selectedQuestions]);
  const classes = useStyles();

  return (
    <Box sx={{ width: "70em", borderBottom: 1, borderColor: "divider" }}>
      <Tabs
        style={{ width: window.innerWidth < 800 ? "42em" : window.innerWidth - 420,border: "2px solid ", borderColor: AppColors.SUBTEXT, color: AppColors.PRIMARY }}
        TabIndicatorProps={{
          style: { color: AppColors.ACCENT, background: AppColors.ACCENT, height: "5px" }
        }}
        indicatorColor='primary'
        variant="fullWidth"
        centered
        scrollButtons="auto"
        value={sectionValue}>
        {(tabs && !loading) && <Tab
          style={{ minHeight: 0 }}
          onDoubleClick={() => handleEditSection(summary.id)}
          classes={{
            wrapper: classes.iconLabelWrapper2,
            labelContainer: classes.labelContainer
          }}
          //label={`${tabs.summary_translations[0].title}`}
          label={<Grid container direction="row"
            justifyContent="space-between"
            alignItems="center">
            <Grid>
            </Grid>
            <Grid>
              <Typography style={{ fontSize: "15px" }}>{`${tabs.summary_translations[0].title}`}</Typography>

            </Grid>
            <Grid>
              <InfoOutlinedIcon
                onClick={handleClick}
                style={{ verticalAlign: "middle" }} />
            </Grid> </Grid>}
        />}
        <Popover
          id={idPopover}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid container
            style={{ width: "514px", height: "152px", margin: "1em" }}>
            <Typography
              style={{ fontSize: "12px", color: AppColors.PRIMARY }}>{LabelsForm.INFO_SUMMARY}</Typography>
            <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
              href={LinksInfo.SUMMARIES}
              target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
          </Grid>
        </Popover>
      </Tabs>
      <Box sx={{ p: 3 }} style={{ paddingLeft: 0, width: stateSelect ? "73.2em" : window.innerWidth - 400}}>
        {!loading && !stateSelect && summary &&
          <DragAndDropUhda handleClickEdit={handleClickEdit} getQuestions={getQuestions}
            formId={formId} setShowDeleteQuestion={setShowDeleteQuestion}
            setLoading={setLoading} getSections={getSections}
            loading={loading} canEdit={canEdit} canDelete={canDelete}
            headCells={headCells}
            userData={summary.questions} isSummary={true} getSummary={getSummary} />}
        {!loading && stateSelect &&
          <CheckBoxUhda handleClickEdit={handleClickEdit} getQuestions={getQuestions}
            formId={formId} setShowDeleteQuestion={setShowDeleteQuestion}
            setLoading={setLoading} getSections={getSections}
            loading={loading} canEdit={canEdit} canDelete={canDelete}
            headCells={headCells}
            userData={summary.questions} selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions} isSummary={true} />
        }
      </Box>

      {showEditSection >= 0 && (
        <EditSectionModal
          sectionTitle={sectionTitle}
          formId={formId}
          studyId={studyId}
          summaryEdit={summary}
          sectionsLength={summary.length}
          showEditSection={showEditSection}
          setShowEditSection={setShowEditSection}
          getSections={getSections}
          setLoading={setLoading}
          setShowSnackbarAddSection={setShowSnackbarEditSection}
          sectionEdit={sectionEdit}
          setShowDeleteModalConfirm={setShowDeleteModalConfirm}
          isSummary={true}
          getSummary={getSummary}
        />
      )}
      <SnackBarUhda handleClose={handleCloseEditSection} message={SnackBarResponses.SECTION_SAVED}
        openSnack={showSnackbarEditSection} />
    </Box>
  )
}

TabUhda.propTypes = {
  bg: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

TabUhda.defaultProps = {
  bg: AppColors.WHITE
};

export default TabUhda;
