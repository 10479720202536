/* eslint-disable no-shadow */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { AppColors } from "../../resources/AppColors"
import DialogUhda from "../DialogUhda"
import { CommonsTexts } from "../../locale/en"
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from "@material-ui/core"
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import TextField from "@mui/material/TextField";
import TextUhda from "../TextUdha";
import { textType } from "../../resources/AppTexts";
import { makeStyles } from "@material-ui/core/styles";

import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import { parseISO } from "date-fns";
import Moment from "moment";
import ExportExcel from "react-export-excel"
import { orderArray } from "../../utils/HelperFunctions";

const ExcelFile = ExportExcel.ExcelFile;
const ExcelSheet = ExportExcel.ExcelSheet
const ExcelColumn = ExportExcel.ExcelColumn
const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  }, textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },

}));

/**
 * @component
 * Component to add a new question for the form
 *
 * @param {function} handleClickNext: handle to show the next modal
 * @param {object} initialValues: array of dictionaries of the initial values
 * @param {object} values: values that will change with the new initial values
 * @param {function} setValues: values that will change
 * @param {boolean} showAddQuestion: state to show or not the new question
 * @param {function} setShowAddQuestion: function to set if the add question will be showed
 * @param {string} formId: id of the form
 * @param {string} studyId: id of the study
 * @param {function} changeMandatory: function to change the values of the mandatory
 * @param {function} changeConditional: function to change the values of the conditional
 * @param {boolean} hasNextModal: value to know if the questionary have more questions
 * @param {function} setHasNextModal: state to change when will be next question.
 *
 * @constructor
 * <AddQuestionModal hasNextModal={hasNextModal} setHasNextModal={setHasNextModal} formId={formId} studyId={studyId} handleClickNext={handleClickNext}
 * initialValues={initialValues} values={values} setValues={setValues} showAddQuestion={showAddQuestion} setShowAddQuestion={setShowAddQuestion}
 * changeConditional={changeConditional} changeMandatory={changeMandatory}
 />
 */
export default function DownloadModal({
  initialValues,
  values,
  setValues,
  showDownload,
  setShowDownload,
  formId,
  studyId,
  minDate,
  headCells
}) {

  const [firstDateValue, setFirstDateValue] = React.useState(null);
  const [lastDateValue, setLastDateValue] = React.useState(null);
  const [typeData, setTypeData] = useState(1)
  // eslint-disable-next-line no-unused-vars
  const [response, setResponse] = useState()
  const classes = useStyles();
  const [excelData, setExcelData] = useState()
  // eslint-disable-next-line no-unused-vars
  const [maxLength, setMaxLength] = useState()
  const [form, setForm] = useState()
  const [loading, setLoading] = useState(false);

  let unique = headCells;
  //Function that change the type of the data
  const handleChange = (event) => {
    setTypeData(event.target.value);
  };

  useEffect(() => {
    setValues(initialValues)
    setValues({ ...values, ["form"]: formId })
    //getAnswers()
    // eslint-disable-next-line no-use-before-define
    //getMaxLengthAnswers()
    // eslint-disable-next-line no-use-before-define
    getForm()
  }, [showDownload])

  const getForm = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId}/form/${formId}`);
      setForm(response.data.data.item)

    } catch (err) {
      setForm(err.message)
    }
  }

  const getMaxLengthAnswers = async () => {
    try {
      // eslint-disable-next-line no-shadow
      const response = await MyAxiosInstance.get(`${BASE_PATH}/form/${formId}/answers`);
      const response2 = await MyAxiosInstance.get(`${BASE_PATH}/studies/${studyId}/form/${formId}/questions`);

      var variables = []
      var end = []
      /*  response.data.data.item.forEach((res) => {
         for (var j = 0; j < res.answers.length; j++) {
           // variables.push(response.data.data.item.map(item => {console.log(item.answers[j].question.variable_name)}))
           response.data.data.item.map(item => {
             for (var i = 0; i < item.answers.length; i++) {
               if (item.answers[i].question !== null) {
                 variables.push(item.answers[i].question.variable_name)
               }
             }
           })
         }
       }); */
      response2.data.data.item.forEach((res) => {
        variables.push(res.variable_name)
      });
      end = [].concat.apply([], variables)
      unique = [...new Set(end)];
    } catch (err) {
      setMaxLength(err.message)
    }
  }

  const handleClickSubmit = async () => {
    try {
      setLoading(true);

      var lengthMax = unique.length
      const response = await MyAxiosInstance.get(`${BASE_PATH}/form/${formId}/answers`);
      var data = []
      if (firstDateValue != null && lastDateValue != null) {
        var first = firstDateValue.toISOString().split("T")[0]
        var last = lastDateValue.toISOString().split("T")[0]
        first = `${first}T00:00:00Z`
        last = `${last}T23:59:00Z`
        response.data.data.item.forEach((res) => {
          var answers = []
          if (parseISO(first) < parseISO(res.updated_at) && parseISO(last) > parseISO(res.updated_at)) {
            //data.push(res)
            var entriesValues = {}
            entriesValues["id"] = res.id
            if (res.participant == null) {
              entriesValues["participant_id"] = "Anonymous"
            } else {
              entriesValues["participant_id"] = res.participant.uuid
            }
            for (var i = 0; i < res.answers.length; i++) {
              if (res.answers[i].question !== null) {
                answers.push(res.answers[i])
              }
            }
            /* answers.sort(function (a, b) {
              return unique.indexOf(a.question.variable_name) - unique.indexOf(b.question.variable_name);
            }); */

            orderArray(unique, answers)

            for (var j = 0; j < unique.length; j++) {
              if (answers[j] != undefined) {
                if (typeData === 0) {
                  if (Array.isArray(answers[j].data)) {
                    entriesValues[`${unique[j]}`] = answers[j].data.toString()
                  } else {
                    entriesValues[`${unique[j]}`] = answers[j].data.toString()
                  }
                } else if (typeData === 1) {
                  entriesValues[`${unique[j]}`] = answers[j].data_label.toString()
                }
              } else {
                entriesValues[`${unique[j]}`] = "-"
              }
            }

            if (answers.length === 0) {
              for (var x = 0; x < answers.length; x++) {
                entriesValues[`${unique[x]}`] = "-"
              }
            }
            Moment.locale("es")
            entriesValues["submitted_at"] = Moment(res.updated_at).format("DD-MM-YYYY HH:mm")
            if (res.participant == null) {
              entriesValues["user"] = "Anonymous"
            } else {
              entriesValues["user"] = res.participant.user_name.toString()
            }
            data.push(entriesValues)
          }
        });
      } else {
        response.data.data.item.forEach((res) => {
          var answers = []
          //data.push(res)
          var entriesValues = {}
          entriesValues["id"] = res.id
          if (res.participant == null) {
            entriesValues["participant_id"] = "Anonymous"
          } else {
            entriesValues["participant_id"] = res.participant.uuid
          }
          for (var i = 0; i < res.answers.length; i++) {
            if (res.answers[i].question !== null) {
              answers.push(res.answers[i])
            }
          }
          /* answers.sort(function (a, b) {
            return unique.indexOf(a.question.variable_name) - unique.indexOf(b.question.variable_name);
          }); */
          orderArray(unique, answers)

          for (var j = 0; j < unique.length; j++) {
            if (answers[j] != undefined) {
              if (typeData === 0) {

                if (Array.isArray(answers[j].data)) {
                  entriesValues[`${unique[j]}`] = answers[j].data.toString()
                } else {
                  entriesValues[`${unique[j]}`] = answers[j].data.toString()
                }
              } else if (typeData === 1) {
                entriesValues[`${unique[j]}`] = answers[j].data_label.toString()
              }
            } else {
              entriesValues[`${unique[j]}`] = "-"
            }
          }
          if (answers.length === 0) {
            for (var x = 0; x < answers.length; x++) {
              entriesValues[`${unique[x]}`] = "-"
            }
          }
          Moment.locale("es")
          entriesValues["submitted_at"] = Moment(res.updated_at).format("DD-MM-YYYY HH:mm")

          if (res.participant == null) {
            entriesValues["user"] = "Anonymous"
          } else {
            entriesValues["user"] = res.participant.user_name
          }
          data.push(entriesValues)
        });
      }
      data.reverse()
      setExcelData(data)
      console.log("data", data);
      // ! Stack trace ended here
      document.getElementById("takePic").click();
    } catch (err) {
      setResponse(err.message)
    }
    setLoading(false);

  }

  return (
    <DialogUhda
      handleModalClose={() => {
        setValues(initialValues)
        setShowDownload(-999)
      }}
      size={false}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.DOWNLOAD}
      handleShow={setShowDownload}
      handleConfirm={(typeData === 1 || typeData === 0) && handleClickSubmit}
      values={values}
      setValues={setValues}
      title={CommonsTexts.DOWNLOAD_FORM}
      body={
        <Grid
          style={{ paddingLeft: "30px" }}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <Grid item style={{ paddingTop: "20px" }}>
            <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
              text={"Between dates "} />
          </Grid>
          <FormControl className={classes.select} margin='normal' style={{ width: "152px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date(minDate)}
                maxDate={new Date(Moment().toISOString().split("T")[0])}
                inputFormat="dd/MM/yyyy"
                label={""}
                value={firstDateValue}
                onChange={(newValue) => {
                  setFirstDateValue(newValue);
                  setValues({ ...values, ["birthdate"]: newValue.toLocaleDateString("en-US") })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl className={classes.select} margin='normal' style={{ width: "152px" }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date(minDate)}
                maxDate={new Date(Moment().toISOString().split("T")[0])}
                inputFormat="dd/MM/yyyy"
                label={""}
                value={lastDateValue}
                onChange={(newValue) => {
                  setLastDateValue(newValue);
                  setValues({ ...values, ["birthdate"]: newValue.toLocaleDateString("en-US") })
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FormControl>

          <Grid
            container spacing={2}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item style={{ marginRight: "3.8em", paddingTop: "20px" }}>
              <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                text={"Type of data"} />
            </Grid>
            <Grid item>
              <FormControl className={classes.select} variant="outlined" margin='normal'
                style={{ width: "9.75em" }}>
                <InputLabel className={classes.select}
                >Types</InputLabel>

                <Select className={classes.select} IconComponent={Icons.ARROW_DOWN}
                  value={typeData}
                  onChange={handleChange}
                  label="Types"
                  style={{ width: 360 }}
                >
                  <MenuItem style={{ color: AppColors.PRIMARY }}
                    value={1}>{CommonsTexts.LABELS}</MenuItem>
                  <MenuItem style={{ color: AppColors.PRIMARY }}
                    value={0}>{CommonsTexts.VALUES}</MenuItem>

                </Select>
              </FormControl>

            </Grid>
            {excelData && form &&
              <ExcelFile element={<p hidden id={"takePic"} />} filename={form.form_translations[0].title}>
                <ExcelSheet data={excelData} name={"Data dashboard"}>
                  <ExcelColumn label={"Participant Id"} value={(excelData) => excelData.participant_id} />
                  <ExcelColumn label={"Subbmited"} value={"submitted_at"} />
                  {[...Array(unique.length)].map((x, i) =>
                    // eslint-disable-next-line react/jsx-key
                    <ExcelColumn label={unique[i]} value={unique[i]} />
                  )}

                  <ExcelColumn label={"Submitted by"} value={"user"} />

                </ExcelSheet>
              </ExcelFile>}

          </Grid>
          {loading &&
            (<Grid container justifyContent={"center"}>
              <CircularProgress color={"#C4141B"} />
            </Grid>)
          }

        </ Grid>
      }
      show={showDownload}
    />
  )
}
DownloadModal.propTypes = {
  formId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
}
