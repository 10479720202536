import React from "react";
import {Grid, Typography} from "@material-ui/core"
import {AppColors} from "../resources/AppColors"

/**
 * @page
 * Page that we use when a page is not done
 */
const NotFoundPage = () => {
  return (
    <Grid
      container
      spacing={ 0 }
      direction="column"
      alignItems="center"
      justifyContent='center'
      style={ {minHeight: "100vh"} }
    >
      <Typography variant="h1" style={ {color: AppColors.PRIMARY, textAlign: "center"} }>
                404 - Not Found!
      </Typography>
    </Grid>
  )
}

export default NotFoundPage;
