/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useRef } from "react";
import {
  CommonsTexts as CommonsText,
  ErrorTexts,
  LabelsDrawer,
  LabelsSettings,
  LabelsStudy,
  SnackBarResponses
} from "../locale/en";
import { CardHeader, Divider, Grid, TextField } from "@material-ui/core";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import { makeStyles } from "@material-ui/core/styles";
import { AppColors } from "../resources/AppColors";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import SnackBarUhda from "../components/SnackBarUhda";
import { Alert } from "@material-ui/lab";
import CardUhda from "../components/CardUhda";
import { textType, useTextStyles } from "../resources/AppTexts";
import StudyModal from "../modal/StudyModal"
import StudiesService from "../services/StudiesService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { useHistory, useLocation } from "react-router-dom";
import TextUhda from "../components/TextUdha";
import DrawerUhda from "../components/DrawerUhda";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,

      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      //marginTop: '-0.3em',

    }, "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },

  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2"
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },
}));

/**
 * @Page
 * Settings page of a study that exits when a new study is created or when this study is modified
 */
const SettingsPage = ({ drawer }) => {

  const location = useLocation();
  const texts = useTextStyles();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [privado, setPrivado] = useState();
  const [open, setOpen] = useState(drawer);//false
  const [openSnack, setOpenSnack] = useState();//false
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [study, setStudy] = useState();
  const classes = useStyles();
  //let id = location.state.detail;
  const permissions = useRef();//location.state.permissions
  const id = useRef();
  const [idStudy, setId] = useState(id.current);
  const [labels, setLabels] = useState()
  const history = useHistory()
  const [openSnackPermissionsEdit, setOpenSnackPermissionsEdit] = React.useState(false);
  const [devicesSize, setDevicesSize] = useState("20em")
  const [cardWidth, setCardWidth] = useState("500px")
  const [isTypingTitle, setIsTypingTitle] = useState(false)
  const [isTypingDesc, setIsTypingDesc] = useState(false)

  const [permissionsNew] = useState([
    "study-duplicate",
    "study-delete",
    "study-edit-settings",
    "teammember-create",
    "teammember-edit",
    "teammember-delete",
    "access-dissemination",
    "participants-study-view",
    "participant-create",
    "participant-edit",
    "participant-delete",
    "forms-view",
    "form-create",
    "form-edit",
    "form-duplicate",
    "form-delete",
    "question-create",
    "question-edit",
    "question-duplicate",
    "question-delete",
    "collect-data",
    "access-data"
  ])

  const [labelNew] = useState([
    "Dashboard",
    "Forms",
    "Participants",
    "Dissemination",
    "Team Members",
    "Settings"
  ])

  const getRoute = () => {
    const route = localStorage.getItem("route");

    if (route === "edit") {

      //This user wants to edit a study
      const newItem = JSON.parse(localStorage.getItem("sectionDetails"));
      permissions.current = newItem.permissions;
      id.current = newItem.detail;

    } else {
      //This user wants to create a study
      const n = JSON.parse(localStorage.getItem("createStudy"));
      permissions.current = n.permissions;
      id.current = n.detail;

    }

  }

  useEffect(() => {
    getRoute()

  }, [])

  //Function to close the snackbar
  const knowPermissions = () => {
    const label = [];
    if (permissions.current.includes("access-data")) {
      label.push(LabelsStudy.DASHBOARD)
    }
    if (permissions.current.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
    }
    if (permissions.current.includes("participant-create") || permissions.current.includes("participant-edit") || permissions.current.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
    }
    if (permissions.current.includes("teammember-create") || permissions.current.includes("teammember-edit") || permissions.current.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
    }
    if (permissions.current.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
    }
    setLabels(label)

  };

  //Function to check if the inputs are empty when the form is modified
  const checkTextInputModify = () => {
    if (study.permissions.includes("study-edit-settings")) {
      if (title === "" || description === "") {
        setShowValidationAlert(true);
      } else {
        setShowValidationAlert(false);
        modifyStudy().then()
      }
    } else {
      setOpenSnackPermissionsEdit(true)
    }
  };

  //Function to check if the inputs are empty when the form is created
  const checkTextInputCreate = () => {
    if
      (title === "" || description === "" || title === undefined || description === undefined) {
      setShowValidationAlert(true);
    } else {
      setOpenSnack(true);
      setShowValidationAlert(false);
      createStudyEmpty().then()

    }
  };

  //Function to create a new Study from the values
  const createStudyEmpty = async () => {
    const study = new StudyModal(title, description, privado);
    try {
      const studyCreated = await StudiesService.postStudies(study.createToJSON)
      const id = studyCreated.data.data.item.id

      var label = [
        "Dashboard",
        "Forms",
        "Participants",
        "Team Members",
        "Settings"
      ]

      const newObj = { "id": id, "value": 5, "permissions": label }
      //localStorage.setItem("sectionDetails", JSON.stringify(newObj));

      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));

      history.push({
        pathname: `/study/${id}/forms`,
        state: { detail: id, value: labelNew.indexOf("Forms"), permissions: labelNew }
      })
      setId(id)
      getStudy()
      setOpenSnack(true);
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

  }

  //Function to modify a study from the values
  const modifyStudy = async () => {
    const study = new StudyModal(title, description, privado, id.current);
    try {
      await StudiesService.putStudies(study.modifyToJSON)
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    getStudy()
    setOpenSnack(true);

  }

  //Function to get the study data from the id of this
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
      setTitle(response.data.data.translations[0].study_title)
      setDescription(response.data.data.translations[0].short_description)
    } catch (err) {
      setStudy(err.message)
    }
  }

  useEffect(() => {
    if (window.location.pathname != "/study") {
      id.current = window.location.pathname.split("/")[2]
    }
    //if (!location.state.detail) {
    if (id.current === null) {
      id.current = null
      const study = new StudyModal("", "", false);
    } else {

      getStudy()
    }

  }, [id.current])

  //Function to close the snackbar
  const handleClose = () => {
    setOpenSnack(false);
  };

  //Function to close the snackbar
  const handleClosePermissionsEdit = () => {
    setOpenSnackPermissionsEdit(false);
  };

  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (open) {
        if (window.innerWidth >= 1000) {
          setDevicesSize("18em")
          setCardWidth("500px")
        } else {
          setDevicesSize("1em")
          setCardWidth("500px")
        }

      } else {

        if (window.innerWidth >= 640 && window.innerWidth <= 1680) {
          setDevicesSize("18em")
          setCardWidth("500px")
        } else if (window.innerWidth <= 600) {
          setDevicesSize("10em")
          setCardWidth("60%")

        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])
  useEffect(() => {
    knowPermissions()
    //permissions.current.indexOf('Settings')
    localStorage.removeItem("dashBoardSection")

    if (study) {
      const newObj = { "id": id.current, "permissions": study.permissions }
      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    } else {
      const newObj = { "id": id.current, "permissions": permissionsNew }
      localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    }
  }, [])

  //Function to know when a user is typing in title filed
  const titleChange = (e) => {
    setTitle(e.target.value)
    setIsTypingTitle(true)
  }
  //Function to know when a user is typing in description filed

  const descriptionChanged = (e) => {
    setDescription(e.target.value)
    setIsTypingDesc(true)

  }

  return (
    <div>

      {study && <DrawerUhda id={id.current} drawer={open} select={4}
        settings={study.permissions} />}
      {!study &&
        <DrawerUhda id={id.current} drawer={open} select={4} settings={permissionsNew}
          newStudy={true} />}

      {/* <Grid container justifyContent={"center"} alignItems={"flex-start"}
                  style={{marginLeft: open?0:"15em"}}
                  
                  direction={"column"}
                  spacing={2}
                  xs={8}
                  sm={5}
                  md={8}
                  lg={8}
                  xl={4.5} */}

      <Grid style={{ marginLeft: devicesSize, width: cardWidth }}>
        <Grid item style={{ width: cardWidth }}>
          {study && <BreadcrumbsUhda routes={
            [
              { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
              {
                id: 1, label: `${study.translations[0].study_title}`,
                disabled: true,
                studyId: id.current, permissions: permissions.current
              },
              { id: 2, label: LabelsStudy.SETTINGS },
            ]
          } />}
        </Grid>

        <Grid item >
          <CardUhda bg={AppColors.WHITE} children={
            <Grid container
              style={{ position: "relative", margin: "1em", display: "grid" }}
            >
              <Grid item xs zeroMinWidth>
                <CardHeader
                  classes={{
                    title: texts.title_bold,
                    root: classes.cardHeaderRoot,
                    content: classes.cardHeaderContent
                  }}
                  style={{
                    width: "232px",
                    color: AppColors.PRIMARY,
                    paddingBottom: "8px",
                    paddingLeft: 0,
                    paddingTop: 0
                  }}
                  title={
                    <TextUhda margin={0} style={"break-word"} type={textType.SUBTITLE_BOLD}
                      text={LabelsSettings.STUDY_DETAILS} />}
                />
              </Grid>
              <Divider style={{ marginLeft: "-32px", marginBottom: "20px" }} />
              <Grid container style={{ paddingRight: "2em", flexDirection: "column" }}>
                {study && <TextField
                  defaultValue={study.translations[0].study_title}
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                  type="text"
                  label={LabelsSettings.STUDY_TITLE}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldLabel}
                  inputProps={{ maxLength: 500 }}

                />}
                {study && <TextField
                  defaultValue={study.translations[0].short_description}
                  onChange={(e) => setDescription(e.target.value)}
                  type="text"
                  label={LabelsSettings.STUDY_DESCRIPTION}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className={classes.textFieldLabel}

                />}
                {!id.current && !study && <TextField
                  fullWidth
                  onChange={(e) => titleChange(e)}

                  type="text"
                  label={LabelsSettings.STUDY_TITLE}
                  // margin="normal"
                  variant="outlined"
                  className={classes.textFieldLabel}
                  inputProps={{ maxLength: 500 }}

                />}

                {!id.current && !study && <TextField
                  onChange={(e) => descriptionChanged(e)}
                  type="text"
                  label={LabelsSettings.STUDY_DESCRIPTION}
                  margin="normal"
                  variant="outlined"
                  multiline
                  className={classes.textFieldLabel}
                />}
              </Grid>
              <Grid container
                style={{
                  paddingRight: "2em",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "row",
                  minWidth: cardWidth
                }}>
                <Grid container spacing-xs-1 wrap="nowrap" direction="column"
                  alignItems="flex-end"
                  justifyContent="center">
                  <Grid item>
                    {study &&
                      <ButtonFilledUhda onClick={checkTextInputModify} color={AppColors.WHITE}
                        margin={"0.7em"}
                        text={LabelsSettings.SAVE_CHANGES}
                      />}

                    {!id.current && !study &&
                      <ButtonFilledUhda onClick={checkTextInputCreate} color={AppColors.WHITE}
                        margin={"0.7em"}
                        text={CommonsText.CREATE}
                      />}
                  </Grid>
                  {showValidationAlert && (
                    <Grid item style={{ marginTop: "0.5em" }}><Alert
                      severity="error">{ErrorTexts.SETTINGS}</Alert></Grid>
                  )}
                </Grid>
              </Grid>
              <SnackBarUhda handleClose={handleClose} message={SnackBarResponses.CHANGES_SAVED}
                openSnack={openSnack} />
              <SnackBarUhda handleClose={handleClosePermissionsEdit} severity={"error"}
                message={SnackBarResponses.NOT_PERMISSION}
                openSnack={openSnackPermissionsEdit} />
            </Grid>} />
        </Grid>
      </Grid>

    </div>
  )
}

export default SettingsPage;
