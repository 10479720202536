import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import { Grid } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"

import TableUhda from "./TableUhda"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",

  }, contentLittle: {
    flexGrow: 1,
    height: "100vh",
    maxWidth: "71.5em",

  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    //marginLeft:"10px",

  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",

  },
}));

/**
 * @component
 * Component that have all the subcomponents of the table
 * @param {string} tableTitle: title of the table
 * @param {object} HeadCells: array of dictionaries with the info of each cell
 * @param {object} DataToUse: data that the table will use
 * @param {function} AddModal: function to open and create new data
 * @param {function} DeleteModal: function to open and delete data
 * @param {function} EditModal:function to open and edit data
 * @param {boolean} interactive: value to know if the table is interactive or not
 * @param {object} initialValues: initial values for the table
 * @param {object} values: values that will use the component
 * @param {function} setValues: function to change the values
 * @param {object} editValues: values that will be editables
 * @param {function} setEditValues: function to edit values
 * @param {string} selectedDate: date selected
 * @param {function} setSelectedDate: function to change the selected date
 * @param {function} handleAddParticipant: handle to open and add a new participant
 * @param {function} handleEditParticipant: handle to open and edit a new participant
 * @param {function} handleDeleteParticipant: handle to open and delete a new participant
 * @param {number} showAddModal: value to show or not the add modal
 * @param {function} setShowAddModal: function to change the value of the boolean
 * @param {number} showEditModal: value to show or not the edit modal
 * @param {function} setShowEditModal: : function to change the value of the boolean
 * @param showDeleteModal: value to show or not the delete modal
 * @param {function} setShowDeleteModal: : function to change the value of the boolean
 */
export default function TableBaseUhda({
  tableTitle,
  headCells: HeadCells,
  dataToUse: DataToUse,
  addModal: AddModal,
  deleteModal: DeleteModal,
  editModal: EditModal,
  downloadPdfModal: DownloadPdfModal,
  showDownloadPdfModal,
  setShowDownloadPdfModal,
  interactive,
  initialValues,
  values,
  setValues,
  editValues,
  edit,
  setEditValues,
  selectedDate,
  setSelectedDate,
  handleAddParticipant,
  handleAddParticipantContinue,
  handleEditParticipant,
  handleDeleteParticipant,
  handleDownloadPdf,
  showAddModal,
  setShowAddModal,
  showEditModal,
  setShowEditModal,
  showDeleteModal,
  setShowDeleteModal,
  size,
  study,
  canAdd,
  canEdit,
  canDelete,
  canDownload,
  paperWidth,
  paperMargin,
  textDataEmpty,
  hasChat
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-param-reassign
  if (size == null) size = true

  const myComponentClass = size ? classes.content : classes.contentLittle;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={myComponentClass} >
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container} style={{ marginLeft: paperMargin }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper style={{
                width: paperWidth,
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)", borderRadius: 6
              }} className={classes.paper}
              >
                <TableUhda
                  tableTitle={tableTitle}
                  headCells={HeadCells}
                  dataToUse={DataToUse}
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  addModal={AddModal}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  editModal={EditModal}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  deleteModal={DeleteModal}
                  showDownloadPdfModal={showDownloadPdfModal}
                  setShowDownloadPdfModal={setShowDownloadPdfModal}
                  downloadPdfModal={DownloadPdfModal}
                  handleDownloadPdf={handleDownloadPdf}
                  initialValues={initialValues}
                  setValues={setValues}
                  values={values}
                  editValues={editValues}
                  setEditValues={setEditValues}
                  handleAddParticipant={handleAddParticipant}
                  handleAddParticipantContinue={handleAddParticipantContinue}
                  handleEditParticipant={handleEditParticipant}
                  handleDeleteParticipant={handleDeleteParticipant}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  interactive={interactive}
                  edit={edit}
                  // TODO Remove the props that depend on study and just pass study
                  study={study}
                  canAdd={canAdd}
                  canEdit={canEdit}
                  canDelete={canDelete}
                  canDownload={canDownload}
                  textDataEmpty={textDataEmpty}
                  hasChat={hasChat}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
}
