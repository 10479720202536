import { questionTypes } from "../resources/QuestionConstants";

export const stringToDate = strDate => {
  const splittedDate = strDate.trim().split(" ");
  const [year, month, day] = splittedDate[0].split("-").map(s => parseInt(s));
  const [hour, minutes, seconds] = splittedDate[1].split(":").map(s => parseInt(s));

  return new Date(year, month - 1, day, hour, minutes, seconds);
}

export const isValidToken = (token, expirationDate) => {
  return token && expirationDate && stringToDate(expirationDate).getTime() > new Date().getTime();
}

// Used for the react-select component
export const valueToSelectObject = (v, isQuestion = false) => ({
  value: v,
  label: isQuestion ? questionTypes[v].label : v
});
export const selectObjectToValue = obj => obj && obj.label;
export const valuesToSelectArray = v => v ? v.map(elem => ({ value: elem, label: elem })) : [];
export const selectArrayToValues = arr => arr.map(elem => elem.label);
export const rangeToSelectArray = (from, to) => [...Array(to - from + 1).keys()]
  .map(elem => ({ value: elem + from, label: elem + from }));
export const getResponseAccessToken = response => response.data.access_token;

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateVarName(varName) {
  const re = /^[ña-zÑA-Z0-9_]+$/;
  return re.test(String(varName).toLowerCase());
}

export function orderArray(arrayWithOrder, arrayToOrder) {
  var orderedArray = [],
    len = arrayToOrder.length,
    lenCopy = len,
    index, current;

  for (; len--;) {
    current = arrayToOrder[len];
    index = arrayWithOrder.indexOf(current.question.variable_name);
    orderedArray[index] = current;
  }

  //change the array
  Array.prototype.splice.apply(arrayToOrder, [0, lenCopy].concat(orderedArray));
}

export function orderArrayAlphabetical(array) {
  array.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  })
}
