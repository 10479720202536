export const patientsMock = {
  "success": true,
  "code": 0,
  "locale": "ca",
  "message": "OK",
  "data": {
    "0": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "0",
        "name": "Miriam",
        "email": "mail@mail.com",
        "surname": "Martinez",
        "birthdate": "09-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "1": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "1",
        "name": "Miriom",
        "email": "mail@mail.com",
        "surname": "Martinez",
        "birthdate": "09-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "2": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "2",
        "name": "Muruam",
        "email": "mail@mail.com",
        "surname": "Martinez",
        "birthdate": "09-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "3": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "3",
        "name": "Agustí",
        "email": "muul@mail.com",
        "surname": "murtunez",
        "birthdate": "17-12-1998",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "4": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "4",
        "name": "Merem",
        "email": "moil@mail.com",
        "surname": "mertenez",
        "birthdate": "02-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "5": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "5",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "6": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "6",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "7": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "7",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "8": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "8",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "9": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "9",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
    "10": {
      "id": "mpalliative",
      "state": "IPOS",
      "participant": {
        "id": "10",
        "name": "Maram",
        "email": "meil@mail.com",
        "surname": "morotoz",
        "birthdate": "01-11-1999",
        "biologicSex": "FEMALE",
        "affections": "Diabetes, Hipertension",
        "tags": "diabetics, hipertension",
      },
    },
  }
}
