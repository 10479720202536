/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useContext, useEffect, useState, useRef } from "react";
import { AppColors } from "../resources/AppColors";
import DrawerUhda from "../components/DrawerUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import { makeStyles } from "@material-ui/core/styles"
import { FormControl, Grid, InputAdornment } from "@material-ui/core";
import { CommonsTexts, DashboardTexts, LabelsDrawer, LabelsStudy, TableTexts } from "../locale/en";
import { textType } from "../resources/AppTexts";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import TextUhda from "../components/TextUdha";
import CardUhda from "../components/CardUhda";
import Box from "@mui/material/Box";
import arrowUp from "../img/arrow_up_icon.svg"
import arrowDown from "../img/arrow_down_icon.svg"
import dataPointsIcon from "../img/dashboard_data_points.svg"
import participantsIcon from "../img/dashboard_participants.svg"
import teamMembersIcon from "../img/dashboard_team_members.svg"
import downloadDisabledIcon from "../img/download_disabled_icon.svg"
import downloadEnabledIcon from "../img/download_enabled_icon.svg"
import Moment from "moment";
import moment from "moment";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DialogUhda from "../components/DialogUhda/DialogUhda"
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Avatar from "@mui/material/Avatar";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import { validateEmail } from "../utils/HelperFunctions"
import DateFnsUtils from "@date-io/date-fns";
import ErrorIcon from "@material-ui/icons/Error"
import PropTypes from "prop-types"
import { dashboardMock } from "../mocks/dashboardMock"
import DownloadModal from "../components/QuestionModals/DownloadModal";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { StorageManager } from "../utils";
import { orderArray } from "../utils/HelperFunctions";

const drawerWidth = 150;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: AppColors.BACKGROUND,
    display: "flex",
  },
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {

    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center"
  },
  imageIcon: {
    height: "100%"
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),

  },
  drawerClose: {
    marginTop: "64px",
    marginLeft: "70px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,
    },
  },
  avatar: {
    border: "1px solid #C6D2E3",
    "&.MuiAvatar-img": {
      width: "25px",
      height: "25px",

    }

  },
  dropdownStyle:
  {
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL
  },
  formControl:
  {
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL
  },

  textFieldLabel: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  },

}));

function AddModal({
  selectedDate,
  setSelectedDate,
  handleAddParticipant,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal
}) {
  const [showEmailError, setShowEmailError] = useState(false)

  const handleInputChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  useEffect(() => {

    setValues(initialValues)
    setSelectedDate(null)
  }, [showAddModal])

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(values["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleAddParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_PARTICIPANT_TITLE}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={values.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={values.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                autoOk={true}
                name='birthdate'
                variant="outlined"
                inputVariant="outlined"
                label={TableTexts.BIRTHDATE}
                format="MM/dd/yyyy"
                maxDate={new Date()}
                value={selectedDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={date => {
                  setSelectedDate(date)
                  handleInputChange({ target: { name: "birthdate", value: date } })
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={values.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showAddModal}
    />
  )
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({ setShowDeleteModal, showDeleteModal, selected, handleDeleteParticipant }) {
  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      buttonColor={AppColors.RED}
      handleConfirm={() => {
        handleDeleteParticipant()
      }}
      title={
        showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_ANSWERS_TITLE : TableTexts.DELETE_ANSWERS_TITLE
          : TableTexts.DELETE_ANSWERS_TITLE
      }
      body={
        <Grid>
          <TextUhda color={AppColors.PRIMARY} type={textType.BODY} text={showDeleteModal === true ?
            selected.length > 1 ? TableTexts.DELETE_ANSWERS_MULTIPLE : TableTexts.DELETE_ANSWERS
            : TableTexts.DELETE_ANSWERS
          } />

          <TextUhda type={textType.BODY_BOLD} color={AppColors.PRIMARY} text={showDeleteModal === true ?
            selected.length > 1 ? selected.toString() : selected.toString()
            : showDeleteModal
          } />

          <TextUhda type={textType.BODY} color={AppColors.PRIMARY} text={showDeleteModal === true ?
            selected.length > 1 ? TableTexts.DELETE_ANSWERS_IDS : selected.toString()
            : TableTexts.DELETE_ANSWERS_ID
          } />

        </Grid>}
      show={showDeleteModal}
    />
  )
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
  selectedDate,
  setSelectedDate,
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues
}) {
  const [showEmailError, setShowEmailError] = useState(false)
  useEffect(() => {
    var response = Object.values(dashboardMock.data)
    setEditValues(response[showEditModal].participant)
    const date = response[showEditModal].participant.birthdate
    setSelectedDate(moment(date, "DD-MM-YYYY"))
  }, [showEditModal])

  const handleInputChange = e => {
    const { name, value } = e.target
    setEditValues({ ...editValues, [name]: value })
  }

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleEditParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='name'
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='surname'
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                defaultValue={selectedDate}
                autoOk={true}
                name='birthdate'
                variant="outlined"
                inputVariant="outlined"
                label={TableTexts.BIRTHDATE}
                format="dd/MM/yyyy"
                maxDate={new Date()}
                value={selectedDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={date => {
                  setSelectedDate(date)
                  handleInputChange({ target: { name: "birthdate", value: date } })
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl margin='normal' style={{ width: "100%" }}>
            <TextfieldUhda
              name='email'
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={{ color: AppColors.RED }} /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
      }
      show={showEditModal}
    />
  )
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired
}

function DownloadPdfModal({
  selected,
  setShowDownloadPdfModal,
  showDownloadPdfModal,
  handleDownloadPdf
}) {

  useEffect(() => {

  }, [])

  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.DOWNLOAD}
      handleShow={setShowDownloadPdfModal}
      handleConfirm={handleDownloadPdf}
      title={TableTexts.DOWNLOAD_ANSWER + showDownloadPdfModal}
      body={

        <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
          text={"You are going to download the pdf of the Summary"
          } />
      }
      show={showDownloadPdfModal}
    />
  )
}

DownloadPdfModal.propTypes = {}

/**
 * @Page
 * Page for the dashboard of the study
 */
const DashboardPage = ({ drawer }) => {
  const storageManager = new StorageManager();
  const [study, setStudy] = useState();
  const [dataDashboard, setDataDashboard] = useState()
  const permissions = useRef();
  const id = useRef();

  const [open, setOpen] = useState(drawer);//false
  const [forms, setForms] = useState();
  const [idForm, setIdForm] = useState()
  const initialValues = { name: "", surname: "", birthdate: "", email: "" }
  const [values, setValues] = useState(initialValues)
  const [editValues, setEditValues] = useState(initialValues)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showAddModal, setShowAddModal] = useState(-999)
  const [showEditModal, setShowEditModal] = useState(-999)
  const [showDeleteModal, setShowDeleteModal] = useState(-999)
  const [showDownloadPdfModal, setShowDownloadPdfModal] = useState(-999)
  const [showDownload, setShowDownload] = useState(-999)
  const [maxLength, setMaxLength] = useState()
  const [response, setResponse] = useState([])
  const [loading, setLoading] = useState(false);
  const [minDate, setMinDate] = useState()
  const [allSummaries, setAllSummaries] = useState([])
  const [devicesSize, setDevicesSize] = useState("280px")
  const [cardWidth, setCardWidth] = useState("600px")
  const [tableWidth, setTableWidth] = useState("80%")
  const [exportButtonMargin, setExportButtonMargin] = useState("40em")

  const [uniqueDownload, setUniqueDownload] = useState([])
  let unique = []
  const [headCells] = useState([
    { id: "participant_id", numeric: true, disablePadding: false, label: TableTexts.PARTICIPANT_ID.toUpperCase(), isSortable: false },
    { id: "submitted_at", numeric: false, disablePadding: false, label: TableTexts.SUBMITTED, isSortable: true },
    { id: "user", numeric: false, disablePadding: false, label: TableTexts.SUBMITTED_BY.toUpperCase(), isSortable: false },
    { id: "actions", numeric: false, disablePadding: false, label: "", isSortable: false },
  ])

  //This function retrives the stored permissions and id from local storage
  useEffect(() => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
  }, [])

  //Function to get the data of the study from the id
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }

  //Function to get the data of the dashboard of the study from the id
  const getDashboard = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/dashboard/${id.current}`);
      setDataDashboard(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}/summary-forms/locale/en`);

      setForms(response.data.data.items)

    } catch (err) {
      setForms(err.message)
    }
  }

  const getMaxLengthAnswers = async () => {
    try {
      const response2 = await MyAxiosInstance.get(`${BASE_PATH}/studies/${id.current}/form/${idForm}/questions`);
      var variables = []
      var end = []

      response2.data.data.item.forEach((res) => {
        if (res.type != "INFO") {
          variables.push(res.variable_name)
        }
      });
      end = [].concat.apply([], variables)
      unique = [...new Set(end)];
      setUniqueDownload([...new Set(end)])
      unico = [...new Set(end)]
    } catch (err) {

      setMaxLength(err.message)
    }
  }

  const getAnswers = async () => {
    try {
      const response2 = await MyAxiosInstance.get(`${BASE_PATH}/studies/${id.current}/form/${idForm}/questions`);

      var unico = []
      var variables = []
      var end = []
      response2.data.data.item.forEach((res) => {
        if (res.type != "INFO") {
          variables.push(res.variable_name)
        }
      });
      end = [].concat.apply([], variables)
      unique = [...new Set(end)];
      setUniqueDownload([...new Set(end)])
      unico = [...new Set(end)]

      for (var i = 0; i < unico.length; i++) {
        headCells.splice(i + 2, 0, {
          id: unico[i],
          numeric: false,
          disablePadding: false,
          label: `${unico[i]}`,
          isSortable: false
        })
      }
      const response = await MyAxiosInstance.get(`${BASE_PATH}/form/${idForm}/answers`);

      var data = []
      var allSummaries = []
      var dates = []
      response.data.data.item.forEach((res) => {
        var summary = {}
        summary["id"] = res.id
        summary["uuid"] = res.uuid

        var answers = []
        var entriesValues = {}
        entriesValues["id"] = res.id
        if (res.participant == null) {
          entriesValues["participant_id"] = "Anonymous"
        } else {
          entriesValues["participant_id"] = res.participant.uuid
        }
        for (var i = 0; i < res.answers.length; i++) {
          if (res.answers[i].question !== null && res.answers[i].question.type !== "INFO") {
            answers.push(res.answers[i])
          }
        }
        orderArray(unique, answers)
        for (var j = 0; j < unique.length; j++) {
          if (answers[j] != undefined) {
            if (answers[j].data === answers[j].data_label) {
              entriesValues[`${unique[j]}`] = answers[j].data
            } else if (answers[j].question.type.includes("SELECT")) {
              entriesValues[`${unique[j]}`] = `[${answers[j].data}] ` + answers[j].data_label
            } else {
              entriesValues[`${unique[j]}`] = `[${answers[j].data}] ` + answers[j].data_label
            }
          } else {
            entriesValues[`${unique[j]}`] = "-"
          }
        }
        if (answers.length === 0) {
          for (var y = 0; y < answers.length; y++) {
            entriesValues[`${unique[y]}`] = "-"
          }
        }
        Moment.locale("es")
        entriesValues["submitted_at"] = Moment(res.updated_at).format("DD-MM-YYYY")
        dates.push(new Date(res.updated_at))
        if (res.participant == null) {
          entriesValues["user"] = "Anonymous"
        } else {
          entriesValues["user"] = res.participant.user_name
        }
        data.push(entriesValues)
        allSummaries.push(summary)
      });
      data.reverse()
      var minDate = new Date(Math.min(...dates));
      setMinDate(minDate.toISOString().split("T")[0])
      setResponse(data)
      setAllSummaries(allSummaries)
    } catch (err) {
      setResponse(err.message)
      setResponse([])
    }
    setLoading(false)
  }

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {

    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (open) {
        if (window.innerWidth >= 640 && window.innerWidth <= 2000) {
          setDevicesSize("280px")
          setCardWidth("340px")
          setTableWidth("100%")
          setExportButtonMargin("38em")

        } else if (window.innerWidth >= 2560) {
          setTableWidth("2000px")
          setExportButtonMargin("38em")

        } else if (window.innerWidth <= 500) {
          setExportButtonMargin("1em")
        } else {
          setDevicesSize("10px")
          setCardWidth("200px")
          setTableWidth("100%")
          setExportButtonMargin("3em")

        }

      } else {

        if (window.innerWidth >= 2560) {

          setTableWidth("2250px")
          setExportButtonMargin("38em")

        } else if (window.innerWidth >= 640 && window.innerWidth <= 2000) {
          setDevicesSize("280px")
          setCardWidth("340px")
          setTableWidth("100%")
          setExportButtonMargin("38em")

        } else if (window.innerWidth <= 500) {

          setDevicesSize("180px")
          setCardWidth("350px")
          setTableWidth("100%")
          setExportButtonMargin("1em")

        } else {
          setExportButtonMargin("3em")
          setTableWidth("100%")
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  useEffect(() => {
    getStudy()
    getForms()
    getDashboard()
  }, []);

  useEffect(() => {
    setOpen(drawer)

  }, [drawer])

  useEffect(() => {
    setLoading(true)
    if (idForm !== undefined)
      headCells.splice(2, headCells.length - 5)
    getMaxLengthAnswers()
    getAnswers()
  }, [idForm])

  const handleChangeForm = (event) => {
    setIdForm(event.target.value);
  };

  //Function to open the modal of the questions
  const handleOpenModal = () => {
    setShowDownload(1)
  }

  //Function to show or not the addModal
  const handleAddParticipant = () => {
    setShowAddModal(-999)
  }

  //Function to show or not the editModal
  const handleEditParticipant = () => {
    setShowEditModal(-999)
  }

  const handleDownloadPdf = async () => {

    const uuid = allSummaries.find(o => o.id === showDownloadPdfModal).uuid;

    try {
      const response = axios(`${BASE_PATH}/print/${uuid}`, {
        method: "GET",
        responseType: "blob", //Force to receive data in a Blob Format
        headers: {
          "Authorization": `Bearer ${storageManager.getToken()}`
        }
      })
        .then(response => {//Create a Blob from the PDF Stream
          const file = new Blob(
            [response.data],
            { type: "application/pdf" });//Build a URL from the file
          const fileURL = URL.createObjectURL(file);//Open the URL on new Window
          window.open(fileURL);
        })
        .catch(error => {

        });
      setShowDownloadPdfModal(-999)
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  //Function to show or not the deleteModal
  const handleDeleteParticipant = async () => {
    setLoading(true)
    let body = null
    if (selectedDate !== null) {

      body = { "ids": selectedDate }
    } else {
      body = { "ids": [showDeleteModal] }
    }

    try {
      const response = await MyAxiosInstance.post(`${BASE_PATH}/form-answers`, body);
      setSelectedDate(null)
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    headCells.splice(2, headCells.length - 5)
    getMaxLengthAnswers().then(getAnswers)
    setShowDeleteModal(-999)
  }
  const classes = useStyles();

  return (
    <>
      {study &&
        <DrawerUhda id={id.current} drawer={open} select={0} settings={study.permissions} />}
      <Grid container style={{ paddingLeft: devicesSize, minWidth: "100%" }}
        direction={"column"}
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item>
          {study && <BreadcrumbsUhda routes={
            [
              { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
              {
                id: 1,
                label: `${study.translations[0].study_title}`,
                studyId: id.current,
                permissions: permissions.current,
                disabled: true
              },
              { id: 2, label: LabelsStudy.DASHBOARD },
            ]
          } />}
        </Grid>

        {dataDashboard && <Grid container spacing={4}>
          <Grid item className="dashboard-grid" style={{ maxWidth: cardWidth }}>
            <CardUhda width={"303px"} height={"150px"} bg={AppColors.WHITE} children={
              <Grid container
                xs={8}
                sm={5}
                md={8}
                lg={5}
                xl={10} spacing={1}
                style={{ position: "relative", margin: "1em", display: "flex", minWidth: 500 }}
              >
                <Box>
                  <Grid item xs={6} sm container>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs>
                        <TextUhda margin={0} text={DashboardTexts.STUDY_PARTICIPANTS}
                          color={AppColors.SECONDARY_TITLE}
                          type={textType.SUBTITLE} />
                      </Grid>
                      <Grid item xs>

                        <TextUhda margin={0} text={dataDashboard.participants_current}
                          color={AppColors.BLACK}
                          type={textType.NUMBER} />
                      </Grid>

                      {dataDashboard.participants_percent_lastweek < 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.participants_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.RED}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon}
                          src={arrowDown} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}
                      {dataDashboard.participants_percent_lastweek > 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.participants_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.GREEN}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon}
                          src={arrowUp} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}
                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={1} style={{ marginLeft: "45px", marginTop: "30px" }} container>
                  <Avatar
                    className={classes.avatar}
                    sx={{ width: 62, height: 62, bgcolor: AppColors.BOX_FILL }}>
                    <img alt='icon' style={{ height: 25, width: 25 }} className={classes.imageIcon}
                      src={participantsIcon} />
                  </Avatar>

                </Grid>
              </Grid>

            } />
          </Grid>
          <Grid item style={{ maxWidth: cardWidth }}>
            <CardUhda width={"303px"} height={"150px"} bg={AppColors.WHITE} children={
              <Grid container
                xs={8}
                sm={5}
                md={8}
                lg={5}
                xl={10} spacing={1}
                style={{ position: "relative", margin: "1em", display: "flex", minWidth: 500 }}
              >
                <Box>
                  <Grid item xs={6} sm container>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs>
                        <TextUhda margin={0} text={DashboardTexts.STUDY_TEAM_MEMBERS}
                          color={AppColors.SECONDARY_TITLE}
                          type={textType.SUBTITLE} />
                      </Grid>
                      <Grid item xs>

                        <TextUhda margin={0} text={dataDashboard.team_members_current}
                          color={AppColors.BLACK}
                          type={textType.NUMBER} />
                      </Grid>

                      {dataDashboard.team_members_percent_lastweek < 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.team_members_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.RED}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon} src={arrowDown} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}

                      {dataDashboard.team_members_percent_lastweek > 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.team_members_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.GREEN}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon} src={arrowUp} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}
                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={1} style={{ marginLeft: "20px", marginTop: "30px" }} container>
                  <Avatar
                    className={classes.avatar}
                    sx={{ width: 62, height: 62, bgcolor: AppColors.BOX_FILL }}>
                    <img alt='icon' style={{ height: 25, width: 25 }} className={classes.imageIcon}
                      src={teamMembersIcon} />
                  </Avatar>

                </Grid>
              </Grid>

            } />
          </Grid>

          <Grid item style={{ maxWidth: cardWidth }}>
            <CardUhda width={"303px"} height={"150px"} bg={AppColors.WHITE} children={
              <Grid container
                xs={8}
                sm={5}
                md={8}
                lg={5}
                xl={10} spacing={1}
                style={{ position: "relative", margin: "1em", display: "flex", minWidth: 500 }}
              >
                <Box>
                  <Grid item xs={6} sm container>
                    <Grid container direction="column" spacing={1}>
                      <Grid item xs>
                        <TextUhda margin={0} text={DashboardTexts.DATA_POINTS_COLLECTED}
                          color={AppColors.SECONDARY_TITLE}
                          type={textType.SUBTITLE} />
                      </Grid>
                      <Grid item xs>
                        <TextUhda margin={0} text={dataDashboard.data_points_current}
                          color={AppColors.BLACK}
                          type={textType.NUMBER} />
                      </Grid>

                      {dataDashboard.data_points_percent_lastweek < 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.data_points_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.RED}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon} src={arrowDown} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}
                      {dataDashboard.data_points_percent_lastweek > 0 && <Grid container item>
                        <TextUhda margin={0}
                          text={`${(dataDashboard.data_points_percent_lastweek).toFixed(1).replace(".", ",")}%`}
                          color={AppColors.GREEN}
                          type={textType.SUBTITLE_BOLD} />
                        <img alt='icon' style={{ height: 16, width: 16, paddingTop: 2 }}
                          className={classes.imageIcon} src={arrowUp} />

                        <TextUhda margin={2} text={"Since last week"}
                          color={AppColors.SECONDARY_SUBTEXT}
                          type={textType.BODY_SUBTEXT} />

                      </Grid>}

                    </Grid>
                  </Grid>
                </Box>
                <Grid item xs={1} style={{ marginLeft: "20px", marginTop: "30px" }} container>
                  <Avatar
                    className={classes.avatar}
                    sx={{ width: 62, height: 62, bgcolor: AppColors.BOX_FILL }}>
                    <img alt='icon' style={{ height: 25, width: 25 }} className={classes.imageIcon}
                      src={dataPointsIcon} />
                  </Avatar>

                </Grid>
              </Grid>

            } />
          </Grid>
        </Grid>}

        <Grid container style={{ paddingTop: "2em" }} direction={"column"}>
          <Grid container
            spacing={3}         //style={{paddingBottom: 0}}

          >
            <Grid item >
              <FormControl style={{ width: "272px" }} variant="outlined" className={classes.textFieldLabel}>
                <InputLabel
                  style={{ color: AppColors.PRIMARY, paddingTop: 0 }}>{DashboardTexts.CHOOSE_DATA}</InputLabel>
                {forms && <Select
                  className={classes.textFieldLabel}
                  label={DashboardTexts.CHOOSE_DATA}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                  onChange={handleChangeForm}
                >
                  {forms.map(form => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem value={form.id}>{form.form_translations[0].title}</MenuItem>))}

                </Select>}
              </FormControl>
            </Grid>

            <Grid
              direction="column"
              justifyContent="flex-end"
              style={{ marginTop: "10px" }}
            //alignItems="flex-end"

            >
              {(idForm && response.length !== 0) && study.permissions.includes("export-data") &&
                <div style={{ marginLeft: window.innerWidth >= 700 && window.innerWidth <= 1500 ? "3em" : exportButtonMargin }}>
                  <ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
                    border={"2px solid"} onClick={handleOpenModal}
                    icon={<img alt='icon' style={{ height: 25, width: 25 }}
                      className={classes.imageIcon} src={downloadEnabledIcon} />}
                    text={CommonsTexts.EXPORT} /></div>}

            </Grid>
          </Grid>

          {
            loading ?
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
              :
              <Grid item style={{ maxWidth: tableWidth, }}
              >
                {idForm && <TableBaseUhda
                  tableTitle={TableTexts.FORM_RESPONSES}
                  headCells={headCells}
                  dataToUse={response}
                  addModal={AddModal}
                  deleteModal={DeleteModal}
                  editModal={EditModal}
                  initialValues={initialValues}
                  values={values}
                  setValues={setValues}
                  editValues={editValues}
                  setEditValues={setEditValues}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  handleAddParticipant={handleAddParticipant}
                  handleEditParticipant={handleEditParticipant}
                  handleDeleteParticipant={handleDeleteParticipant}
                  handleDownloadPdf={handleDownloadPdf}
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  showDeleteModal={showDeleteModal}
                  downloadPdfModal={DownloadPdfModal}
                  setShowDeleteModal={setShowDeleteModal}
                  showDownloadPdfModal={showDownloadPdfModal}
                  setShowDownloadPdfModal={setShowDownloadPdfModal}
                  interactive={true}
                  edit={false}
                  paperWidth={tableWidth}
                  paperMargin="8px"
                  //size={false}
                  canDelete={study.permissions.includes("delete-data")}
                  canDownload={false}
                  textDataEmpty={TableTexts.NO_ELEMENTS}

                />}

                {!idForm && <TableBaseUhda
                  tableTitle={TableTexts.FORM_RESPONSES}
                  headCells={headCells}
                  dataToUse={null}
                  addModal={AddModal}
                  deleteModal={DeleteModal}
                  editModal={EditModal}
                  initialValues={initialValues}
                  values={values}
                  setValues={setValues}
                  editValues={editValues}
                  setEditValues={setEditValues}
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  handleAddParticipant={handleAddParticipant}
                  handleEditParticipant={handleEditParticipant}
                  handleDeleteParticipant={handleDeleteParticipant}
                  showAddModal={showAddModal}
                  setShowAddModal={setShowAddModal}
                  showEditModal={showEditModal}
                  setShowEditModal={setShowEditModal}
                  showDeleteModal={showDeleteModal}
                  setShowDeleteModal={setShowDeleteModal}
                  interactive={true}
                  edit={false}
                  //size={false}
                  paperWidth={tableWidth}
                  paperMargin="8px"
                  canDownload={false}
                  textDataEmpty={TableTexts.SELECT_FORM}

                />}

              </Grid>}

        </Grid>
      </Grid>
      {showDownload >= 0 &&
        <DownloadModal
          minDate={minDate}
          formTitle={"{form.form_translations[0].title}"}
          formId={idForm}
          studyId={id.current}
          initialValues={initialValues}
          values={values}
          setValues={setValues}
          showDownload={showDownload}
          setShowDownload={setShowDownload}
          headCells={uniqueDownload}

        />
      }
    </>
  )
}
export default DashboardPage;
