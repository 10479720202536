/* eslint-disable camelcase */
export default class MyStorageManager {
    _USERNAME = "uhs-username";
    _ID = "uhs-id";
    _SURNAME = "uhs-surname";
    _NAME = "uhs-name"
    _EMAIL = "uhs-email";
    _PWD = "uhs-pwd";
    _IS_ADMIN = 0;
    _TOKEN = "uhs-token";
    _TOKEN_TEMPORAL = "uhs-token-temporal";
    _EXPIRATION_DATE = "ush-token-expiration";
    _localStorage = window.localStorage;
    _sessionStorage = window.sessionStorage;

    // Get and store the username
    getEmail = () => this._localStorage.getItem(this._EMAIL) || "";
    storeEmail = (email) =>
        this._localStorage.setItem(this._EMAIL, email);

    // Get and store the username
    getName = () => this._localStorage.getItem(this._NAME) || "";
    storeName = (name) =>
        this._localStorage.setItem(this._NAME, name);

    // Get and store the username
    getUsername = () => this._localStorage.getItem(this._USERNAME) || "";
    storeUsername = (username) =>
        this._localStorage.setItem(this._USERNAME, username);

    // Get and store the surname
    getSurname = () => this._localStorage.getItem(this._SURNAME) || "";
    storeSurname = (surname) =>
        this._localStorage.setItem(this._SURNAME, surname);

    // Get and store the password
    getPwd = () => this._localStorage.getItem(this._PWD) || "";
    storePwd = (pwd) => this._localStorage.setItem(this._PWD, pwd);

    // Get and store the password
    getId = () => this._localStorage.getItem(this._ID) || "";
    storeId = (id) => this._localStorage.setItem(this._ID, id);

    // Get and store the surname
    getAdmin = () => this._localStorage.getItem(this._IS_ADMIN) || 0;
    storeAdmin = (is_admin) =>
        this._localStorage.setItem(this._IS_ADMIN, is_admin);

    // Get and store the token
    getToken = () =>
        this._localStorage.getItem(this._TOKEN) ||
        this._sessionStorage.getItem(this._TOKEN) ||
        "";
    getTokenTemporal = () =>
        this._localStorage.getItem(this._TOKEN_TEMPORAL) ||
        this._sessionStorage.getItem(this._TOKEN_TEMPORAL) ||
        "";
    storeToken = (token) => this._localStorage.setItem(this._TOKEN, token);
    storeTokenTemporal = (token) => this._localStorage.setItem(this._TOKEN_TEMPORAL, token);
    storeTokenOnSession = (token) => {
        this._sessionStorage.setItem(this._TOKEN, token);
    };
    removeToken = () => {
        this._localStorage.removeItem(this._TOKEN);
        this._sessionStorage.removeItem(this._TOKEN);
    };

    // Get and store the expiration date of the token
    getExpirationDate = () =>
        this._localStorage.getItem(this._EXPIRATION_DATE) || "";
    storeExpirationDate = (date) =>
        this._localStorage.setItem(this._EXPIRATION_DATE, date);

    // Store both the username and the password
    storeCredentials = (username, pwd) => {
        this.storeUsername(username);
        this.storePwd(pwd);
    };

    // Store the username, the password and the token
    storeCredentialsWithToken = (username, pwd, token) => {
        this.storeUsername(username);
        this.storePwd(pwd);
        this.storeToken(token);
    };

    // Store both the token and its expiration date
    storeTokenWithExpirationDate = (token, date) => {
        this.storeToken(token);
        this.storeExpirationDate(date);
    };

    // Clear local storage
    clear = () => this._localStorage.clear();

    // Clear local storage except the username and the password
    clearExceptCredentials = () => {
        this._localStorage.removeItem(this._TOKEN);
        this._localStorage.removeItem(this._EXPIRATION_DATE);
    };
}
