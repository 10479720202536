import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, TextField, Typography } from "@material-ui/core";
import { AppColors } from "../resources/AppColors";
import AuthContext from "../context/authentication/authContext";
import { makeStyles } from "@material-ui/core/styles";
import { CommonsTexts, ErrorTexts, SnackBarResponses } from "../locale/en";
import { Alert } from "@material-ui/lab";
import CardUhda from "../components/CardUhda";
import NavbarUhda from "../components/NavbarUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import ButtonUnfilledUhda from "../components/ButtonUnfilledUhda";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import SnackBarUhda from "../components/SnackBarUhda";
import taglineIcon from "../img/UhDa_Studies.png"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND,
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },

      "& .MuiInputBase-root": {
        color: AppColors.PRIMARY,
      },
      "& .MuiInputLabel-root": {
        color: AppColors.PRIMARY,
      },
    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
      //marginTop: '-0.3em'
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },

  textFieldLabelWithText: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
      //marginTop: '-0.3em'
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon: {
    height: "50px",
  },
}));

/**
 * @Page
 * Page for when the user have forgot the password and want to change it
 */
export default function ForgotPass(props) {
  const [email, setEmail] = useState("");

  const authContext = useContext(AuthContext);
  const { message, authenticated, reset, checkAuth } = authContext;

  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openSendEmail, setOpenSendEmail] = useState()

  useEffect(() => {
    checkAuth();
    if (authenticated) {
      props.history.push("/home");
    }
    if (message) {
      setAlertMessage(message);
      setShowValidationAlert(true);
    }
  }, [message, authenticated, props.history]);

  //Function to reset the password
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") setShowValidationAlert(true);
    else {
      const params = {
        email: email,
      };

      setShowValidationAlert(false);
      reset(params);
      setOpenSendEmail(true)
    }
  };

  const handleCloseEmail = () => {
    setOpenSendEmail(false);
  };

  //Function that will be called when the user wants to go to the login Page
  const handleLogin = async () => {
    props.history.push("/login");
  };

  const classes = useStyles();
  return (
    <Grid
      className={classes.background}
      container
      style={{ minHeight: "100vh" }}
      alignItems={"center"}
      direction={"column"}
    >
      <Grid item>
        <NavbarUhda
          open={false}
          setOpen={false}
          setSelected={null}
          logged={false}
        />
      </Grid>

      <Grid item style={{ marginTop: "25vh", marginBottom: "15vh" }}>
        <CardUhda
          height={"338px"}
          width={"582px"}
          //style={{marginLeft: "5px"}}
          bg={AppColors.WHITE}
          children={
            <Grid
              container
              item
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <div
                style={{
                  margin: "5vh",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 300,

                }}
              >
                <div style={{ textAlign: "center" }}>
                  <TextUhda
                    type={textType.TITLE}
                    text={CommonsTexts.FORGOT_PASS.toUpperCase()}
                  />
                </div>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  label="E-mail"
                  margin="normal"
                  variant="outlined"
                  className={email === "" ? classes.textFieldLabel : classes.textFieldLabelWithText}
                />

                <Grid
                  container
                  style={{ paddingTop: "10px" }}
                  spacing-xs-1
                  wrap="nowrap"
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container item alignItems="center" justify="center">
                    <ButtonFilledUhda
                      margin={"0.7em"}
                      text={CommonsTexts.RESET_PASS}
                      onClick={handleSubmit}
                    />
                  </Grid>
                  <Grid container item alignItems="center" justify="center">
                    <ButtonUnfilledUhda
                      onClick={handleLogin}
                      children={
                        <TextUhda
                          color={AppColors.PRIMARY}
                          type={textType.BODY}
                          text={CommonsTexts.LOGIN}
                        />
                      }
                    />
                    {showValidationAlert &&
                      (alertMessage ? (
                        <Alert severity="error">{alertMessage.message}</Alert>
                      ) : (
                        <Alert severity="error">{ErrorTexts.LOGIN}</Alert>
                      ))}
                  </Grid>
                </Grid>
              </div>
            </Grid>
          }
        />
      </Grid>
      <SnackBarUhda severity={"info"} handleClose={handleCloseEmail} message={SnackBarResponses.EMAIL_SEND}
        openSnack={openSendEmail} />

      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          paddingBottom: "1em",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box style={{ marginBottom: 0 }} display="flex" justifyContent="center" m={3}>
          <Box style={{ marginTop: "0.8em", width: "38em", marginLeft: 0, marginRight: 0 }} mx="auto">
            <Typography style={{ marginLeft: "20em" }}>{CommonsTexts.ABOUT}</Typography>
            <Typography>{CommonsTexts.ABOUT_TEXT}</Typography>

          </Box>
          <Box style={{ margin: 0 }} direction="row" display="flex" justifyContent="center" m={3}
            className="bottom-bar"
          >
            <Typography style={{ marginTop: "0.5em" }} >Powered by </Typography>
            <img alt='icon' className={classes.icon} src={taglineIcon} />
          </Box>
          {/*  <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/UhD-Studies-User-Guide-b0d41862529a4d8196d56370465c7250'
              target="_blank" rel="noreferrer">{CommonsTexts.USER_GUIDE}</a>
          </Box> */}
          {/* <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/Terms-of-Use-f5a590057a7f46deaa854af7cc336dc2'
              target="_blank" rel="noreferrer">{CommonsTexts.TERMS}</a>
          </Box> */}
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vSz4FmaCBAcvcuZ1Kw9eB7nmCgSS0lwaY5C3rMN9vN3Xd7Mhva9U8ffdGx-e1vIqgCn0P7KtlyT27b_/pub'
              target="_blank" rel="noreferrer">{CommonsTexts.PRIVACY}</a>
          </Box>
        </Box>

      </AppBar>
    </Grid>
  );
}
