/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CardActions, CardContent, CardHeader, Fade, Grid, Link, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import Icons from "../../resources/Icons";
import CardUhda from "../CardUhda";
import { useHistory } from "react-router-dom";
import { AppTextsFontSize, AppTextsFontWeight, textType, useTextStyles } from "../../resources/AppTexts";
import { menuOptions } from "../../locale/en";
import TextUhda from "../TextUdha";
import IconProviderUhda from "../IconProviderUhda";
import "../../styles/studiesCard.scss";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  text: {
    fontSize: AppTextsFontSize.SIZE_BODY,
    fontWeight: AppTextsFontWeight.WEIGHT_LIGHT
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    overflowX: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: `underline ${AppColors.WHITE}`
    }
  }, cardHeaderRoot: {
    overflow: "hidden"
  },
  cardHeaderContent: {
    overflow: "hidden"
  }

});
/**
 * @component
 * Component to create the card of one form
 *
 * @param {number} studyId: id of the study
 * @param {number} formId: id of the form
 * @param {string} formTitle: title of the form
 * @param {string} formDescription: description of the form
 * @param {number} numberOfResponses: responses of the form
 * @param {function} handleOnEdit: handle to push to the edit page
 * @param {function} handleOnActivate:
 * @param {function} handleOnInactivate:
 * @param {function} handleOnDuplicate:
 * @param {function} handleOnExport:
 * @param {function} handleOnDownload:
 * @param {function} handleOnDelete:
 * @constructor
 * <FormCardUhda studyId={25}  formId={15}  formTitle={"Title"}  formDescription={"Description"}
 * numberOfResponses={23} handleOnEdit={handleOnEdit}  handleOnDuplicate={handleOnDuplicate} handleOnDelete={handleOnDelete} />
 */
const FormCardUhda = ({
  studyId,
  formId,
  formTitle,
  formDescription,
  handleOnEdit,
  handleOnDuplicate,
  handleOnDelete,
  permissions,
  labels
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const texts = useTextStyles();
  const history = useHistory()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClickHandler = () => {
    localStorage.setItem("formRoute", "edit")
    const newObj = { "detail": studyId, "formId": formId, "permissions": labels }
    localStorage.setItem("formDetails", JSON.stringify(newObj));

    history.push({
      pathname: `/study/${studyId}/form/${formId}`, //TODO cambiar porque es de pruebas para el study
      state: { detail: studyId, formId: formId, value: 6, permissions: labels }

    })

  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  /*
      const truncate = (str, n) => {
          return str.length > n ? str.substring(0, n) + "..." : str;
      };

      const newTitle = truncate(formTitle, 10)
      const newDescription = truncate(formDescription, 40)*/
  const body = (
    <Grid
      container
      item
      direction="column"
      justifyContent="space-between">
      <Link className={classes.link}>
        <CardHeader classes={{
          title: texts.title_bold,
          root: classes.cardHeaderRoot,
          content: classes.cardHeaderContent
        }} style={{
          height: "64px",
          width: "232px",
          color: AppColors.PRIMARY,
          paddingLeft: "24px",
          paddingTop: "24px",
          paddingBottom: "8px"
        }}

          onClick={onClickHandler}
          title={
            <TextUhda margin={"0px"} style={"break-word"} type={textType.TITLE_BOLD}
              text={formTitle} />}
        />

      </Link>
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs zeroMinWidth>
          <CardContent style={{
            textAlign: "center",
            height: "110px", width: "250px",
            paddingTop: "6px", paddingBottom: 0, paddingLeft: "1px", paddingRight: "4px",
          }}>
            <TextUhda margin={"0px"} style={"break-word"} type={textType.BODY_CARD}
              text={formDescription} />
          </CardContent>
        </Grid>
      </Grid>
      <CardActions>

        <Grid
          style={{ padding: "10px", paddingTop: 0 }}
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item style={{ paddingLeft: "18px" }}>
            {/* <h4 style={{padding: 0, margin: 0}}>
                            <IconProviderUhda icon={<Icons.RESPONSES
                                style={{verticalAlign: "middle", display: "inline-flex", paddingRight: '4px'}}
                                size="1rem"/>}/>
                            {numberOfResponses}
                        </h4>*/}
          </Grid>
          <Grid
            item>
            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
              <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL color={AppColors.PRIMARY} style={{
                verticalAlign: "middle",
                display: "inline-flex",
                paddingRight: "4px"
              }} size="2rem" />} />
            </Button>
            <Menu
              style={{
                boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
              }}
              color={AppColors.WHITE}
              id="fade-menu"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}>
              <MenuItem style={{ color: AppColors.PRIMARY }}
                onClick={() => {
                  handleOnEdit(formId);
                  handleClose()
                }}> {menuOptions.EDIT} </MenuItem>
              {permissions.includes("form-duplicate") && <MenuItem style={{ color: AppColors.PRIMARY }}
                onClick={() => {
                  handleOnDuplicate(formId);
                  handleClose()
                }}> {menuOptions.DUPLICATE}</MenuItem>}
              {permissions.includes("form-delete") && <MenuItem style={{ color: AppColors.PRIMARY }}
                onClick={() => {
                  handleOnDelete(formId);
                  handleClose()
                }}> {menuOptions.DELETE}</MenuItem>}
            </Menu>
          </Grid>

        </Grid>
      </CardActions>
    </Grid>

  );

  return <div style={{ width: "280px", height: "280px" }}><CardUhda> {body} </CardUhda></div>

}

FormCardUhda.propTypes = {

  formId: PropTypes.number,
  formTitle: PropTypes.string,
  formDescription: PropTypes.string,
  numberOfResponses: PropTypes.number,
  handleOnEdit: PropTypes.func,
  handleOnActivate: PropTypes.func,
  handleOnInactivate: PropTypes.func,
  handleOnDuplicate: PropTypes.func,
  handleOnExport: PropTypes.func,
  handleOnDownload: PropTypes.func,
  handleOnDelete: PropTypes.func,
}

export default FormCardUhda;
