import React from "react";
import { Breadcrumbs, Link } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { AppColors } from "../../resources/AppColors";
import PropTypes from "prop-types";
import { textType, useTextStyles } from "../../resources/AppTexts";
import TextUhda from "../TextUdha"
import { useHistory } from "react-router-dom";

/**
 * @component
 * Component to create custom Breadcrumbs depending on the routes
 *
 * @param routes: array of routes to show
 *
 * @example
 * const routes=[{}]
 * <BreadcrumbsUhda routes={routes} />
 *
 */
const BreadcrumbsUhda = ({ routes }) => {
  const history = useHistory()
  const texts = useTextStyles();
  const truncate = (str, n) => {
    return str.length > n ? str.substring(0, n - 3) + "..." : str;
  };

  return (
    <div>
      <Breadcrumbs className={texts.subtitle} style={{ color: AppColors.PRIMARY }}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb">
        {routes.map((value, index) => {
          const newLabel = truncate(value.label, 20);
          if (index === routes.length - 1) {
            return (
              <div style={{ marginTop: "15px" }} key={index}>
                <TextUhda type={textType.TITLE} color={AppColors.ACCENT} text={value.label} />
              </div>
            )
          } else {
            if (value.disabled) {
              return (
                <div style={{ marginTop: "15px" }} key={index}>
                  <TextUhda type={textType.TITLE} color={AppColors.PRIMARY} text={value.label} />
                </div>
              )
            } else {
              return (
                <Link key={index} onClick={() => history.push({
                  pathname: value.url,
                  state: { detail: value.studyId, permissions: value.permissions }
                })} style={{ cursor: "pointer", fontSize: "18px", color: AppColors.PRIMARY, fontWeight: "bold", marginTop: "15px" }}>
                  {newLabel}
                </Link>
              )
            }
          }
        })}
      </Breadcrumbs>
    </div>
  )
}

BreadcrumbsUhda.propTypes = {
  routes: PropTypes.array.isRequired,
}

export default BreadcrumbsUhda
