/* eslint-disable */

import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Thread, Window, LoadingIndicator } from "stream-chat-react";
import { useChatClient } from "../../hooks/useChatClient";
import { Box } from "@mui/system";
import { AppColors } from "resources/AppColors";

import "stream-chat-react/dist/css/v2/index.css";
import "./styles.css";

const ChatUhda = ({ channeLId, user }) => {
  const { chatClient, channel } = useChatClient({
    apiKey: "hcq6wjqutq32",
    userData: user.id,
    channeLId
  });

  if (!chatClient) {
    return (
      <Box className="center" >
        <LoadingIndicator size={45} color={AppColors.PRIMARY} />
      </Box>
    )
  }

  return (
    <Chat client={chatClient} theme="str-chat__theme-light">
      <Channel channel={channel}>
        <Window>
          <ChannelHeader />
          <MessageList messageActions={['delete', 'flag', 'mute', 'pin', 'quote', 'react', 'reply']} />
          <MessageInput />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default ChatUhda