import {
  QUESTION_CALENDAR,
  QUESTION_INFO,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_NUMERIC,
  QUESTION_RANGE,
  QUESTION_SINGLE_CHOICE
} from "./QuestionConstants";

// Question types used for recommending variable names in the visibility formula (QuestionBuilderUhda)
const visibilityFormulaQuestions = [
  QUESTION_RANGE,
  QUESTION_NUMERIC,
  QUESTION_SINGLE_CHOICE,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_CALENDAR
];
const isQuestionTypeSingleChoice = type => QUESTION_SINGLE_CHOICE === type;
const isQuestionTypeMultipleChoice = type => QUESTION_MULTIPLE_CHOICE === type;
export const isQuestionTypeSingleOrMultipleChoice =
    type => isQuestionTypeSingleChoice(type) || isQuestionTypeMultipleChoice(type);
export const isQuestionTypeSingleOrMultipleChoiceOrRange =
    // eslint-disable-next-line no-use-before-define
    type => isQuestionTypeSingleChoice(type) || isQuestionTypeMultipleChoice(type) || isQuestionTypeRange(type);

export const isQuestionTypeInfo = type => QUESTION_INFO === type;
export const isQuestionTypeNumeric = type => QUESTION_NUMERIC === type;
export const isQuestionTypeRange = type => QUESTION_RANGE === type;
export const isQuestionTypeCalendar = type => QUESTION_CALENDAR === type;

export const isQuestionTypeForVisibilityFormula = type => visibilityFormulaQuestions.includes(type);
