import { useEffect, useState } from "react";
import { CHAT_REGISTER } from "resources/ApiUrls";
import { StreamChat } from "stream-chat";
import MyAxiosInstance from "utils/MyAxiosInstance";

export const useChatClient = ({ apiKey, userData, channeLId }) => {
  const [chatClient, setChatClient] = useState(null);
  const [channel, setChannel] = useState(null)
  const [userToken, setToken] = useState(null)

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await MyAxiosInstance.get(CHAT_REGISTER) // * participant's uuid is chat id
        setToken(token.data.data.value)
      } catch (error) {
        console.error(error)
        throw new Error("Error while getting token from server")
      }
    }
    getToken()
  }, [])

  useEffect(() => {
    if (!userToken || !userData) {
      console.log("CONNECTING");
      return
    }
    const client = StreamChat.getInstance(apiKey);

    // * prevents application from setting stale client (user changed, for example)
    let didUserConnectInterrupt = false;

    // * Add user name
    const connectionPromise = client.connectUser({ id: userData }, userToken).then(() => {
      if (!didUserConnectInterrupt) {
        setChatClient(client);
        setChannel(client.channel("messaging", channeLId))
      }
    });

    return () => {
      didUserConnectInterrupt = true;
      setChatClient(null);
      // * wait for connection to finish before initiating closing sequence
      connectionPromise
        .then(() => client.disconnectUser())
        .then(() => {
          console.log("connection closed");
        });
    };
  }, [apiKey, userData, userToken, channeLId]);

  return { chatClient, channel };
};