import {FORM_STUDY} from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

// const getForms = (id) => MyAxiosInstance.get(FORMS_STUDY(id));

const postForm = (id, body) => MyAxiosInstance.post(FORM_STUDY(id), body);

const putForm = (id, body) => MyAxiosInstance.put(FORM_STUDY(id), body);

const exports = {
  // getForms,
  postForm,
  putForm,
}
export default exports
