import {
  DELETE_QUESTION, DELETE_QUESTION_SECTION, DELETE_QUESTION_SUMMARY,
  DELETE_SECTION, DELETE_SUMMARY, DUPLICATE_QUESTION,
  POST_QUESTION,
  POST_QUESTIONS,
  POST_SECTION, POST_SUMMARY,
  PUT_SECTION, PUT_SUMMARY
} from "../resources/ApiUrls";
import MyAxiosInstance from "../utils/MyAxiosInstance";

const postQuestion = body => MyAxiosInstance.post(POST_QUESTION, body);

const postQuestions = body => MyAxiosInstance.post(POST_QUESTIONS, body)

const deleteQuestion = id => MyAxiosInstance.delete(DELETE_QUESTION(id))

const deleteQuestionSection = (sectionId, questionId) => MyAxiosInstance.delete(DELETE_QUESTION_SECTION(sectionId, questionId))

const duplicateQuestion = (questionId) => MyAxiosInstance.post(DUPLICATE_QUESTION(questionId))

const postSection = (studyId, formId, body) => MyAxiosInstance.post(POST_SECTION(studyId, formId), body)

const putSection = (studyId, formId, body) => MyAxiosInstance.put(PUT_SECTION(studyId, formId), body)

const deleteSection = (studyId, formId, sectionId) => MyAxiosInstance.delete(DELETE_SECTION(studyId, formId, sectionId))

const postSummary = (studyId, formId, body) => MyAxiosInstance.post(POST_SUMMARY(studyId, formId), body)

const putSummary = (studyId, formId, body) => MyAxiosInstance.put(PUT_SUMMARY(studyId, formId), body)

const deleteSummary = (studyId, formId, summaryId) => MyAxiosInstance.delete(DELETE_SUMMARY(studyId, formId, summaryId))

const deleteQuestionSummary = (summaryId, questionId) => MyAxiosInstance.delete(DELETE_QUESTION_SUMMARY(summaryId, questionId))

const exports = {
  postQuestion,
  postQuestions,
  deleteQuestion,
  deleteQuestionSection,
  postSection,
  putSection,
  deleteSection,
  deleteQuestionSummary,
  deleteSummary,
  postSummary,
  putSummary,
  duplicateQuestion
}
export default exports
