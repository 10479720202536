/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox, Fade,
  Grid,
  IconButton, Menu, MenuItem,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { AppColors } from "../../resources/AppColors";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { QUESTION_INFO, questionTypes, questionTypesByName } from "../../resources/QuestionConstants";
import SnackBarUhda from "../SnackBarUhda";
import { menuOptions, SnackBarResponses, TableTexts } from "../../locale/en";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import parse from "html-react-parser"
import { textType } from "../../resources/AppTexts";
import TextUdha from "../TextUdha";
import SearchBar from "material-ui-search-bar"

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
    width: "97.4%"
  },
  tableHead: {
    backgroundColor: AppColors.PRIMARY_WITH_OPACITY
  },
  tableCell: {
    height: "auto",
    paddingLeft: 0,
    fontSize: "16px",
    color: AppColors.PRIMARY,

  },
  tableCellInit: {
    padding: 0,
    paddingLeft: "16px",
    borderTopLeftRadius: "5px!important",
    borderBottomLeftRadius: "5px!important",
    borderLeft: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,
  },
  tableCellDraggable: {
    padding: 0,
    paddingRight: 20,
    borderRadius: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,
  },
  tableCellFinish: {
    padding: 0,
    borderTopRightRadius: "5px!important",
    borderBottomRightRadius: "5px!important",
    borderRight: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,

  },
  switch_track: {
    backgroundColor: AppColors.SECONDARY,
  },
  switch_base: {
    color: AppColors.SECONDARY,
    "&.Mui-disabled": {
      color: AppColors.PRIMARY_WITH_OPACITY
    },
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    }
  },
  switch_primary: {
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    },
  },

}));

const CheckBoxUhda = ({
  formId,
  headCells,
  userData,
  getQuestions,
  getSections,
  loading,
  setLoading,
  setShowDeleteQuestion,
  showEditQuestion,
  setShowEditQuestion,
  handleClickEdit,
  canEdit,
  canDelete,
  selectedQuestions,
  isSummary,
  setSelectedQuestions
}) => {
  const [tableData, setTableData] = useState([])
  const [checked, setChecked] = useState(userData)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [openSnackDelete, setOpenSnackDelete] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [selected, setSelected] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [searched, setSearched] = useState();

  //const open = Boolean(anchorEl);

  const handleActionClick = (id, event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget })

  }

  // eslint-disable-next-line no-unused-vars
  const handleActionClose = (id, event) => {
    delete anchorEl[id]
    setAnchorEl({ ...anchorEl, [id]: null })

  }
  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClosePermissionsDelete = () => {
    setOpenSnackDelete(false);
  };
  const getData = async () => {
    var response = Object.values(userData)
    var data = []
    response.forEach((res) => {
      data.push(res)
    })
    setTableData(data)
  }
  const [questions, setQuestions] = useState(Object(userData));

  useEffect(() => {
    getData()

  }, [])

  const handleDelete = async (id) => {
    if (canDelete) {
      setShowDeleteQuestion(id)
    } else {
      setOpenSnackDelete(true)
    }

  }

  //Function to select all the items on the table from the button upside
  const handleSelectAllClick = (event) => {
    const questionsFinal = [];
    let a = []
    if (event.target.checked && tableData) {
      const newSelected = tableData.map((n) => n.id);
      a = tableData.map((n) => n.id);
      setSelected(newSelected);
      for (let i = 0; i < newSelected.length; i++) {
        questionsFinal.push(questions.find(x => x.id === a[i]))
      }
      setSelectedQuestions(questionsFinal)
      return;
    }
    setSelectedQuestions([])
    setSelected([]);
  };

  //Function to select one item of the table
  const handleSelectClick = (event, question) => {
    const selectedIndex = selected.indexOf(question.id);
    let newSelected = [];
    const questionsFinal = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, question.id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    for (let i = 0; i < newSelected.length; i++) {
      questionsFinal.push(questions.find(x => x.id === newSelected[i]))
    }
    setSelected(newSelected);
    setSelectedQuestions(questionsFinal)

  };

  const requestSearch = (searchedVal) => {
    const filteredRows = Object(userData).filter((row) => {
      return row.variable_name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setQuestions(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  const classes = useStyles();
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <>
      <SearchBar
        style={{ marginRight: "50px" }}

        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
      />
      <Table className={classes.table} aria-label={"simple table"} style={{ paddingRight: "20px" }}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell padding='checkbox'>
              {tableData.length !== 0 && <Checkbox
                style={{ marginLeft: "0.55em" }}
                color="primary"
                indeterminate={selected.length > 0 && selected.length < tableData.length}
                checked={tableData.length > 0 && selected.length === tableData.length}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all" }}
              />}
            </TableCell>

            {headCells.map((headCell) => (
              <TableCell className={classes.tableCell} key={headCell.id}> {headCell.label}</TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody
          className={classes.tableCell}>
          {(questions && !loading) && questions.map((question, index) => {
            const isItemSelected = isSelected(question.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            return (

              <TableRow hover
                role="checkbox"
                aria-checked={false}
                tabIndex={-1}
                key={question.id}
                selected={false}

              >
                <TableCell padding={"checkbox"} className={classes.tableCellInit} style={{ width: 50 }}>
                  <Checkbox
                    color="primary"
                    onClick={(event) =>
                      handleSelectClick(event, question)
                    }
                    checked={isItemSelected}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </TableCell>
                <TableCell padding={"checkbox"} className={classes.tableCellDraggable}
                  style={{ width: 0 }}>

                </TableCell>
                {/* {!isSummary && <TableCell
                  className={classes.tableCellDraggable}>{question.order}</TableCell>} */}
                <TableCell
                  className={classes.tableCellDraggable}>{question.variable_name}</TableCell>
                <TableCell
                  className={classes.tableCellDraggable}>{questionTypesByName[question.type].label}</TableCell>
                <TableCell
                  className={classes.tableCellDraggable}>{parse(question.question_translations[0].question)}</TableCell>
                <TableCell className={classes.tableCellDraggable}>
                  {question.type === questionTypes[QUESTION_INFO].name ?
                    <Switch
                      checked={question.mandatory}
                      classes={{
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                      }}
                      disabled
                    />
                    :
                    <Switch
                      checked={question.mandatory}
                      classes={{
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                      }}
                    />
                  }
                </TableCell>
                <TableCell className={classes.tableCellDraggable}>
                  {question["conditional"] === null || question["conditional"] === "" ? "No" : "Yes"}
                </TableCell>
                {/* {!isSummary && <TableCell className={classes.tableCellDraggable}>
                  {question["summary"] === false || question["summary"] === "" ? "No" : "Yes"}
                </TableCell>} */}
                <TableCell className={classes.tableCellFinish} align='left'>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button aria-controls="fade-menu" aria-haspopup="true"
                      onClick={e => handleActionClick(question.id, e)}>
                      <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL
                        color={AppColors.PRIMARY} style={{
                          verticalAlign: "middle",
                          display: "inline-flex",
                          paddingRight: "4px"
                        }} size="2rem" />} />
                    </Button>
                    <Menu
                      style={{
                        boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
                      }}
                      color={AppColors.WHITE}
                      id={question.id}
                      anchorEl={anchorEl[question.id]}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      keepMounted
                      open={Boolean(anchorEl[question.id])}
                      onClose={e => handleActionClose(question.id, e)}
                      TransitionComponent={Fade}>
                      {canEdit && (
                        <MenuItem style={{ color: AppColors.PRIMARY }}
                          onClick={() => {
                            handleClickEdit(question.id);
                          }}> {menuOptions.EDIT} </MenuItem>)}
                      {canEdit && (
                        <MenuItem style={{ color: AppColors.PRIMARY }}
                          onClick={() => {
                            //handleClickEdit(question.id);
                          }}> {menuOptions.DUPLICATE} </MenuItem>)}
                      {canDelete &&
                        <MenuItem style={{ color: AppColors.PRIMARY }}
                          onClick={() => {
                            handleDelete(question.id);
                          }}> {menuOptions.DELETE}</MenuItem>}
                    </Menu>
                  </Grid>
                </TableCell>
              </TableRow>

            )
          })}
          <SnackBarUhda handleClose={handleClosePermissionsDelete} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackDelete} />
          <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.FORM_DELETED}
            openSnack={openDelete} />

        </TableBody>

      </Table>

      {questions.length === 0 &&
        <Grid
          style={{ height: "200px" }}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <TextUdha
            type={textType.TITLE}
            color={AppColors.ACCENT}
            text={TableTexts.NO_ELEMENTS}
          />
        </Grid>
      }

      <Grid container justifyContent={"flex-end"} style={{ paddingRight: "1.5em" }}>
        <Typography style={{ color: AppColors.PRIMARY }}>
          Total: {questions.length} questions
        </Typography>
      </Grid>
    </>
  )

}

export default CheckBoxUhda
