/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import {Button, Tooltip, withStyles} from "@material-ui/core"
import {AppColors} from "../../resources/AppColors";
import {AppTextsFontSize} from "../../resources/AppTexts";

const StudiesToolTip = withStyles({
  tooltip: {
    color: AppColors.BLACK,
    backgroundColor: AppColors.WHITE,
    fontSize: AppTextsFontSize.SIZE_BODY,

  }
})(Tooltip);

/**
 * @component
 * Component to create unfilled buttons with text or icons that have popover
 *
 * @param textAlign: alignment of the text in the button
 * @param backGroundColor: background color of the button
 * @param size: size of the button
 * @param onClick: function to use when click
 * @param isDisabled: boolean to disable the button
 * @param children: content of the buttonUnfilled
 * @param className: className for the styles
 * @param popover: boolean to know if the button have popover
 * @param textPopover: text for the Popover
 * @param color: color of the text of the button
 *
 * @example
 *
 * <ButtonUnfilledUhda onClick={handleForgot} children={<TextUhda type={textType.BODY} text={CommonsTexts.FORGOT_PASS}/>}
 * className={classes.button}/>
 *
 */
const ButtonUnfilledUhda = ({
  textAlign,
  backgroundColor,
  size,
  onClick,
  isDisabled,
  children,
  className,
  popover,
  textPopover,
  color
}) => {

  if (isDisabled == null) isDisabled = false
  if (backgroundColor == null) backgroundColor = AppColors.WHITE
  if (textAlign == null) textAlign = "center"
  if (popover) {
    return (
      <StudiesToolTip title={ textPopover } arrow>
        <Button
          style={ {
            color: color,
            backgroundColor: backgroundColor,
            textTransform: "none",
            textAlign: textAlign
          } }
          className={ className }
          textTransform={ "none" }
          size={ size }
          color="primary"
          disabled={ isDisabled }
          onClick={ onClick }>{children}
        </Button>
      </StudiesToolTip>
    )
  }
  return (
    <Button
      style={ {backgroundColor: backgroundColor, textTransform: "none", textAlign: textAlign} }
      className={ className }
      textTransform={ "none" }
      size={ size }
      color="primary"
      disabled={ isDisabled }
      onClick={ onClick }>{children}
    </Button>)

}

ButtonUnfilledUhda.propTypes = {
  children: PropTypes.element.isRequired,
  size: PropTypes.string,
  textAlign: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.any,
  popover: PropTypes.bool,
  textPopover: PropTypes.string
}

export default ButtonUnfilledUhda
