import React from "react"
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip
} from "@material-ui/core"
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";

/**
 * @component
 * Component to show the answer options
 *
 * @param {object} answerOptionsArray: array of answerOptions of the question
 * @param {function} handleDeleteAnswer: handle to show the delete answer or not
 * @param {function} handleAddQuestionPressed: handle to show the add new Question
 * @param {string} value: answer option
 * @param {function} handleKeyDown: handle to check if the user clicked enter or tab
 * @param {function} handleChange: state to change the answerOption
 * @param {string} label: Title of the answer
 *
 * @constructor
 * <SingleMultipleAnswerUhda handleDeleteAnswer={handleDeleteAnswer} value={answerOption} handleAddQuestionPressed={handleAddQuestionPressed}
 * handleChange={setAnswerOption} answerOptionsArray={answerOptionsArray} handleKeyDown={handleKeyDown} label={"Answer options"}/>
 */
const SingleMultipleAnswerUhda = ({
  answerOptionsArray,
  handleDeleteAnswer,
  handleAddQuestionPressed,
  value,
  handleKeyDown,
  handleChange,
}) => {

  return (

    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >

      {answerOptionsArray.map(
        answer =>
          <div key={ `formAnswer-${answer.value}` }>
            {answer.text}
            <Button onClick={ () => handleDeleteAnswer(answer.value) }>
                            &times;
            </Button>
          </div>
      )}
      <FormControl margin='normal' style={ {width: "100%"} } variant="outlined">
        <InputLabel>Answer option</InputLabel>
        <OutlinedInput
          value={ value }
          onKeyDown={ handleKeyDown }
          onChange={ (e) => handleChange(e.target.value) }
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="You can also add questions pressing Enter or Tab" aria-label="add">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={ handleAddQuestionPressed }
                  edge="end"
                >
                  <AddIcon/>
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </FormControl>
    </Grid>

  )
}

SingleMultipleAnswerUhda.propTypes = {
  answerOptionsArray: PropTypes.array.isRequired,
  handleDeleteAnswer: PropTypes.func.isRequired,
  handleAddQuestionPressed: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}

export default SingleMultipleAnswerUhda;
