/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Checkbox, Grid, TextField, Typography } from "@material-ui/core";
import { AppColors } from "../resources/AppColors";
import { StorageManager } from "../utils";
import AuthContext from "../context/authentication/authContext"
import { makeStyles } from "@material-ui/core/styles";
import CardUhda from "../components/CardUhda";
import NavbarUhda from "../components/NavbarUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import ButtonUnfilledUhda from "../components/ButtonUnfilledUhda";
import { CommonsTexts, SnackBarResponses } from "../locale/en";
import { textType } from "../resources/AppTexts";
import TextUhda from "../components/TextUdha";
import SnackBarUhda from "../components/SnackBarUhda";
import taglineIcon from "../img/UhDa_Studies.png"

const useStyles = makeStyles((theme) => ({

  subText: {
    color: AppColors.SUBTEXT,
    backgroundColor: "red"
  },
  button: {
    textTransform: "none",
    color: AppColors.PRIMARY
  },
  title: {
    color: AppColors.PRIMARY
  },
  background: {
    backgroundColor: AppColors.BACKGROUND
  },
  backgroundBox: {
    backgroundColor: AppColors.WHITE
  },
  colorLabel: {
    color: AppColors.PRIMARY,
    opacity: "0,2",

  },
  textField: {
    border: "1px solid blue",
    borderRadius: "8px"
  },

  textFieldLabel: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",

      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,

    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,

  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon: {
    height: "50px",
  },

}));

/**
 * @page
 * Login page where the user enters the data and will be logged in
 */
export default function Login(props) {
  const storageManager = new StorageManager();
  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState(storageManager.getUsername());
  const [password, setPassword] = useState(storageManager.getPwd());
  const authContext = useContext(AuthContext)
  const { message, authenticated, login, checkAuth, change_req } = authContext;
  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [openLogin, setOpenLogin] = useState()

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  useEffect(() => {

    checkAuth()
    if (authenticated) {
      props.history.push("/studies")
    }
    if (change_req) {
      props.history.push("/change")
    }
    if (message) {
      setOpenLogin(true)
      setAlertMessage(message)
      setShowValidationAlert(true)
    }
  }, [message, authenticated, props.history, change_req])

  //Function to change the state of checked
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //Function that is called when the user submit and will be logged
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (username === "" || password === "") setShowValidationAlert(true);
    else {
      const params = {
        username,
        password,
        "remember-me": checked,
      };
      setShowValidationAlert(false);
      login(params)
    }
  };

  //Function that will be called when the user wants to go to the forgot Page
  const handleForgot = async () => {
    props.history.push("/forgot")
  }
  const usernameChange = (e) => {
    setUsername(e.target.value)
  }

  const passwordChanged = (e) => {
    setPassword(e.target.value)

  }

  const classes = useStyles();
  return (
    <Grid container style={{ minHeight: "40%" }} alignItems={"center"}
      direction={"column"}>

      <Grid item>
        <NavbarUhda open={false} setOpen={false} setSelected={null} logged={false} />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda bg={AppColors.WHITE} children={
          <Grid
            className="card"
            container
            item
            alignItems="center"
            direction="column"
            justifyContent="center"

          >
            <div style={{ margin: "5vh", display: "flex", flexDirection: "column", minWidth: 300 }}
            >

              <div style={{ textAlign: "center" }}>
                <TextUhda type={textType.TITLE_MEDIUM} text={CommonsTexts.WELCOME_BACK_UPPER} />
              </div>

              <div style={{ textAlign: "center", height: 40 }} />

              <TextField
                onChange={(e) => usernameChange(e)}
                type="email"
                label="Username"
                margin="normal"
                variant="outlined"

                className={classes.textFieldLabel}

              />

              <TextField
                onChange={(e) => passwordChanged(e)}
                type="password"
                label="Password"
                margin="normal"
                variant="outlined"
                className={classes.textFieldLabel}
              />
              <TextUhda color={AppColors.SUBTEXT} type={textType.BODY} text={CommonsTexts.INFO_LOGIN} />
              <Grid container spacing-xs-1 wrap="nowrap" spacing={4} direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid container item alignItems="center" justify="center">
                  <Checkbox
                    style={{
                      color: AppColors.PRIMARY,
                    }}
                    checked={checked}
                    onChange={handleChange}
                  />
                  <TextUhda type={textType.BODY} text={CommonsTexts.REMEMBER_ME} margin={"0.7em"} />
                </Grid>
                <Grid container item alignItems="center" justify="center">
                  <ButtonFilledUhda text={CommonsTexts.LOGIN} onClick={handleSubmit} margin={"0.7em"} />

                  {showValidationAlert && (
                    alertMessage ? <SnackBarUhda severity={"error"} handleClose={handleCloseLogin}
                      message={SnackBarResponses.LOGIN_ERROR}
                      openSnack={openLogin} />
                      : <SnackBarUhda handleClose={handleCloseLogin} message={"A"}
                        openSnack={openLogin} />
                  )}

                </Grid>
                <ButtonUnfilledUhda onClick={handleForgot}
                  children={<TextUhda type={textType.BODY}
                    text={CommonsTexts.FORGOT_PASS} />}
                  className={classes.button} />
              </Grid>
            </div>

          </Grid>} />
      </Grid>

      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          paddingBottom: "1em",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box style={{ marginBottom: 0 }} display="flex" justifyContent="center" m={3}>
          <Box style={{ marginTop: "0.8em", width: "38em", marginLeft: 0, marginRight: 0 }} mx="auto">
            <Typography style={{ marginLeft: "20em" }}>{CommonsTexts.ABOUT}</Typography>
            <Typography>{CommonsTexts.ABOUT_TEXT}</Typography>

          </Box>
          <Box style={{ margin: 0 }} direction="row" display="flex" justifyContent="center" m={3}
            className="bottom-bar"
          >
            <Typography style={{ marginTop: "0.5em" }} >Powered by </Typography>
            <img alt='icon' className={classes.icon} src={taglineIcon} />
          </Box>
          {/*  <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/UhD-Studies-User-Guide-b0d41862529a4d8196d56370465c7250'
              target="_blank" rel="noreferrer">{CommonsTexts.USER_GUIDE}</a>
          </Box> */}
          {/* <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/Terms-of-Use-f5a590057a7f46deaa854af7cc336dc2'
              target="_blank" rel="noreferrer">{CommonsTexts.TERMS}</a>
          </Box> */}
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vSz4FmaCBAcvcuZ1Kw9eB7nmCgSS0lwaY5C3rMN9vN3Xd7Mhva9U8ffdGx-e1vIqgCn0P7KtlyT27b_/pub'
              target="_blank" rel="noreferrer">{CommonsTexts.PRIVACY}</a>
          </Box>
        </Box>

      </AppBar>

    </Grid>

  )
    ;
}
