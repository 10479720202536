import React from "react";
import {Grid} from "@material-ui/core"
import {AppColors} from "../resources/AppColors"
import TextUhda from "../components/TextUdha";
import {CommonsTexts} from "../locale/en";
import {textType} from "../resources/AppTexts";

/**
 * @page
 * Page that shows the main page where there is only a text welcoming
 */
const HomePage = () => {
  return (
    <Grid
      container
      spacing={ 0 }
      direction="column"
      alignItems="center"
      style={ {minHeight: "100vh"} }
    >
      <Grid item style={ {margin: "30vh"} }>
        <TextUhda type={ textType.TITLE } color={ AppColors.PRIMARY } text={ CommonsTexts.WELCOME_BACK }/>
      </Grid>

    </Grid>
  )
}

export default HomePage;
