/* eslint-disable */

import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { LabelsDrawer, LabelsStudy } from "../../locale/en"
import MyStorageManager from "../../utils/StorageManager"
import BreadcrumbsUhda from "../BreadcrumbsUhda"
import ChatUhda from "./ChatUhda"
import { useDispatch } from "react-redux"
import { setMessageAsReadAction } from "reducers/chatReducer"

const ChatIndex = ({ drawer }) => {
  const location = useLocation()
  const [participant] = useState(location.state.participant)
  const storageManager = new MyStorageManager()

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setMessageAsReadAction(participant.uuid))
  }, [])

  return (
    <Box style={{ paddingLeft: drawer ? "8px" : "82px", paddingRight: "8px" }}>
      <BreadcrumbsUhda
        routes={[
          { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
          {
            id: 1,
            label: location.state.study.translations[0].study_title,
            url: `/study/${location.state.study.id}/dashboard`,
            studyId: location.state.study.id,
            permissions: JSON.parse(localStorage.getItem("dashBoardSection")).permissions
          },
          {
            id: 2,
            label: LabelsStudy.PARTICIPANTS,
            url: `/study/${location.state.study.id}/participants`
          },
          {
            id: 3,
            label: `${participant.name} ${participant.surname}'s chat`,
          },
        ]} />
      <ChatUhda
        channeLId={participant.uuid.toString()}
        user={{
          id: storageManager.getId(),
          name: storageManager.getName()
        }}
      />
    </Box>
  )
}

export default ChatIndex