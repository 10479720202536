import React from "react"
import { FormControlLabel, Switch } from "@material-ui/core";
import PropTypes from "prop-types"

/**
 * @component
 * Component to create a switch option on the form
 * @param {string} label: title of the switch
 * @param {boolean} value: state of the switch
 * @param {function} handleChange: handle to change the state of the switch
 * @constructor
 * <FormSwitchUhda label={"Mandatory"} handleChange={handleChange} value={true} />

 */
const FormSwitchUhda = ({ label, value, handleChange }) => {
  return (
    <FormControlLabel style={{ minWidth: "140px" }}
      control={
        <Switch
          checked={value}
          onChange={e => handleChange(e.target.checked)}
          label={label}
          color='primary'
        />
      }
      labelPlacement="start"
      label={label}
    />
  )
}

FormSwitchUhda.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default FormSwitchUhda;
