import React, {useEffect, useState} from "react";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import {patientsMock} from "../mocks/patientsMock"
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers"
import {FormControl, Grid, InputAdornment} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns";
import PropTypes from "prop-types"
import DialogUhda from "../components/DialogUhda/DialogUhda"
import moment from "moment"
import {AppColors} from "../resources/AppColors"
import ErrorIcon from "@material-ui/icons/Error"
import {validateEmail} from "../utils/HelperFunctions"
import {CommonsTexts, TableTexts} from "../locale/en";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import TextUhda from "../components/TextUdha";
import {textType} from "../resources/AppTexts";
import ParticipantsService from "../services/ParticipantsService";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import {BASE_PATH} from "../resources/ApiUrls";

function AddModal({
  selectedDate,
  setSelectedDate,
  handleAddParticipant,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal
}) {
  const [showEmailError, setShowEmailError] = useState(false)

  const handleInputChange = e => {
    const {name, value} = e.target
    setValues({...values, [name]: value})
  }

  useEffect(() => {
    setValues(initialValues)
    setSelectedDate(null)
  }, [showAddModal])

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(values["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleAddParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={ CommonsTexts.CANCEL }
      textConfirmButton={ CommonsTexts.SAVE }
      textConfirmAndContinue={ TableTexts.SAVE_AND_ADD }
      handleShow={ setShowAddModal }
      handleConfirm={ handleClickSubmit }
      values={ values }
      setValues={ setValues }
      title={ TableTexts.ADD_PARTICIPANT_TITLE }
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='name'
              handleChange={ handleInputChange }
              label={ TableTexts.NAME }
              value={ values.name }
            />
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='surname'
              handleChange={ handleInputChange }
              label={ TableTexts.SURNAME }
              value={ values.surname }
            />
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
              <KeyboardDatePicker
                autoOk={ true }
                name='birthdate'
                variant="outlined"
                inputVariant="outlined"
                label={ TableTexts.BIRTHDATE }
                format="MM/dd/yyyy"
                maxDate={ new Date() }
                value={ selectedDate }
                InputAdornmentProps={ {position: "end"} }
                onChange={ date => {
                  setSelectedDate(date)
                  handleInputChange({target: {name: "birthdate", value: date}})
                } }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='email'
              handleChange={ handleInputChange }
              label={ TableTexts.EMAIL }
              value={ values.email }
              error={ showEmailError }
              helperText={ showEmailError && TableTexts.EMAIL_ERROR }
              inputProps={ {
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={ {color: AppColors.RED} }/></InputAdornment>,
              } }
            />
          </FormControl>
        </Grid>
      }
      show={ showAddModal }
    />
  )
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired
}

function DeleteModal({setShowDeleteModal, showDeleteModal, selected}) {

  useEffect(() => {
   
  }, [showDeleteModal])
  return (
    <DialogUhda
      textCancelButton={ CommonsTexts.CANCEL }
      textConfirmButton={ TableTexts.DELETE_CONFIRM }
      handleShow={ setShowDeleteModal }
      buttonColor={ AppColors.RED }
      handleConfirm={ () => {
      } }
      title={
        showDeleteModal === true ?
          selected.length > 1 ? TableTexts.DELETE_PARTICIPANTS : TableTexts.DELETE_PARTICIPANT
          : TableTexts.DELETE_PARTICIPANT + showDeleteModal
      }
      body={
        <Grid>
          <TextUhda color={ AppColors.PRIMARY } type={ textType.BODY } text={ showDeleteModal === true ?
            selected.length > 1 ? TableTexts.DELETE_TEXT_MULTIPLE : TableTexts.DELETE_TEXT
            : TableTexts.DELETE_TEXT
          }/>

          <TextUhda type={ textType.BODY } text={ showDeleteModal === true ?
            selected.length > 1 ? selected.toString() : selected.toString()
            : TableTexts.DELETE_TEAM_MEMBER_ID + showDeleteModal
          }/>

          <TextUhda color={ AppColors.PRIMARY } type={ textType.BODY }
            text={ `${TableTexts.TEXT_MODAL_DELETE}` }/>

        </Grid> }
      show={ showDeleteModal }
    />
  )
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired
}

function EditModal({
  selectedDate,
  setSelectedDate,
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues
}) {
  const [showEmailError, setShowEmailError] = useState(false)
  useEffect(() => {
    var response = Object.values(patientsMock.data)
    setEditValues(response[showEditModal].participant)
    const date = response[showEditModal].participant.birthdate
    setSelectedDate(moment(date, "DD-MM-YYYY"))
  }, [showEditModal])

  const handleInputChange = e => {
    const {name, value} = e.target
    setEditValues({...editValues, [name]: value})
  }

  const handleClickSubmit = async () => {
    const isEmail = await validateEmail(editValues["email"])
    if (isEmail) {
      setShowEmailError(false)
      handleEditParticipant()
    } else {
      setShowEmailError(true)
    }
  }

  return (
    <DialogUhda
      textCancelButton={ CommonsTexts.CANCEL }
      textConfirmButton={ CommonsTexts.SAVE }
      handleShow={ setShowEditModal }
      handleConfirm={ handleClickSubmit }
      values={ editValues }
      setValues={ setEditValues }
      title={ TableTexts.EDIT_TITLE + showEditModal }
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='name'
              handleChange={ handleInputChange }
              label={ TableTexts.NAME }
              value={ editValues.name }
            />
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='surname'
              handleChange={ handleInputChange }
              label={ TableTexts.SURNAME }
              value={ editValues.surname }
            />
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
              <KeyboardDatePicker
                defaultValue={ selectedDate }
                autoOk={ true }
                name='birthdate'
                variant="outlined"
                inputVariant="outlined"
                label={ TableTexts.BIRTHDATE }
                format="dd/MM/yyyy"
                maxDate={ new Date() }
                value={ selectedDate }
                InputAdornmentProps={ {position: "end"} }
                onChange={ date => {
                  setSelectedDate(date)
                  handleInputChange({target: {name: "birthdate", value: date}})
                } }
              />
            </MuiPickersUtilsProvider>
          </FormControl>
          <FormControl margin='normal' style={ {width: "100%"} }>
            <TextfieldUhda
              name='email'
              handleChange={ handleInputChange }
              label={ TableTexts.EMAIL }
              value={ editValues.email }
              error={ showEmailError }
              helperText={ showEmailError && TableTexts.EMAIL_ERROR }
              inputProps={ {
                endAdornment: showEmailError && <InputAdornment position="end"><ErrorIcon
                  style={ {color: AppColors.RED} }/></InputAdornment>,
              } }
            />
          </FormControl>
        </Grid>
      }
      show={ showEditModal }
    />
  )
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired
}
/**
 * @Page
 * Page that shows the table of participants that a user has
 * @param match
 * @returns {JSX.Element}
 * @constructor
 */
const ParticipantsPage = () => {
  const initialValues = {name: "", surname: "", birthdate: "", email: "", studies: []}
  const [values, setValues] = useState(initialValues)
  const [editValues, setEditValues] = useState(initialValues)
  const [selectedDate, setSelectedDate] = useState(null)
  const [showAddModal, setShowAddModal] = useState(-999)
  const [showEditModal, setShowEditModal] = useState(-999)
  const [showDeleteModal, setShowDeleteModal] = useState(-999)
  const [response, setResponse] = useState()

  const headCells = [
    {id: "name", numeric: false, disablePadding: false, label: TableTexts.NAME, isSortable: true},
    {id: "surname", numeric: false, disablePadding: false, label: TableTexts.SURNAME, isSortable: false},
    {id: "birth_date", numeric: false, disablePadding: false, label: TableTexts.BIRTHDATE, isSortable: false},
    {id: "email", numeric: false, disablePadding: false, label: TableTexts.EMAIL, isSortable: false},
    {id: "studies", numeric: false, disablePadding: false, label: TableTexts.STUDIES, isSortable: false},
  ];

  //Function to show or not the addModal
  const handleAddParticipant = () => {
    setShowAddModal(-999)
  }

  //Function to show or not the editModal
  const handleEditParticipant = () => {
    setShowEditModal(-999)
  }

  //Function to show or not the deleteModal
  const handleDeleteParticipant = () => {
    setShowDeleteModal(-999)
  }

  const getStudies = async (data) => {
    try {
      const res = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      data.forEach((participant) => {
        var studyList = ""
        participant["studies_id"].forEach((study, index) => {
          // eslint-disable-next-line camelcase
          const resultado = res.data.data.find(study_id => study_id.id === study);
          if (index === 0) {
            studyList = resultado["translations"][0]["study_title"]
          } else {
            studyList = studyList + " / " + resultado["translations"][0]["study_title"]
          }
        })
        participant["studies"] = studyList
      })
      setResponse(data)
      // eslint-disable-next-line no-empty
    } catch (error) {
      
    }
  }
  //Function to get all the data of the participants
  const getParticipants = async () => {
    try {
      var responseAPI = await ParticipantsService.getParticipants()
      responseAPI = Object.values(responseAPI.data.data.values)
      var data = []
      responseAPI.forEach((res) => {
        var studies = []
        res["studies"].forEach((study) => {
          if (study) {
            studies.push(study.id)
          }
        })
        res.user["studies_id"] = studies
        res.user["birth_date"] = res["birth_date"]
        data.push(res.user)
      })
      getStudies(data)
      // eslint-disable-next-line no-empty
    } catch {
    
    }
  }

  useEffect(() => {
    getParticipants()
  }, [])

  return (
    <div style={ {paddingLeft: "48px"} }>
      {response && <TableBaseUhda
        tableTitle={ TableTexts.PARTICIPANTS_TITLE }
        headCells={ headCells }
        dataToUse={ response }
        addModal={ AddModal }
        deleteModal={ DeleteModal }
        editModal={ EditModal }
        initialValues={ initialValues }
        values={ values }
        setValues={ setValues }
        editValues={ editValues }
        setEditValues={ setEditValues }
        selectedDate={ selectedDate }
        setSelectedDate={ setSelectedDate }
        handleAddParticipant={ handleAddParticipant }
        handleEditParticipant={ handleEditParticipant }
        handleDeleteParticipant={ handleDeleteParticipant }
        showAddModal={ showAddModal }
        setShowAddModal={ setShowAddModal }
        showEditModal={ showEditModal }
        setShowEditModal={ setShowEditModal }
        showDeleteModal={ showDeleteModal }
        setShowDeleteModal={ setShowDeleteModal }
        interactive={ false }
        
      />}
    </div>
  )
}

export default ParticipantsPage;
