/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, {useEffect, useState, useRef} from "react";
import {Grid} from "@material-ui/core";
import {AppColors} from "../resources/AppColors";
import {CommonsTexts, LabelsDrawer, LabelsStudy, SnackBarResponses} from "../locale/en";
import {textType} from "../resources/AppTexts";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import TextUhda from "../components/TextUdha";
import CardUhda from "../components/CardUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import {BASE_PATH} from "../resources/ApiUrls";
import DrawerUhda from "../components/DrawerUhda";
import SnackBarUhda from "../components/SnackBarUhda";

/**
 * @Page
 * Page for the dissemination of one study
 */
const DisseminationPage = ({drawer}) => {
  
  const [study, setStudy] = useState();
  // const id = location.state.detail
  // const permissions = location.state.permissions
  const permissions = useRef();
  const id = useRef();
   
  const [open, setOpen] = useState(drawer);
  const [openSnackPermissionsSend, setOpenSnackPermissionsSend] = React.useState(false);
  const [devicesSize, setDevicesSize] = useState("280px")
  const [cardWidth, setCardWidth] = useState(500)
  const [cardMargin, setCardMargin] = useState("10px")
  const [cardHeight, setCardHeight] = useState("296px")

  //Function to get the data of the study from the id
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }

  const toDissemination = () => {
    if (study.permissions.includes("access-dissemination")) {
      window.open("https://forms.epidemixs.org/form/study/epidemixsstudies-es", "_blank")
    } else {
      setOpenSnackPermissionsSend(true)
    }
  }

  //Function to close the snackbar
  const handleClosePermissionsEdit = () => {
    setOpenSnackPermissionsSend(false);
  };

  //This function retrives the stored permissions and id from local storage
  useEffect(() => {
       
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
         
  }, [])

  useEffect(() => {
    getStudy()
  }, []);
  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if(open){
        if(window.innerWidth >= 1000){
          setDevicesSize("280px")
          setCardWidth(500)
          setCardMargin("10px")
          setCardHeight("296px")
        }else{
          setDevicesSize("10px") 
          setCardWidth(250) 
          setCardMargin("40px")
          setCardHeight("396px")
        }
        
      }else{
           
        if(window.innerWidth >= 640 && window.innerWidth <= 1680){
          setDevicesSize("280px")
          setCardWidth(500)
          setCardMargin("10px")
          setCardHeight("296px")
        }else if(window.innerWidth <= 600){
                
          setDevicesSize("180px")
          setCardWidth(250)
          setCardMargin("40px")
          setCardHeight("396px")
        }
      }
            
    }, 300)
       
    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size
    
    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)
      
    }

  },[open])

  return (
    <>
      {study && <DrawerUhda id={ id.current } drawer={ open } select={ 3 }
        settings={ study.permissions }/>}

      <Grid container justifyContent={ "center" } alignItems={ "flex-start" }
        direction={ "column" } style={ {
          maxWidth: "50%", marginLeft: devicesSize
        } }
        spacing={ 2 }
        xs={ 8 }
        sm={ 5 }
        md={ 8 }
        lg={ 8 }
        xl={ 4.5 }
      >
        <Grid item>
          {study && <BreadcrumbsUhda routes={
            [
              {id: 0, label: LabelsDrawer.STUDIES, url: "/studies"},
              {
                id: 1, label: `${study.translations[0].study_title}`,
                disabled: true,
                studyId: id.current, permissions: permissions.current
              },
              {id: 2, label: LabelsStudy.DISSEMINATION},
            ]
          }/>}
        </Grid>

        <Grid item>
          <CardUhda width={ "496px" } height={ cardHeight } bg={ AppColors.WHITE } children={
            <Grid container
              xs={ 8 }
              sm={ 5 }
              md={ 8 }
              lg={ 5 }
              xl={ 10 }
              style={ { margin: "1em", maxWidth: cardWidth} }
            >
              <Grid item style={ {width: "426px", height: "199px"} }>
                <TextUhda margin={ 0 } text={ CommonsTexts.DISSEMINATION } color={ AppColors.PRIMARY }
                  type={ textType.BODY_CARD }/>
                <TextUhda text={ CommonsTexts.DISSEMINATION_2 } color={ AppColors.PRIMARY }
                  type={ textType.BODY_CARD }/>
                <TextUhda text={ CommonsTexts.HAPPY_DISSEMINATION } color={ AppColors.PRIMARY }
                  type={ textType.BODY_CARD }/>

              </Grid>
              <Grid item style={ {marginTop:cardMargin} }>
                <ButtonFilledUhda color={ AppColors.WHITE } style={ {
                  backgroundColor: AppColors.PRIMARY,
                } } text={ CommonsTexts.SEND_INFO } onClick={ toDissemination } margin={ "0.7em" }
                />

                <SnackBarUhda handleClose={ handleClosePermissionsEdit } severity={ "error" }
                  message={ SnackBarResponses.NOT_PERMISSION }
                  openSnack={ openSnackPermissionsSend }/>
              </Grid>
            </Grid> }/>
        </Grid>
      </Grid>
    </>

  )
}

export default DisseminationPage;
