import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import GridStudiesUhda from "../components/GridStudiesUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import {BASE_PATH} from "../resources/ApiUrls";

/**
 * @Page
 * Page that shows the grid of studies that a user has
 * @returns {JSX.Element}
 * @constructor
 */
const StudiesPage = () => {
  const [study, setStudy] = useState();

  //Function to get all the studies that the user have
  const getStudies = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      setStudy(response.data.data)
            // eslint-disable-next-line no-empty
    } catch (err) {
           
    }
  }

  useEffect(() => {
    getStudies()

  }, []);

  return (
    <Grid container alignItems="center" direction={ "column" }>
 
      {study && <GridStudiesUhda studies={ study }/>}
    </Grid>
        
  )
}

export default StudiesPage;
