/* eslint-disable no-redeclare */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import {
  CardHeader,
  Divider,
  FormControl,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  CommonsTexts,
  CommonsTexts as CommonsText,
  ErrorTexts,
  LabelsDrawer,
  LabelsForm,
  LabelsStudy,
  LinksInfo,
  SnackBarResponses,
  TableTexts,
} from "../locale/en";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { AppColors } from "../resources/AppColors";
import PropTypes from "prop-types";
import Icons from "../resources/Icons";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import CardUhda from "../components/CardUhda";
import TextUhda from "../components/TextUdha";
import { textType, useTextStyles } from "../resources/AppTexts";
import IconProviderUhda from "../components/IconProviderUhda";
import Select from "@material-ui/core/Select";
import { useHistory, useLocation } from "react-router-dom";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH, FORMS_STUDY, QUESTIONS, SECTIONS, SUMMARY } from "../resources/ApiUrls";
import copyIcon from "../img/copy_icon.svg";
import SnackBarUhda from "../components/SnackBarUhda";
import FormModal from "../modal/FormModal";
import FormService from "../services/FormsService";
import AddQuestionModal from "../components/QuestionModals/AddQuestionModal";
import DrawerUhda from "../components/DrawerUhda";
import DialogUhda from "../components/DialogUhda";
import QuestionsService from "../services/QuestionsService";
import EditQuestionModal from "../components/QuestionModals/EditQuestionModal";
import FormModalStatus from "../modal/FormModalStatus";
import TabsUhda from "../components/TabsUhda/TabsUhda";
import AddSectionModal from "../components/QuestionModals/AddSectionModal";
import MoveQuestionModal from "../components/QuestionModals/MoveQuestionModal";
import SummarizeOutlinedIcon from "../img/summarize_icon.svg"

import {
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  questionTypes,
  questionTypesByName
} from "../resources/QuestionConstants";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY,
  },
  textFieldLabel: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,

      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      //marginTop: '-0.3em',
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  },

  textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,

    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: "transparent",
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  },

  iconRoot: {
    textAlign: "center",
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit"
  },
}));

function DeleteModal({
  setShowDeleteQuestion,
  showDeleteQuestion,
  handleDeleteQuestion,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestion}
      handleConfirm={() => {
        handleDeleteQuestion();
      }}
      title={"Delete question"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.DELETE_INFO_SECTIONS}
          </Typography>
          <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
            href='https://right-chocolate-d0c.notion.site/Terms-of-Use-f5a590057a7f46deaa854af7cc336dc2'
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>
      }
      show={showDeleteQuestion}
    />
  );
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function DuplicateModal({
  setShowDuplicateQuestion,
  showDuplicateQuestion,
  handleDuplicateQuestion,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.PRIMARY}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDuplicateQuestion}
      handleConfirm={() => {
        handleDuplicateQuestion();
      }}
      title={"Duplicate question"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.DUPLICATE__INFO_QUESTION}
          </Typography>

        </>
      }
      show={showDuplicateQuestion}
    />
  );
}

DeleteModal.propTypes = {
  setShowDuplicateQuestion: PropTypes.func.isRequired,

};

function DeleteSectionModal({
  setShowDeleteModalConfirm,
  showDeleteModalConfirm,
  handleDeleteSection,
}) {

  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModalConfirm}
      handleConfirm={() => {
        handleDeleteSection();
      }}
      title={"Delete section"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {"Are you sure you want to delete this section?"}
          </Typography>
        </>
      }
      show={showDeleteModalConfirm}
    />
  );
}

DeleteSectionModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function DeleteQuestionsModal({
  setShowDeleteQuestions,
  showDeleteQuestions,
  selected,
  handleDeleteQuestions,
}) {

  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestions}
      handleConfirm={() => {
        handleDeleteQuestions();
      }}
      title={"Delete question(s)"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.DELETE_INFO_QUESTIONS_SECTIONS}
          </Typography>
          <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>

      }
      show={showDeleteQuestions}
    />
  );
}

DeleteQuestionsModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function IncludeQuestionsModal({
  setShowIncludeSummary,
  showIncludeSummary,
  selected,
  handleIncludeQuestions,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.PRIMARY}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowIncludeSummary}
      handleConfirm={() => {
        handleIncludeQuestions();
      }}
      title={"Include in summary"}
      body={
        <>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.INCLUDE_SUMMARY_1}
          </Typography>
          <Typography style={{ fontSize: "14px", color: AppColors.PRIMARY }} variant="subtitle1" gutterBottom>
            {LabelsForm.INCLUDE_SUMMARY_2}
          </Typography>
          <a style={{ fontSize: "12px", color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>

      }
      show={showIncludeSummary}
    />
  );
}

IncludeQuestionsModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

/**
 * @Page
 * Page for one form of the study. This have the settings of the form and the questions.
 */
const FormPage = ({ drawer }) => {
  const location = useLocation();
  const classes = useStyles();
  const texts = useTextStyles();
  const [title, setTitle] = useState("");
  const [existSummary, setExistSummary] = useState();
  const [description, setDescription] = useState("");
  const [showValidationAlert, setShowValidationAlert] = useState(false);

  const [openSaved, setOpenSaved] = useState(false);
  const [openCopied, setOpenCopied] = useState(false);
  const [openArchived, setOpenArchived] = useState(false);
  const [openPublished, setOpenPublished] = useState(false);
  const [studies, setStudies] = useState(null);
  const [forms, setForms] = useState(null);

  // eslint-disable-next-line prefer-const
  let [privado, setPrivado] = useState();
  // eslint-disable-next-line prefer-const
  let [dateCreation, setDateCreation] = useState()
  const [study, setStudy] = useState(null);
  const [form, setForm] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [archived, setArchived] = useState(true);
  const [status, setStatus] = useState();
  // let studyId = location.state.detail;
  // let formId = location.state.formId;
  // let permissions = location.state.permissions;

  const studyId = useRef();
  const formId = useRef();
  const permissions = useRef();

  const [showAddQuestion, setShowAddQuestion] = useState(-999);
  const [showAddQuestionMoreInfo, setShowAddQuestionMoreInfo] = useState(-999);
  const [showAddSection, setShowAddSection] = useState(-999);
  const [showMoveQuestion, setShowMoveQuestion] = useState(-999);
  const initialValues = {
    form: formId.current,
    order: "42a",
    variableName: "",
    questionType: "INFO",
    mandatory: false,
    conditional: false,
    summary: false,
    conditionalVariable: "",
    calculation: "",
    conditionalAnswer: "",
    questionTitle: "",
    helperText: "",
    minValue: "",
    maxValue: "",
    units: "",
    interval: "",
    hasConditional: false,
    long_text: false, //TODO Look the name that the back is naming
    answersList: [
      { answer: "", value: "" },
      //{ answer: "", value: "" },
    ],
    conditionalList: [
      { variable: "", answer: "", type: "" },
    ]
  };
  const [values, setValues] = useState(initialValues);
  const [hasNextModal, setHasNextModal] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(-999);
  const [showDuplicateQuestion, setShowDuplicateQuestion] = useState(-999);
  const [showDeleteQuestions, setShowDeleteQuestions] = useState(false);
  const [showIncludeSummary, setShowIncludeSummary] = useState(false);
  const [showEditQuestion, setShowEditQuestion] = useState(-999);
  const [showEditQuestionMoreInfo, setShowEditQuestionMoreInfo] = useState(
    -999
  );
  const [editValues, setEditValues] = useState();
  const [conditionalListFinal, setConditionalListFinal] = useState();
  const [loading, setLoading] = useState(true);
  const [allLoading, setAllLoading] = useState(true);
  const history = useHistory();
  const [openSnackArchived, setOpenSnackArchived] = React.useState(false);
  const [
    openSnackPermissionsEdit,
    setOpenSnackPermissionsEdit,
  ] = React.useState(false);
  const [openSnackAdd, setOpenSnackAdd] = React.useState(false);
  const [openSnackEdit, setOpenSnackEdit] = React.useState(false);
  const [openSnackSelected, setOpenSnackSelected] = React.useState(false);
  const [openSnackNoQuestions, setOpenSnackNoQuestions] = React.useState(false);
  const [openSnackSectionDeleted, setOpenSnackSectionDeleted] = React.useState(false);
  const [openSnackSectionError, setOpenSnackSectionError] = React.useState(false);
  const [openSnackSectionDefault, setOpenSnackSectionDefault] = React.useState(false);
  const [showSnackbarAddSummary, setShowSnackbarAddSummary] = React.useState(false);
  const [showSnackbarIncludeSummary, setShowSnackbarIncludeSummary] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [showSnackbarAdd, setShowSnackbarAdd] = useState(false);
  const [showSnackbarEdit, setShowSnackbarEdit] = useState(false);
  const [showSnackbarAddSection, setShowSnackbarAddSection] = useState(false);
  const [variableId, setVariableId] = useState();
  const [answerId, setAnswerId] = useState();
  const [sectionState, setSectionState] = useState();
  const [showSnackbarMove, setShowSnackbarMove] = useState(false);
  const [sections, setSections] = useState()
  const [summary, setSummary] = useState([])
  const [stateSelect, setStateSelect] = useState(false)
  const [selectQuestions, setSelectQuestions] = useState([])
  const [showDeleteModalConfirm, setShowDeleteModalConfirm] = useState(-999)
  const [conditionalOperator, setConditionalOperator] = useState(null)
  const [conditionalListLength, setConditionalListLength] = useState()
  const camel_to_snake = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_");
  //Function that change the state of the privacity of the form
  const handleChange = (event) => {
    setPrivado(event.target.value);

  };

  const handleClickOpen = () => {
    setOpenSaved(true);
  };
  const handleCloseAdd = () => {
    setShowSnackbarAdd(false);
  };
  //Function that close all the snackbars
  const handleClose = () => {
    setOpenCopied(false);
    setOpenSaved(false);
    setOpenArchived(false);
    setOpenPublished(false);
  };
  const handleCloseArchived = () => {
    setOpenSnackArchived(false);
  };

  //Function to close the snackbar
  const handleClosePermissionsEdit = () => {
    setOpenSnackPermissionsEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  //Function to close the snackbar
  const handleClosePermissionsEditQuestion = () => {
    setOpenSnackEdit(false);
  };

  const handleClosePermissionsAdd = () => {
    setOpenSnackAdd(false);
  };

  const handleCloseEdit = () => {
    setShowSnackbarEdit(false);
  };

  const handleCloseSnackSelected = () => {
    setOpenSnackSelected(false);
  };

  const handleCloseSnackNoQuestions = () => {
    setOpenSnackNoQuestions(false);
  };

  const handleCloseSnackSectionDeleted = () => {
    setOpenSnackSectionDeleted(false);
  };
  const handleCloseSnackSectionError = () => {
    setOpenSnackSectionError(false);
  };
  const handleCloseSnackSectionDefault = () => {
    setOpenSnackSectionDefault(false);
  };

  const handleCloseSnackSummaryCreated = () => {
    setShowSnackbarAddSummary(false);
  };
  const handleCloseSnackIncludeSummary = () => {
    setShowSnackbarIncludeSummary(false);
  };

  const handleCreateSection = () => {
    if (study.permissions.includes("question-create")) {
      setShowAddSection(1);
    } else {
      setOpenSnackAdd(true);
    }
  }

  const handleCreateSummary = async () => {
    if (study.permissions.includes("question-create")) {
      try {
        const summaryBody = {
          internalCode: `${form.form_translations[0].title}`,
          summaryTranslation: [
            {
              locale: "en",
              title: "Summary",
            },
            {
              locale: "es",
              title: "Summary",
            }
          ],
        };
        setLoading(true);
        const response = await QuestionsService.postSummary(studyId.current, formId.current, summaryBody);
        getSections()
        setShowSnackbarAddSummary(true)
        setTimeout(() => {
          handleGoSummary()
        }, 1000)
        // eslint-disable-next-line no-empty
      } catch (err) {

      }
    } else {
      setOpenSnackAdd(true);
    }
  }

  const handleGoSummary = () => {

    const newObj = { "studyId": studyId.current, "formId": formId.current, "permissions": permissions.current }
    localStorage.setItem("summaryPage", JSON.stringify(newObj));

    const id = study.id
    history.push({
      pathname: `/study/${studyId.current}/form/${formId.current}/summary`,
      state: { detail: id, formId: formId.current, value: 0, permissions: permissions.current }

    })
  }
  const handleMoveQuestion = () => {
    if (study.permissions.includes("question-create")) {
      if (selectQuestions !== undefined && selectQuestions.length !== 0) {
        setShowMoveQuestion(1);
      } else {
        setOpenSnackSelected(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }
  const handleDeleteSelect = () => {
    if (study.permissions.includes("question-create")) {
      if (selectQuestions !== undefined && selectQuestions.length !== 0) {
        setShowDeleteQuestions(true)
      } else {
        setOpenSnackSelected(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }
  const handleIncludeInSummary = () => {
    if (study.permissions.includes("question-create")) {
      if (selectQuestions !== undefined && selectQuestions.length !== 0) {
        setShowIncludeSummary(true)
      } else {
        setOpenSnackSelected(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }
  const handleStateSelect = () => {
    if (study.permissions.includes("question-create")) {
      var section = (sections.find(x => x.id === sectionState))
      if (stateSelect === false && section.questions.length === 0) {
        setOpenSnackNoQuestions(true)
      } else if (section.questions !== undefined || section.questions.length !== 0) {
        setStateSelect(!stateSelect);
      } else {
        setOpenSnackNoQuestions(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }

  //Function that show the moreInfo add question
  const handleClickNext = () => {
    setShowAddQuestionMoreInfo(1);
  };

  const handleEditQuestion = async () => {
    setShowEditQuestion(-999);
    setShowEditQuestionMoreInfo(-999);
  };

  const handleClickNextEdit = (id) => {
    setShowEditQuestionMoreInfo(id);
  };

  const handleCloseAddSection = () => {
    setShowSnackbarAddSection(false);
  };

  const handleCloseMoveQuestion = () => {
    setShowSnackbarMove(false);
  };

  //Function to change the state of the archived
  const handleChangeArchived = () => {
    if (study.permissions.includes("form-edit")) {
      if (archived) {
        setOpenArchived(true);
        archiveForm();
        setStatus(1);
      } else {
        setOpenPublished(true);
        publishForm();
        setStatus(2);
      }
      setArchived(!archived);
    } else {
      setOpenSnackArchived(true);
    }
  };

  //Function to modify a archived the form
  const archiveForm = async () => {
    if (privado === 0 || privado === "0") {
      privado = "PRIVATE";
    } else if (privado === 1 || privado === "1") {
      privado = "PUBLIC";
    }
    const internal_code = camel_to_snake(title)
    const form = new FormModalStatus(title, description, privado, formId.current, "ARCHIVED", internal_code, dateCreation);
    try {
      await FormService.putForm(studyId.current, form.statusToJSON);
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    getForm();
  };

  //Function to modify a archived the form
  const publishForm = async () => {
    if (privado === 0 || privado === "0") {
      privado = "PRIVATE";
    } else if (privado === 1 || privado === "1") {
      privado = "PUBLIC";
    }
    const internal_code = camel_to_snake(title)
    const form = new FormModalStatus(title, description, privado, formId.current, "PUBLISHED", internal_code, dateCreation);
    try {
      await FormService.putForm(studyId.current, form.statusToJSON);
      setStatus(2);
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    getForm();
  };

  //Function to change the conditional of the value
  const changeConditional = () => {
    setValues({ ...values, ["conditional"]: !values["conditional"] });
    if (editValues["conditional"] === "0") {
      setEditValues({ ...editValues, ["conditional"]: true });
    } else if (editValues["conditional"] === "1") {
      setEditValues({ ...editValues, ["conditional"]: false });
    } else {
      setEditValues({
        ...editValues,
        ["conditional"]: !editValues["conditional"],
      });
    }
  };

  //Function to change the mandatory of the value
  const changeMandatory = () => {
    setValues({ ...values, ["mandatory"]: !values["mandatory"] });
    if (editValues && editValues["mandatory"] === 0) {
      setEditValues({ ...editValues, ["mandatory"]: 1 });
    } else if (editValues && editValues["mandatory"] === 1) {
      setEditValues({ ...editValues, ["mandatory"]: 0 });
    } else if (editValues && editValues["mandatory"] === undefined) {
      setEditValues({ ...editValues, ["mandatory"]: !editValues["mandatory"] });
    }
  };

  //Function to check if the inputs are empty when the form is modified
  const checkTextInputModify = () => {
    if (study.permissions.includes("form-edit")) {
      if (title === "" || description === "" || privado === "") {
        setShowValidationAlert(true);
      } else {
        setShowValidationAlert(false);
        modifyForm().then();
        setOpenSaved(true);
      }
    } else {
      setOpenSnackPermissionsEdit(true);
    }
  };

  //Function to check if the inputs are empty when the form is created
  const checkTextInputCreate = () => {
    if (title === "" || description === "" || privado === undefined) {
      setShowValidationAlert(true);
    } else {
      setShowValidationAlert(false);
      createFormEmpty().then();
      setOpenSaved(true);
    }
  };

  //Function to create a new Form from the values
  const createFormEmpty = async () => {
    if (privado === 0) {
      privado = "PRIVATE";
    } else if (privado === 1) {
      privado = "PUBLIC";
    }
    const internal_code = camel_to_snake(title);
    const form = new FormModal(title, description, privado, internal_code);
    try {
      const formCreated = await FormService.postForm(
        studyId.current,
        form.createToJSON
      );
      //formId.current = formCreated.data.data.item.id;
      const formId = formCreated.data.data.item.id;
      localStorage.setItem("formRoute", "edit")
      const newObj = { "detail": studyId.current, "formId": formId, "permissions": permissions }
      localStorage.setItem("formDetails", JSON.stringify(newObj));

      history.push({
        pathname: `/study/${studyId.current}/form/${formId}`,
        state: { detail: studyId.current, formId: formId, value: 1, permissions: permissions },
      });
      getStudy();
      getForm();
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  };

  //Function to modify a form from the values
  const modifyForm = async () => {
    if (privado === 0 || privado === "0") {
      privado = "PRIVATE";
    } else if (privado === 1 || privado === "1") {
      privado = "PUBLIC";
    }
    setOpenSaved(true);
    const internal_code = camel_to_snake(title)
    const form = new FormModal(title, description, privado, internal_code, formId.current, dateCreation);
    try {
      await FormService.putForm(studyId.current, form.modifyToJSON);
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    getForm();
  };

  //Function to get the study data from the id of this
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(
        `${BASE_PATH}/study/${studyId.current}`
      );
      setStudy(response.data.data);
    } catch (err) {
      setStudy(err.message);
    }
  };

  //Function to get the sections from the id of the form and study
  const getStudies = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      setStudies(response.data.data);
      setLoading(false)

    } catch (e) {
      setLoading(false);
    }
  };

  //Function to get the sections from the id of the form and study
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(FORMS_STUDY(studyId.current));
      setForms(response.data.data.items);
      setLoading(false)
    } catch (e) {
      setLoading(false);
    }
  };

  //Function to get the form data from the of the study and the form
  const getForm = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId.current}/form/${formId.current}`);
      setExistSummary(response.data.data.item.summary)
      setForm(response.data.data.item)
      setTitle(response.data.data.item.form_translations[0].title)
      setDescription(response.data.data.item.form_translations[0].description)
      setPrivado(response.data.data.item.visibility)
      setStatus(response.data.data.item.form_status)
      setDateCreation(response.data.data.item.date_creation)
      setAllLoading(false)
    } catch (err) {
      setForm(err.message);
    }
  }
  //Function to get the questions from the id of the form and study
  const getQuestions = async () => {
    setShowAddQuestion(-999);
    setShowAddQuestionMoreInfo(-999);
    setValues(initialValues);
    try {
      const response = await MyAxiosInstance.get(QUESTIONS(formId.current, studyId.current));
      setQuestions(response.data.data.item);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuestions(e.message);
    }
  };

  //Function to get the sections from the id of the form and study
  const getSections = async () => {
    setShowAddSection(-999);
    setValues(initialValues);
    try {
      const response = await MyAxiosInstance.get(SECTIONS(formId.current));
      setSections(response.data.data.item);
      if (sectionState === undefined)
        setSectionState(response.data.data.item[0].id)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuestions(e.message);
    }
  };
  //Function to get the sections from the id of the form and study
  const getSummary = async () => {
    try {
      const response = await MyAxiosInstance.get(SUMMARY(formId.current));
      setSummary(response.data.data.item);
      setLoading(false)
    } catch (e) {
      setSummary([])
      setLoading(false);
    }
  };

  const handleDeleteSection = async () => {
    try {
      var section = (sections.find(x => x.id === showDeleteModalConfirm))
      if (section.questions.length === 0) {
        const response = await QuestionsService.deleteSection(studyId.current, formId.current, showDeleteModalConfirm);
        setLoading(true);
        setOpenSnackSectionDeleted(true);
        getSections();
      } else {
        setOpenSnackSectionError(true);

      }
    } catch (e) {

      setOpenSnackSectionDefault(true);

    }
    setShowDeleteModalConfirm(-999);
  };

  const handleDuplicateQuestion = async () => {
    try {
      setLoading(true);
      const response = await QuestionsService.duplicateQuestion(
        showDuplicateQuestion
      );
      getSections();
      setOpenDuplicate(true);
    } catch (e) {
      console.error(e)
      // TODO: Remove this call when POST to duplicateQuestion is fixed
      getSections();
    }
    setShowDuplicateQuestion(-999);
  };

  const handleDeleteQuestion = async () => {
    try {
      setLoading(true);
      const response = await QuestionsService.deleteQuestionSection(sectionState,
        showDeleteQuestion
      );
      getSections();
      setOpenDelete(true);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteQuestion(-999);
  };

  const handleDeleteQuestions = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectQuestions.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        const response = await QuestionsService.deleteQuestionSection(sectionState,
          selectQuestions[i].id
        );
      }
      getSections();
      setOpenDelete(true);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteQuestions(false);
  };

  const handleIncludeQuestion = async (question, index) => {
    const questionData = question
    var options = []
    var hasSingleOrMultiple = false
    if (questionData.answer_values) {
      if (questionData.type === questionTypes[QUESTION_SINGLE_CHOICE].name || questionData.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name) {
        hasSingleOrMultiple = true
      }
      for (var i = 0; i < questionData.answer_values.length; i++) {
        options.push(
          {
            "answerTitle": questionData.answer_values[i].translations[0].text,
            "answerValue": parseInt(questionData.answer_values[i].value),
            "answerTranslations": [
              {
                "locale": "en",
                "text": questionData.answer_values[i].translations[0].text
              }
            ]
          }
        )
      }
    }
    try {
      const questionBody = {
        "order": questionData.order,
        "formId": formId.current,
        "sectionId": questionData.sectionId,
        "summary": true,
        "variableName": questionData.variable_name,
        "standarName": "",
        "conditional": questionData.conditional,
        "mandatory": questionData.mandatory,
        "type": questionTypesByName[questionData.type].name,
        "minValue": questionData.min_value,
        "maxValue": questionData.max_value,
        "units": questionData.units,
        "interval": questionData.interval,
        "answerOptions": hasSingleOrMultiple ? options : [],
        "questionTranslation": [
          {
            "locale": "en",
            "question": questionData["question_translations"][0]["question"],
            "subtitle": "",
            "info": questionData["question_translations"][0]["info"]
          }
        ]
      }
      const response = await MyAxiosInstance.put(`${BASE_PATH}/question/${question.id}`, questionBody)
      setLoading(true)
    } catch (e) {
      setLoading(false)

    }
  }

  const handleIncludeQuestions = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectQuestions.length; i++) {
        handleIncludeQuestion(selectQuestions[i])
      }
      getSections();
      setShowSnackbarIncludeSummary(true);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowIncludeSummary(false);
  };
  //This function retrives the stored permissions and id from local storage
  const retriveStorageData = async () => {
    const formRoute = localStorage.getItem("formRoute");

    if (formRoute === "edit") {
      const newItem = JSON.parse(localStorage.getItem("formDetails"));
      permissions.current = newItem.permissions;
      studyId.current = newItem.detail;
      formId.current = newItem.formId;
    } else {
      const newItem = JSON.parse(localStorage.getItem("createFormDetails"));
      permissions.current = newItem.permissions;
      studyId.current = newItem.detail;
      formId.current = newItem.formId;
    }

  }

  useEffect(() => {

    retriveStorageData();

  }, [])

  useEffect(() => {
    if (!formId.current) {
      getStudy()
      formId.current = null;
      const form = new FormModal("", "", false);
      setAllLoading(false)
    } else {
      getForm();
      getStudy();
      getQuestions();
      getSections();
      getSummary()
      getStudies()
      getForms()
    }
    //}, []);
  }, [studyId]);
  /* useEffect(() => {
    getQuestions()
  }, [setShowSnackbarAdd, setShowEditQuestion]);
   */
  /* useEffect(() => {
    getStudies()
    getForms()
  }, [showMoveQuestion]);
 */
  /*  useEffect(() => {
     getStudy()
   }, []) */

  const [open, setOpen] = useState(drawer); //false
  const [devicesSize, setDevicesSize] = useState("280px")
  const [breadcrumbMargin, setBreadcrumbMargin] = useState("100%")
  const [cardMargin, setCardmargin] = useState("-1em")
  const [cardWidth, setCardWidth] = useState("80%")

  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {

    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (open) {
        if (window.innerWidth >= 1000) {
          setDevicesSize("280px")
          setBreadcrumbMargin("100%")
          setCardmargin("-1em")
          setCardWidth("80%")
        } else {
          setDevicesSize("10px")
          setBreadcrumbMargin("40%")
          setCardmargin("-2em")
          setCardWidth("100%")
        }

      } else {

        if (window.innerWidth >= 640 && window.innerWidth <= 1680) {
          setDevicesSize("280px")
          setBreadcrumbMargin("100%")
          setCardmargin("-1em")
          setCardWidth("80%")
        } else if (window.innerWidth <= 600) {
          setBreadcrumbMargin("30%")
          setDevicesSize("180px")
          setCardmargin("-1em")
          setCardWidth("100%")

        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  //Function to open the modal of the questions
  const handleOpenModal = () => {
    if (study.permissions.includes("question-create")) {

      setShowAddQuestion(1);
    } else {

      setOpenSnackAdd(true);
    }
  };

  const handleClickEdit = (id) => {
    var conditionalArray = "";
    var conditionalList = [];
    var section = (sections.find(x => x.id === sectionState))
    if (study.permissions.includes("question-edit")) {
      a = section.questions.find(x => x.id === id)
      setEditValues({
        ...a,
        ["hasConditional"]:
          false,
      });
      var editQuestion = section.questions.find(x => x.id === id)
      if (editQuestion.conditional) {
        setEditValues({
          ...a,
          ["hasConditional"]:
            true,
        });
        if (editQuestion.conditional.includes("AND")) {
          setConditionalOperator(1)
          var a = []
          conditionalArray = editQuestion.conditional.split("AND");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }
          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = questions[i].id
                conditionalList[j]["type"] = questions[i].type
                for (let x = 0; x < questions[i].answer_values.length; x++) {
                  if (questions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = questions[i].answer_values[x].id
                  }
                }
              }
            }
          }
        } else if (editQuestion.conditional.includes("OR")) {
          setConditionalOperator(0)
          var a = []
          conditionalArray = editQuestion.conditional.split("OR");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }
          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = questions[i].id
                conditionalList[j]["type"] = questions[i].type
                for (let x = 0; x < questions[i].answer_values.length; x++) {
                  if (questions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = questions[i].answer_values[x].id
                  }
                }
              }
            }
          }
        } else {
          var a = []

          conditionalArray = editQuestion.conditional.split("OR");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }
          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = questions[i].id
                conditionalList[j]["type"] = questions[i].type
                for (let x = 0; x < questions[i].answer_values.length; x++) {
                  if (questions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = questions[i].answer_values[x].id
                  }
                }
              }
            }
          }
        }
        // } else {
        //     var a = []
        //     conditionalArray = editQuestion.conditional.split("=");
        //     for (let i = 0; i < conditionalArray.length; i++) {
        //         conditionalList = {'variable': conditionalArray[0], 'answer':conditionalArray[1]}
        //     }
        //     console.log(conditionalList)
        //     for (let i = 0; i < questions.length; i++) {
        //         console.log(questions[i].variable_name,conditionalList.variable)
        //         if (questions[i].variable_name === conditionalList.variable) {
        //             conditionalList['variableId'] = questions[i].id
        //             for (let x = 0; x < questions[i].answer_values.length; x++) {
        //                 if (questions[i].answer_values[x].value === conditionalList.answer) {
        //                     conditionalList['answerId'] = questions[i].answer_values[x].id
        //                 }
        //             }
        //         }
        //
        //     }
        // }

        setConditionalListLength(conditionalList.length)
        setConditionalListFinal(conditionalList)
        setShowEditQuestion(id)
      } else {
        setShowEditQuestion(id)
      }
    } else {
      setOpenSnackEdit(true)
    }
  }
  return (
    <>
      {study &&
        <DrawerUhda id={studyId.current} drawer={open} select={1} settings={study.permissions} />}

      {allLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>) : <Grid
          container
          direction={"column"}
          style={{
            paddingLeft: devicesSize,//open ? "200px" : "250px",

          }}
          spacing={2}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
        <Grid item>
          {study && form && (
            <div style={{ maxWidth: breadcrumbMargin }}><BreadcrumbsUhda
              routes={[
                { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                {
                  id: 1,
                  label: `${study.translations[0].study_title}`,
                  disabled: true,
                  studyId: studyId.current,
                  permissions: permissions.current
                },
                {
                  id: 2,
                  label: LabelsStudy.FORMS,
                  url: `/study/${studyId.current}/forms`,
                  studyId: studyId.current,
                  permissions: permissions.current
                },
                {
                  id: 3,
                  label: `${form.form_translations[0].title}`,
                  url: "/study",
                },
              ]}
            /></div>
          )}
        </Grid>
        <Grid item style={{ marginTop: cardMargin }}>
          <CardUhda
            width={"1000px"}
            bg={AppColors.WHITE}
            children={
              <Grid
                container
                style={{
                  width: "750px",
                  position: "relative",
                  margin: "3em",
                  display: "grid",
                }}
              >
                <Grid item xs zeroMinWidth>
                  <CardHeader
                    classes={{
                      title: texts.title_bold,
                      root: classes.cardHeaderRoot,
                      content: classes.cardHeaderContent,
                    }}
                    style={{
                      width: "232px",
                      color: AppColors.PRIMARY,
                      paddingBottom: "8px",
                      paddingLeft: 0,
                      paddingTop: 0,
                    }}
                    title={
                      <TextUhda
                        margin={0}
                        style={"break-word"}
                        type={textType.SUBTITLE_BOLD}
                        text={LabelsForm.FORM_DETAIL}
                      />
                    }
                  />
                </Grid>
                <Divider
                  style={{ marginLeft: "-32px", marginBottom: "20px" }}
                />

                {study && <Grid

                  container spacing={4} direction={"row"} rowSpacing={1}

                >

                  <Grid item style={{ paddingRight: 0 }}>
                    {form && (<TextField
                      defaultValue={form.form_translations[0].title}
                      onChange={(e) => setTitle(e.target.value)}
                      style={{ margin: 0, width: 360 }}
                      type="text"
                      label={LabelsForm.TITLE}
                      margin="normal"
                      variant="outlined"

                      className={classes.textFieldLabel}
                      disabled={!study.permissions.includes("form-edit")}
                      inputProps={{ maxLength: 500 }}
                    />)}

                    {!form && !formId.current && (<TextField
                      style={{ margin: 0, width: 360 }}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      label={LabelsForm.TITLE}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldLabel}
                      inputProps={{ maxLength: 500 }}
                    />)}
                  </Grid>

                  <Grid item style={{ paddingRight: 0, }}>
                    <FormControl className={classes.select} variant="outlined" margin='normal'
                      style={{ width: "9.75em" }}>
                      <InputLabel className={classes.select}
                        style={{ paddingTop: 0, marginTop: formId.current ? "-15px" : "-15px" }}
                        id="demo-simple-select-label">Privacy</InputLabel>
                      {form && (
                        <Select className={classes.select} IconComponent={Icons.ARROW_DOWN}
                          value={privado}
                          defaultValue={form.visibility}
                          onChange={handleChange}
                          label="Privacy"
                          style={{ width: 312, marginTop: "-15px" }}
                          disabled={!study.permissions.includes("form-edit")}

                        >
                          <MenuItem style={{ color: AppColors.PRIMARY }}
                            value={1}>{CommonsText.PUBLIC}</MenuItem>
                          <MenuItem style={{ color: AppColors.PRIMARY }}
                            value={0}>{CommonsText.PRIVATE}</MenuItem>

                        </Select>)}

                      {!form && !formId.current &&
                        (<Select className={classes.select} IconComponent={Icons.ARROW_DOWN}
                          value={privado}
                          onChange={handleChange}
                          label="Privacy"
                          style={{ width: 312, marginTop: "-15px" }}
                        >
                          <MenuItem style={{ color: AppColors.PRIMARY }}
                            value={1}>{CommonsText.PUBLIC}</MenuItem>
                          <MenuItem style={{ color: AppColors.PRIMARY }}
                            value={0}>{CommonsText.PRIVATE}</MenuItem>

                        </Select>)}
                    </FormControl>
                  </Grid>

                  <Grid item style={{ height: 100, paddingTop: 0, paddingRight: 0 }}>

                    {form && (<TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      style={{ paddingTop: 0, width: 360, }}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      label={LabelsForm.DESCRIPTION}
                      inputProps={{ maxLength: 200 }}
                      defaultValue={form.form_translations[0].description}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldLabel}
                      disabled={!study.permissions.includes("form-edit")}
                    />)}

                    {!formId.current && !form && (<TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={4}
                      style={{ paddingTop: 0, width: 360, }}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      label={LabelsForm.DESCRIPTION}
                      inputProps={{ maxLength: 200 }}
                      margin="normal"
                      variant="outlined"
                      className={classes.textFieldLabel}
                    />)}
                  </Grid>

                  <Grid item style={{ height: 0, paddingRight: 0 }}>
                    {form && (<TextField
                      style={{ marginTop: 0, width: 312 }}
                      type="text"
                      label={LabelsForm.LINK}
                      defaultValue={form.public_url}
                      margin="normal"
                      disabled
                      variant="outlined"
                      className={classes.textFieldLabelDisabled}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton classes={classes.iconRoot} onClick={() => {
                              navigator.clipboard.writeText(form.public_url);
                              setOpenCopied(true)
                            }}>
                              <img alt='icon' className={classes.imageIcon}
                                src={copyIcon} />
                            </IconButton>
                          </InputAdornment>

                        ),
                      }}
                    />)}
                    {!form && (<TextField
                      style={{ marginTop: 0, width: 312 }}
                      type="text"
                      label={LabelsForm.LINK}
                      defaultValue={"forms.uhda/"}
                      margin="normal"
                      disabled
                      variant="outlined"
                      className={classes.textFieldLabelDisabled}
                    />)}

                    <SnackBarUhda handleClose={handleClose} message={SnackBarResponses.LINK_COPIED}
                      openSnack={openCopied} />

                  </Grid>

                  {study && <Grid container spacing={4} justifyContent={"flex-end"} style={{
                    paddingRight: "4.5em",
                    paddingBottom: study.permissions.includes("form-edit") ? 0 : "2.5em"
                  }}>

                    <Grid item>
                      {
                        form && study.permissions.includes("form-edit") &&
                        (<ButtonFilledUhda width={"110px"} onClick={checkTextInputModify}
                          color={AppColors.WHITE}
                          margin={"0.7em"}
                          text={CommonsText.SAVE}
                        />)
                      }

                      {!formId.current && !form &&

                        (

                          <ButtonFilledUhda onClick={checkTextInputCreate} color={AppColors.WHITE}
                            margin={"0.7em"}
                            text={CommonsText.CREATE}
                          />

                        )}
                    </Grid>
                    <SnackBarUhda handleClose={handleClose}
                      message={SnackBarResponses.CHANGES_SAVED}
                      openSnack={openSaved} />

                    <Grid item>
                      {status === 1 && form && study.permissions.includes("form-edit") &&
                        (<ButtonFilledUhda marginLeft={"0.1em"} onClick={handleChangeArchived}
                          color={AppColors.WHITE}
                          backGroundColor={AppColors.RED} margin={"0.7em"}
                          icon={<IconProviderUhda
                            icon={<Icons.ARCHIVE size={"1.5em"}
                              style={{ color: AppColors.WHITE }} />} />}
                          text={LabelsForm.ARCHIVE}
                        />)}

                      {status === 2 && form && (study.permissions.includes("form-edit") &&
                        <ButtonFilledUhda marginLeft={"0.1em"} onClick={handleChangeArchived}
                          color={AppColors.WHITE}
                          margin={"0.7em"}
                          icon={<IconProviderUhda
                            icon={<Icons.UNARCHIVE size={"1.5em"}
                              style={{ color: AppColors.WHITE }} />} />}
                          text={LabelsForm.PUBLISH}
                        />)}

                      <SnackBarUhda handleClose={handleClose}
                        message={SnackBarResponses.FORM_ARCHIVED}
                        openSnack={openArchived} />
                      <SnackBarUhda handleClose={handleClose}
                        message={SnackBarResponses.FORM_PUBLISHED}
                        openSnack={openPublished} />
                      <SnackBarUhda severity={"error"} handleClose={handleCloseArchived}
                        message={SnackBarResponses.NOT_PERMISSION}
                        openSnack={openSnackArchived} />

                    </Grid>

                  </Grid>}
                  {showValidationAlert && (
                    <Grid container spacing={4} justifyContent={"flex-end"}
                      style={{ paddingRight: "2.4em" }}>
                      <Grid item style={{ marginTop: "1em" }}>
                        <Alert severity="error">{ErrorTexts.FORM}</Alert></Grid></Grid>
                  )}
                </Grid>}

                <SnackBarUhda handleClose={handleClosePermissionsEdit} severity={"error"}
                  message={SnackBarResponses.NOT_PERMISSION}
                  openSnack={openSnackPermissionsEdit} />
                <SnackBarUhda handleClose={handleClosePermissionsEditQuestion} severity={"error"}
                  message={SnackBarResponses.NOT_PERMISSION}
                  openSnack={openSnackEdit} />

              </Grid>} />

        </Grid>

        {study && <Grid container style={{ paddingTop: "1em", paddingBottom: "4em" }} direction={"row"}>

          <Grid
            container
            item
            spacing={5}
            style={{ paddingBottom: "1em" }}
            direction={"row"}
          >
            {!stateSelect && <Grid item>

              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
                  onClick={handleOpenModal}
                  icon={<IconProviderUhda
                    icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
                  text={CommonsTexts.NEW_QUESTION} />
                )}

              {!formId.current &&
                (<ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
                  isDisabled={true}
                  onClick={handleOpenModal} backGroundColor={AppColors.BACKGROUND}
                  color={AppColors.SUBTEXT}
                  border={"2px solid"}
                  icon={<IconProviderUhda
                    icon={<Icons.ADD size={"1.5em"} color={AppColors.SUBTEXT} />} />}
                  text={CommonsTexts.NEW_QUESTION} />)}
            </Grid>}
            {/* {!stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleCreateSection}
                  icon={<IconProviderUhda
                    icon={<Icons.ADD size={"1.5em"}
                      color={AppColors.WHITE} />} />}
                  text={CommonsTexts.NEW_SECTION} />
                )}
            </Grid>} */}
            {!stateSelect && summary.length === 0 && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleCreateSummary}

                  text={CommonsTexts.ADD_SUMMARY} />
                )}
            </Grid>}
            {!stateSelect && summary.length !== 0 && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleGoSummary}

                  text={CommonsTexts.GO_SUMMARY} />
                )}
            </Grid>}

            {!stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleStateSelect}
                  backGroundColor={AppColors.SUBTEXT}

                  text={CommonsTexts.SELECT_QUESTIONS} />
                )}
            </Grid>}
            {stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleMoveQuestion}
                  icon={<IconProviderUhda
                    icon={<Icons.MOVE size={"1.5em"} style={{ color: AppColors.WHITE }}
                    />} />}
                  text={CommonsTexts.MOVE} />
                )}
            </Grid>}
            {stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.RED}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleDeleteSelect}
                  icon={<IconProviderUhda
                    icon={<Icons.DELETE size={"1.5em"}
                      style={{ color: AppColors.WHITE }} />} />}
                  text={CommonsTexts.DELETE} />
                )}
            </Grid>}

            {stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.PRIMARY}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleIncludeInSummary}
                  icon={
                    <Icon classes={{ root: classes.iconRoot }}>
                      <img
                        className={classes.imageIcon}
                        src={SummarizeOutlinedIcon}
                      />
                    </Icon>}
                  text={CommonsTexts.INCLUDE_SUMMARY} />
                )}
            </Grid>}

            {stateSelect && <Grid item>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.SUBTEXT}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleStateSelect}

                  text={CommonsTexts.CANCEL} />
                )}
            </Grid>}

          </Grid>
          <SnackBarUhda
            handleClose={handleClosePermissionsAdd}
            severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackAdd}
          />

          {(sections && !loading) && <Grid>
            <TabsUhda
              getSections={getSections}
              formId={formId.current}
              studyId={studyId.current}
              sections={sections}
              handleClickEdit={handleClickEdit}
              getQuestions={getQuestions}
              loading={loading}
              setLoading={setLoading}
              setShowDuplicateQuestion={setShowDuplicateQuestion}
              setShowDeleteQuestion={setShowDeleteQuestion}
              showEditQuestion={showEditQuestion}
              setShowEditQuestion={setShowEditQuestion}
              canEdit={study.permissions.includes("question-edit")}
              canDelete={study.permissions.includes("question-delete")}
              sectionState={sectionState}
              setSectionState={setSectionState}
              stateSelect={stateSelect}
              setSelectQuestions={setSelectQuestions}
              setShowDeleteModalConfirm={setShowDeleteModalConfirm}
            /></Grid>
          }
        </Grid>}
      </Grid>}
      {showAddQuestion >= 0 && (
        <AddQuestionModal
          formTitle={form.form_translations[0].title}
          questionsLength={sections.find(x => x.id === sectionState).questions.length}
          questionsSummaryLength={summary.length !== 0 ? summary.questions.length : 0}
          hasNextModal={hasNextModal}
          setHasNextModal={setHasNextModal}
          formId={formId.current}
          studyId={studyId.current}
          sections={sections}
          sectionState={sectionState}
          handleClickNext={handleClickNext}
          initialValues={initialValues}
          values={values}
          setValues={setValues}
          showAddQuestion={showAddQuestion}
          setShowAddQuestion={setShowAddQuestion}
          // changeConditional={changeConditional}
          changeMandatory={changeMandatory}
          setLoading={setLoading}
          getQuestions={getQuestions}
          questions={questions}
          setShowSnackbarAdd={setShowSnackbarAdd}
          getSections={getSections}
          existSummary={existSummary}
        />
      )}

      {showEditQuestion >= 0 && (
        <EditQuestionModal
          formTitle={form.form_translations[0].title}
          questionsLength={questions.length}
          questionsSummaryLength={summary.length !== 0 ? summary.questions.length : 0}
          hasNextModal={hasNextModal}
          setHasNextModal={setHasNextModal}
          formId={formId.current}
          studyId={studyId.current}
          sections={sections}
          sectionState={sectionState}
          handleClickNext={handleClickNextEdit}
          editValues={editValues}
          setEditValues={setEditValues}
          showEditQuestion={showEditQuestion}
          setShowEditQuestion={setShowEditQuestion}
          // changeConditional={changeConditional}
          changeMandatory={changeMandatory}
          setLoading={setLoading}
          getQuestions={getQuestions}
          getSections={getSections}
          questions={questions}
          setShowSnackbarEdit={setShowSnackbarEdit}
          handleEditQuestion={handleEditQuestion}
          variableId={variableId}
          answerId={answerId}
          conditionalListFinal={conditionalListFinal}
          conditionalOperatorFinal={conditionalOperator}
          existSummary={existSummary}
          conditionalListLength={conditionalListLength}
        />
      )}

      {showMoveQuestion >= 0 && (
        <MoveQuestionModal
          questionsLength={questions.length}
          formId={formId.current}
          studyId={studyId.current}
          studies={studies}
          forms={forms}
          sections={sections}
          sectionState={sectionState}
          showMoveQuestion={showMoveQuestion}
          setShowMoveQuestion={setShowMoveQuestion}
          // changeConditional={changeConditional}
          setLoading={setLoading}
          getQuestions={getQuestions}
          questions={questions}
          setShowSnackbarMove={setShowSnackbarMove}
          getSections={getSections}
          selectedQuestions={selectQuestions}
        />
      )}

      {showAddSection >= 0 && (
        <AddSectionModal
          formId={formId.current}
          studyId={studyId.current}
          sectionsLength={sections.length}
          showAddSection={showAddSection}
          setShowAddSection={setShowAddSection}
          getSections={getSections}
          setLoading={setLoading}
          setShowSnackbarAddSection={setShowSnackbarAddSection}
        />
      )}

      {showDeleteQuestion >= 0 && (
        <DeleteModal
          setShowDeleteQuestion={setShowDeleteQuestion}
          showDeleteQuestion={showDeleteQuestion}
          handleDeleteQuestion={handleDeleteQuestion}
        />
      )}

      {showDuplicateQuestion >= 0 && (
        <DuplicateModal
          setShowDuplicateQuestion={setShowDuplicateQuestion}
          showDuplicateQuestion={showDuplicateQuestion}
          handleDuplicateQuestion={handleDuplicateQuestion}
        />
      )}

      {showDeleteQuestions === true && (
        <DeleteQuestionsModal
          setShowDeleteQuestions={setShowDeleteQuestions}
          showDeleteQuestions={showDeleteQuestions}
          selected={selectQuestions}
          handleDeleteQuestions={handleDeleteQuestions}
        />
      )}
      {showIncludeSummary === true && (
        <IncludeQuestionsModal
          setShowIncludeSummary={setShowIncludeSummary}
          showIncludeSummary={showIncludeSummary}
          selected={selectQuestions}
          handleIncludeQuestions={handleIncludeQuestions}
        />
      )}

      {showDeleteModalConfirm >= 0 && (
        <DeleteSectionModal
          setShowDeleteModalConfirm={setShowDeleteModalConfirm}
          showDeleteModalConfirm={showDeleteModalConfirm}
          selected={selectQuestions}
          handleDeleteSection={handleDeleteSection}
        />
      )}
      <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.QUESTION_DELETED}
        openSnack={openDelete} />
      <SnackBarUhda handleClose={handleCloseDuplicate} message={SnackBarResponses.QUESTION_DUPLICATED}
        openSnack={openDuplicate} />
      <SnackBarUhda handleClose={handleCloseAdd} message={SnackBarResponses.QUESTION_SAVED}
        openSnack={showSnackbarAdd} />
      <SnackBarUhda handleClose={handleCloseEdit} message={SnackBarResponses.QUESTION_SAVED}
        openSnack={showSnackbarEdit} />
      <SnackBarUhda handleClose={handleCloseAddSection} message={SnackBarResponses.SECTION_SAVED}
        openSnack={showSnackbarAddSection} />
      <SnackBarUhda handleClose={handleCloseMoveQuestion} message={SnackBarResponses.QUESTION_MOVED}
        openSnack={showSnackbarMove} />
      <SnackBarUhda handleClose={handleCloseSnackSelected} message={SnackBarResponses.SELECTED_QUESTION}
        openSnack={openSnackSelected} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackSelected} message={SnackBarResponses.SELECTED_QUESTION}
        openSnack={openSnackSelected} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackNoQuestions} message={SnackBarResponses.NO_QUESTIONS}
        openSnack={openSnackNoQuestions} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackSectionDeleted} message={SnackBarResponses.SECTION_DELETED}
        openSnack={openSnackSectionDeleted} />
      <SnackBarUhda handleClose={handleCloseSnackSectionError} severity={"error"}
        message={SnackBarResponses.SECTION_ERROR}
        openSnack={openSnackSectionError} />
      <SnackBarUhda handleClose={handleCloseSnackSectionDefault} severity={"error"}
        message={SnackBarResponses.SECTION_DEFAULT}
        openSnack={openSnackSectionDefault} />
      <SnackBarUhda handleClose={handleCloseSnackSummaryCreated}
        message={SnackBarResponses.SUMMARY_CREATED}
        openSnack={showSnackbarAddSummary} />
      <SnackBarUhda handleClose={handleCloseSnackIncludeSummary}
        message={SnackBarResponses.SUMMARY_INCLUDED}
        openSnack={showSnackbarIncludeSummary} />

    </>
  );
};

export default FormPage;
