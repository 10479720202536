/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Box, Divider, Drawer, List, ListItem, ListItemText } from "@material-ui/core";
import { AppColors } from "../../resources/AppColors";
import { LabelsStudy } from "../../locale/en";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { useTextStyles } from "../../resources/AppTexts";
import { useSelector } from "react-redux";
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

const drawerWidth = 150;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: AppColors.BACKGROUND,
    display: "flex",
  },
  content: {
    backgroundColor: AppColors.BACKGROUND,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    flexGrow: 1,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  drawerHeader: {

    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawer: {

    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  iconRoot: {
    textAlign: "center"
  },
  imageIcon: {
    height: "100%"
  },

  hide: {
    display: "none",
  },
  drawerOpen: {
    marginTop: "64px",
    marginLeft: "240px",

    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,

    }),

    [theme.breakpoints.down("sm")]: {
      marginLeft: "70px",
    },

    [theme.breakpoints.down("xs")]: {
      marginLeft: "57px",
    },
  },
  drawerClose: {
    marginTop: "64px",
    marginLeft: "70px",

    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20) + 1,

    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "55px",
    },

  },

}));

/**
 * @component
 * Component to create the drawer when you are on a study
 *
 * @param id: string to know what study it is
 * @param drawer: boolean to set the drawer open or not
 * @param select: index to know what item on the drawer is selected
 * @constructor
 *
 * <DrawerUhda id={25} drawer={true} select={0} />
 */
const DrawerUhda = ({ id, drawer, select, settings, newStudy }) => {
  // eslint-disable-next-line no-unused-vars
  const texts = useTextStyles();
  // * The labels are the permissions the user has
  const [labels, setLabels] = useState()
  const [drawerLink, setDrawerLink] = useState()
  const [open, setOpen] = useState(drawer);//false
  // TODO: Pointless state, just pass select directly to where is needed (it's everywhere)
  const [selected] = useState(select);

  const unreadChatMessages = useSelector(({ unreadChatMessagesState }) => unreadChatMessagesState)

  const classes = useStyles();

  const knowPermissions = () => {
    const label = [];
    const link = [];
    if (settings.includes("access-data")) {

      label.push(LabelsStudy.DASHBOARD)
      link.push(`/study/${id}/dashboard`)
    }
    if (settings.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      link.push(`/study/${id}/forms`)

    }
    if (settings.includes("participant-create") || settings.includes("participant-edit") || settings.includes("participant-delete")) {

      label.push(LabelsStudy.PARTICIPANTS)
      link.push(`/study/${id}/participants`)

    }
    if (settings.includes("teammember-create") || settings.includes("teammember-edit") || settings.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      link.push(`/study/${id}/teamMembers`)
    }
    if (settings.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      link.push(`/study/${id}/settings`)
    }
    setLabels(label)
    setDrawerLink(link)
  };

  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  useEffect(() => {
    knowPermissions()
  }, [])

  const drawerList = [LabelsStudy.DASHBOARD, LabelsStudy.FORMS, LabelsStudy.PARTICIPANTS, LabelsStudy.TEAM_MEMBERS]

  return (
    <>
      <Drawer
        anchor="left"
        open={drawer}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Divider />
        {!newStudy && labels && drawerLink && <List>
          {labels.map((label, index) => (
            // eslint-disable-next-line react/jsx-key
            <Link to={{
              pathname: drawerLink[index],
              state: { detail: id, drawer: drawer, permissions: labels }
            }}
              style={{ color: AppColors.WHITE, textDecoration: "none" }}>
              <ListItem button key={label} selected={selected === index} style={{
                backgroundColor: selected === index && AppColors.PIRMARY_WITH_OPACITY
              }}>
                <Box sx={{ display: "flex", justifyContent: "spaceBetween" }}>
                  <ListItemText selected={selected === index} classes={{ primary: texts.subtitle_bold }}
                    primary={label}
                    style={{ color: selected === index ? AppColors.ACCENT : AppColors.PRIMARY }} />
                  {label === "Participants" && unreadChatMessages?.length > 0 &&
                    <MarkUnreadChatAltIcon fontSize="small" sx={{ color: "red", ml: .5 }} />
                  }
                </Box>
              </ListItem>
            </Link>
          ))}
        </List>}
        {newStudy && labels && drawerLink &&
          <List>
            {drawerList.map((text, index) => (
              <ListItem disabled button key={text} selected={selected === index} style={{
                backgroundColor: selected === index && AppColors.PIRMARY_WITH_OPACITY,
              }}>
                <ListItemText selected={selected === index} classes={{ primary: texts.subtitle_bold }}
                  primary={text}
                  style={{ color: selected === index ? AppColors.ACCENT : AppColors.PRIMARY, }} />
              </ListItem>
            ))}
            <ListItem key={"Settings"} selected={5} style={{
              backgroundColor: 5 && AppColors.PIRMARY_WITH_OPACITY,
            }}>
              <ListItemText selected={5} classes={{ primary: texts.subtitle_bold }} primary={"Settings"}
                style={{ color: AppColors.ACCENT }} />
            </ListItem>
          </List>}
      </Drawer>
    </>
  )
}

DrawerUhda.propTypes = {}

export default DrawerUhda;
