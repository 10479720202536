/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import FormCardUhda from "../FormCardUhda";
import { CommonsTexts, LabelsDrawer, LabelsForms, LabelsStudy, SnackBarResponses } from "../../locale/en";
import { textType, useTextStyles } from "../../resources/AppTexts";
import { AppColors } from "../../resources/AppColors";
import DialogUhda from "../DialogUhda";
import TextUhda from "../TextUdha";
import BreadcrumbsUhda from "../BreadcrumbsUhda";
import ButtonFilledUhda from "../ButtonFilledUhda";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH, DELETE_FORM, DUPLICATE_FORM } from "../../resources/ApiUrls";
import SnackBarUhda from "../SnackBarUhda";
import SearchBar from "material-ui-search-bar"

/**
 *@component
 * Component to show all the forms of a study + the option to create a new form
 *
 * @param {object} forms: JSON with all forms of one study
 * @param {number} id: value to identify the study
 *
 * @example
 * const forms = {your JSON form data}
 * <GridFormsUhda forms={forms}/>
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  }
}))

const GridFormsUhda = ({ studies, forms, id, permissions, drawer }) => {

  const history = useHistory()
  const texts = useTextStyles();
  const classes = useStyles();
  const [redirectTo, setRedirectTo] = useState([false, -1]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentForm, setCurrentForm] = useState(null);
  const [currentTitleForm, setCurrentTitleForm] = useState(null);
  const studyId = id
  const [study, setStudy] = useState();
  const [form, setForms] = useState(forms);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openSnackDelete, setOpenSnackDelete] = React.useState(false);
  const [openSnackDuplicate, setOpenSnackDuplicate] = React.useState(false);
  const [openSnackCreate, setOpenSnackCreate] = React.useState(false);
  const [open, setOpen] = useState(drawer);//false
  const [breadcrumbMargin, setBreadcrumbMargin] = useState("20px");
  const [cardMargin, setCardMargin] = useState("-37px");
  const [searched, setSearched] = useState();

  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id}/summary-forms/locale/en`);
      setForms(response.data.data.items)

    } catch (err) {
      setForms(err.message)
    }
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  useEffect(() => {
    //getStudy()
    //getForms()
  }, []);

  const handleOnEdit = (formId) => {

    localStorage.setItem("formRoute", "edit")
    const newObj = { "detail": studyId, "formId": formId, "permissions": permissions }
    localStorage.setItem("formDetails", JSON.stringify(newObj));

    history.push({
      pathname: `/study/${studyId}/form/${formId}`, //TODO cambiar porque es de pruebas para el study
      state: { detail: studyId, formId: formId, permissions: permissions }

    })
  }

  const moveToCreateForm = () => {

    localStorage.setItem("formRoute", "create")
    const newObj = { "detail": studyId, "formId": null, "permissions": permissions }
    localStorage.setItem("createFormDetails", JSON.stringify(newObj));

  }
  useEffect(() => {
    moveToCreateForm()
  }, [])

  const handleClosePermissionsDelete = () => {
    setOpenSnackDelete(false);
  };

  const handleClosePermissionsDuplicate = () => {
    setOpenSnackDuplicate(false);
  };

  const handleClosePermissionsCreate = () => {
    setOpenSnackCreate(false);
  };

  const handleOnDuplicate = form => {
    // TODO: Duplicate form
    if (studies.permissions.includes("form-duplicate")) {
      setCurrentTitleForm(form.form_translations[0].title)
      setCurrentForm(form)
      setShowDuplicateModal(!showDuplicateModal)
    } else {
      setOpenSnackDuplicate(true)
    }
  }

  const handleOnConfirmDuplicate = async (e) => {
    // TODO: Duplicate form
    try {
      const formId = currentForm.id
      e.preventDefault()
      try {
        await MyAxiosInstance.post(DUPLICATE_FORM(studyId, formId));
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
      setShowDuplicateModal(!showDuplicateModal)
      setOpenDuplicate(true)
      getForms()

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    setShowDuplicateModal(!showDuplicateModal)
    getForms()
  }

  const handleOnDelete = form => {
    // TODO: Delete form
    if (studies.permissions.includes("form-delete")) {
      setCurrentTitleForm(form.form_translations[0].title)
      setCurrentForm(form)
      setShowDeleteModal(!showDeleteModal)
    } else {
      setOpenSnackDelete(true)
    }
  }

  const handleOnConfirmDelete = async (e) => {
    try {
      const formId = currentForm.id
      e.preventDefault()
      try {
        await MyAxiosInstance.delete(DELETE_FORM(formId));
        // eslint-disable-next-line no-empty
      } catch (error) {

      }
      // TODO: Delete Study
      setShowDeleteModal(!showDeleteModal)
      setOpenDelete(true)
      getForms()

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    // TODO: Delete Study
    setShowDeleteModal(!showDeleteModal)
    getForms()

  }

  const toFormPage = () => {

    if (studies.permissions.includes("form-create")) {
      history.push({
        pathname: `/study/${studyId}/form`,
        state: { detail: studyId, formId: null, value: 6, permissions: permissions }
      })
    } else {
      setOpenSnackCreate(true)
    }
  }

  useEffect(() => {

    if (open) {
      if (window.innerWidth >= 1000 && window.innerWidth < 1289) {
        setBreadcrumbMargin("10px")
        setCardMargin("-37px")

      } else if (window.innerWidth >= 640 && window.innerWidth == 1680) {
        setBreadcrumbMargin("10px")
        setCardMargin("-50px")
      }

      else if (window.innerWidth >= 1920 && window.innerWidth <= 2560) {
        setCardMargin("-37")
        setBreadcrumbMargin("50px")

      } else {
        setBreadcrumbMargin("1px")
        setCardMargin("-0.5em")
      }

    } else {

      if (window.innerWidth >= 640 && window.innerWidth == 1680) {
        setBreadcrumbMargin("65px")
        setCardMargin("-37px")
      } else if (window.innerWidth >= 640 && window.innerWidth <= 1289) {
        setBreadcrumbMargin("20px")
        setCardMargin("10px")

      } else if (window.innerWidth >= 1280 && window.innerWidth <= 1600) {
        setBreadcrumbMargin("35px")
        setCardMargin("10px")

      } else if (window.innerWidth <= 600) {
        setCardMargin("-0.5em")
        setBreadcrumbMargin("10px")

      } else if (window.innerWidth >= 1289 && window.innerWidth <= 1920) {
        setCardMargin("20px")
        setBreadcrumbMargin("40px")

      } else if (window.innerWidth >= 2560) {
        setCardMargin("-50px")
        setBreadcrumbMargin("100px")

      } else {
        //alert(window.innerWidth)
      }
    }

  }, [open])

  const requestSearch = (searchedVal) => {
    const filteredRows = forms.filter((row) => {
      return (row.form_translations[0].title.toLowerCase().includes(searchedVal.toLowerCase()) || row.form_translations[0].description.toLowerCase().includes(searchedVal.toLowerCase()));
    });
    setForms(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  if (redirectTo[0]) {
    return (
      <Container fluid style={{ margin: 0, maxWidth: "100%" }}>
        <div className={classes.root}>

          {studies && <Grid container item
            direction={"row"}
            alignItems={"flex-start"}
            justifyContent={"space-between"}>
            {studies && <div style={{ marginLeft: breadcrumbMargin, marginTop: "2em" }}><BreadcrumbsUhda routes={
              [
                { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                {
                  id: 1,
                  label: `${studies.translations[0].study_title}`,
                  disabled: true,
                  studyId: id,
                  permissions: permissions

                },
                { id: 2, label: LabelsStudy.FORMS },
              ]
            } /></div>}
            <div style={{ marginTop: "2em", marginLeft: breadcrumbMargin }}> <ButtonFilledUhda margin={"0.7em"} marginLeft={"0.5"}
              onClick={toFormPage}

              icon={<IconProviderUhda
                icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
              text={CommonsTexts.NEW_FORM} /></div>

          </Grid>}

          <Grid
            align='center'
            container item
            spacing={2}
            direction="row"
            alignItems={"center"}
            justify="flex-start"
            style={{ marginLeft: cardMargin }}
          >
            {forms.length === 0 && (
              <div style={{ marginTop: "25vh", marginBottom: "15vh", width: "100%" }}>
                <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                  text={CommonsTexts.CREATE_FORM_INFO} align='center' />

              </div>
            )}
            {showDuplicateModal && (
              <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
                body={
                  <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DELETE1}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${currentForm.title}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DELETE2}`} />

                  </Grid>}
                buttonColor={AppColors.PRIMARY}
                title={LabelsForms.TITLE_MODAL_DUPLICATE}
                show={showDuplicateModal}
                handleShow={setShowDuplicateModal}
                handleConfirm={handleOnConfirmDuplicate}
                textConfirmButton={LabelsForms.CONFIRM_MODAL_DUPLICATE} />
            )}
            {showDeleteModal && (
              <DialogUhda textCancelButton={LabelsForms.CANCEL_MODAL}
                body={
                  <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DELETE1}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                      text={`${currentTitleForm}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsForms.TEXT_MODAL_DELETE2}`} />

                  </Grid>}
                buttonColor={AppColors.RED}
                title={LabelsForms.TITLE_MODAL_DELETE}
                show={showDeleteModal}
                handleShow={setShowDeleteModal}
                handleConfirm={handleOnConfirmDelete}
                textConfirmButton={LabelsForms.CONFIRM_MODAL_DELETE} />
            )}
            {
              studies && form.map(elem => (
                <Grid item key={form.indexOf(elem)}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}>
                  <FormCardUhda
                    studyId={studyId}
                    formId={elem.id}
                    formTitle={elem.form_translations[0].title}
                    formDescription={elem.form_translations[0].description}
                    numberOfResponses={elem.numberAnswers}
                    handleOnEdit={handleOnEdit}
                    handleOnDuplicate={() => {
                      handleOnDuplicate(elem)
                    }}
                    handleOnDelete={() => {
                      handleOnDelete(elem)
                    }}
                    permissions={studies.permissions}
                    labels={permissions} />

                </Grid>
              ))
            }
            <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.FORM_DELETED}
              openSnack={openDelete} />
            <SnackBarUhda handleClose={handleCloseDuplicate} message={SnackBarResponses.FORM_DUPLICATED}
              openSnack={openDuplicate} />
            <SnackBarUhda handleClose={handleClosePermissionsDelete} severity={"error"}
              message={SnackBarResponses.NOT_PERMISSION}
              openSnack={openSnackDelete} />
            <SnackBarUhda handleClose={handleClosePermissionsCreate} severity={"error"}
              message={SnackBarResponses.NOT_PERMISSION}
              openSnack={openSnackCreate} />
          </Grid>
        </div>

      </Container>

    )
  }

  return (
    <Container fluid style={{ margin: 0, maxWidth: "100%" }}>
      <div className={classes.root}>

        {studies && <Grid container item
          direction={"row"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}

        >
          {studies && <div style={{
            marginLeft: breadcrumbMargin,

            marginTop: "2em"
          }}><BreadcrumbsUhda routes={
            [
              { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
              {
                id: 1,
                label: `${studies.translations[0].study_title}`,
                disabled: true,
                studyId: id,
                permissions: permissions

              },
              { id: 2, label: LabelsStudy.FORMS },
            ]
          } /></div>}

          {studies.permissions.includes("form-create") &&
            <div style={{
              marginTop: "2em",
              //marginLeft: breadcrumbMargin 
            }}><ButtonFilledUhda margin={"0.7em"} marginLeft={"0.5em"}

              onClick={() => history.push({
                pathname: `/study/${studyId}/form`,
                state: {
                  detail: studyId,
                  formId: null,
                  value: 6,
                  permissions: permissions
                }
              })}

              icon={<IconProviderUhda
                icon={<Icons.ADD size={"1.5em"}
                  color={AppColors.WHITE} />} />}
              text={CommonsTexts.NEW_FORM} /></div>}

        </Grid>}
        <Grid item style={{ marginLeft: "2em" }}>
          <SearchBar
            placeholder={"Search by title or description of the form"}
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </Grid>
        <br />

        <Grid
          align='center'
          container item
          spacing={2}
          direction="row"
          alignItems={"center"}
          justify="flex-start"
        //style={{ marginLeft: cardMargin }}

        >
          {forms.length === 0 && (
            <div style={{ marginTop: "25vh", marginBottom: "15vh", width: "100%" }}>
              <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                text={CommonsTexts.CREATE_FORM_INFO} align='center' />

            </div>
          )}

          {showDuplicateModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DUPLICATE1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleForm}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DUPLICATE2}`} />

                </Grid>}
              buttonColor={AppColors.PRIMARY}
              title={LabelsForms.TITLE_MODAL_DUPLICATE}
              show={showDuplicateModal}
              handleShow={setShowDuplicateModal}
              handleConfirm={handleOnConfirmDuplicate}
              textConfirmButton={LabelsForms.CONFIRM_MODAL_DUPLICATE} />
          )}
          {showDeleteModal && (
            <DialogUhda textCancelButton={LabelsForms.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DELETE1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleForm}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsForms.TEXT_MODAL_DELETE2}`} />

                </Grid>}
              buttonColor={AppColors.RED}
              title={LabelsForms.TITLE_MODAL_DELETE}
              show={showDeleteModal}
              handleShow={setShowDeleteModal}
              handleConfirm={handleOnConfirmDelete}
              textConfirmButton={LabelsForms.CONFIRM_MODAL_DELETE} />
          )}
          {
            studies && form.map(elem => (
              <Grid item key={form.indexOf(elem)}

                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}>
                <FormCardUhda

                  studyId={studyId}
                  formId={elem.id}
                  formTitle={elem.form_translations[0].title}
                  formDescription={elem.form_translations[0].description}
                  numberOfResponses={elem.numberAnswers}
                  handleOnEdit={handleOnEdit}
                  handleOnDuplicate={() => {
                    handleOnDuplicate(elem)
                  }}
                  handleOnDelete={() => {
                    handleOnDelete(elem)
                  }}
                  permissions={studies.permissions}
                  labels={permissions} />

              </Grid>
            ))
          }
          <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.STUDY_DELETED}
            openSnack={openDelete} />
          <SnackBarUhda handleClose={handleClosePermissionsDelete} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackDelete} />
          <SnackBarUhda handleClose={handleClosePermissionsDuplicate} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackDelete} />
          <SnackBarUhda handleClose={handleClosePermissionsCreate} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackCreate} />
        </Grid>
      </div>

    </Container>

  )
}

export default GridFormsUhda;
