import {makeStyles} from "@material-ui/core/styles";
import {AppColors} from "../../resources/AppColors";

export const textFieldStyles = makeStyles(() => ({
   selectMargin:{
    marginTop: "-5px",
    paddingTop: 0,
   },

    textFieldLabel: {
        
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: AppColors.GREY,
            marginTop: "5px",
            
          },
          "&.Mui-focused fieldset":{
            borderColor: AppColors.ACCENT,
          },
          "&.Mui-after fieldset":{
            borderColor: AppColors.ACCENT,
          }
        }, "& .MuiInputBase-root": {
          color: AppColors.BLACK,
    
        }, "& .MuiInputLabel-root": {
          color: AppColors.BLACK,
            
        }, "& label.Mui-focused": {
          color: AppColors.BLACK,
          backgroundColor: AppColors.WHITE,
        
        },
            
        color: AppColors.BLACK,
        //backgroundColor:AppColors.RED,
         
      },
    
    textFieldLabelWithText: {
          
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: AppColors.GREY,
              marginTop: "5px",
            },
            "&.Mui-focused fieldset":{
              borderColor: AppColors.ACCENT,
            }
          }, "& .MuiInputBase-root": {
            color: AppColors.BLACK,
      
          }, "& .MuiInputLabel-root": {
            color: AppColors.BLACK,
            backgroundColor:AppColors.WHITE
          }, "& label.Mui-focused": {
            color: AppColors.BLACK,
            backgroundColor: AppColors.WHITE,
          
          },
              
          color: AppColors.BLACK,
          backgroundColor:AppColors.WHITE,
       
    },

    textFieldLabelDisabled: {
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: AppColors.GREY,
            marginTop: "5px",
            
          },
        
        "&.Mui-focused fieldset":{
            borderColor: AppColors.ACCENT,
          }
        }, "& .MuiInputBase-root": {
          color: AppColors.BLACK,
    
        }, "& .MuiInputLabel-root": {
          color: AppColors.BLACK,
          backgroundColor:AppColors.WHITE
        }, "& label.Mui-focused": {
          color: AppColors.BLACK,
          backgroundColor: AppColors.WHITE,
          
        },
    
        color: AppColors.GREY,
      
      },
  
  }));
