/* eslint-disable camelcase */
import React, {useState} from "react"
import PropTypes from "prop-types"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core"
import {QUESTION_INFO, QUESTION_RANGE, questionTypes} from "../../resources/QuestionConstants";
import {formTypes, formTypesEnum} from "../../resources/FormEnums";
import TextfieldUhda from "../TextfieldUhda/TextfieldUhda";
import RichTextEditor from "react-rte";
import {HIGHER_THAN, validationTypes} from "../../resources/ValidationConstants";
import FormSelectorUhda from "../FormSelectorUhda/FormSelectorUhda";
import {
  isQuestionTypeNumeric,
  isQuestionTypeRange,
  isQuestionTypeSingleOrMultipleChoice
} from "../../resources/QuestionOptions";
import {rangeToSelectArray, valueToSelectObject} from "../../utils/HelperFunctions"
import SingleMultipleAnswerUhda from "../SingleMultipleAnswerUhda/SingleMultipleAnswerUhda"
import FormSwitchUhda from "../FormSwitchUhda/FormSwitchUhda";

/**
 * @component
 * Component to create a builder for the questions
 * @param {string} createOrEdit: value to check if the modal will create or edit
 * @param {object} questionData: data of the question builder
 * @param {function} handleShow: handle to show or not the QuestionBuilder
 * @param {function} handleSubmitQuestion: handle to submit the question
 * @param {boolean} showModal: boolean to show or not the QuestionBuilder
 * @param {array} variableNames: array of the variable names
 *
 * @constructor
 * <QuestionBuilderUhda createOrEdit={'EDIT'} questionData = {showQuestionBuilder[1] >= 0 ? questions.find(q => q.id === showQuestionBuilder[1]) : undefined}
 * handleShow = {handleShowQuestionBuilder} handleSubmitQuestion = {handleSubmitQuestion} showModal = {showQuestionBuilder[0]}
 * variableNames = {formData}/>
 */
const QuestionBuilderUhda = ({
  createOrEdit,
  questionData,
  handleShow,
  handleSubmitQuestion,
  showModal = false,
  //variableNames = []
}) => {

  const [standardName, setStandardName] = useState((questionData && questionData.standard_name) || "");
  const [variableName, setVariableName] = useState((questionData && questionData.internal_code) || "");
  const [question, setQuestion] = useState((questionData && questionData.title) || "");
  const [notes, setNotes] = useState((questionData && questionData.subtitle) || "");
  const [info, setInfo] = useState((questionData && questionData.info && RichTextEditor.createValueFromString(questionData.info, "html")) || RichTextEditor.createEmptyValue());
  const [questionType, setQuestionType] = useState(valueToSelectObject((questionData && questionData.type) ? questionData.type : QUESTION_INFO, true));
  const questionRange = questionTypes[QUESTION_RANGE];
  const [fromRange, setFromRange] = useState((questionData && isQuestionTypeRange(questionType.value) && questionData.min_value) || questionRange.min_scale[0]);
  const [toRange, setToRange] = useState((questionData && isQuestionTypeRange(questionType.value) && questionData.max_value) || questionRange.max_scale[0]);
  const [answerOption, setAnswerOption] = useState("");
  const [answerOptionsArray, setAnswerOptionsArray] = useState((questionData && isQuestionTypeSingleOrMultipleChoice(questionType.value) && questionData.values) || []);
  const [fromRangeLabel, setFromRangeLabel] = useState((questionData && questionData.min_value_label) || "");
  const [toRangeLabel, setToRangeLabel] = useState((questionData && questionData.max_value_label) || "");
  const [validationType, setValidationType] = useState((questionData && questionData.validationType) || HIGHER_THAN);
  const [validation, setValidation] = useState((questionData && questionData.validation && String(questionData.validation)) || "");
  const [defaultAnswer, setDefaultAnswer] = useState((questionData && questionData.default_answer && valueToSelectObject(questionData.default_answer)) || "");
  const [mandatory, setMandatory] = useState(questionData ? !!questionData.mandatory : false);
  const [identifiable, setIdentifiable] = useState(questionData ? !!questionData.identifiable : false);
  // eslint-disable-next-line no-unused-vars
  const [validated, setValidated] = useState(false);

  const isValidQuestion = () => question.length > 0;
  const isValidNumberOfAnswers = () => answerOptionsArray.length >= 2;
  const isValidForm = () => isValidQuestion() && (!isQuestionTypeSingleOrMultipleChoice(questionType.value) || isValidNumberOfAnswers());
  // eslint-disable-next-line no-unused-vars
  const [visibilityVariableName, setVisibilityVariableName] = useState((questionData && questionData.visibility_formula && questionData.visibility_formula.internal_code) || "");
  // eslint-disable-next-line no-unused-vars
  const [visibilityCondition, setVisibilityCondition] = useState((questionData && questionData.visibility_formula && questionData.visibility_formula.condition) || HIGHER_THAN)
  // eslint-disable-next-line no-unused-vars
  const [visibilityValue, setVisibilityValue] = useState((questionData && questionData.visibility_formula && questionData.visibility_formula.value) || "")

  const handleClose = () => handleShow(false);
  const questionTypeValues = formTypes[createOrEdit];

  const handleSubmit = e => {
    e.preventDefault();
    setValidated(true);

    if (isValidForm()) {
      const questionObj = questionData ? JSON.parse(JSON.stringify(questionData)) : {};
      questionObj.standard_name = standardName;
      questionObj.internal_code = variableName;
      questionObj.type = questionType.value;
      questionObj.title = question;
      questionObj.subtitle = notes;
      questionObj.info = info.toString("html");
      questionObj.mandatory = mandatory ? 1 : 0;
      questionObj.identifiable = identifiable ? 1 : 0;
      questionObj.values = isQuestionTypeSingleOrMultipleChoice(questionType.value) ? answerOptionsArray : [];
      questionObj.validationType = isQuestionTypeNumeric(questionType.value) && validation ? validationType : null;
      questionObj.validation = isQuestionTypeNumeric(questionType.value) && validation ? parseInt(validation) : null;

      if (isQuestionTypeRange(questionType.value)) {
        questionObj.min_value = fromRange;
        questionObj.max_value = toRange;
        questionObj.step_value = 1;
        questionObj.min_value_label = fromRangeLabel;
        questionObj.max_value_label = toRangeLabel;
        questionObj.values = [...Array(toRange - fromRange + 1).keys()]
          .map(i => ({
            "field_id": questionData ? questionData.id : null,
            "value": i + fromRange,
            "text": String(i + fromRange)
          }))
        questionObj.default_answer = (defaultAnswer && defaultAnswer.label) || "";
      }
      if (isQuestionTypeSingleOrMultipleChoice(questionType.value))
        questionObj.default_answer = (defaultAnswer && defaultAnswer.label) || "";

      if (visibilityVariableName) {
        questionObj.visibility_formula = {
          internal_code: visibilityVariableName,
          condition: visibilityCondition,
          value: visibilityValue
        }
      }
      handleSubmitQuestion(questionObj);
    }
  }
  const handleOnChangeQuestionType = valueObj => {
    setQuestionType(valueObj)
    if (isQuestionTypeRange(valueObj.value) && defaultAnswer && typeof defaultAnswer.label === "string") {
      setDefaultAnswer("");
    }

    if (isQuestionTypeSingleOrMultipleChoice(valueObj.value) && defaultAnswer && typeof defaultAnswer.label === "number") {
      setDefaultAnswer("");
    }
  }
  const handleOnChangeFromRange = e => {
    const value = parseInt(e.target.value);
    setFromRange(value);

    if (defaultAnswer !== "" && value > defaultAnswer.value)
      setDefaultAnswer(valueToSelectObject(value));

  }

  const handleOnChangeToRange = e => {
    const value = parseInt(e.target.value);
    setToRange(value);

    if (defaultAnswer !== "" && value < defaultAnswer.value)
      setDefaultAnswer(valueToSelectObject(value));
  }

  const handleDeleteAnswer = answerId => {
    const newList = answerOptionsArray.filter(item => item.value !== answerId);
    setAnswerOptionsArray(newList);
  }

  const handleAddAnswer = paste => {
    const value = answerOptionsArray.length === 0 ? 0 : answerOptionsArray[answerOptionsArray.length - 1].value + 1;
    const newAnswer = {
      "field_id": questionData ? questionData.id : null,
      "value": value,
      "text": paste || answerOption
    }
    const newList = answerOptionsArray.concat(newAnswer);
    setAnswerOptionsArray(newList);
    setAnswerOption("");
  }

  const handleKeyDown = e => {
    if (["Enter", "Tab"].includes(e.key)) {
      e.preventDefault();

      if (answerOption)
        handleAddAnswer();
    }
  }

  const handleAddQuestionPressed = () => {
    if (answerOption)
      handleAddAnswer();
  }

  return (
    <Dialog
      disableBackdropClick
      fullWidth={ true }
      open={ showModal }
      onClose={ handleClose }
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle
        id="scroll-dialog-title">{`${questionTypeValues.title} question ${createOrEdit === formTypesEnum.CREATE ? "" : questionData.title}`}</DialogTitle>
      <DialogContent dividers={ true }>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormControl margin='normal' style={ {width: "45%"} }>
            <TextField
              value={ standardName }
              onChange={ (e) => {
                setStandardName(e.target.value)
              } }
              type="text"
              label="Standard name"
              variant='outlined'
            />
          </FormControl>
          <FormControl margin='normal' style={ {width: "45%"} }>
            <TextField
              value={ variableName }
              onChange={ (e) => {
                setVariableName(e.target.value)
              } }
              type="text"
              label="Variable name"
              variant='outlined'
            />
          </FormControl>
        </Grid>
        <FormControl margin='normal' style={ {width: "100%"} }>
          <TextField
            value={ question }
            onChange={ (e) => {
              setQuestion(e.target.value)
            } }
            type="text"
            label="Question"
            required
            variant='outlined'
          />
        </FormControl>
        <FormControl margin='normal' style={ {width: "100%"} }>
          <TextField
            value={ notes }
            onChange={ (e) => {
              setNotes(e.target.value)
            } }
            type="text"
            label="Notes"
            variant='outlined'
          />
        </FormControl>
        <FormControl margin='normal' style={ {width: "100%"} }>
          <TextfieldUhda
            value={ info }
            handleChange={ setInfo }
            label='Info'
          />
        </FormControl>
        <FormControl margin='normal' style={ {width: "100%"} }>
          <FormSelectorUhda
            value={ questionType }
            handleChange={ handleOnChangeQuestionType }
            label='Question type'
            options={ Object.values(questionTypes).filter(val => val.visible).map(val => val.selectObj) }
          />
        </FormControl>

        {isQuestionTypeRange(questionType.value) &&
                (
                  <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <FormControl margin='normal' style={ {width: "45%"} }>
                        <strong>From</strong>
                        <Select value={ fromRange } onChange={ handleOnChangeFromRange } variant='outlined'>
                          {
                            [...Array(questionRange.min_scale[1] - questionRange.min_scale[0] + 1).keys()]
                              .map(i => i + questionRange.min_scale[0])
                              .map(n =>
                                <MenuItem value={ n } key={ `minScale-${n}` }>{n}</MenuItem>
                              )
                          }
                        </Select>
                      </FormControl>
                      <FormControl margin='normal' style={ {width: "45%"} }>
                        <strong>To</strong>
                        <Select value={ toRange } onChange={ handleOnChangeToRange } variant='outlined'>
                          {
                            [...Array(questionRange.max_scale[1] - questionRange.max_scale[0] + 1).keys()]
                              .map(i => i + questionRange.max_scale[0])
                              .map(n =>
                                <MenuItem value={ n } key={ `minScale-${n}` }>{n}</MenuItem>
                              )
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControl margin='normal' style={ {width: "10%"} }>
                        <strong>{fromRange}</strong>
                      </FormControl>
                      <FormControl margin='normal' style={ {width: "90%"} }>
                        <TextField
                          value={ fromRangeLabel }
                          onChange={ (e) => setFromRangeLabel(e.target.value) }
                          type="text"
                          label="Add a label (not mandatory)"
                          variant='outlined'
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <FormControl margin='normal' style={ {width: "10%"} }>
                        <strong>{toRange}</strong>
                      </FormControl>
                      <FormControl margin='normal' style={ {width: "90%"} }>
                        <TextField
                          value={ toRangeLabel }
                          onChange={ (e) => setToRangeLabel(e.target.value) }
                          type="text"
                          label="Add a label (not mandatory)"
                          variant='outlined'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )
        }
        {isQuestionTypeSingleOrMultipleChoice(questionType.value) && (
          <SingleMultipleAnswerUhda
            handleDeleteAnswer={ handleDeleteAnswer }
            value={ answerOption }
            handleAddQuestionPressed={ handleAddQuestionPressed }
            handleChange={ setAnswerOption }
            answerOptionsArray={ answerOptionsArray }
            handleKeyDown={ handleKeyDown }
            label="Answer options"
          />
        )}
        {isQuestionTypeSingleOrMultipleChoice(questionType.value) && answerOptionsArray.length > 0 && (
          <FormControl margin='normal' style={ {width: "100%"} }>
            <FormSelectorUhda
              value={ defaultAnswer }
              handleChange={ setDefaultAnswer }
              label='Default Answer'
              options={ answerOptionsArray.map(opt => ({value: opt.value, label: opt.text})) }
            />
          </FormControl>
        )}
        {isQuestionTypeRange(questionType.value) && (
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <FormControl margin='normal' style={ {width: "100%"} }>
                            Default answer
            </FormControl>
            <FormControl margin='normal' style={ {width: "100%"} }>
              <FormSelectorUhda
                value={ defaultAnswer }
                handleChange={ setDefaultAnswer }
                label="Default answer"
                options={ rangeToSelectArray(fromRange, toRange) }
              />
            </FormControl>
          </Grid>
        )}
        {isQuestionTypeNumeric(questionType.value) && (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <FormControl margin='normal' style={ {width: "45%"} }>
              <Select value={ validationType } onChange={ e => setValidationType(parseInt(e.target.value)) }
                variant='outlined'>
                {Object.keys(validationTypes).map(validationId =>
                  <MenuItem value={ validationId }
                    key={ `formValidation-${validationId}` }>{validationTypes[validationId].label}</MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl margin='normal' style={ {width: "45%"} }>
              <TextField
                value={ validation }
                onChange={ (e) => {
                  setValidation(e.target.value)
                } }
                type="number"
                label="Number"
                variant='outlined'
              />
            </FormControl>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <FormSwitchUhda label='Mandatory' handleChange={ setMandatory } value={ mandatory }/>
          <FormSwitchUhda label='Identifiable' handleChange={ setIdentifiable } value={ identifiable }/>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={ handleClose } color="primary">
          {questionTypeValues.close}
        </Button>
        <Button onClick={ handleSubmit } color="primary">
          {questionTypeValues.submit}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

QuestionBuilderUhda.propTypes = {
  createOrEdit: PropTypes.string.isRequired,
  questionData: PropTypes.object,
  handleShow: PropTypes.func.isRequired,
  handleSubmitQuestion: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  variableNames: PropTypes.array
}

export default QuestionBuilderUhda
