/* eslint-disable no-use-before-define */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { Grid, Typography, } from "@material-ui/core";
import {
  CommonsTexts,
  LabelsDrawer,
  LabelsForm,
  LabelsStudy,
  LinksInfo,
  SnackBarResponses,
  TableTexts,
} from "../locale/en";
import { makeStyles } from "@material-ui/core/styles";
import { AppColors } from "../resources/AppColors";
import PropTypes from "prop-types";
import Icons from "../resources/Icons";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { textType, useTextStyles } from "../resources/AppTexts";
import IconProviderUhda from "../components/IconProviderUhda";
import { useHistory, useLocation } from "react-router-dom";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH, FORMS_STUDY, QUESTIONS, SECTIONS, SUMMARY } from "../resources/ApiUrls";
import SnackBarUhda from "../components/SnackBarUhda";
import FormModal from "../modal/FormModal";
import AddQuestionModal from "../components/QuestionModals/AddQuestionModal";
import DrawerUhda from "../components/DrawerUhda";
import DialogUhda from "../components/DialogUhda";
import QuestionsService from "../services/QuestionsService";
import EditQuestionModal from "../components/QuestionModals/EditQuestionModal";
import MoveQuestionModal from "../components/QuestionModals/MoveQuestionModal";
import ButtonUnfilledUhda from "../components/ButtonUnfilledUhda";
import TabUhda from "../components/TabsUhda/TabUhda";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY,
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },
  textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },
}));

function DeleteModal({
  setShowDeleteQuestion,
  showDeleteQuestion,
  selected,
  handleDeleteQuestion,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestion}
      handleConfirm={() => {
        handleDeleteQuestion();
      }}
      title={"Delete question"}
      body={
        <>
          <Typography style={{ color: AppColors.PRIMARY }} gutterBottom>
            {LabelsForm.DELETE_INFO_SUMMARY}
          </Typography>
          <a style={{ color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>
      }
      show={showDeleteQuestion}
    />
  );
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function DeleteSectionModal({
  setShowDeleteModalConfirm,
  showDeleteModalConfirm,
  selected,
  handleDeleteSection,
}) {

  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModalConfirm}
      handleConfirm={() => {
        handleDeleteSection();
      }}
      title={"Delete question"}
      body={
        <>
          <Typography variant="subtitle1" gutterBottom>
            {"Are you sure you want to delete this section?"}
          </Typography>
        </>
      }
      show={showDeleteModalConfirm}
    />
  );
}

DeleteSectionModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function DeleteQuestionsModal({
  setShowDeleteQuestions,
  showDeleteQuestions,
  selected,
  handleDeleteQuestions,
}) {
  return (
    <DialogUhda
      buttonColor={AppColors.RED}
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteQuestions}
      handleConfirm={() => {
        handleDeleteQuestions();
      }}
      title={"Delete question(s)"}
      body={
        <>
          <Typography style={{ color: AppColors.PRIMARY }} gutterBottom>
            {LabelsForm.DELETE_INFO_SUMMARY_BULK}
          </Typography>
          <a style={{ color: AppColors.ACCENT }}
            href={LinksInfo.SUMMARIES}
            target="_blank" rel="noreferrer">{LabelsForm.LEARN_MORE_SUMMARIES}</a>
        </>

      }
      show={showDeleteQuestions}
    />
  );
}

DeleteQuestionsModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

const SummaryPage = ({ drawer }) => {
  const location = useLocation();
  const classes = useStyles();
  const texts = useTextStyles();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [showValidationAlert, setShowValidationAlert] = useState(false);

  const [openSaved, setOpenSaved] = useState(false);
  const [openCopied, setOpenCopied] = useState(false);
  const [openArchived, setOpenArchived] = useState(false);
  const [openPublished, setOpenPublished] = useState(false);
  const [studies, setStudies] = useState(null);
  const [forms, setForms] = useState(null);

  const [privado, setPrivado] = useState();
  const [dateCreation, setDateCreation] = useState()
  const [visibilty, setVisibilty] = useState();
  const [study, setStudy] = useState(null);
  const [form, setForm] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [summary, setSummary] = useState([]);
  const [archived, setArchived] = useState(true);
  const [status, setStatus] = useState();
  const studyId = useRef();
  const formId = useRef();
  const permissions = useRef();
  const [showAddQuestion, setShowAddQuestion] = useState(-999);
  const [showAddQuestionMoreInfo, setShowAddQuestionMoreInfo] = useState(-999);
  const [showAddSection, setShowAddSection] = useState(-999);
  const [showMoveQuestion, setShowMoveQuestion] = useState(-999);
  const initialValues = {
    form: formId,
    order: "42a",
    variableName: "",
    questionType: "INFO",
    mandatory: false,
    conditional: false,
    summary: true,
    conditionalVariable: "",
    calculation: "",
    conditionalAnswer: "",
    questionTitle: "",
    helperText: "",
    minValue: "",
    maxValue: "",
    units: "",
    interval: "",
    hasConditional: false,
    answersList: [
      { answer: "", value: "" },
      //{ answer: "", value: "" },
    ],
    conditionalList: [
      { variable: "", answer: "" },
    ]
  };
  const [values, setValues] = useState(initialValues);
  const [hasNextModal, setHasNextModal] = useState(false);
  const [showDeleteQuestion, setShowDeleteQuestion] = useState(-999);
  const [showDeleteQuestions, setShowDeleteQuestions] = useState(false);
  const [showEditQuestion, setShowEditQuestion] = useState(-999);
  const [showEditQuestionMoreInfo, setShowEditQuestionMoreInfo] = useState(
    -999
  );
  const [editValues, setEditValues] = useState();
  const [conditionalListFinal, setConditionalListFinal] = useState();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [openSnackArchived, setOpenSnackArchived] = React.useState(false);
  const [
    openSnackPermissionsEdit,
    setOpenSnackPermissionsEdit,
  ] = React.useState(false);
  const [openSnackAdd, setOpenSnackAdd] = React.useState(false);
  const [openSnackEdit, setOpenSnackEdit] = React.useState(false);
  const [openSnackSelected, setOpenSnackSelected] = React.useState(false);
  const [openSnackNoQuestions, setOpenSnackNoQuestions] = React.useState(false);
  const [openSnackSectionDeleted, setOpenSnackSectionDeleted] = React.useState(false);
  const [openSnackSummaryError, setOpenSnackSummaryError] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [showSnackbarAdd, setShowSnackbarAdd] = useState(false);
  const [showSnackbarEdit, setShowSnackbarEdit] = useState(false);
  const [showSnackbarAddSection, setShowSnackbarAddSection] = useState(false);
  const [variableId, setVariableId] = useState();
  const [answerId, setAnswerId] = useState();
  const [sectionState, setSectionState] = useState();
  const [showSnackbarMove, setShowSnackbarMove] = useState(false);
  const [sections, setSections] = useState()
  const [stateSelect, setStateSelect] = useState(false)
  const [selectQuestions, setSelectQuestions] = useState([])
  const [showDeleteModalConfirm, setShowDeleteModalConfirm] = useState(-999)
  const [conditionalOperator, setConditionalOperator] = useState(null)
  const [conditionalListLength, setConditionalListLength] = useState()
  const [allLoading, setAllLoading] = useState(true);
  const [devicesSize, setDevicesSize] = useState("200px")
  const [cardWidth, setCardWidth] = useState("100%")

  // eslint-disable-next-line camelcase
  const camel_to_snake = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("_");

  const handleCloseAdd = () => {
    setShowSnackbarAdd(false);
  };
  //Function that close all the snackbars
  const handleClose = () => {
    setOpenCopied(false);
    setOpenSaved(false);
    setOpenArchived(false);
    setOpenPublished(false);
  };

  //Function to close the snackbar
  const handleClosePermissionsEdit = () => {
    setOpenSnackPermissionsEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  //Function to close the snackbar
  const handleClosePermissionsEditQuestion = () => {
    setOpenSnackEdit(false);
  };

  const handleClosePermissionsAdd = () => {
    setOpenSnackAdd(false);
  };

  const handleCloseEdit = () => {
    setShowSnackbarEdit(false);
  };

  const handleCloseSnackSelected = () => {
    setOpenSnackSelected(false);
  };

  const handleCloseSnackNoQuestions = () => {
    setOpenSnackNoQuestions(false);
  };

  const handleCloseSnackSectionDeleted = () => {
    setOpenSnackSectionDeleted(false);
  };
  const handleCloseSnackSummaryError = () => {
    setOpenSnackSummaryError(false);
  };

  const handleGoForm = () => {
    const id = study.id
    history.push({
      pathname: `/study/${studyId.current}/form/${formId.current}`,
      state: { detail: id, formId: formId.current, value: 0, permissions: permissions.current }

    })
  }

  const handleDeleteSelect = () => {
    if (study.permissions.includes("question-create")) {
      if (selectQuestions !== undefined && selectQuestions.length !== 0) {
        setShowDeleteQuestions(true)
      } else {
        setOpenSnackSelected(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }

  const handleDeleteSummary = async () => {
    try {
      if (summary.questions.length === 0) {
        const response = await QuestionsService.deleteSummary(studyId.current, formId.current, summary.id);
        setLoading(true);
        setOpenSnackSectionDeleted(true);
        history.push({
          pathname: `/study/${studyId.current}/form/${formId.current}`,
          state: { detail: studyId.current, formId: formId.current, value: 0, permissions: permissions.current }

        })

      } else {
        setOpenSnackSummaryError(true);
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteModalConfirm(-999);
  }

  const handleStateSelect = () => {
    if (study.permissions.includes("question-create")) {
      if (stateSelect === false && summary.questions.length === 0) {
        setOpenSnackNoQuestions(true)
      } else if (summary.questions !== undefined || summary.questions.length !== 0) {
        setStateSelect(!stateSelect);
      } else {
        setOpenSnackNoQuestions(true)
      }
    } else {
      setOpenSnackAdd(true);
    }
  }
  //function that gets the routes data
  const getRoute = () => {

    const n = JSON.parse(localStorage.getItem("summaryPage"));
    permissions.current = n.permissions;
    studyId.current = n.studyId;
    formId.current = n.formId;

  }

  useEffect(() => {
    getRoute()

  }, [])

  //Function that show the moreInfo add question
  const handleClickNext = () => {
    setShowAddQuestionMoreInfo(1);
  };

  const handleEditQuestion = async () => {
    setShowEditQuestion(-999);
    setShowEditQuestionMoreInfo(-999);
  };

  const handleClickNextEdit = (id) => {
    setShowEditQuestionMoreInfo(id);
  };

  const handleCloseAddSection = () => {
    setShowSnackbarAddSection(false);
  };

  const handleCloseMoveQuestion = () => {
    setShowSnackbarMove(false);
  };

  //Function to change the conditional of the value
  // eslint-disable-next-line no-unused-vars
  const changeConditional = () => {
    setValues({ ...values, ["conditional"]: !values["conditional"] });
    if (editValues["conditional"] === "0") {
      setEditValues({ ...editValues, ["conditional"]: true });
    } else if (editValues["conditional"] === "1") {
      setEditValues({ ...editValues, ["conditional"]: false });
    } else {
      setEditValues({
        ...editValues,
        ["conditional"]: !editValues["conditional"],
      });
    }
  };

  //Function to change the mandatory of the value
  const changeMandatory = () => {
    setValues({ ...values, ["mandatory"]: !values["mandatory"] });
    if (editValues && editValues["mandatory"] === 0) {
      setEditValues({ ...editValues, ["mandatory"]: 1 });
    } else if (editValues && editValues["mandatory"] === 1) {
      setEditValues({ ...editValues, ["mandatory"]: 0 });
    } else if (editValues && editValues["mandatory"] === undefined) {
      setEditValues({ ...editValues, ["mandatory"]: !editValues["mandatory"] });
    }
  };

  //Function to get the study data from the id of this
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(
        `${BASE_PATH}/study/${studyId.current}`
      );
      setStudy(response.data.data);
    } catch (err) {
      setStudy(err.message);
    }
  };

  //Function to get the sections from the id of the form and study
  const getStudies = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      setStudies(response.data.data);
      setLoading(false)

    } catch (e) {
      setLoading(false);
    }
  };

  //Function to get the sections from the id of the form and study
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(FORMS_STUDY(studyId.current));
      setForms(response.data.data.items);
      setLoading(false)
    } catch (e) {
      setLoading(false);
    }
  };

  //Function to get the form data from the of the study and the form
  const getForm = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId.current}/form/${formId.current}`);
      setForm(response.data.data.item)
      setTitle(response.data.data.item.form_translations[0].title)
      setDescription(response.data.data.item.form_translations[0].description)
      setPrivado(response.data.data.item.visibility)
      setStatus(response.data.data.item.form_status)
      setDateCreation(response.data.data.item.date_creation)
      setAllLoading(false)
    } catch (err) {
      setForm(err.message);
    }
  }
  //Function to get the questions from the id of the form and study
  const getQuestions = async () => {
    setShowAddQuestion(-999);
    setShowAddQuestionMoreInfo(-999);
    setValues(initialValues);
    try {
      const response = await MyAxiosInstance.get(QUESTIONS(formId.current, studyId.current));
      setQuestions(response.data.data.item);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuestions(e.message);
    }
  };

  //Function to get the sections from the id of the form and study
  const getSections = async () => {
    setShowAddSection(-999);
    setValues(initialValues);
    try {
      const response = await MyAxiosInstance.get(SECTIONS(formId.current));
      setSections(response.data.data.item);
      if (sectionState === undefined)
        setSectionState(response.data.data.item[0].id)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setQuestions(e.message);
    }
  };

  //Function to get the sections from the id of the form and study
  const getSummary = async () => {
    try {
      const response = await MyAxiosInstance.get(SUMMARY(formId.current));
      setSummary(response.data.data.item);
      setLoading(false)
    } catch (e) {
      setSummary([])
      setLoading(false);
    }
  };

  const handleDeleteQuestion = async () => {
    try {
      setLoading(true);
      const response = await QuestionsService.deleteQuestionSummary(summary.id,
        showDeleteQuestion
      );
      getSummary();
      setOpenDelete(true);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteQuestion(-999);
  };

  const handleDeleteQuestions = async () => {
    try {
      setLoading(true);
      for (let i = 0; i < selectQuestions.length; i++) {
        const response = QuestionsService.deleteQuestionSummary(summary.id,
          selectQuestions[i].id
        );
      }
      getSummary();
      setOpenDelete(true);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteQuestions(false);
  };

  useEffect(() => {
    if (!formId.current) {
      formId.current = null;
      // eslint-disable-next-line no-shadow
      const form = new FormModal("", "", false);
    } else {
      getForm();
      getStudy();
      getQuestions();
      getSummary()
      getSections()

    }
  }, []);

  const [open, setOpen] = useState(drawer); //false

  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);

  useEffect(() => {
    //getStudies()
    getForms()
  }, [stateSelect]);

  /* useEffect(() => {
    getStudy()
    getSummary()
  }, []) */

  useEffect(() => {
    getStudies()
  }, [showMoveQuestion])

  //Function to open the modal of the questions
  const handleOpenModal = () => {
    if (study.permissions.includes("question-create")) {
      setShowAddQuestion(1);
    } else {
      setOpenSnackAdd(true);
    }
  };

  const handleClickEdit = (id) => {
    var conditionalArray = "";
    var conditionalList = [];
    //TODO Meter aqui todas las questions del form

    // eslint-disable-next-line no-shadow
    const questions = summary.questions
    const allQuestions = form.questions
    if (study.permissions.includes("question-edit")) {
      a = questions.find(x => x.id === id)
      setEditValues({
        ...a,
        ["hasConditional"]:
          false,

      });
      var editQuestion = questions.find(x => x.id === id)

      if (editQuestion.conditional) {
        setEditValues({
          ...a,
          ["hasConditional"]:
            true,

        });

        if (editQuestion.conditional.includes("AND")) {
          setConditionalOperator(1)
          var a = []
          conditionalArray = editQuestion.conditional.split("AND");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }

          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < allQuestions.length; i++) {
              if (allQuestions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = allQuestions[i].id
                for (let x = 0; x < allQuestions[i].answer_values.length; x++) {
                  if (allQuestions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = allQuestions[i].answer_values[x].id
                  }
                }
              }
            }
          }

        } else if (editQuestion.conditional.includes("OR")) {
          setConditionalOperator(0)
          var a = []
          conditionalArray = editQuestion.conditional.split("OR");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }
          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = questions[i].id
                for (let x = 0; x < questions[i].answer_values.length; x++) {
                  if (questions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = questions[i].answer_values[x].id
                  }
                }
              }
            }
          }
        } else {
          conditionalArray = editQuestion.conditional.split("OR");
          for (let i = 0; i < conditionalArray.length; i++) {
            a = conditionalArray[i].split("=")
            conditionalList[i] = { "variable": a[0], "answer": a[1] }
          }
          for (let j = 0; j < conditionalList.length; j++) {
            for (let i = 0; i < questions.length; i++) {
              if (questions[i].variable_name === conditionalList[j].variable) {
                conditionalList[j]["variableId"] = questions[i].id
                for (let x = 0; x < questions[i].answer_values.length; x++) {
                  if (questions[i].answer_values[x].value === conditionalList[j].answer) {
                    conditionalList[j]["answerId"] = questions[i].answer_values[x].id
                  }
                }
              }
            }
          }
        }

        setConditionalListLength(conditionalList.length)
        setConditionalListFinal(conditionalList)
        setShowEditQuestion(id)
      } else {
        setShowEditQuestion(id)
      }
    } else {
      setOpenSnackEdit(true)
    }
  }

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      // eslint-disable-next-line no-shadow
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (open) {
        if (window.innerWidth >= 1000) {
          setDevicesSize("200px")
          setCardWidth("100%")
        } else {
          setDevicesSize("10px")
          setCardWidth("100%")
        }

      } else {

        if (window.innerWidth >= 640 && window.innerWidth <= 2560) {
          setDevicesSize("280px")
          setCardWidth("100%")
        } else if (window.innerWidth <= 600) {

          setDevicesSize("160px")
          setCardWidth("70%")

        } else {
          //alert(window.innerWidth)
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  return (
    <>
      {study &&
        <DrawerUhda id={studyId.current} drawer={open} select={1} settings={study.permissions} />}

      {allLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>) : <Grid
          container
          direction={"column"}

          spacing={2}
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
        <Grid item>
          {study && form && summary.length !== 0 && (
            <div style={{ marginLeft: devicesSize }}>
              <BreadcrumbsUhda
                routes={[
                  { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                  {
                    id: 1,
                    label: `${study.translations[0].study_title}`,
                    disabled: true,
                    studyId: studyId.current,
                    permissions: permissions.current
                  },
                  {
                    id: 2,
                    label: LabelsStudy.FORMS,
                    url: `/study/${studyId.current}/forms`,
                    studyId: studyId.current,
                    permissions: permissions.current
                  },
                  {
                    id: 3,
                    label: `${form.form_translations[0].title}`,
                    url: `/study/${studyId.current}/form/${formId.current}`,
                    studyId: studyId.current,
                    permissions: permissions.current
                  },
                  {
                    id: 4,
                    label: `${summary.summary_translations[0].title}`,
                  }
                ]}
              /></div>
          )}
        </Grid>

        {study && <Grid container style={{ paddingTop: "1em", paddingBottom: "4em" }} direction={"row"}>

          <Grid container style={{ marginBottom: "2em", marginLeft: devicesSize }} direction={"row"}>
            <ButtonUnfilledUhda backgroundColor={AppColors.BACKGROUND} onClick={() => handleGoForm()}>
              <Grid item>
                <IconProviderUhda icon={<Icons.ARROW_LEFT style={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  color: AppColors.ACCENT,
                  fontSize: "2em"
                }} size="100px" />} />
              </Grid>
              <Grid item>
                <Typography
                  style={{
                    fontSize: "20px",
                    color: AppColors.ACCENT,
                  }}>{"Back to the form"}</Typography>
              </Grid>
            </ButtonUnfilledUhda>
          </Grid>
          <Grid

            container
            item
            style={{ paddingBottom: "1em", marginLeft: devicesSize,maxWidth: "70%" }}
            direction={"row"}
            spacing={5}

          >
            {!stateSelect && <Grid item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
            >

              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
                  onClick={handleOpenModal}
                  icon={<IconProviderUhda
                    icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
                  text={CommonsTexts.NEW_QUESTION} />
                )}

              {!formId.current &&
                (<ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
                  isDisabled={true}
                  onClick={handleOpenModal} backGroundColor={AppColors.BACKGROUND}
                  color={AppColors.SUBTEXT}
                  border={"2px solid"}
                  icon={<IconProviderUhda
                    icon={<Icons.ADD size={"1.5em"} color={AppColors.SUBTEXT} />} />}
                  text={CommonsTexts.NEW_QUESTION} />)}
            </Grid>}

            {!stateSelect && <Grid item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}

            >
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleStateSelect}
                  backGroundColor={AppColors.SUBTEXT}

                  text={CommonsTexts.SELECT_QUESTIONS} />
                )}
            </Grid>}

            {stateSelect && <Grid item xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}>
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.RED}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleDeleteSelect}
                  icon={<IconProviderUhda
                    icon={<Icons.DELETE size={"1.5em"}
                      style={{ color: AppColors.WHITE }} />} />}
                  text={CommonsTexts.DELETE} />
                )}
            </Grid>}

            {stateSelect && <Grid item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
            >
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.SUBTEXT}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleStateSelect}

                  text={CommonsTexts.CANCEL} />
                )}
            </Grid>}

            {stateSelect && <Grid item xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3} container justifyContent="flex-start">
              {formId.current && study.permissions.includes("question-create") &&
                (<ButtonFilledUhda margin={"10px"}
                  backGroundColor={AppColors.RED}
                  type={textType.SUBTITLE_BOLD}
                  onClick={handleDeleteSummary}
                  text={LabelsForm.DELETE_SUMMARY} />
                )}
            </Grid>}
            {!stateSelect &&
              <Grid item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                xl={3}
                style={{  }} container >
                {formId.current && study.permissions.includes("question-create") &&
                  (<ButtonFilledUhda margin={"10px"}
                    backGroundColor={AppColors.RED}
                    type={textType.SUBTITLE_BOLD}
                    onClick={handleDeleteSummary}
                    text={LabelsForm.DELETE_SUMMARY} />
                  )}
              </Grid>}

          </Grid>
          <SnackBarUhda
            handleClose={handleClosePermissionsAdd}
            severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackAdd}
          />

          {(summary.length !== 0 && !loading) && <div style={{ marginLeft: devicesSize, }}><TabUhda
            getSections={getSections}
            formId={formId.current}
            studyId={studyId.current}
            summary={summary}
            handleClickEdit={handleClickEdit}
            getQuestions={getQuestions}
            getSummary={getSummary}
            loading={loading}
            setLoading={setLoading}
            setShowDeleteQuestion={setShowDeleteQuestion}
            showEditQuestion={showEditQuestion}
            setShowEditQuestion={setShowEditQuestion}
            canEdit={study.permissions.includes("question-edit")}
            canDelete={study.permissions.includes("question-delete")}
            sectionState={sectionState}
            setSectionState={setSectionState}
            stateSelect={stateSelect}
            setSelectQuestions={setSelectQuestions}
            setShowDeleteModalConfirm={setShowDeleteModalConfirm}
          /></div>
          }
        </Grid>}
      </Grid>}
      {showAddQuestion >= 0 && (
        <AddQuestionModal
          formTitle={form.form_translations[0].title}
          questionsLength={sections.find(x => x.id === sectionState).questions.length}
          questionsSummaryLength={summary.questions.length}
          hasNextModal={hasNextModal}
          setHasNextModal={setHasNextModal}
          formId={formId.current}
          studyId={studyId.current}
          sections={sections}
          sectionState={sectionState}
          handleClickNext={handleClickNext}
          initialValues={initialValues}
          values={values}
          setValues={setValues}
          showAddQuestion={showAddQuestion}
          setShowAddQuestion={setShowAddQuestion}
          // changeConditional={changeConditional}
          changeMandatory={changeMandatory}
          setLoading={setLoading}
          getQuestions={getQuestions}
          questions={questions}
          setShowSnackbarAdd={setShowSnackbarAdd}
          getSections={getSections}
          getSummary={getSummary}
          isSummary={true}
        />
      )}

      {showEditQuestion >= 0 && (
        <EditQuestionModal
          formTitle={form.form_translations[0].title}
          questionsLength={questions.length}
          hasNextModal={hasNextModal}
          setHasNextModal={setHasNextModal}
          formId={formId.current}
          studyId={studyId.current}
          sections={sections}
          sectionState={sectionState}
          handleClickNext={handleClickNextEdit}
          editValues={editValues}
          setEditValues={setEditValues}
          showEditQuestion={showEditQuestion}
          setShowEditQuestion={setShowEditQuestion}
          // changeConditional={changeConditional}
          changeMandatory={changeMandatory}
          setLoading={setLoading}
          getQuestions={getQuestions}
          getSections={getSections}
          getSummary={getSummary}
          questions={questions}
          setShowSnackbarEdit={setShowSnackbarEdit}
          handleEditQuestion={handleEditQuestion}
          variableId={variableId}
          answerId={answerId}
          conditionalListFinal={conditionalListFinal}
          conditionalOperatorFinal={conditionalOperator}
          isSummary={true}
          conditionalListLength={conditionalListLength}
        />
      )}

      {showMoveQuestion >= 0 && (
        <MoveQuestionModal
          questionsLength={questions.length}
          formId={formId.current}
          studyId={studyId.current}
          studies={studies}
          forms={forms}
          sections={sections}
          sectionState={sectionState}
          showMoveQuestion={showMoveQuestion}
          setShowMoveQuestion={setShowMoveQuestion}
          // changeConditional={changeConditional}
          setLoading={setLoading}
          getQuestions={getQuestions}
          questions={questions}
          setShowSnackbarMove={setShowSnackbarMove}
          getSections={getSections}
          selectedQuestions={selectQuestions}
        />
      )}

      {showDeleteQuestion >= 0 && (
        <DeleteModal
          setShowDeleteQuestion={setShowDeleteQuestion}
          showDeleteQuestion={showDeleteQuestion}
          handleDeleteQuestion={handleDeleteQuestion}
        />
      )}

      {showDeleteQuestions === true && (
        <DeleteQuestionsModal
          setShowDeleteQuestions={setShowDeleteQuestions}
          showDeleteQuestions={showDeleteQuestions}
          selected={selectQuestions}
          handleDeleteQuestions={handleDeleteQuestions}
        />
      )}

      <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.QUESTION_DELETED}
        openSnack={openDelete} />
      <SnackBarUhda handleClose={handleCloseAdd} message={SnackBarResponses.QUESTION_SAVED}
        openSnack={showSnackbarAdd} />
      <SnackBarUhda handleClose={handleCloseEdit} message={SnackBarResponses.QUESTION_SAVED}
        openSnack={showSnackbarEdit} />
      <SnackBarUhda handleClose={handleCloseAddSection} message={SnackBarResponses.SECTION_SAVED}
        openSnack={showSnackbarAddSection} />
      <SnackBarUhda handleClose={handleCloseMoveQuestion} message={SnackBarResponses.QUESTION_MOVED}
        openSnack={showSnackbarMove} />
      <SnackBarUhda handleClose={handleCloseSnackSelected} message={SnackBarResponses.SELECTED_QUESTION}
        openSnack={openSnackSelected} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackSelected} message={SnackBarResponses.SELECTED_QUESTION}
        openSnack={openSnackSelected} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackNoQuestions} message={SnackBarResponses.NO_QUESTIONS}
        openSnack={openSnackNoQuestions} severity={"warning"} />
      <SnackBarUhda handleClose={handleCloseSnackSectionDeleted} message={SnackBarResponses.SECTION_DELETED}
        openSnack={openSnackSectionDeleted} />
      <SnackBarUhda handleClose={handleCloseSnackSummaryError} severity={"error"}
        message={SnackBarResponses.SUMMARY_ERROR}
        openSnack={openSnackSummaryError} />

    </>
  );
};

export default SummaryPage;
