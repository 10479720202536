import React from "react"
import FormBuilderUhda from "../components/FormBuilderUhda/FormBuilderUhda"

const CreateFormPage = () => {

  return (
    <>
      <FormBuilderUhda/>
    </>
  )
}

export default CreateFormPage;
