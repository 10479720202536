import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CardActions, CardContent, CardHeader, Fade, Grid, Link, Menu, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import Icons from "../../resources/Icons";
import CardUhda from "../CardUhda";
import { useHistory } from "react-router-dom";
import { AppTextsFontSize, AppTextsFontWeight, textType, useTextStyles } from "../../resources/AppTexts";
import { LabelsStudy, menuOptions } from "../../locale/en";
import TextUhda from "../TextUdha";
import IconProviderUhda from "../IconProviderUhda";
import "../../styles/studiesCard.scss";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
  root: {
    minWidth: 275,

  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  text: {
    fontSize: AppTextsFontSize.SIZE_BODY,
    fontWeight: AppTextsFontWeight.WEIGHT_LIGHT
  },
  pos: {
    marginBottom: 12,

  },
  link: {
    overflowX: "auto",
    "&:hover": {
      cursor: "pointer",
      textDecoration: `underline ${AppColors.WHITE}`
    }
  }, cardHeaderRoot: {
    overflow: "hidden"

  },
  cardHeaderContent: {
    overflow: "hidden"

  }

});

/**
 * @component
 * Component to create the card of the study
 *
 * @param {number} studyId: id of the study
 * @param {string} studyTitle: title of the study
 * @param {string} studyDescription: description of the study
 * @param {number} numberOfParticipants: num participants
 * @param {number} numberOfResponses: num of responses
 * @param {boolean} privacity: value to know the privacity of one study
 * @param {function} handleOnEdit: handle to history push to a edit page of the study
 * @param {function} handleOnActivate:
 * @param {function} handleOnInactivate:
 * @param {function} handleOnDuplicate: handle to open the modal to duplicate the study
 * @param {function} handleOnExport
 * @param {function} handleOnDownload
 * @param {function} handleOnDelete: handle to open the modal to delete the study
 *
 * @constructor
 * <StudyCardUhda studyId={'12'} studyTitle={'TITLE'} studyDescription={'DESCRIPTION'} numberOfParticipants={2}
 * numberOfResponses={1} privacity={True} handleOnEdit={handleOnEdit} handleOnDuplicate={handleOnDuplicate}
 * handleOnDelete={handleOnDelete} />
 *
 */
const StudyCardUhda = ({
  studyId,
  studyTitle,
  studyDescription,
  handleOnEdit,
  handleOnDuplicate,
  handleOnDelete,
  permissions
}) => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const history = useHistory()
  const [permisos, setPermissions] = useState()
  // eslint-disable-next-line no-unused-vars
  const [drawerLink, setDrawerLink] = useState()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const newObj = { "id": studyId, "permissions": permisos }
    localStorage.setItem("dashBoardSection", JSON.stringify(newObj));

    const newObj2 = { "detail": studyId, "permissions": permisos }
    localStorage.setItem("sectionDetails", JSON.stringify(newObj2));

  }, [])

  //Function to close the snackbar
  const knowPermissions = () => {

    const label = [];
    const link = [];
    if (permissions.includes("access-data")) {

      label.push(LabelsStudy.DASHBOARD)
      link.push(`/study/${studyId}/dashboard`)
    }
    if (permissions.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      link.push(`/study/${studyId}/forms`)
    }
    if (permissions.includes("participant-create") || permissions.includes("participant-edit") || permissions.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
      link.push(`/study/${studyId}/participants`)
    }
    /* if (permissions.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
      link.push(`/study/${studyId}/dissemination`)
    } */
    if (permissions.includes("teammember-create") || permissions.includes("teammember-edit") || permissions.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      link.push(`/study/${studyId}/teamMembers`)
    }
    if (permissions.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      link.push(`/study/${studyId}/settings`)
    }
    setPermissions(label)
    setDrawerLink(link)
  };

  const onClickHandler = () => {

    localStorage.setItem("route", "edit");

    const newObj = { "id": studyId, "permissions": permisos }
    const newObj2 = { "detail": studyId, "permissions": permisos }
    localStorage.setItem("dashBoardSection", JSON.stringify(newObj));
    localStorage.setItem("sectionDetails", JSON.stringify(newObj2));

    if (permissions.includes("access-data")) {
      history.push({
        pathname: `/study/${studyId}/dashboard`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("forms-view")) {
      history.push({
        pathname: `/study/${studyId}/forms`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("participant-create") || permissions.includes("participant-edit") || permissions.includes("participant-delete")) {
      history.push({
        pathname: `/study/${studyId}/participants`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("access-dissemination")) {
      history.push({
        pathname: `/study/${studyId}/dissemination`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("teammember-create") || permissions.includes("teammember-edit") || permissions.includes("teammember-delete")) {
      history.push({
        pathname: `/study/${studyId}/teamMembers`,
        state: { detail: studyId, permissions: permisos }
      })
    } else if (permissions.includes("study-edit-settings")) {
      history.push({
        pathname: `/study/${studyId}/settings`,
        state: { detail: studyId, permissions: permisos }
      })
    }
  }
  useEffect(() => {
    knowPermissions()
  }, [])

  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  const texts = useTextStyles();

  /*
      const truncate = (str, n) => {
          return str.length > n ? str.substring(0, n - 3) + "..." : str;
      };

      const newTitle = truncate(studyTitle, 50);
      const newDescription = truncate(studyDescription, 200);
  */

  const body = (
    <Grid
      container
      item
      xs
      direction="column"
      justifyContent="space-between"
    >
      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs zeroMinWidth>
          <Link className={classes.link}>
            <CardHeader
              classes={{
                title: texts.title_bold,
                root: classes.cardHeaderRoot,
                content: classes.cardHeaderContent
              }}
              style={{
                height: "64px",
                width: "232px",
                color: AppColors.PRIMARY,
                paddingLeft: "24px",
                paddingTop: "24px",
                paddingBottom: "8px"
              }}
              onClick={onClickHandler}
              title={
                <TextUhda margin={"0px"} style={"break-word"} type={textType.TITLE_BOLD}
                  text={studyTitle} />}
            />

          </Link>
        </Grid>
      </Grid>

      <Grid container wrap="nowrap" spacing={2}>
        <Grid item xs zeroMinWidth >
          <CardContent style={{
            height: "110px", width: "280px",
            paddingTop: "6px", paddingBottom: 0, paddingLeft: "24px",
          }}>
            <Grid

              style={{ paddingRight: "30px", paddingLeft: window.innerWidth > 1800 ? "3px" : "30px", textAlign: "center" }}><TextUhda margin={"0px"} style={"break-word"} type={textType.BODY_CARD}
                text={studyDescription} /></Grid>
          </CardContent>
        </Grid>
      </Grid>
      <CardActions>
        <Grid
          item
          zeroMinWidth
          xs
          direction="column"
          justifyContent="flex-start"
          alignItems="center">
          <Grid
            style={{ padding: "10px", paddingTop: 0 }}
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid
              item
              xs={2}>
              {/*<h4 className={texts.body} style={{ padding: 0, margin: 0 }}>
              <IconProviderUhda icon={<Icons.PARTICIPANTS
                style={{ verticalAlign: "middle", display: "inline-flex", paddingRight: '4px' }}
                size="1rem" />} />
              {numberOfParticipants}
            </h4>*/}
            </Grid>
            <Grid
              item
              style={{ flexGrow: "1" }}>
              {/*<h4 className={texts.body} style={{ padding: 0, margin: 0 }}>
              <IconProviderUhda icon={<Icons.RESPONSES
                style={{ verticalAlign: "middle", display: "inline-flex", paddingRight: '4px' }}
                size="1rem" />} />

              {numberOfResponses}
            </h4>*/}
            </Grid>
            <Grid
              item>
              <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL color={AppColors.PRIMARY} style={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  paddingRight: "4px"
                }} size="2rem" />} />
              </Button>
              <Menu
                style={{
                  boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
                }}
                color={AppColors.WHITE}
                id="fade-menu"
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                {permissions.includes("study-edit-settings") &&
                  <MenuItem style={{ color: AppColors.PRIMARY }}
                    onClick={() => {
                      handleOnEdit(studyId);
                      handleClose()
                    }}> {menuOptions.EDIT} </MenuItem>}
                {permissions.includes("study-duplicate") && <MenuItem style={{ color: AppColors.PRIMARY }}
                  onClick={() => {
                    handleOnDuplicate(studyId);
                    handleClose()
                  }}> {menuOptions.DUPLICATE} </MenuItem>}
                {permissions.includes("study-delete") && <MenuItem style={{ color: AppColors.PRIMARY }}
                  onClick={() => {
                    handleOnDelete(studyId);
                    handleClose()
                  }}> {menuOptions.DELETE}</MenuItem>}
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </CardActions>
    </Grid>
  )
    ;

  return <div className="uhda-card"><CardUhda> {body} </CardUhda></div>

}

StudyCardUhda.propTypes = {

  studyId: PropTypes.number,
  studyTitle: PropTypes.string,
  numberOfResponses: PropTypes.number,
  handleOnEdit: PropTypes.func,
  handleOnActivate: PropTypes.func,
  handleOnInactivate: PropTypes.func,
  handleOnDuplicate: PropTypes.func,
  handleOnExport: PropTypes.func,
  handleOnDownload: PropTypes.func,
  handleOnDelete: PropTypes.func,
}

export default StudyCardUhda;
