import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, TextField, Typography } from "@material-ui/core";
import { AppColors } from "../resources/AppColors";
import AuthContext from "../context/authentication/authContext";
import { makeStyles } from "@material-ui/core/styles";
import NavbarUhda from "../components/NavbarUhda";
import CardUhda from "../components/CardUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { CommonsTexts, SnackBarResponses } from "../locale/en";
import IconProviderUhda from "../components/IconProviderUhda";
import Icons from "../resources/Icons";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import { useParams } from "react-router-dom";
import SnackBarUhda from "../components/SnackBarUhda";
import taglineIcon from "../img/UhDa_Studies.png"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND,
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    },
    "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,

    },
    "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },

  textFieldLabelWithText: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,

  },

  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon: {
    height: "50px",
  },
}));

/**
 * @Page
 * Page to change the password of the user when is not logged
 */
export default function ForgotPassSecondPage(props) {
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const authContext = useContext(AuthContext);
  const { message, authenticated, login, checkAuth } = authContext;

  const [alertMessage, setAlertMessage] = useState("");
  const { id } = useParams();

  const [openAlert, setOpenAlert] = useState(false);
  const [isTypingOldP, setIsTypingOldP] = useState(false)
  const [isTypingNewP, setIsTypingNewP] = useState(false)
  const [isTypingRepeatP, setIsTypingRepeatP] = useState(false)

  useEffect(() => {
    checkAuth();
    if (authenticated) {
      props.history.push("/home");
    }
    if (message) {
      setAlertMessage(message);
      setOpenAlert(true);
    }
  }, [message, authenticated, props.history]);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //Function called when the user submit the change password
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oldPassword === "" || password === "" || rePassword === "") {
      setAlertMessage(SnackBarResponses.EMPTY_FIELDS);
      setOpenAlert(true);
    } else if (password !== rePassword) {
      setAlertMessage(SnackBarResponses.PASWORD_NOT_MATCH);
      setOpenAlert(true);
    } else {
      const params = id.split("&email=");
      // eslint-disable-next-line no-unused-vars
      const body = {
        email: params[1],
        token: params[0],
        password: password,
        // eslint-disable-next-line camelcase
        password_confirmation: rePassword,
      };

      setOpenAlert(false);
      login(params);
    }
  };

  const classes = useStyles();

  const oldPasswordChange = (e) => {
    setOldPassword(e.target.value)
    setIsTypingOldP(true)
  }

  const newPasswordChanged = (e) => {
    setPassword(e.target.value)
    setIsTypingNewP(true)

  }

  const repeatPasswordChanged = (e) => {
    setRePassword(e.target.value)
    setIsTypingRepeatP(true)

  }

  return (
    <Grid
      className={classes.background}
      container
      style={{ minHeight: "100vh" }}
      alignItems={"center"}
      direction={"column"}
    >
      <Grid item>
        <NavbarUhda
          open={false}
          setOpen={false}
          setSelected={null}
          logged={false}
        />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda
          bg={AppColors.WHITE}
          children={
            <Grid
              container
              item
              alignItems="center"
              direction="column"
              justifyContent="center"
            >
              <div
                style={{
                  margin: "5vh",
                  display: "flex",
                  flexDirection: "column",
                  minWidth: 300,
                }}
              >
                <div style={{ textAlign: "center" }}>
                  <IconProviderUhda
                    icon={<Icons.VERIFIED style={{ fontSize: 50 }} />}
                  />
                  <TextUhda
                    type={textType.TITLE}
                    text={CommonsTexts.WELCOME.toUpperCase() + "!"}
                  />
                  <TextUhda
                    type={textType.SUBTITLE}
                    text={CommonsTexts.PLEASE_CHANGE_PASS}
                  />
                </div>

                <TextField
                  onChange={(e) => oldPasswordChange(e)}
                  type="password"
                  label="Old Password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingOldP ? classes.textFieldLabelWithText : classes.textFieldLabel}

                />
                <TextField
                  onChange={(e) => newPasswordChanged(e)}
                  type="password"
                  label="New Password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingNewP ? classes.textFieldLabelWithText : classes.textFieldLabel}
                />
                <TextField
                  onChange={(e) => repeatPasswordChanged(e)}
                  type="password"
                  label="Repeat your new password"
                  margin="normal"
                  variant="outlined"
                  className={isTypingRepeatP ? classes.textFieldLabelWithText : classes.textFieldLabel}
                />
                {openAlert &&
                  (alertMessage !== "" ? (
                    <SnackBarUhda
                      severity={"error"}
                      handleClose={handleCloseAlert}
                      message={alertMessage}
                      openSnack={openAlert}
                    />
                  ) : (
                    <SnackBarUhda
                      severity={"error"}
                      handleClose={handleCloseAlert}
                      message={SnackBarResponses.COMMON_ERROR}
                      openSnack={openAlert}
                    />
                  ))}

                <Grid
                  container
                  style={{ paddingTop: "20px" }}
                  spacing-xs-1
                  wrap="nowrap"
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid container item alignItems="center" justify="center">
                    <ButtonFilledUhda
                      text={CommonsTexts.CHANGE_PASS}
                      onClick={handleSubmit}
                      margin={"0.7em"}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          }
        />
      </Grid>
      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          paddingBottom: "1em",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box style={{ marginBottom: 0 }} display="flex" justifyContent="center" m={3}>
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE, }}
              href="https://right-chocolate-d0c.notion.site/About-0f941e2e9b604e9a8182d16a4f8ab48c"
              target="_blank" rel="noreferrer">{CommonsTexts.ABOUT}</a>

          </Box>
          <Box style={{ margin: 0 }} direction="row" display="flex" justifyContent="center" m={3}
            className="bottom-bar"
          >
            <Typography style={{ marginTop: "0.5em" }} >Powered by </Typography>
            <img alt='icon' className={classes.icon} src={taglineIcon} />
          </Box>
          {/*  <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/UhD-Studies-User-Guide-b0d41862529a4d8196d56370465c7250'
              target="_blank" rel="noreferrer">{CommonsTexts.USER_GUIDE}</a>
          </Box> */}
          {/* <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/Terms-of-Use-f5a590057a7f46deaa854af7cc336dc2'
              target="_blank" rel="noreferrer">{CommonsTexts.TERMS}</a>
          </Box> */}
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vSz4FmaCBAcvcuZ1Kw9eB7nmCgSS0lwaY5C3rMN9vN3Xd7Mhva9U8ffdGx-e1vIqgCn0P7KtlyT27b_/pub'
              target="_blank" rel="noreferrer">{CommonsTexts.PRIVACY}</a>
          </Box>
        </Box>

      </AppBar>
    </Grid>
  );
}
