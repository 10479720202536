/* eslint-disable no-empty */
import React, { useEffect, useState } from "react";
import { Container, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"
import StudyCardUhda from "../StudyCardUhda";
import DialogUhda from "../DialogUhda";
import { CommonsTexts, LabelsStudy, SnackBarResponses } from "../../locale/en";
import { AppColors } from "../../resources/AppColors";
import { textType } from "../../resources/AppTexts";
import TextUhda from "../TextUdha";
import ButtonFilledUhda from "../ButtonFilledUhda";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import SnackBarUhda from "../SnackBarUhda";
import "../../styles/studiesCard.scss";
import SearchBar from "material-ui-search-bar";

/**
 * @component
 * Component to show all the studies of a user + the option to create a new study
 *
 * @param {object} studies: JSON with all the data available of the study
 *
 * @example
 * const study = {your JSON studies data}
 * <GridStudiesUhda study={study}/>
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)

  }
}))

const GridStudiesUhda = ({ studies }) => {

  const history = useHistory()
  //const texts = useTextStyles();
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [redirectTo, setRedirectTo] = useState([false, -1]);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentStudy, setCurrentStudy] = useState(null);
  const [currentTitleStudy, setCurrentTitleStudy] = useState(null);
  const [openDuplicate, setOpenDuplicate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [searched, setSearched] = useState();

  // eslint-disable-next-line no-unused-vars
  const [labels, setLabels] = useState();

  const [openSnackDuplicate, setOpenSnackDuplicate] = React.useState(false);
  const [openSnackDelete, setOpenSnackDelete] = React.useState(false);

  const [study, setStudy] = useState(studies);

  const getStudies = async () => {

    try {

      const response = await MyAxiosInstance.get(`${BASE_PATH}/studies`);
      setStudy(response.data.data)
      // eslint-disable-next-line no-empty
    } catch (err) {

    }
  }
  const moveToNextSection = () => {
    localStorage.setItem("route", "create")

    const permissions = [
      "Dashboard",
      "Forms",
      "Participants",
      "Team Members",
      "Settings"
    ]
    const newObj = { "detail": null, "value": 5, "permissions": permissions }
    localStorage.setItem("createStudy", JSON.stringify(newObj));

  }

  useEffect(() => {
    moveToNextSection()

  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const handleCloseDuplicate = () => {
    setOpenDuplicate(false);
  };

  const handleClosePermissionsDuplicate = () => {
    setOpenSnackDuplicate(false);
  };

  const handleClosePermissionsDelete = () => {
    setOpenSnackDelete(false);
  };

  //Function to close the snackbar
  // eslint-disable-next-line no-shadow
  const knowPermissions = (study) => {
    const label = [];
    if (study.permissions.includes("access-data")) {
      label.push(LabelsStudy.DASHBOARD)
      //link.push(`/study/${studyId}/dashboard`)
    }
    if (study.permissions.includes("forms-view")) {
      label.push(LabelsStudy.FORMS)
      // link.push(`/study/${studyId}/forms`)
    }
    if (study.permissions.includes("participant-create") || study.permissions.includes("participant-edit") || study.permissions.includes("participant-delete")) {
      label.push(LabelsStudy.PARTICIPANTS)
      // link.push(`/study/${studyId}/participants`)
    }
    if (study.permissions.includes("access-dissemination")) {
      label.push(LabelsStudy.DISSEMINATION)
      //link.push(`/study/${studyId}/dissemination`)
    }
    if (study.permissions.includes("teammember-create") || study.permissions.includes("teammember-edit") || study.permissions.includes("teammember-delete")) {
      label.push(LabelsStudy.TEAM_MEMBERS)
      //link.push(`/study/${studyId}/teamMembers`)
    }
    if (study.permissions.includes("study-edit-settings")) {
      label.push(LabelsStudy.SETTINGS)
      //link.push(`/study/${studyId}/settings`)
    }
    setLabels(label)
    return label
  };

  // eslint-disable-next-line no-shadow
  const handleOnEdit = study => {
    localStorage.setItem("route", "edit")

    const id = study.id
    var label = knowPermissions(study)
    const newObj = { "detail": id, "value": 5, "permissions": label, "editSection": true }
    localStorage.setItem("sectionDetails", JSON.stringify(newObj));

    history.push({
      pathname: `/study/${id}/settings`, //TODO poner los permissions del study en concreto
      state: { detail: id, value: 5, permissions: label }

    })
  }

  // eslint-disable-next-line no-shadow
  const handleOnDuplicate = study => {
    // TODO: Duplicate Study
    if (study.permissions.includes("study-duplicate")) {
      setCurrentTitleStudy(study.translations[0].study_title)
      setCurrentStudy(study)
      setShowDuplicateModal(!showDuplicateModal)
    } else {
      setOpenSnackDuplicate(true)
    }

  }

  const handleOnConfirmDuplicate = async (e) => {
    // TODO: Duplicate Study
    const id = currentStudy.id
    e.preventDefault()
    try {
      await MyAxiosInstance.post(`${BASE_PATH}/study/${id}/duplicate`);

      // eslint-disable-next-line no-empty
    } catch (error) {

    }
    // TODO: Delete Study
    setShowDuplicateModal(!showDuplicateModal)
    setOpenDuplicate(true)
    getStudies()
  }
  // eslint-disable-next-line no-shadow
  const handleOnDelete = study => {
    // TODO: Delete Study
    if (study.permissions.includes("study-duplicate")) {
      setCurrentTitleStudy(study.translations[0].study_title)
      setCurrentStudy(study)
      setShowDeleteModal(!showDeleteModal)
    } else {
      setOpenSnackDelete(true)
    }

  }

  const handleOnConfirmDelete = async (e) => {
    const id = currentStudy.id
    e.preventDefault()
    try {
      await MyAxiosInstance.delete(`${BASE_PATH}/study/${id}`);

    } catch (error) {

    }
    // TODO: Delete Study
    setShowDeleteModal(!showDeleteModal)
    setOpenDelete(true)
    getStudies()

  }

  const requestSearch = (searchedVal) => {
    const filteredRows = studies.filter((row) => {
      return (row.translations[0].study_title.toLowerCase().includes(searchedVal.toLowerCase()) || row.translations[0].short_description.toLowerCase().includes(searchedVal.toLowerCase()));
    });
    setStudy(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  if (redirectTo[0]) {
    alert("redirect to 0")
    return (
      <Container fluid className="studies-grid">
        <div className={classes.root}>
          <Grid container item
            style={{ marginBottom: "24px" }}
            direction={"row"}
            alignItems={"flex-end"}
            justifyContent={"flex-end"}>
            <ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}
              onClick={() => history.push({
                pathname: "/study", state: {
                  detail: null, value: 5, permissions: [
                    "Dashboard",
                    "Forms",
                    "Participants",
                    "Team Members",
                    "Settings"
                  ]

                }
              })}
              icon={<IconProviderUhda
                icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
              text={CommonsTexts.NEW_STUDY} />
          </Grid>
          <Grid
            align="center"
            container
            spacing={2}
            direction="row"
            alignItems={"center"}
            justify="flex-start">
            {showDuplicateModal && (
              <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
                body={
                  <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsStudy.TEXT_MODAL_DUPLICATE1}`} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                      text={`${currentTitleStudy} `} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsStudy.TEXT_MODAL_DUPLICATE2} `} />

                  </Grid>
                }
                buttonColor={AppColors.PRIMARY}
                title={LabelsStudy.TITLE_MODAL_DUPLICATE}
                show={showDuplicateModal}
                handleShow={setShowDuplicateModal}
                handleConfirm={handleOnConfirmDuplicate}
                textConfirmButton={LabelsStudy.CONFIRM_MODAL_DUPLICATE} />
            )}
            {showDeleteModal && (
              <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
                body={
                  <Grid>
                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsStudy.TEXT_MODAL_DELETE1} `} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                      text={`${currentTitleStudy} `} />

                    <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                      text={`${LabelsStudy.TEXT_MODAL_DELETE2} `} />

                  </Grid>}
                buttonColor={AppColors.RED}
                title={LabelsStudy.TITLE_MODAL_DELETE}
                show={showDeleteModal}
                handleShow={setShowDeleteModal}
                handleConfirm={handleOnConfirmDelete}
                textConfirmButton={LabelsStudy.CONFIRM_MODAL_DELETE} />
            )}
            {
              study.map(elem => (
                <Grid item key={study.indexOf(elem)}
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                >
                  <StudyCardUhda

                    studyId={elem.id}
                    studyTitle={elem.translations[0].study_title}
                    studyDescription={elem.translations[0].short_description}
                    numberOfParticipants={38}
                    numberOfResponses={1}
                    privacity={elem.private}
                    handleOnEdit={() => handleOnEdit(elem)}
                    handleOnDuplicate={() => {
                      handleOnDuplicate(elem)
                    }}
                    handleOnDelete={() => {
                      handleOnDelete(elem)
                    }}
                    roles={elem.roles}
                    permissions={elem.permissions} />
                </Grid>
              ))}
            <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.STUDY_DELETED}
              openSnack={openDelete} />
            <SnackBarUhda handleClose={handleCloseDuplicate} message={SnackBarResponses.STUDY_DUPLICATE}
              openSnack={openDuplicate} />

          </Grid>
        </div>
      </Container>

    )
  }

  return (
    <Container fluid className="studies-grid">
      <div className={classes.root}>
        <Grid container item
          style={{ marginBottom: "24px" }}
          direction={"row"}
          alignItems={"flex-end"}
          justifyContent={"flex-end"}>
          <ButtonFilledUhda margin={"10px"} marginLeft={"0.7em"} type={textType.SUBTITLE_BOLD}

            onClick={() => history.push({
              pathname: "/study", state: {
                detail: null,

                value: 5, permissions: [
                  "Dashboard",
                  "Forms",
                  "Participants",
                  "Team Members",
                  "Settings"
                ]
              }
            })}
            icon={<IconProviderUhda
              icon={<Icons.ADD size={"1.5em"} color={AppColors.WHITE} />} />}
            text={CommonsTexts.NEW_STUDY} />
        </Grid>
        <Grid item style={{ marginBottom: "2em" }}>
          <SearchBar
            style={{ marginLeft: "1em", marginRight: "1em" }}
            placeholder={"Search by title or description of the study"}
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
          />
        </Grid>
        <Grid
          align="center"
          container
          spacing={2}
          direction="row"
          alignItems={"center"}
          justify="flex-start">
          {showDuplicateModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DUPLICATE1}`} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleStudy} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DUPLICATE2} `} />

                </Grid>
              }
              buttonColor={AppColors.PRIMARY}
              title={LabelsStudy.TITLE_MODAL_DUPLICATE}
              show={showDuplicateModal}
              handleShow={setShowDuplicateModal}
              handleConfirm={handleOnConfirmDuplicate}
              textConfirmButton={LabelsStudy.CONFIRM_MODAL_DUPLICATE} />
          )}
          {showDeleteModal && (
            <DialogUhda textCancelButton={LabelsStudy.CANCEL_MODAL}
              body={
                <Grid>
                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DELETE1} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY_BOLD}
                    text={`${currentTitleStudy} `} />

                  <TextUhda color={AppColors.PRIMARY} type={textType.BODY}
                    text={`${LabelsStudy.TEXT_MODAL_DELETE2} `} />

                </Grid>}
              buttonColor={AppColors.RED}
              title={LabelsStudy.TITLE_MODAL_DELETE}
              show={showDeleteModal}
              handleShow={setShowDeleteModal}
              handleConfirm={handleOnConfirmDelete}
              textConfirmButton={LabelsStudy.CONFIRM_MODAL_DELETE} />
          )}

          {
            study.map(elem => (
              <Grid item key={study.indexOf(elem)}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
              >
                <StudyCardUhda
                  studyId={elem.id}
                  studyTitle={elem.translations[0].study_title}
                  studyDescription={elem.translations[0].short_description}
                  numberOfParticipants={""}
                  numberOfResponses={""}
                  privacity={elem.private}
                  handleOnEdit={() => handleOnEdit(elem)}
                  handleOnDuplicate={() => {
                    handleOnDuplicate(elem)
                  }}
                  handleOnDelete={() => {
                    handleOnDelete(elem)
                  }}
                  roles={elem.roles}
                  permissions={elem.permissions}
                />
              </Grid>
            ))}
          <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.STUDY_DELETED}
            openSnack={openDelete} />
          <SnackBarUhda handleClose={handleCloseDuplicate} message={SnackBarResponses.STUDY_DUPLICATE}
            openSnack={openDuplicate} />
          <SnackBarUhda handleClose={handleClosePermissionsDuplicate} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackDuplicate} />
          <SnackBarUhda handleClose={handleClosePermissionsDelete} severity={"error"}
            message={SnackBarResponses.NOT_PERMISSION}
            openSnack={openSnackDelete} />

        </Grid>
      </div>
    </Container>

  )
}

export default GridStudiesUhda;
