/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import DialogUhda from "../DialogUhda";
import {CommonsTexts, SnackBarResponses} from "../../locale/en";
import {FormControl, Grid, InputLabel, MenuItem, Select,} from "@material-ui/core";
import SnackBarUhda from "../SnackBarUhda";
import TextUhda from "../TextUdha";
import {textType} from "../../resources/AppTexts";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import {BASE_PATH, SECTIONS} from "../../resources/ApiUrls";
import {
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  questionTypes,
  questionTypesByName
} from "../../resources/QuestionConstants";
import {AppColors} from "../../resources/AppColors";

export default function MoveQuestionModal({
  showMoveQuestion,
  setShowMoveQuestion,
  studies, forms,
  formId,
  studyId,
  questionsLength,
  loading,
  setLoading,
  getQuestions,
  formTitle,
  questions,
  getSections,
  setShowSnackbarMove, sections, sectionState,
  selectedQuestions
}) {
  const [showError, setShowError] = useState(false);
  const [showErrorMoreInfo, setShowErrorMoreInfo] = useState();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [openSnackDelete, setOpenSnackDelete] = useState()
  const [allStudies, setAllStudies] = useState(studies)
  const [allSections, setAllSections] = useState(sections)
  const [allForms, setAllForms] = useState(forms)
  const [idStudy, setIdStudy] = useState(studyId)
  const [idForm, setIdForm] = useState(formId)
  const [idSection, setIdSection] = useState()

  // eslint-disable-next-line no-shadow
  const handleChangeQuestion = async (question, studyId, formId, sectionId,index) => {
    var newOrder = (allSections.find(x => x.id === sectionId))
    // setLoading(true)
    const questionData = question
    // var questionData = questions[index]
    var options = []
    var hasSingleOrMultiple = false
    if (questionData.answer_values) {
      if (questionData.type === questionTypes[QUESTION_SINGLE_CHOICE].name || questionData.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name) {
        hasSingleOrMultiple = true
      }
      for (var i = 0; i < questionData.answer_values.length; i++) {
        options.push(
          {
            "answerTitle": questionData.answer_values[i].translations[0].text,
            "answerValue": parseInt(questionData.answer_values[i].value),
            "answerTranslations": [
              {
                "locale": "en",
                "text": questionData.answer_values[i].translations[0].text
              }
            ]
          }
        )
      }
    }
    try {
      
      const questionBody = {
        "order": newOrder.questions.length + (index+1),
        "formId": formId,
        "sectionId": sectionId,
        "summary":null,
        "variableName": questionData.variable_name,
        "standarName": "",
        "conditional": questionData.conditional,
        "mandatory": questionData.mandatory,
        "type": questionTypesByName[questionData.type].name,
        "minValue": questionData.min_value,
        "maxValue": questionData.max_value,
        "units": questionData.units,
        "interval": questionData.interval,
        "answerOptions": hasSingleOrMultiple ? options : [],
        "questionTranslation": [
          {
            "locale": "en",
            "question": questionData["question_translations"][0]["question"],
            "subtitle": "",
            "info": questionData["question_translations"][0]["info"]
          }
        ]
      }
      
      const response = await MyAxiosInstance.put(`${BASE_PATH}/question/${question.id}`, questionBody)
     
      setLoading(true)
      getSections()
      setShowMoveQuestion(-999)
      setShowSnackbarMove(true)
    } catch (e) {
      setLoading(false)
      
    }
    getSections()
  }
  const handleChangeSectionId = async (e) => {
    setIdSection(e.target.value)

  };

  //Function to get the sections from the id of the form and study
  // eslint-disable-next-line no-shadow
  const getNewSections = async (formId) => {
   
    try {
      const response = await MyAxiosInstance.get(SECTIONS(formId));
      setAllSections(response.data.data.item);
      setIdSection(response.data.data.item[0].id)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAllSections(e.message);
    }
  };

  const handleChangeFormId = async (e) => {
    setIdForm(e.target.value)
    getNewSections(e.target.value)

  };

  //Function to get the sections from the id of the form and study
  // eslint-disable-next-line no-shadow
  const getNewForms = async (studyId) => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${studyId}/summary-forms/locale/en`);
      
      setAllForms(response.data.data.items);
      setIdForm(response.data.data.items[0].id)
      getNewSections(response.data.data.items[0].id)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAllSections(e.message);
    }
  };

  const handleChangeStudyId = async (e) => {
    setIdStudy(e.target.value)
    getNewForms(e.target.value)

  };
  const handleCloseDeleteLess = () => {
    setOpenSnackDelete(false);
  };

  const handleClickSubmit = async () => {
    for (let i = 0; i < selectedQuestions.length; i++) {
      handleChangeQuestion(selectedQuestions[i], idStudy, idForm, idSection,i)

    }
   
  }

  useEffect(() => {
  }, [])
  return (
    <DialogUhda
      buttonColor={ AppColors.PRIMARY }
      handleModalClose={ () => {
        setShowMoveQuestion(-999);
      } }
      size={ true }
      textCancelButton={ CommonsTexts.CANCEL }
      textConfirmButton={
        CommonsTexts.SAVE
      }
      handleCloseButton={ () => {
        setShowMoveQuestion(-999);

      } }
      closeButton={ true }
      cancelButton={ !showMoreInfo }
      handleShow={ setShowMoveQuestion }
      handleConfirm={
        handleClickSubmit
      }
      // title={TableTexts.ADD_TEAM_MEMBER_TITLE}
      title={ "Move question" }
      body={
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <>
            <Grid
              item
              xs={ 5 }
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <TextUhda type={ textType.BODY } text={ CommonsTexts.MOVE_QUESTIONS }/>
              <FormControl
                margin="normal"
                style={ {width: "100%"} }
                variant="outlined"
              >
                <InputLabel id="label">Study</InputLabel>
                <Select
                  labelId="label"
                  label="Study"
                  id="select"
                  value={ idStudy }
                  onChange={ handleChangeStudyId }
                  error={ idStudy === "" && showError }
                >
                  {allStudies.map((study) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem
                      value={ study.id }>{study.translations[0].study_title}</MenuItem>

                  ))}

                </Select>
              </FormControl>
              <FormControl
                margin="normal"
                style={ {width: "100%"} }
                variant="outlined"
              >
                <InputLabel id="label">Forms</InputLabel>
                <Select
                  labelId="label"
                  label="Section"
                  id="select"
                  value={ idForm }
                  onChange={ handleChangeFormId }
                  error={ idForm === "" && showError }
                >
                  {allForms.map((form) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem
                      value={ form.id }>{form.form_translations[0].title}</MenuItem>

                  ))}

                </Select>
              </FormControl>

              <FormControl
                margin="normal"
                style={ {width: "100%"} }
                variant="outlined"
              >
                <InputLabel id="label">Section</InputLabel>
                <Select
                  labelId="label"
                  label="Section"
                  id="select"
                  value={ idSection }
                  onChange={ handleChangeSectionId }
                  error={ idSection === "" && showError }
                >
                  {allSections.map((section) => (
                    // eslint-disable-next-line react/jsx-key
                    <MenuItem
                      value={ section.id }>{section.section_translations[0].title}</MenuItem>

                  ))}

                </Select>
              </FormControl>

            </Grid>

          </>
          <SnackBarUhda handleClose={ handleCloseDeleteLess } severity={ "error" }
            message={ SnackBarResponses.ANSWER_OPTION }
            openSnack={ openSnackDelete }/>
        </Grid>
      }
      show={ showMoveQuestion }
    />
  );
}
MoveQuestionModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleClickNext: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
  formId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
  changeMandatory: PropTypes.func.isRequired,
  hasNextModal: PropTypes.bool.isRequired,
  setHasNextModal: PropTypes.func.isRequired,
  questionsLength: PropTypes.number.isRequired,
};
