import {BsPlusCircle, BsPlusCircleFill} from "react-icons/bs";
import {BiDotsHorizontalRounded, BiDotsVerticalRounded} from "react-icons/all";
import PublicIcon from "@material-ui/icons/Public";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import IndeterminateCheckBoxRoundedIcon from "@material-ui/icons/IndeterminateCheckBoxRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoveToInboxOutlinedIcon from "@material-ui/icons/MoveToInboxOutlined";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
const Icons = {
  "ADD": BsPlusCircle,
  "ADD_FILLED": BsPlusCircleFill,
  "PUBLIC": PublicIcon,
  "PARTICIPANTS": PeopleAltOutlinedIcon,
  "MORE": MoreHorizIcon,
  "RESPONSES": IndeterminateCheckBoxRoundedIcon,
  "PRIVATE": LockOutlinedIcon,
  "OPTIONS": BiDotsVerticalRounded,
  "OPTIONS_HORIZONTAL": BiDotsHorizontalRounded,
  "VERIFIED": VerifiedUserOutlinedIcon,
  "INFO": InfoOutlinedIcon,
  "ARROW_DOWN": KeyboardArrowDownIcon,
  "ARCHIVE": ArchiveOutlinedIcon,
  "UNARCHIVE": UnarchiveOutlinedIcon,
  "DELETE":DeleteOutlineIcon,
  "MOVE":MoveToInboxOutlinedIcon,
  "ARROW_LEFT":KeyboardArrowLeftIcon

}

export default Icons
