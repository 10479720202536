/* eslint-disable no-shadow */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppColors } from "../resources/AppColors";
import { CommonsTexts, LabelsDrawer, LabelsStudy, TableTexts, } from "../locale/en";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import ErrorIcon from "@material-ui/icons/Error";
import DialogUhda from "../components/DialogUhda";
import TextfieldUhda from "../components/TextfieldUhda/TextfieldUhda";
import BreadcrumbsUhda from "../components/BreadcrumbsUhda";
import TableBaseUhda from "../components/TableUhda/TableBaseUhda";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import TeamMembersService from "../services/TeamMembersService";
import DrawerUhda from "../components/DrawerUhda";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@mui/material/TextField";
import SnackBarUhda from "../components/SnackBarUhda";
import { orderArrayAlphabetical, validateEmail } from "../utils/HelperFunctions";
import { StorageManager } from "../utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  option: {
    backgroundColor: "#0000000a"
  }
}));

function AddModal({
  handleAddParticipant,
  handleAddParticipantContinue,
  initialValues,
  values,
  setValues,
  showAddModal,
  setShowAddModal,
  data
}) {
  const storageManager = new StorageManager();
  const [showEmailError, setShowEmailError] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [selectedRole, setSelectedRole] = useState(0);
  const [emailsAvailable, setEmailsAvailable] = useState(["invite this email"]);
  const [isNewEmail, setIsNewEmail] = useState(true);
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  //let studyId = location.state.detail;
  const studyId = useRef();
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [optionsByName, setOptionsByName] = useState({});
  const [flag, setFlag] = useState(false)

  const [options, setOptions] = useState([]);
  const isAllSelected =
    options.length > 0 && selectedRoles.length === options.length;
  const classes = useStyles();
  const [researcherId, setResearcherId] = useState();
  const [emailData, setEmailData] = useState();
  const [showError, setShowError] = useState(false);

  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const getRoles = async () => {
    let optionsFinal = {}
    try {
      const responseRoles = await TeamMembersService.getRoles();
      const email = storageManager.getEmail()
      var userRoles;
      const user = data.find(user => (user.email || user.username) === email)
      if (user == undefined) {
        userRoles = responseRoles.data.map(a => a.name).join("/");
      } else {
        userRoles = user.userrole.split("/").map(str => str.replace(/\s/g, ""));
      }

      const roles = responseRoles.data.filter((role) =>
        userRoles.includes(role.name)
      );
      const actualRoles = roles;
      var roleList = [];
      let roleLevel = 999
      for (const element of actualRoles) {
        if (element.level < roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.level >= roleLevel) {
          optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
          roleList.push(role.name);
        }
      });
      orderArrayAlphabetical(roleList)
      setOptionsByName(optionsFinal);
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  };
  //This function retrives the stored permissions and id from local storage
  useEffect(() => {

    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    studyId.current = newItem.id;

  }, [])
  useEffect(() => {
    setValues(initialValues);
    getRoles();
  }, [showAddModal]);

  const handleClickSubmit = async () => {
    if (!email) {
      return setShowError(true);
    }
    if (
      isNewEmail &&
      (values.name === "" ||
        values.surname === "" ||
        selectedRoles.length === 0)
    ) {
      return setShowError(true);
    } else if (selectedRoles.length === 0) {
      return setShowError(true);
    } else {
      setShowError(false);
    }
    var newEmail = [];

    if (showEmailError) {
      return;
    }
    newEmail = email.split(": ");

    if (validateEmail(newEmail[1])) {
      setShowEmailError(false);
      var rolesForBody = [];
      selectedRoles.forEach((role) => {
        rolesForBody.push(optionsByName[role]);
      });
      const postBody = {
        name: isNewEmail ? values.name : emailData.name,
        surname: isNewEmail ? values.surname : emailData.surname,
        roles: rolesForBody,
        email: newEmail[1],
        "id-researcher": isNewEmail ? null : researcherId,
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const responsePost = await MyAxiosInstance.post(
          `${BASE_PATH}/study/${studyId.current}/team-member`,
          postBody
        );
        handleAddParticipant();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    } else {
      setShowEmailError(true);
    }
  };

  const handleClickSubmitContinue = async () => {
    if (!email) {
      return setShowError(true);
    }
    if (
      isNewEmail &&
      (values.name === "" ||
        values.surname === "" ||
        selectedRoles.length === 0)
    ) {
      return setShowError(true);
    } else if (selectedRoles.length === 0) {
      return setShowError(true);
    } else {
      setShowError(false);
    }
    var newEmail = [];

    if (showEmailError) {
      return;
    }
    newEmail = email.split(": ");

    if (validateEmail(newEmail[1])) {
      setShowEmailError(false);
      var rolesForBody = [];
      selectedRoles.forEach((role) => {
        rolesForBody.push(optionsByName[role]);
      });
      const postBody = {
        name: isNewEmail ? values.name : emailData.name,
        surname: isNewEmail ? values.surname : emailData.surname,
        roles: rolesForBody,
        email: newEmail[1],
        "id-researcher": isNewEmail ? null : researcherId,
      };

      try {
        // eslint-disable-next-line no-unused-vars
        const responsePost = await MyAxiosInstance.post(
          `${BASE_PATH}/study/${studyId.current}/team-member`,
          postBody
        );
        handleAddParticipantContinue();
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
    } else {
      setShowEmailError(true);
    }
  };

  const getResearcher = async (emailValue) => {
    setFlag(false)
    var emailFieldList = emailValue.split(": ");
    var finalEmailValue = "";
    if (emailFieldList.length === 1) {
      finalEmailValue = emailFieldList[0];
    } else {
      finalEmailValue = emailFieldList[1];
    }
    try {
      const response = await MyAxiosInstance.get(
        `${BASE_PATH}/researcher-by-email/${studyId.current}/${finalEmailValue}`
      );
      if (response.status === 200) {
        var newList = [];

        if (response.data.data.team_member_id) {
          setShowEmailError(true);
          newList.push(response.data.data.email);
          setEmailsAvailable(newList);
        } else {
          setShowEmailError(false);
          if (response.data.data.researcher_id !== null) {
            setResearcherId(response.data.data.researcher_id);
            newList = ["Add existing: " + response.data.data.email];
            setEmailsAvailable(newList);
            setEmailData({
              name: response.data.data.name,
              surname: response.data.data.surname,
            });
            setIsNewEmail(false);
          } else {
            setEmailData(null);
            var newEmail = ["invite this email: " + finalEmailValue];
            setEmailsAvailable(newEmail);
            setIsNewEmail(true);
          }
        }
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.SAVE}
      textConfirmAndContinue={TableTexts.SAVE_AND_ADD}
      handleShow={setShowAddModal}
      handleConfirm={handleClickSubmit}
      handleConfirmAndContinue={handleClickSubmitContinue}
      values={values}
      setValues={setValues}
      title={TableTexts.ADD_TEAM_MEMBER_TITLE}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin="normal" style={{ width: "100%" }}>
            <Autocomplete
              classes={{ option: classes.option }}
              freeSolo
              open={open}
              onOpen={() => {
                if (validateEmail(email)) {
                  setOpen(true);
                }
              }}
              //onClose={ () => setOpen(false) }
              inputValue={email}
              // eslint-disable-next-line no-unused-vars
              onInputChange={(e, value, reason) => {
                if (flag) {
                  // eslint-disable-next-line no-param-reassign
                  value = email
                } else {
                  setEmail(value);
                }
                setFlag(false)
                if (!validateEmail(email)) {
                  setOpen(false);
                } else {
                  getResearcher(value);
                }
              }}
              // eslint-disable-next-line no-unused-vars
              onPaste={(e, value, reason) => {
                var text = (e.clipboardData.getData("Text"))
                text = text.trim()
                text = text.replace(/\s+/g, " ").trim();
                setEmail(text)
                //setEmail(e.clipboardData.getData('Text'));
                setFlag(true)
              }}
              options={validateEmail(email) ? emailsAvailable : []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={showEmailError}
                  helperText={
                    showEmailError &&
                    "This email is already a researcher of this study"
                  }
                  label={TableTexts.EMAIL}
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          {emailData && !isNewEmail && (
            <>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  isDisabled={true}
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={emailData.name}
                />
              </FormControl>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  isDisabled={true}
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={emailData.surname}
                />
              </FormControl>
              <FormControl
                margin="normal"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <InputLabel id="multiple-select-label">Role</InputLabel>
                <Select
                  label="Role"
                  labelId="multiple-select-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleChangeRole}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedRoles.length > 0 &&
                          selectedRoles.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedRoles.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {isNewEmail && (
            <>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <TextfieldUhda
                  name="name"
                  handleChange={handleInputChange}
                  label={TableTexts.NAME}
                  value={values.name}
                />
              </FormControl>
              <FormControl margin="normal" style={{ width: "100%" }}>
                <TextfieldUhda
                  name="surname"
                  handleChange={handleInputChange}
                  label={TableTexts.SURNAME}
                  value={values.surname}
                />
              </FormControl>
              <FormControl
                margin="normal"
                style={{ width: "100%" }}
                variant="outlined"
              >
                <InputLabel id="multiple-select-label">Role</InputLabel>
                <Select
                  label="Role"
                  labelId="multiple-select-label"
                  multiple
                  value={selectedRoles}
                  onChange={handleChangeRole}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{ root: isAllSelected ? classes.selectedAll : "" }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{ indeterminate: classes.indeterminateColor }}
                        checked={isAllSelected}
                        indeterminate={
                          selectedRoles.length > 0 &&
                          selectedRoles.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          checked={selectedRoles.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {showError && (
            <FormControl margin="normal" style={{ width: "100%" }}>
              <FormHelperText
                style={{ paddingLeft: "15px", color: "red" }}
                id="my-helper-text"
              >
                {showEmailError
                  ? "The email is already a participant of this study"
                  : "Make sure to fill all the fields"}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
      }
      show={showAddModal}
    />
  );
}

AddModal.propTypes = {
  setSelectedData: PropTypes.func.isRequired,
  handleAddParticipant: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
};

function DeleteModal({
  setShowDeleteModal,
  showDeleteModal,
  selected,
  handleDeleteParticipant,
}) {
  useEffect(() => {
  }, [showDeleteModal]);
  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={TableTexts.DELETE_CONFIRM}
      handleShow={setShowDeleteModal}
      handleConfirm={() => {
        handleDeleteParticipant();
      }}
      title={
        showDeleteModal === true
          ? selected.length > 1
            ? TableTexts.DELETE_TEAM_MEMBER_IDS + selected.toString()
            : TableTexts.DELETE_TEAM_MEMBER_ID + selected.toString()
          : TableTexts.DELETE_TEAM_MEMBER_ID + showDeleteModal
      }
      body={
        <Typography variant="subtitle1" gutterBottom>
          {showDeleteModal === true
            ? selected.length > 1
              ? TableTexts.DELETE_TEAM_MEMBER_TEXT_MULTIPLE
              : TableTexts.DELETE_TEAM_MEMBER_TEXT
            : TableTexts.DELETE_TEAM_MEMBER_TEXT}
        </Typography>
      }
      show={showDeleteModal}
    />
  );
}

DeleteModal.propTypes = {
  setShowDeleteModal: PropTypes.func.isRequired,
};

function EditModal({
  handleEditParticipant,
  setShowEditModal,
  showEditModal,
  editValues,
  setEditValues,
  data
}) {
  const storageManager = new StorageManager();
  // eslint-disable-next-line no-unused-vars
  const [showEmailError, setShowEmailError] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const isAllSelected =
    options.length > 0 && selectedRoles.length === options.length;
  const classes = useStyles();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditValues({ ...editValues, [name]: value });
  };

  const getRoles = async () => {
    let optionsFinal = {}
    try {
      const responseRoles = await TeamMembersService.getRoles();
      const email = storageManager.getEmail()
      var userRoles;
      const user = data.find(user => (user.email || user.username) === email)
      if (user == undefined) {
        userRoles = responseRoles.data.map(a => a.name).join("/");
      } else {
        userRoles = user.userrole.split("/");
      }
      const roles = responseRoles.data.filter((role) =>
        userRoles.includes(role.name)
      );
      const actualRoles = roles;
      var roleList = [];
      let roleLevel = 999
      for (const element of actualRoles) {
        if (element.level < roleLevel) {
          roleLevel = element.level
        }
      }
      responseRoles.data.forEach((role) => {
        if (role.level >= roleLevel) {
          optionsFinal = { ...optionsFinal, [role["name"]]: role.id };
          roleList.push(role.name);
        }
      });
      orderArrayAlphabetical(roleList)
      setOptions(roleList);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  };

  const handleChangeRole = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedRoles(selectedRoles.length === options.length ? [] : options);
      return;
    }
    setSelectedRoles(value);
  };

  const handleClickSubmit = async () => {
    if (selectedRoles.length > 0) {
      const putData = {
        roles: selectedRoles,
      };
      try {
        await MyAxiosInstance.put(
          `${BASE_PATH}/team-member/${editValues.researcher_id}`,
          putData
        );
        // eslint-disable-next-line no-empty
      } catch (e) {

      }
      handleEditParticipant();
    }
  };

  useEffect(() => {
    getRoles();
    setSelectedRoles(editValues["userrole"].split(" / "));
  }, [showEditModal]);

  return (
    <DialogUhda
      textCancelButton={CommonsTexts.CANCEL}
      textConfirmButton={CommonsTexts.SAVE}
      handleShow={setShowEditModal}
      handleConfirm={handleClickSubmit}
      values={editValues}
      setValues={setEditValues}
      title={TableTexts.EDIT_TEAM_MEMBER_TITLE + showEditModal}
      body={
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="email"
              handleChange={handleInputChange}
              label={TableTexts.EMAIL}
              value={editValues.email}
              error={showEmailError}
              helperText={showEmailError && TableTexts.EMAIL_ERROR}
              inputProps={{
                endAdornment: showEmailError && (
                  <InputAdornment position="end">
                    <ErrorIcon style={{ color: AppColors.RED }} />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="name"
              handleChange={handleInputChange}
              label={TableTexts.NAME}
              value={editValues.name}
            />
          </FormControl>
          <FormControl margin="normal" style={{ width: "100%" }}>
            <TextfieldUhda
              isDisabled={true}
              name="surname"
              handleChange={handleInputChange}
              label={TableTexts.SURNAME}
              value={editValues.surname}
            />
          </FormControl>
          <FormControl
            margin="normal"
            style={{ width: "100%" }}
            variant="outlined"
          >
            <InputLabel id="multiple-select-label">Role</InputLabel>
            <Select
              label="Role"
              labelId="multiple-select-label"
              multiple
              value={selectedRoles}
              onChange={handleChangeRole}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              <MenuItem
                value="all"
                classes={{ root: isAllSelected ? classes.selectedAll : "" }}
              >
                <ListItemIcon>
                  <Checkbox
                    classes={{ indeterminate: classes.indeterminateColor }}
                    checked={isAllSelected}
                    indeterminate={
                      selectedRoles.length > 0 &&
                      selectedRoles.length < options.length
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.selectAllText }}
                  primary="Select All"
                />
              </MenuItem>
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemIcon>
                    <Checkbox checked={selectedRoles.indexOf(option) > -1} />
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
      show={showEditModal}
    />
  );
}

EditModal.propTypes = {
  setShowEditModal: PropTypes.func.isRequired,
  handleEditParticipant: PropTypes.func.isRequired,
};

/**
 * @Page
 * Page that shows the table of the user's members
 */
const TeamMembersPage = ({ drawer }) => {
  const [loading, setLoading] = useState(true);
  const [study, setStudy] = useState();
  // const id = location.state.detail;
  // const permissions = location.state.permissions
  const id = useRef();
  const permissions = useRef()

  const initialValues = { name: "", surname: "", userrole: "0", email: "" };
  const [values, setValues] = useState(initialValues);
  const [editValues, setEditValues] = useState(initialValues);
  const [showAddModal, setShowAddModal] = useState(-999);
  const [showEditModal, setShowEditModal] = useState(-999);
  const [showDeleteModal, setShowDeleteModal] = useState(-999);
  const [researcherId, setResearcherId] = useState();
  const [response, setResponse] = useState();
  //let studyId = location.state.detail;
  const studyId = useRef();
  const [open, setOpen] = useState(drawer); //false
  const [snackbarOpen, setSnackbarOpen] = useState("");
  const [devicesSize, setDevicesSize] = useState("200px")
  const [cardWidth, setCardWidth] = useState("100%")
  //const [windowSize, setWindowSize] = useState()
  const [breadcrumbMargin, setBreadcrumbMargin] = useState("275px");

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: TableTexts.NAME,
      isSortable: true,
    },
    {
      id: "surname",
      numeric: false,
      disablePadding: false,
      label: TableTexts.SURNAME,
      isSortable: false,
    },
    {
      id: "userrole",
      numeric: false,
      disablePadding: false,
      label: TableTexts.USER_ROLE,
      isSortable: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: TableTexts.EMAIL,
      isSortable: false,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: true,
      label: TableTexts.ACTIONS,
      isSortable: false,
    },
  ];

  //Function to get all the teamMembers of the user
  const getTeamMembers = async () => {
    try {
      var responseAPI = await TeamMembersService.getTeamMembers(id.current);
      responseAPI = Object.values(responseAPI.data.data.items);
      var data = [];
      var rolesList = "";
      responseAPI.forEach((res) => {
        res.roles.forEach((role, index) => {
          if (index === 0) {
            rolesList = role.name;
          } else {
            rolesList = rolesList + " / " + role.name;
          }
        });
        res["researcher"]["user"]["userrole"] = rolesList;
        res["researcher"]["user"]["researcher_id"] = res["id"];
        data.push(res["researcher"]["user"]);
      });

      if (data.length === 0) {
        data = [
          {
            id: -999,
            email: "not data",
            name: "not data",
            surname: "not data",
            username: "not data",
          },
        ];
      }
      setResponse(data);
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setLoading(false);
  };

  //Function to show the modal to add a new teamMember
  const handleAddParticipant = () => {
    setLoading(true);
    setValues(initialValues);
    getTeamMembers();
    setSnackbarOpen("Team member added");
    setShowAddModal(-999);
  };

  //Function to show the modal to add a new teamMember
  const handleAddParticipantContinue = () => {
    setLoading(true);
    setValues(initialValues);
    getTeamMembers();
    setSnackbarOpen("Team member added");
  };

  //Function to delete a teamMember
  const handleDelete = async () => {
    setLoading(true);
    try {
      const deleteData = response.find((x) => x.id === showDeleteModal);
      const deleteBody = {
        name: deleteData.name,
        surname: deleteData.surname,
        email: deleteData.email,
      };
      // eslint-disable-next-line no-unused-vars
      const deleteResp = await MyAxiosInstance.delete(
        `${BASE_PATH}/team-member/${researcherId}`,
        deleteBody
      );
      setSnackbarOpen("Team member deleted");
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
    setShowDeleteModal(-999);
    getTeamMembers();
  };

  //Function to edit a teamMember
  const handleEditParticipant = async () => {
    setLoading(true);
    setEditValues(initialValues);
    getTeamMembers();
    setSnackbarOpen("Team member edited");
    // try {
    //   const response = await TeamMembersService.updateTeamMember(id, showEditModal, editValues)
    //   console.log('update data response: ', response)
    //   getTeamMembers()
    // } catch (e) {
    //   console.log('Error: ', e)
    // }
    setShowEditModal(-999);
  };

  //Function to get the data of the study from the id
  const getStudy = async () => {
    try {
      const res = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(res.data.data);
    } catch (err) {
      setStudy(err.message);
    }
  };
  //This function retrives the stored permissions and id from local storage
  useEffect(() => {

    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
    studyId.current = newItem.id;

  }, [])

  useEffect(() => {
    setOpen(drawer);
  }, [drawer]);

  function debounce(fn, ms) {
    // eslint-disable-next-line no-unused-vars
    //This will run the code on every 1 second
    let timer
    // eslint-disable-next-line no-unused-vars
    return _ => {
      clearTimeout(timer)
      // eslint-disable-next-line no-unused-vars
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      // if(open){
      //   if(window.innerWidth >= 1000){
      //     setDevicesSize("200px")
      //     setCardWidth("100%")
      //   }else{
      //     setDevicesSize("20px") 
      //     setCardWidth("100%") 
      //   }

      // }else{
      //   if(window.innerWidth >= 1680){
      //     setDevicesSize("160px")
      //     setCardWidth("100%")
      //   }else if(window.innerWidth >= 640 && window.innerWidth <= 1680){
      //     setDevicesSize("280px")
      //     setCardWidth("100%")
      //   }else if(window.innerWidth <= 600){

      //     setDevicesSize("160px")
      //     setCardWidth("70%")

      //   }
      // }

      if (open) {
        if (window.innerWidth >= 1000 && window.innerWidth <= 1680) {
          setDevicesSize("200px")
          setCardWidth("100%")
          setBreadcrumbMargin("200px")
        }
        else if (window.innerWidth >= 1920 && window.innerWidth === 2560) {
          setDevicesSize("-250px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")

        } else if (window.innerWidth >= 1920 && window.innerWidth <= 2560) {
          setDevicesSize("-5px")
          setCardWidth("100%")
          setBreadcrumbMargin("195px")

        }

        else {

          setDevicesSize("20px")
          setCardWidth("100%")
        }

      } else {
        if (window.innerWidth >= 640 && window.innerWidth <= 1600) {
          setDevicesSize("280px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")
        } else if (window.innerWidth >= 640 && window.innerWidth <= 1680) {
          setDevicesSize("170px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")
        } else if (window.innerWidth === 1920) {
          setDevicesSize("-40px")
          setCardWidth("100%")
          setBreadcrumbMargin("275px")

        }

        else if (window.innerWidth >= 1920 && window.innerWidth <= 2560) {
          setDevicesSize("-355px")
          setCardWidth("100%")

        }

        else if (window.innerWidth <= 600) {

          setDevicesSize("160px")
          setCardWidth("70%")
          setBreadcrumbMargin("150px")

        } else {
          //alert(window.innerWidth)
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    // eslint-disable-next-line no-unused-vars
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  useEffect(() => {
    getStudy().then(() => {
      getTeamMembers();
    });
  }, []);

  const handleCloseDelete = () => {
    setSnackbarOpen("");
  };

  useEffect(() => {
    if (showDeleteModal !== -999) {
      setResearcherId(
        response.find((x) => x.id === showDeleteModal)["researcher_id"]
      );
    }
  }, [showDeleteModal]);

  useEffect(() => {
    if (showEditModal !== -999) {
      setEditValues(response.find((x) => x.id === showEditModal));
    }
  }, [showEditModal]);

  return (
    <>
      {study && (
        <DrawerUhda
          id={studyId.current}
          drawer={open}
          select={permissions.current.indexOf("Team Members")}
          settings={study.permissions}
        />
      )}
      <Grid
        container
        direction={"column"}
        spacing={2}
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item>
          {study && (
            <div style={{ marginLeft: breadcrumbMargin }}><BreadcrumbsUhda
              routes={[
                { id: 0, label: LabelsDrawer.STUDIES, url: "/studies" },
                {
                  id: 1,
                  label: `${study.translations[0].study_title}`,
                  disabled: true,
                  studyId: id.current,
                  permissions: permissions.current
                },
                { id: 2, label: LabelsStudy.TEAM_MEMBERS },
              ]}
            /></div>
          )}
        </Grid>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            {response && (
              <div style={{ marginLeft: devicesSize, maxWidth: window.innerWidth <= 1000 ? "60%" : cardWidth }}><TableBaseUhda
                title=""
                headCells={headCells}
                dataToUse={response}
                addModal={AddModal}
                deleteModal={DeleteModal}
                editModal={EditModal}
                initialValues={initialValues}
                values={values}
                setValues={setValues}
                editValues={editValues}
                setEditValues={setEditValues}
                handleAddParticipant={handleAddParticipant}
                handleAddParticipantContinue={handleAddParticipantContinue}
                handleEditParticipant={handleEditParticipant}
                handleDeleteParticipant={handleDelete}
                showAddModal={showAddModal}
                setShowAddModal={setShowAddModal}
                showEditModal={showEditModal}
                setShowEditModal={setShowEditModal}
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                interactive={true}
                canAdd={study.permissions.includes("teammember-create")}
                canEdit={study.permissions.includes("teammember-edit")}
                canDelete={study.permissions.includes("teammember-delete")}
                textDataEmpty={TableTexts.TEAM_MEMBERS_EMPTY}

              /></div>
            )}
          </>
        )}
        <SnackBarUhda
          severity="success"
          handleClose={handleCloseDelete}
          message={snackbarOpen}
          openSnack={snackbarOpen !== ""}
        />
      </Grid>
    </>
  );
};

export default TeamMembersPage;
