/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppColors } from "../../resources/AppColors";
import DialogUhda from "../DialogUhda";
import { CommonsTexts, LabelsForm, LabelsForms, LinksInfo, SnackBarResponses, } from "../../locale/en";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Typography,
} from "@material-ui/core";
import TextfieldUhda from "../TextfieldUhda/TextfieldUhda";
import TextfieldMultilineUhda from "../TextfieldMultilineUhda/TextfieldMultilineUhda";
import FormSwitchUhda from "../FormSwitchUhda";
import ErrorIcon from "@material-ui/icons/Error";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import {
    QUESTION_CALCULATION,
    QUESTION_CALENDAR,
    QUESTION_INFO,
    QUESTION_MULTIPLE_CHOICE,
    QUESTION_NUMERIC,
    QUESTION_RANGE,
    QUESTION_SINGLE_CHOICE,
    QUESTION_TEXT,
    questionTypes,
    questionTypesByName,
} from "../../resources/QuestionConstants";
import { Alert } from "@material-ui/lab";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import SnackBarUhda from "../SnackBarUhda";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { validateVarName } from "../../utils/HelperFunctions";
import { textFieldStyles } from "../TextfieldUhda/TextFieldStyle";
import MUIEditor, { MUIEditorState, toolbarControlTypes } from "react-mui-draft-wysiwyg";
import { convertToRaw, EditorState, ContentState, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const useStyles = makeStyles({

    button: {
        "&:hover": {
            backgroundColor: "transparent",
        }, "& .MuiTouchRipple-root span": {
            backgroundColor: "transparent",
            opacity: .3,
        },
    }
})

export default function EditQuestionModal({
    handleClickNext, handleEditQuestion,
    editValues,
    setEditValues,
    showEditQuestion,
    setShowEditQuestion,
    formId,
    studyId,
    changeMandatory,
    hasNextModal,
    setHasNextModal,
    questionsLength,
    setLoading,
    getQuestions,
    formTitle,
    questions,
    getSections,
    setShowSnackbarEdit,
    variableId, answerId,
    conditionalListFinal,
    conditionalOperatorFinal, sections, sectionState,
    getSummary, isSummary, questionsSummaryLength,
    existSummary,
    conditionalListLength
}) {
    const [showError, setShowError] = useState(false);
    const [showErrorVar, setShowErrorVar] = useState(false);
    const [showErrorVarName, setShowErrorVarName] = useState(false);
    const [showErrorVarSpace, setShowErrorVarSpace] = useState(false);
    const [openSnackAddRepeat, setOpenSnackAddRepeat] = useState()
    const [openSnackAddSpace, setOpenSnackAddSpace] = useState()

    const [showErrorMoreInfo, setShowErrorMoreInfo] = useState();
    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const [conditionalQuestions, setConditionalQuestions] = useState([]);
    const [initialValues, setInitialValues] = useState();
    const [questionsValuesList, setQuestionsValuesList] = useState();
    const [showConditionalError, setShowConditionalError] = useState();
    const [openSnackDelete, setOpenSnackDelete] = useState()
    const [openSnackDeleteConditional, setOpenSnackDeleteConditional] = useState()

    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [isLoadingVariableName, setIsLoadingVariableName] = useState(false);
    const [conditionalOperator, setConditonalOperator] = useState(conditionalOperatorFinal == null ? 1 : conditionalOperatorFinal) //TODO CAMBIARLO
    const [sectionId, setSectionId] = useState(sectionState)
    const [answerNameId, setAnswerNameId] = useState(answerId);
    const [variableNameId, setVariableNameId] = useState(variableId);
    const [variableMap, setVariableMap] = useState(conditionalListLength === undefined ? 0 : conditionalListLength - 1)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()
    const rawJsText = `{
      "entityMap": {},
      "blocks": [
        {
          "key": "e4brl",
          "text": "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [
            {
              "offset": 0,
              "length": 11,
              "style": "BOLD"
            },
            {
              "offset": 28,
              "length": 29,
              "style": "BOLD"
            },
            {
              "offset": 12,
              "length": 15,
              "style": "ITALIC"
            },
            {
              "offset": 28,
              "length": 28,
              "style": "ITALIC"
            }
          ],
          "entityRanges": [],
          "data": {}
        },
        {
          "key": "3bflg",
          "text": "Aenean commodo ligula eget dolor.",
          "type": "unstyled",
          "depth": 0,
          "inlineStyleRanges": [],
          "entityRanges": [],
          "data": {}
        }
      ]
    }`;
    const [editorState, setEditorState] = React.useState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(rawJsText)))
    );
    const [editorStateHelperText, setEditorStateHelperText] = React.useState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(rawJsText)))
    );
    const onChange = (newState) => {
        const rawContent = convertToRaw(newState.getCurrentContent())
        if (rawContent.blocks[0].type === "default") {
            rawContent.blocks[0].type = "unstyled"
        }
        if (Object.keys(rawContent.blocks[0]["data"]).length > 1) {
            rawContent.blocks[0]["data"]["text-align"] = rawContent.blocks[0]["data"]["textAlign"]
            delete rawContent.blocks[0]["data"]["textAlign"]
        }
        let stateHtml = draftToHtml(rawContent)

        stateHtml = stateHtml.replace(/textAlign/g, "text-align")
        setEditValues({ ...editValues, ["questionTitle"]: stateHtml })
        setEditorState(newState);
    };

    const onChangeHelperText = (newStateHelperText) => {
        //This has to be done because the newState is an array and have to pass to HTML to post
        const rawContent = convertToRaw(newStateHelperText.getCurrentContent())
        if (rawContent.blocks[0].type === "default") {
            rawContent.blocks[0].type = "unstyled"
        }
        if (Object.keys(rawContent.blocks[0]["data"]).length > 1) {
            rawContent.blocks[0]["data"]["text-align"] = rawContent.blocks[0]["data"]["textAlign"]
            delete rawContent.blocks[0]["data"]["textAlign"]
        }
        let stateHtml = draftToHtml(rawContent)
        stateHtml = stateHtml.replace(/textAlign/g, "text-align")
        setEditValues({ ...editValues, ["helperText"]: stateHtml })

        setEditorStateHelperText(newStateHelperText);
    };

    const config = {
        toolbar: {
            controls: [
                toolbarControlTypes.bold,
                toolbarControlTypes.italic,
                toolbarControlTypes.underline,
                toolbarControlTypes.textAlign,
                toolbarControlTypes.blockType,
            ],
        },
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const openPopover = Boolean(anchorEl);
    const idPopover = openPopover ? "simple-popover" : undefined;

    const handleChangeSectionId = async (e) => {
        setSectionId(e.target.value)

    };
    const handleChangeQuestionType = async (e) => {
        await setEditValues({
            ...editValues,
            ["type"]: questionTypes[e.target.value].name,
        });
    };
    const handleInputChange = async (e) => {
        if (showErrorVarName)
            setShowErrorVarName(false)
        if (showErrorVarSpace)
            setShowErrorVarSpace(false)
        //TODO:: variableName has to be unic
        const { name, value } = e.target;
        await setEditValues({ ...editValues, [name]: value });
    };

    const handleOnConditionalChange = async () => {
        if (editValues["hasConditional"] === false) {
            editValues["conditionalList"] = [{ variableId: "", answerId: "", type: "" }]
            editValues["conditional"] = null

        }
        if (editValues["hasConditional"] === true) {
            editValues["conditional"] = undefined
            editValues["conditionalList"] = ""
        }
        await setEditValues({
            ...editValues,
            ["hasConditional"]: !editValues["hasConditional"],
        });
    };

    const handleOnLongTextChange = async () => {
        await setEditValues({
            ...editValues,
            ["long_text"]: !editValues["long_text"],
        });
    };

    const handleChangeConditionalOperator = (e) => {
        setConditonalOperator(e.target.value)
    };

    const handleOnSummaryChange = async () => {
        await setEditValues({
            ...editValues,
            ["summary"]: !editValues["summary"],
        });
    };

    useEffect(() => {
        setTimeout(function () { //Start the timer
            setIsLoadingDelete(false)
        }.bind(this), 1000)
    }, [editValues]);
    useEffect(() => {
        // eslint-disable-next-line no-use-before-define
        checkHasNextModal();
    }, [editValues["type"], editValues["hasConditional"]]);

    useEffect(() => {
        setEditValues({
            ...editValues,
            ["hasConditional"]:
                !(editValues["conditional"] === null || editValues["conditional"] === ""),

        });
        var questionTitle = editValues["question_translations"][0]["question"].replace("<span>*</span>", "")
        const blocksFromHtml = htmlToDraft(questionTitle);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorStateDraft = EditorState.createWithContent(contentState);
        setEditorState(editorStateDraft)

        const blocksFromHtmlHelper = htmlToDraft(editValues["question_translations"][0]["info"]);
        const contentStateHelper = ContentState.createFromBlockArray(blocksFromHtmlHelper["contentBlocks"], blocksFromHtmlHelper["entityMap"]);
        const editorStateDraftHelper = EditorState.createWithContent(contentStateHelper);
        setEditorStateHelperText(editorStateDraftHelper)
        // eslint-disable-next-line no-use-before-define
        checkHasNextModal();

    }, [showEditQuestion]);
    useEffect(() => {
        var conditionalArray = "";
        var conditionalList = [];
        var questionList = [];
        for (var i = 0; i < questions.length; i++) {
            if (
                questions[i]["type"] === questionTypes[QUESTION_SINGLE_CHOICE].name ||
                questions[i]["type"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name ||
                questions[i]["type"] === questionTypes[QUESTION_RANGE].name || questions[i]["type"] === questionTypes[QUESTION_CALCULATION].name
            ) {
                questionList.push(questions[i]);
            }
        }
        var answersList =
            editValues["answer_values"].length === 0
                ? //  [{'answer': '', 'value': ''},{'answer': '', 'value': ''}]
                [
                    {
                        value: "",
                        translations: [
                            {
                                locale: "en",
                                text: "",
                            },
                        ],
                    },
                    {
                        value: "",
                        translations: [
                            {
                                locale: "en",
                                text: "",
                            },
                        ],
                    },
                ]
                : editValues["answer_values"];
        setQuestionsValuesList(answersList);
        setEditValues({
            ...editValues,
            ["answer_values"]: answersList,
            ["conditionalVariable"]: "",
            ["conditionalAnswer"]: "",
        });
        if (!(editValues["conditional"] === null || editValues["conditional"] === "")) {
            setEditValues({
                ...editValues,
                ["conditionalList"]: editValues["hasConditional"] ? conditionalListFinal : "",
                ["conditionalVariable"]: (conditionalArray[0]),
                ["conditionalAnswer"]: (conditionalArray[1]),
            });
        }
        setConditionalQuestions(questionList);
        setInitialValues(editValues);
    }, [showMoreInfo]);

    function hasWhiteSpace(s) {
        return s.indexOf(" ") >= 0;
    }
    const handleClickSubmit = async () => {
        var section = (sections.find(x => x.id === sectionId))
        if (
            editValues["variable_name"] === "" ||
            editValues["type"] === "" ||
            editValues["questionTitle"] === "<p></p>\n"
        ) {
            setShowError(true);
        } else if (!validateVarName(editValues["variable_name"])) {
            setShowErrorVar(true);
        }
        else {
            if (!hasNextModal) {
                try {
                    const questionBody = {
                        order: (sectionId === sectionState) ? editValues.order : section.questions.length + 1, //TODO MIRAR COMO HACER LO DEL ORDER EN AMBOS
                        formId: formId,
                        sectionId: isSummary ? null : sectionId,
                        summary: editValues["summary"],
                        variableName: editValues.variable_name,
                        standarName: "",
                        conditional: "",
                        mandatory: editValues.mandatory,
                        type: questionTypesByName[editValues.type].name,
                        minValue: null,
                        maxValue: null,
                        units: "",
                        interval: null,
                        answerOptions: [],
                        longText: editValues.long_text ? 1 : 0, //TODO Look naming on the backend
                        questionTranslation: [
                            {
                                locale: "en",
                                question: editValues["questionTitle"]
                                    ? (editValues.mandatory ? editValues["questionTitle"].replace("</p>", "<span>*</span></p>").replace("</h1>", "<span>*</span></h1>").replace("</h2>", "<span>*</span></h2>").replace("</h3>", "<span>*</span></h3>").replace("</h4>", "<span>*</span></h4>").replace("</h5>", "<span>*</span></h5>").replace("</h6>", "<span>*</span></h6>") : editValues["questionTitle"].replace("</br></p>", "</p>").replace("<span>*</span>", ""))
                                    : (editValues.mandatory ? editValues["question_translations"][0]["question"].replace("\n", "<span>*</span>\n") : editValues["question_translations"][0]["question"].replace("</br></p>", "</p>").replace("<span>*</span>", "")),
                                subtitle: "",
                                info: editValues["helperText"]
                                    ? editValues["helperText"].replace("</br></p>", "</p>")
                                    : editValues["question_translations"][0]["info"]
                            },
                        ],
                    };
                    setLoading(true);
                    const response = await MyAxiosInstance.put(
                        `${BASE_PATH}/question/${showEditQuestion}`,
                        questionBody
                    );
                    if (isSummary) {
                        getSummary();
                    } else {
                        getSections();
                    }
                    setShowEditQuestion(-999);
                    setShowSnackbarEdit(true)
                } catch (e) {
                    setLoading(true);
                    if (hasWhiteSpace(editValues["variable_name"])) {
                        if (showMoreInfo) setShowMoreInfo(false)
                        setShowErrorVarSpace(true)
                    } else {
                        if (showMoreInfo) setShowMoreInfo(false)
                        setShowErrorVarName(true)
                    }

                }
            } else {
                setShowMoreInfo(true);
                setShowErrorVar(false)
            }
        }
    };

    const handleAddAnswer = () => {
        var currentList = editValues["answer_values"];
        if (
            currentList[currentList.length - 1]["answer"] !== "" &&
            currentList[currentList.length - 1]["value"] !== "" &&
            currentList[0]["answer"] !== "" &&
            currentList[0]["value"] !== ""
        ) {
            currentList.push({
                value: "",
                translations: [
                    {
                        locale: "en",
                        text: "",
                    },
                ],
            });
            setEditValues({ ...editValues, ["answersList"]: currentList });
            setShowError(false)
        } else {
            setShowError("Make sure all fields have been filled. The 'value' field must be a number.");

        }
    };

    const handleAddCondition = () => {
        var currentList = editValues["conditionalList"];
        if (
            currentList[currentList.length - 1]["variableId"] !== "" &&
            currentList[currentList.length - 1]["answerId"] !== "" &&
            currentList[0]["variableId"] !== "" &&
            currentList[0]["answerId"] !== ""
        ) {
            setVariableMap(variableMap + 1)
            currentList.push({ variableId: "", answerId: "", variable: "", answer: "", type: "" });
            setEditValues({ ...editValues, ["conditionalList"]: currentList });
            setShowError(false)
        } else {
            setShowError("Make sure you fill all the conditional.");
        }
    };

    const handleCloseDeleteConditional = () => {
        setOpenSnackDeleteConditional(false);
    };

    const handleCloseDeleteLess = () => {
        setOpenSnackDelete(false);
    };

    const handleCloseAddRepeat = () => {
        setOpenSnackAddRepeat(false);
    };

    const handleCloseAddSpace = () => {
        setOpenSnackAddSpace(false);
    };
    const handleDeleteAnswer = (index, e) => {
        var currentList = editValues["answer_values"];
        if (editValues["answer_values"].length <= 1) {
            setOpenSnackDelete(true)
        } else {
            setIsLoadingDelete(true)
            currentList.splice(index, 1)
            setEditValues({ ...editValues, ["answer_values"]: currentList });
        }
    }

    const handleDeleteConditional = (index, e) => {
        var currentList = editValues["conditionalList"];
        if (editValues["conditionalList"].length <= 1) {
            setOpenSnackDeleteConditional(true)
        } else {
            setVariableMap(variableMap - 1)
            setIsLoadingDelete(true)
            currentList.splice(index, 1)
            setEditValues({ ...editValues, ["conditionalList"]: currentList });
        }
    }

    const handleChangeConditionalVariable = (e, index) => {
        var a
        var currentList = editValues["conditionalList"];
        for (let i = 0; i < conditionalQuestions.length; i++) {
            if (conditionalQuestions[i].id === e.target.value) {
                a = conditionalQuestions[i].variable_name
                currentList[index]["variable"] = a
                currentList[index]["variableId"] = e.target.value
                currentList[index]["type"] = conditionalQuestions[i].type
            }
        }
        setEditValues({ ...editValues, ["conditionalList"]: currentList });
        setEditValues({ ...editValues, ["conditionalVariable"]: e.target.value });
        currentList[index]["variableId"] = e.target.value
        currentList[index]["variable"] = a
    };
    const handleChangeConditionalAnswer = (e, index, variable) => {
        var a
        //Buscar el indice y buscar el valor
        var currentList = editValues["conditionalList"];
        for (let i = 0; i < conditionalQuestions.length; i++) {
            if (conditionalQuestions[i].id === variable.variableId) {
                for (let j = 0; j < conditionalQuestions[i].answer_values.length; j++) {
                    a = conditionalQuestions[i].answer_values[j].value
                }
            }
        }
        currentList[index]["answerId"] = e.target.value
        currentList[index]["answer"] = a
        setEditValues({ ...editValues, ["conditionalList"]: currentList });

        setEditValues({ ...editValues, ["conditionalAnswer"]: e.target.value });
    }

    const handleChangeConditionalAnswerText = (e, index) => {
        //Buscar el indice y buscar el valor
        var currentList = editValues["conditionalList"];
        currentList[index]["answer"] = e.target.value
        setEditValues({ ...editValues, ["conditionalList"]: currentList });

        setEditValues({ ...editValues, ["conditionalAnswer"]: e.target.value });
    };
    function validateCalculation(calculation) {
        // eslint-disable-next-line no-useless-escape
        const re = /([-+]?[\w]*?[\w]+[\s]+[\/\+\-\*])+([\s]+[-+]?[\w]*?[\w]+)/g;
        return re.test(String(calculation).toLowerCase());
    }
    const handleSubmitQuestion = async () => {
        try {
            var section = (sections.find(x => x.id === sectionId))

            var operator = conditionalOperator ? " AND " : " OR "
            var options = [];
            var conditionalString = ""

            var hasSingleOrMultiple = false;
            if (questionsValuesList) {
                if (
                    editValues.type === questionTypes[QUESTION_SINGLE_CHOICE].name ||
                    editValues.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name
                ) {
                    hasSingleOrMultiple = true;
                }
                for (var i = 0; i < questionsValuesList.length; i++) {
                    options.push({
                        answerTitle: questionsValuesList[i].translations[0].text,
                        answerValue: parseInt(questionsValuesList[i].value),
                        answerTranslations: [
                            {
                                locale: "en",
                                text: questionsValuesList[i].translations[0].text,
                            },
                        ],
                    });
                }

            }
            if (editValues["hasConditional"]) {
                for (var j = 0; j < editValues["conditionalList"].length; j++) {
                    if (editValues["conditionalList"][j].type !== "RANGE" && editValues["conditionalList"][j].type !== "CALCULATION") {
                        var a = conditionalQuestions.findIndex(x => x.id === editValues["conditionalList"][j].variableId)
                        var b = conditionalQuestions[a].answer_values.findIndex(x => x.id === editValues["conditionalList"][j].answerId)
                        //conditionalString = conditionalString.concat(conditionalQuestions[a].variable_name + "=" + conditionalQuestions[a].answer_values[b].value,operator);
                        conditionalString += (conditionalQuestions[a].variable_name + "=" + conditionalQuestions[a].answer_values[b].value)
                        if (j + 1 !== editValues["conditionalList"].length) {
                            conditionalString += operator
                        }
                    } else {
                        var c = conditionalQuestions.findIndex(x => x.id === editValues["conditionalList"][j].variableId)
                        conditionalString += (conditionalQuestions[c].variable_name + "=" + editValues["conditionalList"][j].answer)
                        if (j + 1 !== editValues["conditionalList"].length) {
                            conditionalString += operator
                        }
                    }
                }
            }
            const questionBody = {
                order: (sectionId === sectionState) ? editValues.order : section.questions.length + 1,
                summary: editValues["summary"],
                sectionId: isSummary ? null : sectionId,
                variableName: editValues.variable_name,
                calculation: editValues.calculation,
                standarName: "",
                conditional: (!(editValues["conditional"] === null || editValues["conditional"] === "") || editValues["hasConditional"]) ? conditionalString : null,
                mandatory: editValues.mandatory ? 1 : 0,
                type: editValues.type,
                minValue:
                    editValues.type === questionTypes[QUESTION_NUMERIC].name ||
                        editValues.type === questionTypes[QUESTION_RANGE].name
                        ? editValues.min_value
                        : null,
                maxValue:
                    editValues.type === questionTypes[QUESTION_NUMERIC].name ||
                        editValues.type === questionTypes[QUESTION_RANGE].name
                        ? editValues.max_value
                        : null,
                units:
                    editValues.type === questionTypes[QUESTION_NUMERIC].name
                        ? editValues.units
                        : "",
                interval:
                    editValues.type === questionTypes[QUESTION_RANGE].name
                        ? parseInt(editValues.interval)
                        : null,
                answerOptions: hasSingleOrMultiple ? options : [],
                longText: editValues.long_text ? 1 : 0, //TODO Look naming on the backend
                questionTranslation: [
                    {
                        locale: "en",
                        question: editValues["questionTitle"]
                            ? (editValues.mandatory ? editValues["questionTitle"].replace("</p>", "<span>*</span></p>").replace("</h1>", "<span>*</span></h1>").replace("</h2>", "<span>*</span></h2>").replace("</h3>", "<span>*</span></h3>").replace("</h4>", "<span>*</span></h4>").replace("</h5>", "<span>*</span></h5>").replace("</h6>", "<span>*</span></h6>") : editValues["questionTitle"].replace("</br></p>", "</p>").replace("<span>*</span>", ""))
                            : (editValues.mandatory ? editValues["question_translations"][0]["question"].replace("\n", "<span>*</span>\n") : editValues["question_translations"][0]["question"].replace("</br></p>", "</p>").replace("<span>*</span>", "")),
                        subtitle: "",
                        info: editValues["helperText"]
                            ? editValues["helperText"].replace("</br></p>", "</p>")
                            : editValues["question_translations"][0]["info"]
                    },
                ],
            };
            setLoading(true);
            // const response = await QuestionsService.postQuestion(questionBody)
            const response = await MyAxiosInstance.put(
                `${BASE_PATH}/question/${showEditQuestion}`,
                questionBody
            );
            if (isSummary) {
                getSummary();
            } else {
                getSections();
            }
            handleEditQuestion();
            setShowSnackbarEdit(true)
        } catch (e) {
            setLoading(true);
            if (hasWhiteSpace(editValues["variable_name"])) {
                if (showMoreInfo) setShowMoreInfo(false)
                setShowErrorVarSpace(true)

            } else {
                if (showMoreInfo) setShowMoreInfo(false)
                setShowErrorVarName(true)
            }

        }
    };
    const handleClickSubmitMoreInfo = async () => {
        if (editValues["hasConditional"]
        ) {
            if (
                editValues["conditionalList"][0].variable === "" ||
                editValues["conditionalList"][0].answer === ""

            ) {
                return setShowConditionalError("Please select a conditional answer");
            }
        }
        if (
            editValues["type"] === questionTypes[QUESTION_SINGLE_CHOICE].name ||
            editValues["type"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name
        ) {
            var currentList = questionsValuesList;
            if (
                currentList[currentList.length - 1]["translations"][0].text !== "" &&
                currentList[currentList.length - 1]["value"] !== "" &&
                currentList[0]["translations"][0].text !== "" &&
                currentList[0]["value"] !== ""
            ) {
                return handleSubmitQuestion();
            } else {
                return setShowError("Make sure all fields have been filled. The 'value' field must be a number.");
            }
        } else if (editValues["type"] === questionTypes[QUESTION_RANGE].name) {
            if (
                editValues["min_value"] !== "" &&
                editValues["max_value"] !== "" &&
                editValues["interval"] !== ""
            ) {
                if (
                    parseInt(editValues["min_value"]) > parseInt(editValues["max_value"])
                ) {
                    return setShowError("Make sure the values are correct");
                } else if (
                    parseInt(editValues["interval"]) <= 0 ||
                    editValues["interval"] >
                    editValues["max_value"] - editValues["min_value"]
                ) {
                    return setShowError("Make sure the interval value is correct");
                }
                return handleSubmitQuestion();
            } else {
                return setShowError("Make sure all fields have been filled. The 'value' field must be a number.");
            }
        } else if (editValues["type"] === questionTypes[QUESTION_NUMERIC].name) {
            if (
                editValues["min_value"] !== "" &&
                editValues["max_value"] !== ""
            ) {
                if (
                    parseInt(editValues["min_value"]) > parseInt(editValues["max_value"])
                ) {
                    return setShowError("Make sure the values are correct");
                } else {
                    return handleSubmitQuestion();
                }
            } else {
                return setShowError("Make sure all fields have been filled. The 'value' field must be a number.");
            }
        } else if (
            editValues["type"] === questionTypes[QUESTION_CALCULATION].name
        ) {
            if (!validateCalculation(editValues["calculation"])) {
                return setShowError("The formula seems to have a bad format, please review the formula and try again");
            } else {
                return handleSubmitQuestion();
            }
        }

        handleSubmitQuestion();
    };

    const handleChangeAnswerOptions = (index, e) => {
        const { name, value } = e.target;

        var currentList = questionsValuesList;
        if (name === "answerTitle") {
            currentList[index]["translations"][0]["text"] = value;
            // currentList[index]['answerTranslations'][0]['text'] = value
        } else {
            currentList[index]["value"] = value;
        }

        setEditValues({ ...editValues, ["answersList"]: currentList });
    };

    const checkHasNextModal = () => {
        switch (true) {
            case editValues["type"] === questionTypes[QUESTION_INFO].name &&
                editValues["hasConditional"]:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_TEXT].name &&
                editValues["hasConditional"]:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_CALENDAR].name &&
                editValues["hasConditional"]:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_NUMERIC].name:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_RANGE].name:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_SINGLE_CHOICE].name ||
                editValues["type"] === questionTypes[QUESTION_MULTIPLE_CHOICE].name:
                setHasNextModal(true);
                break;
            case editValues["type"] === questionTypes[QUESTION_CALCULATION].name:
                setHasNextModal(true);
                break;
            default:
                setHasNextModal(false);
                break;
        }
    };

    return (
        <DialogUhda
            buttonColor={AppColors.PRIMARY}
            handleModalClose={() => {
                showMoreInfo ? setShowMoreInfo(false) :
                    setShowEditQuestion(-999)
            }}
            size={true}
            handleCloseButton={() => {
                setLoading(false)
                setShowEditQuestion(-999);
            }}
            closeButton={true}
            cancelButton={!showMoreInfo}
            textCancelButton={showMoreInfo ? "BACK" : CommonsTexts.CANCEL}
            textConfirmButton={
                hasNextModal
                    ? showMoreInfo
                        ? CommonsTexts.SAVE
                        : CommonsTexts.NEXT
                    : CommonsTexts.SAVE
            }
            handleShow={setShowEditQuestion}
            handleConfirm={
                showMoreInfo ? handleClickSubmitMoreInfo : handleClickSubmit
            }
            values={editValues}
            setValues={setEditValues}
            // title={TableTexts.ADD_TEAM_MEMBER_TITLE}
            title={"Edit question"}
            body={
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                >
                    {showMoreInfo ? (
                        <>
                            <Grid
                                container
                                xs={12}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                            >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <FormControl margin="normal" style={{ width: "45%" }}>
                                        <TextfieldUhda
                                            isDisabled={true}
                                            name="form"
                                            label={"Form"}
                                            value={formTitle}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" style={{ width: "45%" }}>
                                        <TextfieldUhda
                                            isDisabled={true}
                                            name="variableName"
                                            label={"Variable name"}
                                            value={editValues.variable_name}
                                        />
                                    </FormControl>
                                </Grid>

                                {editValues["hasConditional"]
                                    && (
                                        <FormControl margin="normal" style={{ width: "100%" }}>
                                            <Grid
                                                item
                                                direction="row"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Typography
                                                    style={{
                                                        color: AppColors.PRIMARY,
                                                        display: "inline",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    Condition{" "}
                                                </Typography>
                                                <IconButton style={{ color: AppColors.PRIMARY }}
                                                    onClick={handleClick}
                                                >
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                                <Popover
                                                    id={idPopover}
                                                    open={openPopover}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "right",
                                                    }}
                                                >
                                                    <Grid container
                                                        style={{ width: "700px", height: "120px", margin: "1em" }}>
                                                        <Typography
                                                            style={{ color: AppColors.PRIMARY }}>{LabelsForms.CONDITIONAL_POPOVER}</Typography>
                                                    </Grid>
                                                </Popover>
                                            </Grid>
                                        </FormControl>
                                    )}
                                {isLoadingDelete ? (
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <CircularProgress />
                                    </div>) :
                                    (<>{editValues["hasConditional"] && (
                                        <>
                                            {editValues["conditionalList"].map((variable, index) => (
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                >

                                                    {index >= 1 && <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <FormControl
                                                            margin="normal"
                                                            style={{ width: "20%" }}
                                                            variant="outlined"
                                                        >
                                                            <InputLabel id="label"
                                                                style={{ paddingTop: 0, marginTop: "-5px" }}
                                                            >Conditional</InputLabel>
                                                            <Select
                                                                labelId="label"
                                                                label="Operator"
                                                                id="select"
                                                                disabled={index >= 2}
                                                                value={conditionalOperator}
                                                                onChange={handleChangeConditionalOperator}
                                                            >
                                                                <MenuItem value={1}>
                                                                    AND
                                                                </MenuItem>
                                                                <MenuItem value={0}>
                                                                    OR
                                                                </MenuItem>
                                                                ))
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>}
                                                    <FormControl
                                                        margin="normal"
                                                        style={{ width: "35%" }}
                                                        variant="outlined"
                                                    >
                                                        <InputLabel id="label"
                                                            style={{ paddingTop: 0, marginTop: "-5px" }}
                                                        >Variable</InputLabel>
                                                        <Select
                                                            labelId="label"
                                                            label="Variable"
                                                            id="select"
                                                            value={editValues["conditionalList"][index].variableId}
                                                            onChange={(e) =>
                                                                handleChangeConditionalVariable(e, index)
                                                            }
                                                        >
                                                            {conditionalQuestions.map((question) => (
                                                                <MenuItem value={question["id"]}>
                                                                    {question["variable_name"]}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <Grid item style={{ width: "0" }}>=</Grid>
                                                    {(editValues["conditionalList"][index].type !== "CALCULATION" &&
                                                        editValues["conditionalList"][index].type !== "RANGE") ?
                                                        <FormControl
                                                            margin="normal"
                                                            style={{ width: "35%" }}
                                                            variant="outlined"
                                                        >
                                                            <InputLabel id="label"
                                                                style={{ paddingTop: 0, marginTop: "-5px" }}
                                                            >Answer</InputLabel>
                                                            <Select
                                                                labelId="label"
                                                                label="Answer"
                                                                id="select"
                                                                value={variable.answerId}
                                                                onChange={(e) =>
                                                                    handleChangeConditionalAnswer(e, index, variable)
                                                                }>
                                                                {editValues["conditionalList"][index].variableId !== "" && index === editValues["conditionalList"].length - 1 ? (
                                                                    (conditionalQuestions
                                                                        .find((x) => x.id === editValues["conditionalList"][variableMap].variableId) ?
                                                                        conditionalQuestions
                                                                            .find((x) => x.id === editValues["conditionalList"][variableMap].variableId)
                                                                        ["answer_values"].map((question) => (
                                                                            <MenuItem value={question["id"]}>
                                                                                {question["translations"][0]["text"]}
                                                                            </MenuItem>
                                                                        )) : <MenuItem value={0}>-</MenuItem>
                                                                    )
                                                                ) : (
                                                                    editValues["conditionalList"][index].variableId !== "" ? (
                                                                        conditionalQuestions
                                                                            .find((x) => x.id === editValues["conditionalList"][index].variableId)
                                                                        ["answer_values"].map((question) => (
                                                                            <MenuItem value={question["id"]}>
                                                                                {question["translations"][0]["text"]}
                                                                            </MenuItem>
                                                                        ))
                                                                    ) : <MenuItem value={0}>-</MenuItem>
                                                                )
                                                                }
                                                            </Select>
                                                        </FormControl> :
                                                        <FormControl margin="normal" style={{ width: "35%" }}>
                                                            <TextfieldUhda
                                                                label="Answer"
                                                                handleChange={(e) => handleChangeConditionalAnswerText(e, index)}
                                                                value={editValues["conditionalList"][index].answer}
                                                                isNumeric={false}
                                                            />

                                                        </FormControl>
                                                    }
                                                    {editValues["conditionalList"].length > 1 && <Button className={classes.button} style={{
                                                        marginLeft: "-9em",
                                                        marginRight: "-1em",
                                                        marginTop: "0.5em"
                                                    }}
                                                        onClick={(e) => handleDeleteConditional(index, e)}>
                                                        <DeleteIcon />
                                                    </Button>}
                                                </Grid>

                                            ))
                                            }
                                            <Typography
                                                onClick={handleAddCondition}
                                                style={{
                                                    font: "Roboto",
                                                    fontWeight: "bold",
                                                    color: AppColors.PRIMARY,
                                                    display: "inline",
                                                    marginRight: "20px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                + Add condition
                                            </Typography>
                                        </>
                                    )}</>)}
                                {showConditionalError && (
                                    <Alert
                                        severity="error"
                                        onClose={() => {
                                            setShowConditionalError();
                                        }}
                                        style={{ marginBottom: "30px" }}
                                    >
                                        {showConditionalError}
                                    </Alert>
                                )}
                                {editValues["type"] ===
                                    questionTypes[QUESTION_NUMERIC].name && (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <FormControl margin="normal" style={{ width: "30%" }}>
                                                <TextfieldUhda
                                                    name="min_value"
                                                    label={"Min. Value"}
                                                    handleChange={handleInputChange}
                                                    value={editValues.min_value}
                                                    isNumeric={true}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" style={{ width: "30%" }}>
                                                <TextfieldUhda
                                                    name="max_value"
                                                    label={"Max. Value"}
                                                    handleChange={handleInputChange}
                                                    value={editValues.max_value}
                                                    isNumeric={true}
                                                />
                                            </FormControl>
                                            <FormControl margin="normal" style={{ width: "30%" }}>
                                                <TextfieldUhda
                                                    name="units"
                                                    label={"Units"}
                                                    handleChange={handleInputChange}
                                                    value={editValues.units}
                                                />
                                            </FormControl>
                                        </Grid>
                                    )}
                                {editValues["type"] === questionTypes[QUESTION_RANGE].name && (
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <FormControl margin="normal" style={{ width: "30%" }}>
                                            <TextfieldUhda
                                                name="min_value"
                                                label={"Min. Value"}
                                                handleChange={handleInputChange}
                                                value={editValues.min_value}
                                                isNumeric={true}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" style={{ width: "30%" }}>
                                            <TextfieldUhda
                                                name="max_value"
                                                label={"Max. Value"}
                                                handleChange={handleInputChange}
                                                value={editValues.max_value}
                                                isNumeric={true}
                                            />
                                        </FormControl>
                                        <FormControl margin="normal" style={{ width: "30%" }}>
                                            <TextfieldUhda
                                                name="interval"
                                                label={"Interval"}
                                                handleChange={handleInputChange}
                                                value={editValues.interval}
                                                isNumeric={true}
                                            />
                                        </FormControl>
                                    </Grid>
                                )}
                                {editValues["type"] ===
                                    questionTypes[QUESTION_CALCULATION].name && (
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <FormControl margin="normal" style={{ width: "100%" }}>
                                                <TextfieldUhda
                                                    name="calculation"
                                                    label={"Calculation"}
                                                    handleChange={handleInputChange}
                                                    value={editValues.calculation}
                                                    isNumeric={false}
                                                />
                                            </FormControl>
                                            <Typography
                                                style={{
                                                    display: "inline",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {LabelsForms.CALCULATION_INFO}
                                            </Typography>
                                        </Grid>
                                    )}
                                {(editValues["type"] ===
                                    questionTypes[QUESTION_SINGLE_CHOICE].name ||
                                    editValues["type"] ===
                                    questionTypes[QUESTION_MULTIPLE_CHOICE].name) && (
                                        <Grid
                                            container
                                            direction="column"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <FormControl margin="normal" style={{ width: "100%" }}>
                                                <Grid
                                                    item
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                >
                                                    <Typography
                                                        onClick={handleAddAnswer}
                                                        style={{
                                                            display: "inline",
                                                            marginRight: "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        Answer options{" "}
                                                    </Typography>
                                                    <Typography
                                                        onClick={handleAddAnswer}
                                                        variant="h6"
                                                        style={{ display: "inline", cursor: "pointer" }}
                                                    >
                                                        {" "}
                                                        +
                                                    </Typography>
                                                </Grid>
                                            </FormControl>
                                            {isLoadingDelete ? (
                                                <div style={{ display: "flex", justifyContent: "center" }}>
                                                    <CircularProgress />
                                                </div>) :
                                                (<>{questionsValuesList &&
                                                    questionsValuesList.map((answer, index) => (
                                                        <Grid
                                                            container
                                                            direction="row"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <FormControl margin="normal" style={{ width: "40%" }}>
                                                                <TextfieldUhda
                                                                    name={"answerTitle"}
                                                                    label={`Answer ${index + 1}`}
                                                                    handleChange={(e) =>
                                                                        handleChangeAnswerOptions(index, e)
                                                                    }
                                                                    value={answer.translations[0].text}
                                                                />
                                                            </FormControl>
                                                            <FormControl margin="normal" style={{ width: "40%" }}>
                                                                <TextfieldUhda
                                                                    name={"answerValue"}
                                                                    label={`Value ${index + 1}`}
                                                                    handleChange={(e) =>
                                                                        handleChangeAnswerOptions(index, e)
                                                                    }
                                                                    value={answer.value}
                                                                    isNumeric={true}
                                                                    shrink={true}
                                                                />
                                                            </FormControl>
                                                            <Button style={{
                                                                marginLeft: "-9em",
                                                                marginRight: "-1em",
                                                                marginTop: "0.5em"
                                                            }}
                                                                onClick={(e) => handleDeleteAnswer(index, e)}>
                                                                <DeleteIcon />
                                                            </Button>
                                                        </Grid>
                                                    ))
                                                }</>)}
                                        </Grid>
                                    )}
                                {showError && (
                                    <Alert
                                        severity="error"
                                        onClose={() => {
                                            setShowError();
                                        }}
                                        style={{ marginBottom: "30px" }}
                                    >
                                        {showError}
                                    </Alert>
                                )}
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid
                                item
                                xs={5}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormControl margin="normal" style={{ width: "100%" }}>
                                    <TextfieldUhda
                                        isDisabled={true}
                                        name="form"
                                        handleChange={handleInputChange}
                                        label={"Form"}
                                        value={formTitle}
                                    />
                                </FormControl>
                                <FormControl margin="normal" style={{ width: "100%" }}>
                                    <TextfieldUhda
                                        isDisabled={true}
                                        name="order"
                                        handleChange={handleInputChange}
                                        label={"Order"}
                                        value={editValues.order}
                                    />
                                </FormControl>
                                {/* <FormControl
                                    margin="normal"
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                >
                                    <InputLabel id="label"
                                        style={{ paddingTop: 0, marginTop: "-5px" }}
                                    >Section</InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Section"
                                        id="select"
                                        value={sectionId}
                                        onChange={handleChangeSectionId}
                                        error={sectionId === "" && showError}
                                    >
                                        {sections.map((section) => (
                                            <MenuItem
                                                value={section.id}>{section.section_translations[0].title}</MenuItem>

                                        ))}

                                    </Select>
                                </FormControl> */}

                                <FormControl margin="normal" style={{ width: "100%" }}>
                                    <TextfieldUhda

                                        name="variable_name"
                                        handleChange={handleInputChange}
                                        label={"Variable name"}
                                        value={editValues["variable_name"]}
                                        maxLength={30}
                                        error={(editValues["variable_name"] === "" && showError) || showErrorVarName || showErrorVarSpace || showErrorVar}
                                        helperText={
                                            (showError &&
                                                editValues["variable_name"] === "" &&
                                                "Make sure to fill the variable name") ||
                                            (showErrorVarName && "This variable name is already being used in this form. Please, choose a different one") ||
                                            (showErrorVarSpace && "Variable names do not support spaces. Please, write it again with no spaces. You may use symbols like - and _ to make the variable name more legible") ||
                                            (showErrorVar && "Variable name only supports alphanumeric characters")

                                        }
                                        inputProps={{
                                            endAdornment: ((showError && editValues["variable_name"] === "") || (showErrorVarName) || (showErrorVarSpace) || (showErrorVar)) && (
                                                <InputAdornment position="end">
                                                    <ErrorIcon style={{ color: AppColors.RED }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                                <FormControl
                                    margin="normal"
                                    style={{ width: "100%" }}
                                    variant="outlined"
                                >
                                    <InputLabel id="label"
                                    >Question type</InputLabel>
                                    <Select
                                        labelId="label"
                                        label="Question type"
                                        id="select"
                                        value={questionTypesByName[editValues.type].id}
                                        onChange={handleChangeQuestionType}
                                        error={editValues["type"] === "" && showError}
                                    >
                                        <MenuItem value={QUESTION_INFO}>Info</MenuItem>
                                        <MenuItem value={QUESTION_TEXT}>Text</MenuItem>
                                        <MenuItem value={QUESTION_CALENDAR}>Date</MenuItem>
                                        <MenuItem value={QUESTION_NUMERIC}>Numerical</MenuItem>
                                        <MenuItem value={QUESTION_RANGE}>Scale</MenuItem>
                                        <MenuItem value={QUESTION_SINGLE_CHOICE}>
                                            Single select
                                        </MenuItem>
                                        <MenuItem value={QUESTION_MULTIPLE_CHOICE}>
                                            Multiple select
                                        </MenuItem>
                                        <MenuItem value={QUESTION_CALCULATION}>
                                            Calculation
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <a style={{ fontSize: "14px", color: AppColors.ACCENT }}
                                    href={LinksInfo.QUESTION_TYPE}
                                    target="_blank" rel="noreferrer">{CommonsTexts.LEARN_MORE}</a>
                                {editValues.type == questionTypes[QUESTION_TEXT].name && (

                                    <FormControl
                                        style={{ width: "100%", alignItems: "flex-start" }}
                                        margin="normal"
                                    >
                                        <FormSwitchUhda
                                            marginLeft={0}
                                            label="Long text"
                                            value={editValues.long_text}
                                            handleChange={handleOnLongTextChange}
                                        />
                                    </FormControl>
                                )}
                                {editValues.type !== questionTypes[QUESTION_INFO].name && (
                                    <FormControl
                                        margin="normal"
                                        style={{ width: "100%", alignItems: "flex-start" }}
                                    >
                                        <FormSwitchUhda
                                            label="Mandatory"
                                            value={editValues.mandatory}
                                            handleChange={changeMandatory}
                                        />
                                    </FormControl>
                                )}
                                <FormControl
                                    margin="normal"
                                    style={{ width: "100%", alignItems: "flex-start" }}
                                >
                                    <FormSwitchUhda
                                        label="Conditional"
                                        value={editValues["hasConditional"]}
                                        handleChange={handleOnConditionalChange}
                                    />
                                </FormControl>
                                {existSummary && <FormControl
                                    margin="normal"
                                    style={{ width: "100%", alignItems: "flex-start" }}
                                >
                                    <FormSwitchUhda
                                        label="Include in summary"
                                        value={editValues["summary"]}
                                        handleChange={handleOnSummaryChange}
                                    />
                                </FormControl>}
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <FormControl margin="normal" style={{ width: "100%" }}>
                                    <Typography>Question title</Typography>
                                    <MUIEditor editorState={editorState} onChange={onChange} config={config} />
                                </FormControl>
                                <FormHelperText error={editValues["questionTitle"] === "<p></p>\n" && showError} hidden={!(editValues["questionTitle"] === "<p></p>\n" && showError)} classes={{ root: classes.root, error: classes.error }}>
                                    <Grid container direction="row" >
                                        <Typography>{LabelsForm.ERROR_QUESTION_TITLE}</Typography>
                                        <InputAdornment >
                                            <ErrorIcon style={{ marginTop: "1em", color: AppColors.RED }} />
                                        </InputAdornment>
                                    </Grid>
                                </FormHelperText>
                                {/* <FormControl margin="normal" style={{ width: "100%" }}>
                                    <TextfieldMultilineUhda

                                        name="questionTitle"
                                        handleChange={handleInputChange}
                                        label={"Question title"}
                                        value={editValues["question_translations"][0]["question"]}
                                        maxLength={550}
                                        error={
                                            editValues["question_translations"][0]["question"] ===
                                            "" && showError
                                        }
                                        helperText={
                                            showError &&
                                            editValues["question_translations"][0]["question"] ===
                                            "" &&
                                            "Make sure to fill the title"
                                        }
                                        inputProps={{
                                            endAdornment: showError &&
                                                editValues["question_translations"][0]["question"] ===
                                                "" && (
                                                    <InputAdornment position="end">
                                                        <ErrorIcon style={{ color: AppColors.RED }} />
                                                    </InputAdornment>
                                                ),
                                        }}
                                    />
                                </FormControl> */}

                                <FormControl margin="normal" style={{ width: "100%" }}>
                                    <Typography>Helper text</Typography>
                                    <MUIEditor value={editValues["helperText"]} editorState={editorStateHelperText} onChange={onChangeHelperText} config={config} />
                                </FormControl>

                                {/* <FormControl margin="normal" style={{ width: "100%" }}>
                                    <TextfieldMultilineUhda

                                        name="helperText"
                                        handleChange={handleInputChange}
                                        label={"Helper text"}
                                        value={editValues["question_translations"][0]["info"]}
                                        maxLength={550}
                                    />
                                </FormControl> */}
                            </Grid>
                        </>
                    )}
                    <SnackBarUhda handleClose={handleCloseAddRepeat} severity={"error"}
                        message={SnackBarResponses.REPEATED_VARNAME}
                        openSnack={openSnackAddRepeat} />
                    <SnackBarUhda handleClose={handleCloseAddSpace} severity={"error"}
                        message={SnackBarResponses.SPACE_VARNAME}
                        openSnack={openSnackAddSpace} />
                    <SnackBarUhda handleClose={handleCloseDeleteLess} severity={"error"}
                        message={SnackBarResponses.ANSWER_OPTION}
                        openSnack={openSnackDelete} />
                </Grid>
            }
            show={showEditQuestion}
        />
    );
}

EditQuestionModal.propTypes = {
    handleClickNext: PropTypes.func.isRequired,
    setEditValues: PropTypes.func.isRequired,
    showEditQuestion: PropTypes.number.isRequired,
    setShowEditQuestion: PropTypes.func.isRequired,
    formId: PropTypes.number.isRequired,
    studyId: PropTypes.number.isRequired,
    changeMandatory: PropTypes.func.isRequired,
    hasNextModal: PropTypes.bool.isRequired,
    setHasNextModal: PropTypes.func.isRequired,
    questionsLength: PropTypes.number.isRequired,
    setLoading: PropTypes.func.isRequired,
};
