import React from "react";
import FormBuilderUhda from "../components/FormBuilderUhda/FormBuilderUhda";
import {mPalliativeStudy} from "../mocks/mPalliativeStudy";
import {formTypesEnum} from "../resources/FormEnums";

const EditFormPage = ({match}) => {
  const formType = formTypesEnum.EDIT;
  const formId = +match.params.id;
  const mPalliativeForm = Object.values(mPalliativeStudy.data).find(form => form.id === formId);

  return (
    <>
      <FormBuilderUhda createOrEdit={ formType } formData={ mPalliativeForm }/>
    </>
  )
}

export default EditFormPage;
