/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { MdDragHandle } from "react-icons/all";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button, Fade,
  Grid,
  IconButton, Menu, MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { AppColors } from "../../resources/AppColors";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  QUESTION_INFO,
  QUESTION_MULTIPLE_CHOICE,
  QUESTION_SINGLE_CHOICE,
  questionTypes,
  questionTypesByName
} from "../../resources/QuestionConstants";
import MyAxiosInstance from "../../utils/MyAxiosInstance";
import { BASE_PATH } from "../../resources/ApiUrls";
import SnackBarUhda from "../SnackBarUhda";
import { menuOptions, SnackBarResponses, TableTexts } from "../../locale/en";
import IconProviderUhda from "../IconProviderUhda";
import Icons from "../../resources/Icons";
import parse from "html-react-parser"
import TextUdha from "../TextUdha";
import { textType } from "../../resources/AppTexts";
import SearchBar from "material-ui-search-bar"

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
    borderCollapse: "separate",
    borderSpacing: "0px 4px",
  },
  tableHead: {
    backgroundColor: AppColors.PRIMARY_WITH_OPACITY
  },
  tableCell: {
    height: "auto",
    paddingLeft: 0,
    fontSize: "16px",
    color: AppColors.PRIMARY,

  },
  tableCellInit: {
    padding: 0,
    paddingLeft: "16px",
    borderTopLeftRadius: "5px!important",
    borderBottomLeftRadius: "5px!important",
    borderLeft: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,
  },
  tableCellDraggable: {
    padding: 0,
    paddingRight: 20,
    borderRadius: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,
    whiteSpace: "normal",
    wordBreak: "break-word"
  },
  tableCellFinish: {
    padding: 0,
    borderTopRightRadius: "5px!important",
    borderBottomRightRadius: "5px!important",
    borderRight: "2px solid #1D3972",
    borderTop: "2px solid #1D3972",
    borderBottom: "2px solid #1D3972",
    backgroundColor: AppColors.WHITE,
    color: AppColors.PRIMARY,

  },
  // eslint-disable-next-line camelcase
  switch_track: {
    backgroundColor: AppColors.SECONDARY,
  },
  // eslint-disable-next-line camelcase
  switch_base: {
    color: AppColors.SECONDARY,
    "&.Mui-disabled": {
      color: AppColors.PRIMARY_WITH_OPACITY
    },
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    }
  },
  // eslint-disable-next-line camelcase
  switch_primary: {
    "&.Mui-checked": {
      color: AppColors.PRIMARY
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: AppColors.PRIMARY,
    },
  },

}));

/**
 * @component
 * Component to create a Drag and Drop Table
 *
 * @param headCells: array of dictionaries of the id and label for the table
 * @param userData: json of the data that the user use
 *
 * @constructor
 * <DragAndDropUhda headCells={headCells} userData={questions} />
 */
const DragAndDropUhda = ({
  formId,
  headCells,
  userData,
  getSections,
  loading,
  setLoading,
  setShowDuplicateQuestion,
  setShowDeleteQuestion,
  setShowEditQuestion,
  handleClickEdit,
  canEdit,
  canDelete,
  isSummary,
  getSummary
}) => {
  const [tableData, setTableData] = useState([])
  const [checked, setChecked] = useState(userData)
  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [openSnackDelete, setOpenSnackDelete] = React.useState(false);
  const [openSnackDuplicate, setOpenSnackDuplicate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState([]);
  const [searched, setSearched] = useState();
  //const open = Boolean(anchorEl);

  const handleActionClick = (id, event) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget })

  }

  // eslint-disable-next-line no-unused-vars
  const handleActionClose = (id, event) => {
    delete anchorEl[id]
    setAnchorEl({ ...anchorEl, [id]: null })

  }
  const handleStop = (event, dragElement) => {
    setX(dragElement.x)
    setY(dragElement.y)
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClosePermissionsDelete = () => {
    setOpenSnackDelete(false);
  };
  const getData = async () => {
    var response = Object.values(userData)
    var data = []
    response.forEach((res) => {
      data.push(res)
    })
    setTableData(data)
  }
  const [questions, setQuestions] = useState(Object(userData));

  useEffect(() => {
    getData()

  }, [])

  const handleDelete = async (id) => {
    if (canDelete) {
      setShowDeleteQuestion(id)
    } else {
      setOpenSnackDelete(true)
    }

  }
  const handleDuplicate = async (id) => {
    if (canEdit) {
      setShowDuplicateQuestion(id)
    } else {
      setOpenSnackDuplicate(true)
    }

  }

  const changeState = (memberIdx, mandatoryState) => {
    const updatedMandatory = checked[memberIdx];
    updatedMandatory.mandatory = mandatoryState;

    const newMandatory = [...checked];
    newMandatory[memberIdx] = updatedMandatory;
    setChecked(newMandatory);
  };

  const handleDragEnd = async (results) => {
    if (!results.destination) return;
    let response = ""
    const tempQuestion = Array.from(questions);
    const [selectedQuestion] = tempQuestion.splice(results.source.index, 1);
    tempQuestion.splice(results.destination.index, 0, selectedQuestion);
    setQuestions(tempQuestion);
    var questionsBody = { "questions": [] }
    for (var i = 0; i < tempQuestion.length; i++) {
      questionsBody.questions.push(
        {
          "questionId": tempQuestion[i].id,
          "order": i + 1
        }
      )
    }
    try {
      if (isSummary) {
        response = await MyAxiosInstance.put(`${BASE_PATH}/form/${formId}/summary-order`, questionsBody)
        setLoading(true)
        getSummary()
      } else {
        response = await MyAxiosInstance.put(`${BASE_PATH}/form/${formId}/questions`, questionsBody)
        setLoading(true)
        getSections()
      }
      // eslint-disable-next-line no-empty
    } catch (e) {

    }
  }

  const handleChangeMandatory = async (index) => {
    // setLoading(true)
    var questionData = questions[index]
    var newQuestionData = questions
    if (questionData["mandatory"] === 0) {
      questionData["mandatory"] = 1
    } else {
      questionData["mandatory"] = 0
    }
    newQuestionData[index] = questionData

    var options = []
    var hasSingleOrMultiple = false
    if (questionData.answer_values) {
      if (questionData.type === questionTypes[QUESTION_SINGLE_CHOICE].name || questionData.type === questionTypes[QUESTION_MULTIPLE_CHOICE].name) {
        hasSingleOrMultiple = true
      }
      for (var i = 0; i < questionData.answer_values.length; i++) {
        options.push(
          {
            "answerTitle": questionData.answer_values[i].translations[0].text,
            "answerValue": parseInt(questionData.answer_values[i].value),
            "answerTranslations": [
              {
                "locale": "en",
                "text": questionData.answer_values[i].translations[0].text
              }
            ]
          }
        )
      }
    }
    try {
      const questionBody = {
        "order": questionData.order,
        "formId": questionData.form_id,
        "sectionId": questionData.section_id,
        "summary": questionData.summary,
        "variableName": questionData.variable_name,
        "standarName": "",
        "conditional": questionData.conditional,
        "mandatory": questionData.mandatory,
        "type": questionTypesByName[questionData.type].name,
        "minValue": questionData.min_value,
        "maxValue": questionData.max_value,
        "units": questionData.units,
        "interval": questionData.interval,
        "answerOptions": hasSingleOrMultiple ? options : null,
        "questionTranslation": [
          {
            "locale": "en",
            "question": questionData["question_translations"][0]["question"],
            "subtitle": "",
            "info": questionData["question_translations"][0]["info"]
          }
        ]
      }
      const response = await MyAxiosInstance.put(`${BASE_PATH}/question/${questionData.id}`, questionBody)
      getSections()
      setShowEditQuestion(-999)
    } catch (e) {
      setLoading(false)

    }
    getSections()
  }

  const classes = useStyles();

  const requestSearch = (searchedVal) => {
    const filteredRows = Object(userData).filter((row) => {
      return (row.variable_name.toLowerCase().includes(searchedVal.toLowerCase()) || row.question_translations[0].question.toLowerCase().includes(searchedVal.toLowerCase()))
    });
    setQuestions(filteredRows);
  };
  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };
  return (
    <>

      <TableContainer>
        <SearchBar
          style={{ marginRight: "20px" }}
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Table className={classes.table} aria-label={"simple table"} style={{ paddingRight: "20px" }}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell className={classes.tableCell} key={headCell.id}> {headCell.label}</TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <Droppable droppableId="tbody">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}
                  className={classes.tableCell}>
                  {(questions && !loading) && questions.map((question, index) => (
                    <Draggable draggableId={isSummary ? (canEdit && question.order_summary.toString()) : (canEdit && question.order.toString())}
                      index={canEdit && index}
                      key={canEdit && question.id}>
                      {(provided) => (
                        <TableRow
                          ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps} >
                          <TableCell className={classes.tableCellInit} style={{ width: 50 }}>
                            <MdDragHandle size={25} />
                          </TableCell>
                          {/* {!isSummary && <TableCell
                            className={classes.tableCellDraggable}>{question.order}</TableCell>} */}
                          <TableCell
                            className={classes.tableCellDraggable}>{question.variable_name}</TableCell>
                          <TableCell
                            className={classes.tableCellDraggable}>{questionTypesByName[question.type].label}</TableCell>
                          <TableCell
                            className={classes.tableCellDraggable}>{parse((question.question_translations[0].question).replace("<span>*</span>", ""))}</TableCell>
                          <TableCell className={classes.tableCellDraggable}>
                            {question.type === questionTypes[QUESTION_INFO].name ?
                              <Switch
                                checked={question.mandatory}
                                onChange={() => handleChangeMandatory(index)}
                                classes={{
                                  track: classes.switch_track,
                                  switchBase: classes.switch_base,
                                  colorPrimary: classes.switch_primary,
                                }}
                                disabled
                              />
                              :
                              <Switch
                                checked={question.mandatory}
                                onChange={() => handleChangeMandatory(index)}
                                classes={{
                                  track: classes.switch_track,
                                  switchBase: classes.switch_base,
                                  colorPrimary: classes.switch_primary,
                                }}
                              />
                            }
                          </TableCell>
                          <TableCell className={classes.tableCellDraggable}>
                            {question["conditional"] === null || question["conditional"] === "" ? "No" : "Yes"}
                          </TableCell>
                          {/* {!isSummary && <TableCell className={classes.tableCellDraggable}>
                            {question["summary"] === false || question["summary"] === "" ? "No" : "Yes"}
                          </TableCell>} */}
                          <TableCell className={classes.tableCellFinish} align='left'>
                            <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Button aria-controls="fade-menu" aria-haspopup="true"
                                onClick={e => handleActionClick(question.id, e)}>
                                <IconProviderUhda icon={<Icons.OPTIONS_HORIZONTAL
                                  color={AppColors.PRIMARY} style={{
                                    verticalAlign: "middle",
                                    display: "inline-flex",
                                    paddingRight: "4px"
                                  }} size="2rem" />} />
                              </Button>
                              <Menu
                                style={{
                                  boxShadow: "3px 3px 3px 1px rgba(0,0,0,.16)"
                                }}
                                color={AppColors.WHITE}
                                id={question.id}
                                anchorEl={anchorEl[question.id]}
                                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                keepMounted
                                open={Boolean(anchorEl[question.id])}
                                onClose={e => handleActionClose(question.id, e)}
                                TransitionComponent={Fade}>
                                {canEdit && (
                                  <MenuItem style={{ color: AppColors.PRIMARY }}
                                    onClick={() => {
                                      handleClickEdit(question.id);
                                    }}> {menuOptions.EDIT} </MenuItem>)}
                                {canEdit && (
                                  <MenuItem style={{ color: AppColors.PRIMARY }}
                                    onClick={() => {
                                      handleDuplicate(question.id);
                                    }}> {menuOptions.DUPLICATE} </MenuItem>)}
                                {canDelete &&
                                  <MenuItem style={{ color: AppColors.PRIMARY }}
                                    onClick={() => {
                                      handleDelete(question.id);
                                    }}> {menuOptions.DELETE}</MenuItem>}
                              </Menu>
                            </Grid>

                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  <SnackBarUhda handleClose={handleClosePermissionsDelete} severity={"error"}
                    message={SnackBarResponses.NOT_PERMISSION}
                    openSnack={openSnackDelete} />
                  <SnackBarUhda handleClose={handleCloseDelete} message={SnackBarResponses.FORM_DELETED}
                    openSnack={openDelete} />

                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>

          </Table>

          {questions.length === 0 &&
            <Grid
              style={{ height: "200px" }}
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <TextUdha
                type={textType.TITLE}
                color={AppColors.ACCENT}
                text={TableTexts.NO_ELEMENTS}
              />
            </Grid>
          }

        </DragDropContext>
      </TableContainer>
      <Grid container justifyContent={"flex-end"} style={{ paddingRight: "1.5em" }}>
        <Typography style={{ color: AppColors.PRIMARY }}>
          Total: {questions.length} questions
        </Typography>
      </Grid>
    </>
  )

}

export default DragAndDropUhda
