/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import GridFormsUhda from "../components/GridFormsUhda";
import { Grid } from "@material-ui/core";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH, FORMS_STUDY } from "../resources/ApiUrls";
import DrawerUhda from "../components/DrawerUhda";

/**
 * @Page
 * Page to show all the forms of the study
 */
const FormsPage = ({ drawer }) => {
  const [forms, setForms] = useState();
  // const id = location.state.detail
  // const permissions = location.state.permissions

  const permissions = useRef();
  const id = useRef();
  const [devicesSize, setDevicesSize] = useState("280px")
  const [cardWidth, setCardWidth] = useState("80%")

  const [study, setStudy] = useState();

  //This function retrives the stored permissions and id from local storage
  const retriveData = () => {
    const newItem = JSON.parse(localStorage.getItem("dashBoardSection"));
    permissions.current = newItem.permissions;
    id.current = newItem.id;
  }

  useEffect(() => {

    retriveData();

  }, [])

  //Function to get the data of the study from the id
  const getStudy = async () => {
    try {
      const response = await MyAxiosInstance.get(`${BASE_PATH}/study/${id.current}`);
      setStudy(response.data.data)
    } catch (err) {
      setStudy(err.message)
    }
  }

  //Function to get all the forms of the study
  const getForms = async () => {
    try {
      const response = await MyAxiosInstance.get(FORMS_STUDY(id.current));
      setForms(response.data.data.items)

    } catch (err) {

      setForms(err.message)
    }
  }

  useEffect(() => {

    getForms()
    getStudy()
  }, []);

  const [open, setOpen] = useState(drawer);//false

  useEffect(() => {
    setOpen(drawer)
  }, [drawer])

  function debounce(fn, ms) {
    //This will run the code on every 1 second
    let timer
    return _ => {
      clearTimeout(timer)
      // eslint-disable-next-line no-shadow
      timer = setTimeout(_ => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    };
  }
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      //give the paddingLeft size base on drawer open or closed and window size
      if (open) {
        if (window.innerWidth >= 1000) {
          setDevicesSize("160px")
          setCardWidth("80%")
        } else {
          setDevicesSize("10px")
          setCardWidth("100%")
        }

      } else {

        if (window.innerWidth >= 640 && window.innerWidth <= 1680) {
          setDevicesSize("200px")
          setCardWidth("80%")
        
        } else if (window.innerWidth <= 600) {

          setDevicesSize("120px")
          setCardWidth("70%")

        } else {
          //alert(window.innerWidth)
        }
      }

    }, 300)

    // Add event listener to listen for window sizes 
    window.addEventListener("resize", debouncedHandleResize);
    // Call handler right away so state gets updated with initial window size

    debouncedHandleResize()
    return _ => {
      window.removeEventListener("resize", debouncedHandleResize)

    }

  }, [open])

  return (
    <>
      {study &&
        <DrawerUhda id={id.current} drawer={open} select={1} settings={study.permissions} />}
      <Grid container alignItems="center" style={{ marginLeft: devicesSize, maxWidth: cardWidth,marginTop: "-5em"}}
        direction={"column"}>

        {forms && <GridFormsUhda studies={study} forms={forms} id={id.current} permissions={permissions.current} drawer={open} />}

      </Grid>
    </>
  )
}

export default FormsPage;
