/* eslint-disable no-unused-vars */
/* eslint-disable react/no-children-prop */
import React, { useContext, useEffect, useState } from "react";
import { AppBar, Box, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { AppColors } from "../resources/AppColors";
import { StorageManager } from "../utils";
import AuthContext from "../context/authentication/authContext"
import { makeStyles } from "@material-ui/core/styles";
import NavbarUhda from "../components/NavbarUhda";
import CardUhda from "../components/CardUhda";
import ButtonFilledUhda from "../components/ButtonFilledUhda";
import { CommonsTexts, SnackBarResponses, TableTexts } from "../locale/en";
import IconProviderUhda from "../components/IconProviderUhda";
import Icons from "../resources/Icons";
import TextUhda from "../components/TextUdha";
import { textType } from "../resources/AppTexts";
import MyAxiosInstance from "../utils/MyAxiosInstance";
import { BASE_PATH } from "../resources/ApiUrls";
import SnackBarUhda from "../components/SnackBarUhda";
import ErrorIcon from "@material-ui/icons/Error";
import taglineIcon from "../img/UhDa_Studies.png"

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: AppColors.BACKGROUND
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,

    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL
  },

  textFieldLabelWithText: {

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        marginTop: "4px",
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,

    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE

    }, "& label.Mui-focused": {
      color: AppColors.PRIMARY,
      backgroundColor: AppColors.WHITE,
    },

    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,

  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon: {
    height: "50px",
  },
}));

/**
 * @Page
 * Page to change the password of the user when is not logged
 */
export default function ChangePass(props) {

  const storageManager = new StorageManager();

  const [checked, setChecked] = useState(false);
  const [username, setUsername] = useState(storageManager.getUsername());
  const [password, setPassword] = useState(storageManager.getPwd());
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [openChanged, setOpenChanged] = useState()
  const authContext = useContext(AuthContext)
  const { message, authenticated, login, checkAuth, removeFirstTime } = authContext;

  const [showValidationAlert, setShowValidationAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showNewPwdError, setShowNewPwdError] = React.useState(false);
  const [showRepeatPwdError, setShowRepeatPwdError] = React.useState(false);
  const [showOldPwdError, setShowOldPwdError] = React.useState(false);
  const [showPasswordMatchError, setShowPasswordMatchError] = React.useState(false);
  const [isTypingOldP, setIsTypingOldP] = useState(false)
  const [isTypingNewP, setIsTypingNewP] = useState(false)
  const [isTypingRepeatP, setIsTypingRepeatP] = useState(false)

  useEffect(() => {
    checkAuth()
    if (authenticated) {
      props.history.push("/home")
    }
    if (message) {
      setAlertMessage(message)
      setShowValidationAlert(true)
    }
  }, [message, authenticated, props.history])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleCloseChanged = () => {
    setOpenChanged(false);
  };

  const handleCloseMatch = () => {
    setShowPasswordMatchError(false);
  };

  const changePassword = async () => {
    if (newPassword.length < 8) {
      setShowNewPwdError(true)
    }
    if (repeatPassword.length < 8) {
      setShowRepeatPwdError(true)
    } else {

      setShowNewPwdError(false)
      setShowRepeatPwdError(false)
      if (password === oldPassword) {
        setShowOldPwdError(false)

        if (newPassword === repeatPassword) {
          setShowPasswordMatchError(false)

          var body = {
            "current_password": oldPassword,
            "password": newPassword,
            "password_confirmation": repeatPassword
          }
          try {
            await MyAxiosInstance.put(`${BASE_PATH}/auth/password`, body, {
              headers: {
                "Authorization": `Bearer ${storageManager.getTokenTemporal()}`
              }
            });
            setOpenChanged(true);
            removeFirstTime()
            setTimeout(() => {
              props.history.push("/login")
            }, 1000)
            // eslint-disable-next-line no-empty
          } catch (error) {

          }
        } else {
          setShowPasswordMatchError(true)
        }
      } else {
        setShowOldPwdError(true);
      }
    }

  }

  //Function that will be called when the user wants to go to the forgot Page
  const handleForgot = async (e) => {
    props.history.push("/forgot")
  }

  const classes = useStyles();

  const oldPasswordChange = (e) => {
    setOldPassword(e.target.value)
    setIsTypingOldP(true)
  }

  const newPasswordChanged = (e) => {
    setNewPassword(e.target.value)
    setIsTypingNewP(true)

  }

  const repeatPasswordChanged = (e) => {
    setRepeatPassword(e.target.value)
    setIsTypingRepeatP(true)

  }

  return (
    <Grid container style={{ minHeight: "40%" }} alignItems={"center"}
      direction={"column"}>
      <Grid item>
        <NavbarUhda open={false} setOpen={false} setSelected={null} logged={false} />
      </Grid>
      <Grid item style={{ marginTop: "15vh", marginBottom: "15vh" }}>
        <CardUhda bg={AppColors.WHITE} children={
          <Grid
            container
            item
            alignItems="center"
            direction="column"
            justifyContent="center"
            className="card"
          >
            <div
              style={{ margin: "5vh", display: "flex", flexDirection: "column", minWidth: 300 }}
            >
              <div style={{ textAlign: "center" }}>
                <IconProviderUhda icon={<Icons.VERIFIED style={{ fontSize: 50 }} />} />
                <TextUhda type={textType.TITLE} text={CommonsTexts.WELCOME.toUpperCase() + "!"} />
                <TextUhda type={textType.SUBTITLE} text={CommonsTexts.PLEASE_CHANGE_PASS} />
              </div>

              <TextField
                onChange={(e) => oldPasswordChange(e)}
                type="password"
                label="Old Password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={isTypingOldP ? classes.textFieldLabelWithText : classes.textFieldLabel}

                error={showOldPwdError}
                helperText={(showOldPwdError === true) && SnackBarResponses.OLD_PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showOldPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}
              />

              <TextField
                onChange={(e) => newPasswordChanged(e)}
                type="password"
                label="New password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={isTypingNewP ? classes.textFieldLabelWithText : classes.textFieldLabel}

                error={showNewPwdError}
                helperText={(showNewPwdError === true) && TableTexts.PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showNewPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}

              />
              <TextField
                onChange={(e) => repeatPasswordChanged(e)}
                type="password"
                label="Repeat new password"
                margin="normal"
                variant="outlined"
                //className={classes.textFieldLabel}
                className={isTypingRepeatP ? classes.textFieldLabelWithText : classes.textFieldLabel}

                error={showRepeatPwdError}
                helperText={(showRepeatPwdError === true) && TableTexts.PASSWORD_ERROR}
                inputProps={{
                  endAdornment: (showRepeatPwdError === true) &&
                    <InputAdornment position="end"><ErrorIcon
                      style={{ color: AppColors.RED }} /></InputAdornment>,
                }}
              />

              <Grid container style={{ paddingTop: "20px" }} spacing-xs-1 wrap="nowrap" spacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Grid container item alignItems="center" justify="center">
                  <ButtonFilledUhda text={CommonsTexts.CHANGE_PASS} onClick={changePassword} margin={"0.7em"} />
                </Grid>
              </Grid>
              <SnackBarUhda handleClose={handleCloseChanged} message={"Changed password successfully!"}
                openSnack={openChanged} />
              <SnackBarUhda severity={"error"} handleClose={handleCloseMatch}
                message={SnackBarResponses.NEW_PASSWORD_MATCH}
                openSnack={showPasswordMatchError} />

            </div>
          </Grid>} />

      </Grid>
      <AppBar position="fixed" className={classes.appBar}
        style={{
          top: "auto",
          paddingBottom: "1em",
          bottom: 0,
          color: AppColors.WHITE,
          background: AppColors.PRIMARY
        }}>
        <Box style={{ marginBottom: 0 }} display="flex" justifyContent="center" m={3}>
          <Box style={{ marginTop: "0.8em", width: "38em", marginLeft: 0, marginRight: 0 }} mx="auto">
            <Typography style={{ marginLeft: "20em" }}>{CommonsTexts.ABOUT}</Typography>
            <Typography>{CommonsTexts.ABOUT_TEXT}</Typography>

          </Box>
          <Box style={{ margin: 0 }} direction="row" display="flex" justifyContent="center" m={3}
            className="bottom-bar"
          >
            <Typography style={{ marginTop: "0.5em" }} >Powered by </Typography>
            <img alt='icon' className={classes.icon} src={taglineIcon} />
          </Box>
          {/*  <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/UhD-Studies-User-Guide-b0d41862529a4d8196d56370465c7250'
              target="_blank" rel="noreferrer">{CommonsTexts.USER_GUIDE}</a>
          </Box> */}
          {/* <Box mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://right-chocolate-d0c.notion.site/Terms-of-Use-f5a590057a7f46deaa854af7cc336dc2'
              target="_blank" rel="noreferrer">{CommonsTexts.TERMS}</a>
          </Box> */}
          <Box style={{ marginTop: "0.8em" }} mx="auto">
            <a style={{ color: AppColors.WHITE }}
              href='https://docs.google.com/document/u/1/d/e/2PACX-1vSz4FmaCBAcvcuZ1Kw9eB7nmCgSS0lwaY5C3rMN9vN3Xd7Mhva9U8ffdGx-e1vIqgCn0P7KtlyT27b_/pub'
              target="_blank" rel="noreferrer">{CommonsTexts.PRIVACY}</a>
          </Box>
        </Box>

      </AppBar>
    </Grid>
  )
}
