import React from "react"
import PropTypes from "prop-types";
import Select from "react-select"
import {FormControl, InputLabel, MenuItem} from "@material-ui/core";
import Icons from "../../resources/Icons";
import {AppColors} from "../../resources/AppColors";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2"
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
  },
}));

const FormSelectorUhda = ({value, handleChange, options, label}) => {
//TODO review by Agusti
  const classes = useStyles();
  return (
    <FormControl className={ classes.select } variant="outlined" margin='normal' style={ {width: "20%"} }>
      <InputLabel className={ classes.select } id="demo-simple-select-label">{label}</InputLabel>
      <Select className={ classes.select } IconComponent={ Icons.ARROW_DOWN }
        value={ value }
        onChange={ handleChange }
        label={ label }
      >
        {options.map(item =>
          (
            <MenuItem key={ item.key } style={ {color: AppColors.PRIMARY} }
              value={ item.value }>{item.label}</MenuItem>
          )
        )}
      </Select>
    </FormControl>
  )
}

FormSelectorUhda.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string,
}

export default FormSelectorUhda
