export const HIGHER_THAN = 1;
export const LOWER_THAN = 2;
export const EQUAL_TO = 3;
export const NOT_EQUAL_TO = 4;

export const validationTypes = {
  [HIGHER_THAN]: {
    label: "Higher than"
  },
  [LOWER_THAN]: {
    label: "Lower than"
  },
  [EQUAL_TO]: {
    label: "Equal to"
  },
  [NOT_EQUAL_TO]: {
    label: "Not equal to"
  }
};
