import React, {useState} from "react";
import {Button, Container, Grid, TextField} from "@material-ui/core";
import {formTypes, formTypesEnum} from "../../resources/FormEnums";
import {AppColors} from "../../resources/AppColors";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import PropTypes from "prop-types";
import {isQuestionTypeForVisibilityFormula} from "../../resources/QuestionOptions"
import QuestionBuilderUhda from "../QuestionBuilderUhda/QuestionBuilderUhda";
import DialogUhda from "../DialogUhda/DialogUhda";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

/**
 * @component
 * Component to create the builder for a form.
 *
 * @param createOrEdit: Enum to check if they're creating or editing a form
 * @param formData: JSON of the data that the user pass
 *
 * @constructor
 * <FormBuilderUhda createOrEdit={enum} formData={[{JSON}]}/>
 */
const FormBuilderUhda = ({createOrEdit, formData}) => {
  const formTypeValues = formTypes[createOrEdit];
  const [questions, setQuestions] = useState((formData && formData.fields) || []);
  const [showDeleteModalId, setShowDeleteModalId] = useState(null);

  // The first element of the array: false (the QuestionBuilderUhda modal is not shown); true (it is shown)
  // The second element of the array: positive number (id of the question to edit); negative number (create)
  const [showQuestionBuilder, setShowQuestionBuilder] = useState([false, -1]);

  // This is called when the user confirms the creation of a new question within the QuestionBuilderUhda modal
  const handleAddQuestion = (question) => {
    // When a new question is added, an id is autogenerated (max id between all the questions + 1)
    question.id = questions.length === 0 ? 0 : Math.max(...questions.map(q => q.id)) + 1;
    const newList = questions.concat(question);
    setQuestions(newList);
    setShowQuestionBuilder([false, -1]);
  }

  // This is called when the user edits a question of the form within the QuestionBuilderUhda modal
  const handleEditQuestion = question => {
    const newList = questions.map(item => {
      return item.id === question.id ? question : item;
    });
    setQuestions(newList);
  }

  // This is called when the user deletes a question of the form (until the user does not confirm to save the form
  // nothing is deleted/updated)
  const handleDeleteQuestion = questionId => {
    const newList = questions.filter(item => item.id !== questionId);
    setQuestions(newList);

    setShowDeleteModalId(null);
  }

  // Handle the action with the drag ends (updates the order of the questions in the list)
  const handleOnDragEnd = result => {
    if (!result.destination) return;

    const newList = [...questions];
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);

    setQuestions(newList);
  }

  // Handle the submission of the form when the submit button is clicked
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = () => {
    // TODO: Api request and redirect if successful
  }

  // Handle the submission of a question when the submit button in the QuestionBuilderUhda is clicked
  const handleSubmitQuestion = question => {
    //console.log("Question submitted:", question)
    question.id === undefined ? handleAddQuestion(question) : handleEditQuestion(question);
    setShowQuestionBuilder([false, -1]);
  }

  // Handle the visibility of the question builder modal
  const handleShowQuestionBuilder = show => setShowQuestionBuilder([show, -1]);

  // Retrieve all the variable names of all the questions of the current form
  const getAllVariableNames = () => questions
    .filter(q => q.internal_code && isQuestionTypeForVisibilityFormula(q.type))
    // eslint-disable-next-line camelcase
    .map(q => ({variable_name: q.internal_code, type: q.type, values: q.values}));

  return (
    <Container>
      <h2>
        {`${formTypeValues.title} form ${createOrEdit === formTypesEnum.CREATE ? "" : formData.full_title}`}
      </h2>
      <form>
        <div style={ {display: "flex", flexDirection: "column"} }>
          <TextField
            //onChange={ (e) => console.log(e.target.value) }
            type="text"
            label="Title"
            margin="normal"
            variant='outlined'
          />
        </div>
        <DragDropContext onDragEnd={ handleOnDragEnd }>
          <Droppable droppableId='questions'>
            {providedDrop => (
              <div { ...providedDrop.droppableProps } ref={ providedDrop.innerRef }>
                {questions.map((question, index) => {
                  const questionId = question.id
                  return (
                    <Draggable key={ `key-${questionId}` } draggableId={ `draggable-${questionId}` }
                      index={ index }>
                      {providedDrag => (
                        <div
                          ref={ providedDrag.innerRef } { ...providedDrag.draggableProps } { ...providedDrag.dragHandleProps }>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <h4>{`${question.title}`}</h4>
                            <Grid
                              item
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Button onClick={ () => {
                                setShowQuestionBuilder([true, questionId])
                              } }>
                                <EditIcon/>
                              </Button>
                              <Button onClick={ () => setShowDeleteModalId(questionId) }>
                                <DeleteIcon/>
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      )}
                    </Draggable>
                  )
                })}
                {providedDrop.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Button style={ {backgroundColor: AppColors.PRIMARY, margin: "10px 0px", minWidth: "20vw"} }
            color="primary"
            variant="contained" onClick={ () => setShowQuestionBuilder([true, -1]) }>
                        Add question
          </Button>
          <Button style={ {backgroundColor: AppColors.PRIMARY, margin: "10px 0px", minWidth: "20vw"} }
            color="primary"
            variant="contained" onClick={ () => {
              
            } }>
            {`${formTypeValues.submit} form`}
          </Button>
        </Grid>
      </form>

      {showQuestionBuilder[0] &&
            <QuestionBuilderUhda
              createOrEdit={ showQuestionBuilder[1] >= 0 ? "EDIT" : "CREATE" }
              questionData={ showQuestionBuilder[1] >= 0 ? questions.find(q => q.id === showQuestionBuilder[1]) : undefined }
              handleShow={ handleShowQuestionBuilder }
              handleSubmitQuestion={ handleSubmitQuestion }
              showModal={ showQuestionBuilder[0] }
              variableNames={ getAllVariableNames() }
            />
      }
      {showDeleteModalId !== null &&
            <DialogUhda
              handleShow={ show => setShowDeleteModalId(show) }
              handleConfirm={ id => handleDeleteQuestion(id) }
              id={ showDeleteModalId }
              title='Delete confirmation'
              body={
                <>
                  <h4>{`Question with ID: ${showDeleteModalId}`}</h4>
                  <p>
                            Are you sure that you want to delete this question?
                  </p>
                </>
              }
              textCancelButton='No'
              textConfirmButton='Yes'
              size='md'
            />
      }
    </Container>
  )
}

FormBuilderUhda.defaultProps = {
  createOrEdit: "CREATE"
}

FormBuilderUhda.propTypes = {
  createOrEdit: PropTypes.string,
  formData: PropTypes.object,
}

export default FormBuilderUhda;
