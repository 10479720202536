import React from "react"
import {TextField} from "@material-ui/core"
import PropTypes from "prop-types";

/**
 * @component
 * Component to create textFields
 *
 * @param {object} value: value of the user will be entry
 * @param {string} name: identifier of the textfield
 * @param {function} handleChange: handle to change the values
 * @param {string} label: value to show what need to fill the user
 * @param {boolean} error: value that will be true when the component is empty
 * @param {string} helperText: string that help the user when the component is empty
 * @param {object} inputProps: style of the textfield
 * @param {boolean} isDisabled: value to disable the textield
 * @param {number} maxLength: number of maximum of text that the user can fill
 * @param {boolean} isNumeric: value to know if the textfield is numeric or not
 *
 * @constructor
 * <TextfieldUhda  isDisabled={true} name='form' handleChange={handleInputChange} label={'Form'} value={values.form} />
 *
 */
const TextfieldUhda = ({
  value,
  name,
  handleChange,
  label,
  error = false,
  helperText,
  inputProps,
  isDisabled,
  maxLength,
  isNumeric,
  className,
    shrink
}) => {
  return (
    <TextField
    className= {className}
      name={ name }
      onChange={ handleChange }
      type={ isNumeric ? "number" : "text" }
      label={ label }
      defaultValue={ value }
      variant='outlined'
      error={ error }
      margin="normal"
      helperText={ helperText }
      InputProps={ inputProps }
      disabled={ isDisabled }
    InputLabelProps={{
      shrink: shrink,
    }}
      inputProps={ { className: className, maxLength: maxLength ? maxLength : null} }
    />
  )
}

TextfieldUhda.propTypes = {
  value: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxLength: PropTypes.number,
  isNumeric: PropTypes.bool
}

export default TextfieldUhda;
