/* eslint-disable no-unused-vars */
import React, {useState} from "react"
import PropTypes from "prop-types"
import {AppColors} from "../../resources/AppColors"
import DialogUhda from "../DialogUhda"
import {CommonsTexts} from "../../locale/en"
import {FormControl, Grid, InputAdornment} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles";
import QuestionsService from "../../services/QuestionsService";
import ErrorIcon from "@material-ui/icons/Error";
import TextfieldUhda from "../TextfieldUhda/TextfieldUhda";

const useStyles = makeStyles((theme) => ({
  title: {
    font: "Roboto",
    color: AppColors.PRIMARY
  },
  textFieldLabel: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiTextField-root": {
      margin: theme.spacing(1),
      height: "25em",
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,

  }, textFieldLabelDisabled: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.SUBTEXT,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
      borderRadius: 10,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.PIRMARY_WITH_OPACITY,
    borderRadius: 10,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  select: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: AppColors.PRIMARY,
        opacity: "0.2",
        borderRadius: 10,
      },
    }, "& .MuiInputBase-root": {
      color: AppColors.PRIMARY,
    }, "& .MuiInputLabel-root": {
      color: AppColors.PRIMARY,
    },
    "&:after": {
      borderBottomColor: AppColors.PRIMARY,
    },
    "& .MuiSvgIcon-root": {
      color: AppColors.PRIMARY,
    },
    color: AppColors.PRIMARY,
    backgroundColor: AppColors.BOX_FILL,
    borderRadius: 10,
  },

}));

export default function EditSectionModal({
  initialValues,
  values,
  setValues,
  showEditSection,
  setShowEditSection,
  formId,
  studyId,
  sectionsLength,
  setShowSnackbarAddSection,
  getSections,
  setLoading,
  sectionEdit,
  sectionTitle,
  setShowDeleteModalConfirm,
  summaryEdit,
  isSummary,
  getSummary
}) {

  const [showError, setShowError] = useState(false)
  const [typeData, setTypeData] = useState()
  const classes = useStyles();
  const [form, setForm] = useState()
  const [section, setSection] = useState();
  const [sectionName, setSectionName] = useState(sectionTitle);
  const handleInputChange = async (e) => {
    //TODO:: variableName has to be unic
    const {name, value} = e.target;
    setSectionName(value)
  };
  const handleClickSubmit = async () => {
    
    try {
      const sectionBody = {
        id: sectionEdit.id,
        order: sectionEdit.order,
        internalCode: `${sectionName}`,
        sectionTranslation: [
          {
            locale: "en",
            title: sectionName,
          },
          {
            locale: "es",
            title: sectionName,
          }
        ],
      };
     
      setLoading(true);
      const response = await QuestionsService.putSection(studyId, formId, sectionBody);
      setSection(response.data.data.item)
      getSections();

      setShowEditSection(-999);
      setShowSnackbarAddSection(true)

    } catch (err) {
     
      setSection(err.message)
    }
  }

  const handleClickSubmitSummary = async () => {
    try {
      const summaryBody = {
        id: sectionEdit.id,
        order: sectionEdit.order,
        internalCode: `${sectionName}`,
        summaryTranslation: [
          {
            locale: "en",
            title: sectionName,
          },
          {
            locale: "es",
            title: sectionName,
          }
        ],
      };
      setLoading(true);
      const response = await QuestionsService.putSummary(studyId, formId, summaryBody);
      //setSection(response.data.data.item)
      getSummary();

      setShowEditSection(-999);
      setShowSnackbarAddSection(true)

    } catch (err) {
    
      setSection(err.message)
    }
  }

  const handleDelete = async () => {
    setShowDeleteModalConfirm(sectionEdit.id)
    setShowEditSection(-999)
  }
  return (
    <DialogUhda
      buttonColor={ AppColors.PRIMARY }
      size={ false }
      handleCloseButton={ () => {
        setShowEditSection(-999);

      } }
      closeButton={ true }
      textCancelButton={ CommonsTexts.CANCEL }
      textConfirmButton={ CommonsTexts.SAVE }
      textDeleteButton={ CommonsTexts.DELETE }
      handleShow={ setShowEditSection }
      cancelButton={ true }
      deleteButton={ !isSummary }
      handleConfirm={ isSummary ? handleClickSubmitSummary : handleClickSubmit }
      handleDelete={ handleDelete }
      title={ CommonsTexts.EDIT_SECTION }

      body={
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"

        >
          <FormControl margin="normal" style={ {width: "100%"} }>

            <TextfieldUhda
              name="variable_name"
              handleChange={ handleInputChange }
              label={ "Section name" }
              value={ sectionName }
              maxLength={ 20 }
              error={ sectionName === "" && showError }
              helperText={
                showError &&
                                sectionName === "" &&
                                "Make sure to fill the variable name"
              }
              inputProps={ {
                endAdornment: showError &&
                                    sectionName === "" && (
                  <InputAdornment position="end">
                    <ErrorIcon style={ {color: AppColors.RED} }/>
                  </InputAdornment>
                ),
              } }
            />
          </FormControl>

        </ Grid>
      }
      show={ showEditSection }
    />
  )
}
EditSectionModal.propTypes = {
  formId: PropTypes.string.isRequired,
  studyId: PropTypes.string.isRequired,
}
